import { useCallback, useState } from 'react';

import { Box, Avatar } from '@mui/material';
import cn from 'classnames';
import ImageDialog from 'components/shared/ImageDialog';
import SvgIcon from 'components/shared/utils/SvgIcon';
import { B3 } from 'components/Typography/Body';
import { documentsDataSelector } from 'modules/Application/store/selectors';
import { Document, Page } from 'react-pdf';
import { useSelector } from 'react-redux';

import styles from './AttachDocumentsDetails.module.scss';

const AttachDocumentDetails = () => {
  const documentsData = useSelector(documentsDataSelector);
  const [openFullScreen, setOpenFullScreen] = useState(false);
  const [screenImage, setScreenImage] = useState('');

  const customizeFileName = useCallback(
    (file: File | string) => {
      return (
        (typeof file === 'string' && file.toLowerCase().endsWith('.pdf')) ||
        (file instanceof File && !file.type.includes('image'))
      );
    },
    [documentsData]
  );

  const handleOpenFullScreen = (el: string) => {
    setScreenImage(el);
    setOpenFullScreen(true);
  };

  const handleCloseFullScreen = () => {
    setOpenFullScreen(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.documents}>
        <B3 className={styles.title}>
          <SvgIcon icon={'applications'} className={styles.profile} />
          Photo ID:
        </B3>
        <Box className={styles.fileBox}>
          {documentsData?.IDPhoto?.map((el: any, index: number) => (
            <Avatar
              onClick={() => handleOpenFullScreen(el)}
              className={styles.image}
              key={el as string}
              src={`${process.env.REACT_APP_BASE_URL}/api/document?path=${el}`}
              alt={`Uploaded ${index + 1}`}
            />
          ))}
        </Box>
      </div>
      <div className={styles.documents}>
        <B3 className={styles.title}>
          <SvgIcon icon={'applications'} className={styles.profile} />
          Proof of Income:
        </B3>
        <Box className={styles.fileBox}>
          {documentsData?.proofOfIncome?.map((imageUrl: any, index: number) => (
            <div
              key={imageUrl}
              className={cn(
                {
                  [styles.image]: imageUrl.includes('.png') || imageUrl.includes('jpeg'),
                },
                {
                  [styles.document]: imageUrl.includes('.pdf'),
                }
              )}
            >
              {customizeFileName(imageUrl) ? (
                <>
                  <div className={styles.pdfDocumentContainer}>
                    <div className={styles.file}>
                      <Document
                        file={`${process.env.REACT_APP_BASE_URL}/api/document?path=${imageUrl}`}
                        onClick={() => handleOpenFullScreen(imageUrl)}
                      >
                        <Page pageNumber={1} width={230} />
                      </Document>
                    </div>
                    <div className={styles.fileBlock}></div>
                    <div className={styles.fileName}>
                      <span className={styles.docInfo}>{imageUrl}</span>
                    </div>
                  </div>
                </>
              ) : (
                <Avatar
                  onClick={() => handleOpenFullScreen(imageUrl)}
                  className={styles.image}
                  src={`${process.env.REACT_APP_BASE_URL}/api/document?path=${imageUrl}`}
                  alt={`Uploaded ${index + 1}`}
                />
              )}
            </div>
          ))}
        </Box>
      </div>
      <div className={styles.documents}>
        <B3 className={styles.title}>
          <SvgIcon icon={'applications'} className={styles.profile} />
          Other documents:
        </B3>
        <Box className={styles.fileBox}>
          {documentsData?.others.map((imageUrl: any, index: number) => (
            <div
              key={imageUrl}
              className={cn(
                {
                  [styles.image]: imageUrl.includes('.png') || imageUrl.includes('jpeg'),
                },
                {
                  [styles.document]: imageUrl.includes('.pdf'),
                }
              )}
            >
              {customizeFileName(imageUrl) ? (
                <>
                  <div className={styles.pdfDocumentContainer}>
                    <div className={styles.file}>
                      <Document
                        file={`${process.env.REACT_APP_BASE_URL}/api/document?path=${imageUrl}`}
                        onClick={() => handleOpenFullScreen(imageUrl)}
                      >
                        <Page pageNumber={1} width={230} />
                      </Document>
                    </div>
                    <div className={styles.fileBlock}></div>
                    <div className={styles.fileName}>
                      <span className={styles.docInfo}>{imageUrl}</span>
                    </div>
                  </div>
                </>
              ) : (
                <Avatar
                  onClick={() => handleOpenFullScreen(imageUrl)}
                  className={styles.image}
                  src={`${process.env.REACT_APP_BASE_URL}/api/document?path=${imageUrl}`}
                  alt={`Uploaded ${index + 1}`}
                />
              )}
            </div>
          ))}
        </Box>
      </div>
      {openFullScreen &&
        screenImage &&
        (customizeFileName(screenImage) ? (
          <ImageDialog
            imageUrl={`${process.env.REACT_APP_BASE_URL}/api/document?path=${screenImage}`}
            onClose={handleCloseFullScreen}
            pdf={true}
          />
        ) : (
          <ImageDialog
            imageUrl={`${process.env.REACT_APP_BASE_URL}/api/document?path=${screenImage}`}
            onClose={handleCloseFullScreen}
          />
        ))}
    </div>
  );
};

export default AttachDocumentDetails;
