import React, { useEffect } from 'react';

import { B1, B2, B3 } from 'components/Typography/Body';
import { H3 } from 'components/Typography/Headlines';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import styles from './ChooseProperty.module.scss';
import Button from '../../../components/shared/Button';
import Loading from '../../../components/shared/Loading';
import Pagination from '../../../components/shared/Pagination/Pagination';
import PropertyCard from '../../../components/shared/PropertyCard';
import { routes } from '../../../constants/routes';
import { UStatus } from '../../../constants/userStatus';
import { userStatusSelector } from '../../Auth/store/selectors';
import { getPropertiesAction } from '../../Properties/store/actions';
import propertiesSlice from '../../Properties/store/propertiesSlice';
import {
  countSelector,
  propertiesListSelector,
  propertyLoadingSelector,
  propertyQueryOptionsSelector,
} from '../../Properties/store/selectors';

const ChooseProperty = () => {
  const { page, size } = useSelector(propertyQueryOptionsSelector);
  const dispatch = useDispatch();
  const count = useSelector(countSelector);
  const status = useSelector(userStatusSelector);
  const propertyList = useSelector(propertiesListSelector);
  const navigate = useNavigate();

  const propertyLoading = useSelector(propertyLoadingSelector);

  useEffect(() => {
    dispatch(getPropertiesAction({ page, size }));
  }, [page, size]);
  return (
    <div className={styles.chooseProperty}>
      <H3>Choose Property</H3>
      <B3 className={styles.desc}>
        Please choose the property or specific unit for which you wish to generate a lease agreement.
      </B3>
      {!!propertyList?.length &&
        propertyList.map(property => <PropertyCard key={property._id} hideActions property={property} open isForLease />)}
      <Pagination
        page={page}
        pageCount={count}
        gotoPage={page => dispatch(propertiesSlice.actions.setQueryOptions({ page, size }))}
        setPageSize={size => dispatch(propertiesSlice.actions.setQueryOptions({ page: 1, size }))}
        pageSize={size}
        className={styles.paggination}
      />
      {propertyLoading && <Loading isBig />}
      {!count && !propertyLoading && (
        <div className={styles.notFound}>
          <B1 bold>No Property found</B1>
          <B2 className={styles.desc}>You have not created any Property yet</B2>
          <Button
            disabled={status === UStatus.REGISTERED}
            variant="contained"
            label="Create Properties"
            size="sm"
            onClick={() => navigate(routes.createProperty)}
          />
        </div>
      )}
    </div>
  );
};

export default ChooseProperty;
