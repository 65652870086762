import { type ReactNode } from 'react';

import cn from 'classnames';

import styles from './Target.module.scss';

export interface TargetProps {
  children: ReactNode;
  className?: string;
}

const Target = ({ children, className }: TargetProps) => {
  return <div className={cn(styles.target, className)}>{children}</div>;
};

export default Target;
