import { createSlice } from '@reduxjs/toolkit';

import { type CreatePropertiesState } from './types';

const initialState: CreatePropertiesState = {
  isLoading: false,
  amenitiesItems: [],
  amenitiesOption: [],
  featuresItems: [],
  featuresOption: [],
  utilityItems: [],
  utilityOption: [],
};

const createPropertiesSlice = createSlice({
  name: 'createProperties',
  initialState,
  reducers: {
    setLoading: (state: CreatePropertiesState, { payload }) => {
      state.isLoading = payload;
    },
    clearItems: (state: CreatePropertiesState, { payload }) => {
      if (payload === 'amenity') {
        state.amenitiesOption = [];
      }
      if (payload === 'feature') {
        state.featuresOption = [];
      }
      if (payload === 'utility') {
        state.utilityOption = [];
      }
    },
    setItems: (state: CreatePropertiesState, { payload }) => {
      if (payload.query.name === 'amenity' && payload.query.type === 'REGULAR') {
        state.amenitiesItems = payload.data;
      }
      if (payload.query.name === 'amenity' && payload.query.type === 'EXTRA') {
        state.amenitiesOption = payload.data;
      }
      if (payload.query.name === 'feature' && payload.query.type === 'REGULAR') {
        state.featuresItems = payload.data;
      }
      if (payload.query.name === 'feature' && payload.query.type === 'EXTRA') {
        state.featuresOption = payload.data;
      }
      if (payload.query.name === 'utility' && payload.query.type === 'REGULAR') {
        state.utilityItems = payload.data.map((el: { _id: string }) => ({ ...el, isLendloard: false }));
      }
      if (payload.query.name === 'utility' && payload.query.type === 'EXTRA') {
        state.utilityOption = payload.data;
      }
    },
  },
});

export default createPropertiesSlice;
