import ReactCodeInput, { type ReactCodeInputProps } from 'react-code-input';

import styles from './CodeInput.module.scss';

export interface CodeInputProps {
  name: string;
  isValid: boolean;
  value: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (e: string) => void;
  disabled: boolean;
}

const CodeInput = (props: CodeInputProps) => {
  const args = {
    className: `${styles.reactCodeInput} ${props.disabled && 'disabled'}`,
    inputStyle: {
      textAlign: 'center',
      backgroundColor: 'var(--additional-light-grey-1)',
      fontFamily: 'Montserrat',
      MozAppearance: 'textfield',
      width: '58px',
      height: '58px',
      borderRadius: '8px',
      fontSize: '20px',
      fontWeight: '600',
      color: 'var(--additional-grey-1)',
      border: '1px solid var(--additional-light-grey-4)',
      marginRight: '14px',
    },
    inputStyleInvalid: {
      caretColor: 'red',
      textAlign: 'center',
      backgroundColor: 'rgba(243,36,36,0.04)',
      fontFamily: 'Montserrat',
      MozAppearance: 'textfield',
      width: '58px',
      height: '58px',
      borderRadius: '8px',
      fontSize: '20px',
      fontWeight: '600',
      color: 'var(--additional-red)',
      border: '1px solid var(--additional-red)',
    },
  } as Partial<ReactCodeInputProps>;

  return <ReactCodeInput inputMode="numeric" type="text" fields={6} {...props} {...args} />;
};

export default CodeInput;
