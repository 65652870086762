export const defaultValues = {
  firstname: '',
  middleName: '',
  lastName: '',
  email: '',
  phone: '',
  password: '',
  confirmPassword: '',
  check: false,
};
