export const routes = {
  home: '/',
  properties: '/properties',
  createProperty: '/properties/create',
  registration: '/auth/registration',
  forgotPassword: '/auth/forgot-password',
  confirmAccount: '/auth/confirm-account',
  verifyForgotPassword: '/auth/verify-forgot-password',
  verification: '/auth/verification',
  resetPassword: '/auth/reset-password',
  auth: '/auth',
  activate: '/activate',
  profile: '/profile',
  settings: '/settings',
  units: '/units',
  groups: '/groups',
  listings: '/listings',
  listingsCreate: '/listings/create',
  listingsView: '/listingsView',
  application: '/application',
  tenants: '/tenants',
  managers: '/managers',
  maintenance: '/maintenance',
  accounting: '/accounting',
  tasks: '/tasks',
  documents: '/documents',
  notifications: '/notifications',
  report: '/report',
  analytics: '/analytics',
  help: '/help',
  applicants: '/applicants',
  publicListings: '/publicListings',
  lease: '/lease',
  viewLease: '/lease/:id',
  createLease: '/lease/:id/:unit',
  leaseProperty: '/lease/property',
  paymentPage: '/payment',
  landloardPayment: '/paymentList',
  returnDeposit: '/paymentList/returnDeposit',
  tenantApplication: '/applicationList',
  selectProperty: '/maintenance/selectProperty',
  assingTask: '/tasks/assignTask',
  assingMaintenence: '/maintenance/assignMaintenence',
  maintenanceDetails: '/maintenance/maintenanceDetails',
  taskDetails: '/tasks/taskDetails',
  listingsCreateAll: '/listings/create/all',
};
