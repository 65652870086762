import { routes } from 'constants/routes';
import toast from 'react-hot-toast';
import { type ForkEffect, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import { getCategory, createHelp, uploadDocuments } from './helpService';
import helpSlice from './helpSlice';

function* getHelpItemsSaga() {
  yield put(helpSlice.actions.setLoading(true));
  try {
    const { data } = yield call(getCategory);
    yield put(helpSlice.actions.setItems({ data: data.items }));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(helpSlice.actions.setLoading(false));
  }
}

function* createHelpSaga({ payload }: ReturnType<typeof actions.createHelp>) {
  yield put(helpSlice.actions.setLoading(true));
  try {
    const { data }: { data: any } = yield call(createHelp, payload.formData);
    if (payload.formData?.others?.length) {
      yield uploadDocuments(payload.formData.others, data._id);
    }
    toast.success('Congratulations! Your information has been successfully saved.');
    payload.navigate(routes.home);
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(helpSlice.actions.setLoading(false));
  }
}

export function* watchHelpSaga(): Generator<ForkEffect> {
  yield takeEvery(actions.getHelpItems.type, getHelpItemsSaga);
  yield takeLatest(actions.createHelp.type, createHelpSaga);
}
