import styles from './Loading.module.scss';
interface LoadingProps {
  isBig?: boolean;
}

const SvgLoading = ({ isBig }: LoadingProps) => (
  <svg width={isBig ? '150px' : '50px'} height={isBig ? '150px' : '50px'} viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
    <g transform="rotate(0 50 50)">
      <rect x="47.5" y="24" rx="0" ry="0" width="5" height="12" fill="#ffffff">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.9166666666666666s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(30 50 50)">
      <rect x="47.5" y="24" rx="0" ry="0" width="5" height="12" fill="#ffffff">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.8333333333333334s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(60 50 50)">
      <rect x="47.5" y="24" rx="0" ry="0" width="5" height="12" fill="#ffffff">
        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.75s" repeatCount="indefinite"></animate>
      </rect>
    </g>
    <g transform="rotate(90 50 50)">
      <rect x="47.5" y="24" rx="0" ry="0" width="5" height="12" fill="#ffffff">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.6666666666666666s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(120 50 50)">
      <rect x="47.5" y="24" rx="0" ry="0" width="5" height="12" fill="#ffffff">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.5833333333333334s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(150 50 50)">
      <rect x="47.5" y="24" rx="0" ry="0" width="5" height="12" fill="#ffffff">
        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s" repeatCount="indefinite"></animate>
      </rect>
    </g>
    <g transform="rotate(180 50 50)">
      <rect x="47.5" y="24" rx="0" ry="0" width="5" height="12" fill="#ffffff">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.4166666666666667s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(210 50 50)">
      <rect x="47.5" y="24" rx="0" ry="0" width="5" height="12" fill="#ffffff">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.3333333333333333s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(240 50 50)">
      <rect x="47.5" y="24" rx="0" ry="0" width="5" height="12" fill="#ffffff">
        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.25s" repeatCount="indefinite"></animate>
      </rect>
    </g>
    <g transform="rotate(270 50 50)">
      <rect x="47.5" y="24" rx="0" ry="0" width="5" height="12" fill="#ffffff">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.16666666666666666s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(300 50 50)">
      <rect x="47.5" y="24" rx="0" ry="0" width="5" height="12" fill="#ffffff">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.08333333333333333s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(330 50 50)">
      <rect x="47.5" y="24" rx="0" ry="0" width="5" height="12" fill="#ffffff">
        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animate>
      </rect>
    </g>
  </svg>
);

const Loading = ({ isBig = false }: LoadingProps) => {
  return isBig ? (
    <div className={styles.loading}>
      <SvgLoading isBig={isBig} />
    </div>
  ) : (
    <SvgLoading />
  );
};

export default Loading;
