import { routes } from 'constants/routes';
import toast from 'react-hot-toast';
import { type ForkEffect, call, put, takeEvery, takeLatest, select } from 'redux-saga/effects';

import * as actions from './actions';
import { assigneManager, createMaintenenceRequestData } from './dataAdapter';
import maintenenceSlice from './maintenanceSlice';
import {
  assignManager,
  createMaintenence,
  getCategory,
  getMaintenanceDetails,
  getMaintenenceList,
  getPropertyByUnitId,
  updateMaintenanceStatus,
  uploadDocuments,
  getAssigned,
} from './maintenenceRequestService';
import { maintenenceDataSelector } from './selector';
import { type maintenanceDetails, type MaintenenceState, type propertyResDataModel } from './types';

function* getItemsSaga({ payload }: ReturnType<typeof actions.getItems>) {
  yield put(maintenenceSlice.actions.setLoading(true));
  try {
    const { data } = yield call(getCategory);
    yield put(maintenenceSlice.actions.setItems({ data: data.items, query: payload }));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(maintenenceSlice.actions.setLoading(false));
  }
}

function* getPropertyByUnitIdSaga({ payload }: ReturnType<typeof actions.getProperty>) {
  yield put(maintenenceSlice.actions.setLoading(true));
  try {
    const { data }: { data: propertyResDataModel } = yield call(getPropertyByUnitId, payload);
    yield put(maintenenceSlice.actions.setPropertyData(data));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(maintenenceSlice.actions.setLoading(false));
  }
}

function* createMaintenenceSaga({ payload }: ReturnType<typeof actions.createMaintenence>) {
  yield put(maintenenceSlice.actions.setLoading(true));
  try {
    const maintenenceCreateData: MaintenenceState = yield select(maintenenceDataSelector);
    const requestData = createMaintenenceRequestData(payload.formData, maintenenceCreateData);
    const { data }: { data: any } = yield call(createMaintenence, requestData);
    if (payload.formData?.others?.length) {
      const { docData } = yield uploadDocuments(payload.formData.others, data._id);
      yield put(maintenenceSlice.actions.setDocumentsData(docData));
    }
    yield put(maintenenceSlice.actions.setMaintenenceData(data));
    toast.success('Congratulations! Your information has been successfully saved.');
    payload.navigate(routes.maintenance);
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(maintenenceSlice.actions.setLoading(false));
  }
}

function* getMaintenenceListSaga({ payload }: ReturnType<typeof actions.getMaintenenceList>) {
  yield put(maintenenceSlice.actions.setLoading(true));
  try {
    const { data }: { data: any } = yield call(getMaintenenceList, payload);
    yield put(maintenenceSlice.actions.setMaintenenceList({ data, queryOptions: payload }));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(maintenenceSlice.actions.setLoading(false));
  }
}

function* getMaintenenceDetailsSaga({ payload }: ReturnType<typeof actions.getMaintenanceDetails>) {
  yield put(maintenenceSlice.actions.setLoading(true));
  try {
    const { data }: { data: maintenanceDetails } = yield call(getMaintenanceDetails, payload);
    yield put(maintenenceSlice.actions.setMaintenanceDetails(data));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(maintenenceSlice.actions.setLoading(false));
  }
}

function* updatetMaintenanceStatusSaga({ payload }: ReturnType<typeof actions.updatetMaintenanceStatus>) {
  yield put(maintenenceSlice.actions.setLoading(true));
  try {
    yield call(updateMaintenanceStatus, payload);
    toast.success('Congratulations! Your information has been successfully saved.');
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(maintenenceSlice.actions.setLoading(false));
  }
}

function* assignManagerSaga({ payload }: ReturnType<typeof actions.managerAssign>) {
  yield put(maintenenceSlice.actions.setLoading(true));
  try {
    const adaptedData = assigneManager(payload);
    yield call(assignManager, adaptedData);
    payload.navigate(routes.maintenance);
    toast.success('Congratulations! Your information has been successfully saved.');
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(maintenenceSlice.actions.setLoading(false));
  }
}

function* getAssignedSaga({ payload }: ReturnType<typeof actions.getAssigned>) {
  yield put(maintenenceSlice.actions.setLoading(true));
  try {
    const { data } = yield call(getAssigned, payload);
    yield put(maintenenceSlice.actions.setAssigned(data));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(maintenenceSlice.actions.setLoading(false));
  }
}

export function* watchMaintenenceSaga(): Generator<ForkEffect> {
  yield takeEvery(actions.getItems.type, getItemsSaga);
  yield takeEvery(actions.getProperty.type, getPropertyByUnitIdSaga);
  yield takeLatest(actions.createMaintenence.type, createMaintenenceSaga);
  yield takeLatest(actions.getMaintenenceList.type, getMaintenenceListSaga);
  yield takeLatest(actions.getMaintenanceDetails.type, getMaintenenceDetailsSaga);
  yield takeLatest(actions.updatetMaintenanceStatus.type, updatetMaintenanceStatusSaga);
  yield takeLatest(actions.managerAssign.type, assignManagerSaga);
  yield takeLatest(actions.getAssigned.type, getAssignedSaga);
}
