import { regexp } from 'constants/regexp';
import dayjs from 'dayjs';
import * as Yup from 'yup';

import { AplicationSteps } from '../constants';

const validateTotalDuration = (values: any) => {
  let totalYears = 0;
  const currentDate = dayjs();

  Object.keys(values).forEach(key => {
    if (key.startsWith('residedFrom#')) {
      const residedFromDate = dayjs(values[key]);
      totalYears += currentDate.diff(residedFromDate, 'years', true);
    }
  });
  return totalYears >= 2;
};

export const applicationSchema = Yup.object().when((_value, schema, form: any) => {
  const newSchema: any = {};
  const urlParams = new URLSearchParams(window.location.search);

  if (form.value && Number(form.value.step) === AplicationSteps.RESIDENCEADDRESS) {
    const originalValue = Object.keys(form.value).filter(el => el.includes('#'));

    originalValue.forEach((controlName: string) => {
      if (!controlName.includes('address1')) {
        if (controlName.includes('address') || controlName.includes('country')) {
          newSchema[controlName] = Yup.object().required('Required field');
        } else {
          newSchema[controlName] = Yup.string().required('Required field');
        }
      }
    });

    originalValue.forEach(controlName => {
      if (controlName.includes('residedFrom')) {
        newSchema[controlName] = Yup.string()
          .required('Required field')
          .test({
            name: 'total-duration',
            message: 'The total duration of residence must be at least 2 years',
            test: function (value, context) {
              const formValues = context.parent; // Access form values
              return validateTotalDuration(formValues);
            },
          });
      }
    });
  }
  return schema.shape({
    ...newSchema,
    phones: Yup.object().when(['step'], (_value, schema, form: any) => {
      const newSchema: any = {};
      if (form.key === 'phones' && form.parent.step === String(AplicationSteps.PERSONALINFORMATION)) {
        const originalValue = Object.keys(form.originalValue);
        originalValue.forEach((value: string) => {
          if (value.includes('phoneType')) {
            newSchema[value] = Yup.object().required('Required field');
          } else {
            newSchema[value] = Yup.string().required('Required field');
          }
        });
      }
      return schema.shape({
        ...newSchema,
      });
    }),
    pets: Yup.object().when(['step'], (_value, schema, form: any) => {
      const newSchema: any = {};
      if (form.parent.step === String(AplicationSteps.OCCUPANTS) && form.parent.hasPet) {
        const petsGroup = Object.keys(form.originalValue);
        petsGroup.forEach((key: string) => {
          key.includes('breed') &&
            (newSchema[key] = Yup.string()
              .required('Please enter pet Type/Breed')
              .min(2, 'Please enter min 2 characters')
              .max(25, 'Please enter max 25 characters'));
          if (form.value[key] !== '') {
            key.includes('name') &&
              (newSchema[key] = Yup.string().min(2, 'Please enter min 2 characters').max(25, 'Please enter max 25 characters'));

            key.includes('weight') && (newSchema[key] = Yup.string().max(25, 'Please enter max 25 characters'));
            key.includes('age') && (newSchema[key] = Yup.string().max(25, 'Please enter max 25 characters'));
          }
        });
      }
      return schema.shape({
        ...newSchema,
      });
    }),
    vehicles: Yup.object().when(['step'], (_value, schema, form: any) => {
      const newSchema: any = {};
      if (form.parent.step === String(AplicationSteps.OCCUPANTS) && form.parent.hasVehicle) {
        const vehiclesGroup = Object.keys(form.originalValue);
        vehiclesGroup.forEach((key: string) => {
          if (form.value[key] !== '') {
            key.includes('mark') &&
              (newSchema[key] = Yup.string().min(2, 'Please enter min 2 characters').max(25, 'Please enter max 25 characters'));
            key.includes('model') &&
              (newSchema[key] = Yup.string().min(2, 'Please enter min 2 characters').max(25, 'Please enter max 25 characters'));
            key.includes('year') &&
              (newSchema[key] = Yup.string().min(4, 'Please enter 4 characters').max(4, 'Please enter 4 characters'));
            key.includes('color') &&
              (newSchema[key] = Yup.string().min(2, 'Please enter min 2 characters').max(25, 'Please enter max 25 characters'));
          }
        });
      }
      return schema.shape({
        ...newSchema,
      });
    }),
    login: Yup.object().when(['step'], (value, schema, form: any) => {
      const isLogin = urlParams.get('auth') === 'login';
      const newSchema: any = {};
      if (isLogin && form.parent.step === String(AplicationSteps.BEFOREBEGIN) && form.value) {
        newSchema.email = Yup.string()
          .required('Please enter a valid email address')
          .matches(regexp.email, 'Please enter a valid email address');
        newSchema.password = Yup.string().required('Please enter your password');
      }
      return schema.shape({ ...newSchema });
    }),
    signUp: Yup.object().when(['step'], (value, schema, form: any) => {
      const isSignUp = urlParams.get('auth') === 'signup' || urlParams.get('auth') === 'confirm';
      const newSchema: any = {};
      if (isSignUp && form.parent.step === String(AplicationSteps.BEFOREBEGIN) && form.value) {
        newSchema.email = Yup.string()
          .required('Please enter a valid email address')
          .matches(regexp.email, 'Please enter a valid email address');
        newSchema.password = Yup.string()
          .required('Please enter your password')
          .matches(
            /^.*(?=.{8,})((?=.*[!?@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            'Min 8 characters  one uppercase, lowercase letters, a number and a symbol'
          );
        newSchema.confirmPassword = Yup.string()
          .required('Please confirm your password')
          .oneOf([Yup.ref('password'), ''], 'Password Does Not Match');
        newSchema.check = Yup.boolean().oneOf([true], 'Required field');
      }
      return schema.shape({ ...newSchema });
    }),
    firstName: Yup.string().when(['step'], {
      is: (step: string) => step === String(AplicationSteps.PERSONALINFORMATION),
      then: schema => schema.required('Required field'),
    }),
    lastName: Yup.string().when(['step'], {
      is: (step: string) => step === String(AplicationSteps.PERSONALINFORMATION),
      then: schema => schema.required('Required field'),
    }),
    SSN: Yup.string().when(['step'], {
      is: (step: string) => step === String(AplicationSteps.PERSONALINFORMATION),
      then: schema =>
        schema.required('Required field').min(9, 'Please enter max 9 characters').max(9, 'Please enter max 9 characters'),
      otherwise: schema => schema.nullable(),
    }),
    personRefFirstName: Yup.string().when(['step'], {
      is: (step: string) => step === String(AplicationSteps.RESIDENCEADDRESS),
      then: schema => schema.required('Required field'),
    }),
    personRefAddress: Yup.string().when(['step'], {
      is: (step: string) => step === String(AplicationSteps.RESIDENCEADDRESS),
      then: schema => schema.required('Required field'),
    }),
    personRefRelationship: Yup.string().when(['step'], {
      is: (step: string) => step === String(AplicationSteps.RESIDENCEADDRESS),
      then: schema => schema.required('Required field'),
    }),
    personRefPhoneNumber: Yup.string().when(['step'], {
      is: (step: string) => step === String(AplicationSteps.RESIDENCEADDRESS),
      then: schema => schema.required('Required field'),
    }),
    dateOfBirth: Yup.object().when(['step'], {
      is: (step: string) => step === String(AplicationSteps.PERSONALINFORMATION),
      then: schema => schema.required('Required field'),
      otherwise: schema => schema.nullable(),
    }),
    aboveNumber: Yup.string().when(['step'], {
      is: (step: string) => step === String(AplicationSteps.OCCUPANTS),
      then: schema => schema.required('Required field'),
    }),
    underNumber: Yup.string().when(['step'], {
      is: (step: string) => step === String(AplicationSteps.OCCUPANTS),
      then: schema => schema.required('Required field'),
    }),
    employerName: Yup.string().when(['step'], {
      is: (step: string) => step === String(AplicationSteps.YOURINCOME),
      then: schema =>
        schema
          .required('Please enter Employer Name')
          .min(2, 'Please enter min 2 characters')
          .max(25, 'Please enter max 25 characters'),
    }),
    employerPhone: Yup.string().when(['step'], {
      is: (step: string) => step === String(AplicationSteps.YOURINCOME),
      then: schema => schema.required('Please enter a valid phone number'),
    }),
    monthlyIncome: Yup.string().when(['step'], {
      is: (step: string) => step === String(AplicationSteps.YOURINCOME),
      then: schema => schema.required('Please enter a Monthly Income'),
    }),
    monthlyExpense: Yup.string().when(['step'], {
      is: (step: string) => step === String(AplicationSteps.YOURINCOME),
      then: schema => schema.required('Please enter a  Monthly expense'),
    }),
    positionHeld: Yup.string().when(['step'], {
      is: (step: string) => step === String(AplicationSteps.YOURINCOME),
      then: schema =>
        schema
          .required('Please enter a  Position Held')
          .min(2, 'Please enter min 2 characters')
          .max(25, 'Please enter max 25 characters'),
    }),
    yearsWorked: Yup.string().when(['step'], {
      is: (step: string) => step === String(AplicationSteps.YOURINCOME),
      then: schema => schema.required('Please enter a  Years Worked'),
    }),
    IDPhoto: Yup.array().when(['step'], {
      is: (step: string) => step === String(AplicationSteps.ATTACHDOCUMENTS),
      then: schema => schema.required('Required field'),
      otherwise: schema => schema.nullable(),
    }),
    proofOfIncome: Yup.array().when(['step', 'plaidVerified'], {
      is: (step: string, plaidVerified: boolean) => step === String(AplicationSteps.ATTACHDOCUMENTS) && !plaidVerified,
      then: schema => schema.required('Required field'),
      otherwise: schema => schema.nullable(),
    }),
  });
});
