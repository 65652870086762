import React, { useCallback } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Modal } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import styles from './AddUserModal.module.scss';
import { defaultValues } from './constants';
import { type IForm } from './types';
import { addPropertySchema } from './validations/addPropertySchema';
import Button from '../../components/shared/Button';
import { Input } from '../../components/shared/FormElements';
import PhoneInput from '../../components/shared/FormElements/PhoneInput';
import SvgIcon from '../../components/shared/utils/SvgIcon';
import { H5 } from '../../components/Typography/Headlines';
import modalNames from '../../constants/modalNames';
import { type RootState } from '../../store/setupStore';
import { modalsSlice } from '../index';
import { checkModalVisibility, getModalByName } from '../store/selectors';
const AddUserModal = () => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IForm>({ defaultValues, resolver: yupResolver(addPropertySchema) });
  const dispatch = useDispatch();
  const isModalVisible = useSelector((store: RootState) => checkModalVisibility(store, modalNames.ADD_USER_MODAL));
  const modalData: any = useSelector((store: RootState) => getModalByName(store, modalNames.ADD_USER_MODAL));

  const handleModalClose = useCallback(() => {
    reset();
    dispatch(modalsSlice.actions.hideModal(modalNames.ADD_USER_MODAL));
  }, [dispatch]);

  const onSubmit = (data: IForm) => {
    if (modalData?.addUser) {
      modalData.addUser(data);
    }
    handleModalClose();
  };

  return (
    <Modal open={isModalVisible} onClose={handleModalClose} className={styles.modal}>
      <div className={styles.addRentalOwnerModal}>
        <header className={styles.header}>
          <H5 bold>{modalData?.title}</H5>
          <SvgIcon icon="close" className={styles.close} onClick={handleModalClose} />
        </header>
        <form className={styles.content}>
          <Controller
            name="fullName"
            control={control}
            render={({ field }) => (
              <Input
                label="Name*"
                errorMessage={errors.fullName?.message}
                hasError={!!errors.fullName}
                placeholder="Enter Name"
                {...field}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                label="Email*"
                placeholder="Enter Email address"
                errorMessage={errors.email?.message}
                hasError={!!errors.email}
                {...field}
              />
            )}
          />
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <PhoneInput
                label="Phone number*"
                placeholder="Enter Phone number"
                errorMessage={errors.phone?.message}
                hasError={!!errors.phone}
                {...field}
              />
            )}
          />
          <Box className={styles.buttons}>
            <Button variant="outlined" size="ds" type="button" label="Cancel" onClick={handleModalClose} />
            <Button variant="contained" size="ds" type="button" label="Invite" onClick={handleSubmit(onSubmit)} />
          </Box>
        </form>
      </div>
    </Modal>
  );
};

export default AddUserModal;
