import { type RootState } from 'store/setupStore';

export const paymentLoadingSelector = (state: RootState) => state.tenantPaymentSlice.isLoading;
export const newPaymentListSelector = (state: RootState) => state.tenantPaymentSlice.newPaymentList;
export const paymentStoryListSelector = (state: RootState) => state.tenantPaymentSlice.paymentStoryList;
export const singlePaymentSelector = (state: RootState) => state.tenantPaymentSlice.singlePayment;
export const queryOptionsSelector = (state: RootState) => state.tenantPaymentSlice.queryOptions;
export const paymentMethodsSelector = (state: RootState) => state.tenantPaymentSlice.paymentMethods;
export const depositSelector = (state: RootState) => state.tenantPaymentSlice.deposit;
export const dashboardPayments = (state: RootState) => state.tenantPaymentSlice.dashboardPayments;
