import request from '../../../services/request';
import { type IGetAccountingQuery } from '../store/types';
const END_POINTS = {
  getAccounting: 'api/payment/accounting',
};

export function getAccountingService({ startDate, endDate, propertyId }: IGetAccountingQuery) {
  return request({
    url:
      `${END_POINTS.getAccounting}?propertyId=${propertyId}` +
      (startDate ? `&startDate=${startDate}` : '') +
      (endDate ? `&endDate=${endDate}` : ''),
  });
}
