import { useCallback, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { getRandomId, unregisterFormById } from 'common/utils/utils';
import Button from 'components/shared/Button/Button';
import { Input } from 'components/shared/FormElements';
import PhoneInput from 'components/shared/FormElements/PhoneInput';
import SvgIcon from 'components/shared/utils/SvgIcon';
import { B3 } from 'components/Typography/Body';
import { H4, H5 } from 'components/Typography/Headlines';
import { regexp } from 'constants/regexp';
import { getApplicationById } from 'modules/Application/store/actions';
import { yourIncomeDataSelector } from 'modules/Application/store/selectors';
import { type IForm } from 'modules/Application/types';
import { type additionalIncome } from 'modules/types/applicationTypes';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import styles from './TenantIncome.module.scss';
import { CurrencyOptions } from '../constants';

const TenantIncome = () => {
  const [incomeGroupIdList, setIncomeGroupIdList] = useState<{ id: string }[]>([]);
  const dispatch = useDispatch();
  const yourIncomeData = useSelector(yourIncomeDataSelector);

  const {
    control,
    unregister,
    setValue,
    formState: { errors },
  } = useFormContext<IForm>();
  const addIncomeGroup = useCallback(() => {
    const newIncomeGroup = [...incomeGroupIdList, { id: getRandomId() }];
    setIncomeGroupIdList(newIncomeGroup);
  }, [incomeGroupIdList]);

  const deleteincomeGroupId = useCallback(
    (id: string) => {
      unregisterFormById([`additionalIncome.sourceName${id}`, `additionalIncome.monthlyIncom${id}`], unregister);
      const newIncomeGroupId = incomeGroupIdList.filter(income => income.id !== id);
      setIncomeGroupIdList(newIncomeGroupId);
    },
    [incomeGroupIdList, unregister]
  );

  useEffect(() => {
    const userId = localStorage.getItem('id');
    if (userId) {
      dispatch(getApplicationById(userId));
    }
  }, []);

  useEffect(() => {
    if (yourIncomeData) {
      const [mainIncomeGroup, ...aditionalIncomeGroup] = yourIncomeData.additionalIncome || [];
      setValue('additionalIncome.monthlyIncome#', mainIncomeGroup?.monthlyIncome);
      setValue('additionalIncome.sourceName#', mainIncomeGroup?.sourceName);
      const newIncomeGroup: { id: string }[] = [];
      if (aditionalIncomeGroup) {
        aditionalIncomeGroup.forEach((income: additionalIncome) => {
          const id = getRandomId();
          setValue(`additionalIncome.monthlyIncome#${id}`, income.monthlyIncome);
          setValue(`additionalIncome.sourceName#${id}`, income.sourceName);
          newIncomeGroup.push({ id });
        });
      }
      setIncomeGroupIdList(newIncomeGroup);
    }
    setValue('employerName', yourIncomeData?.employerName);
    setValue('employerPhone', yourIncomeData?.employerPhone);
    setValue('monthlyIncome', yourIncomeData?.monthlyIncome);
    setValue('monthlyExpense', yourIncomeData?.monthlyExpense);
    setValue('positionHeld', yourIncomeData?.positionHeld);
    setValue('yearsWorked', yourIncomeData?.yearsWorked);
  }, [yourIncomeData]);

  return (
    <div className={styles.container}>
      <H4 className={styles.title} bold>
        Rental Application
      </H4>
      <B3 className={styles.desc}>Evolve Realty & Development</B3>
      <H5 className={styles.incomeTitle}>Your income</H5>
      <Box className={styles.inputBox}>
        <Box className={styles.grid}>
          <Controller
            name="employerName"
            control={control}
            render={({ field }) => (
              <Input
                label="Employer name*"
                errorMessage={errors.employerName?.message}
                hasError={!!errors.employerName}
                placeholder="Enter Employer name"
                {...field}
              />
            )}
          />
          <Controller
            name="employerPhone"
            control={control}
            render={({ field }) => (
              <PhoneInput
                label="Employer Phone Number*"
                placeholder="Enter Employer Phone Number"
                errorMessage={errors.employerPhone?.message}
                hasError={!!errors.employerPhone}
                {...field}
              />
            )}
          />
        </Box>
        <Box className={styles.grid}>
          <Controller
            name="monthlyIncome"
            control={control}
            render={({ field }) => (
              <Input
                label="Monthly Income*"
                options={CurrencyOptions}
                selectedOption={CurrencyOptions[0]}
                regexp={regexp.number}
                errorMessage={errors.monthlyIncome?.message}
                hasError={!!errors.monthlyIncome}
                placeholder="Enter Your Income"
                {...field}
              />
            )}
          />
          <Controller
            name="monthlyExpense"
            control={control}
            render={({ field }) => (
              <Input
                label="Monthly expense*"
                placeholder="Enter Your Monthly expense"
                errorMessage={errors.monthlyExpense?.message}
                hasError={!!errors.monthlyExpense}
                regexp={regexp.number}
                {...field}
              />
            )}
          />
        </Box>
        <Box className={styles.grid}>
          <Controller
            name="positionHeld"
            control={control}
            render={({ field }) => (
              <Input
                label="Position Held*"
                errorMessage={errors.positionHeld?.message}
                hasError={!!errors.positionHeld}
                placeholder="Enter Position Held"
                {...field}
              />
            )}
          />
          <Controller
            name="yearsWorked"
            control={control}
            render={({ field }) => (
              <Input
                label="Years Worked*"
                placeholder="Choose Years Worked"
                regexp={regexp.number}
                errorMessage={errors.yearsWorked?.message}
                hasError={!!errors.yearsWorked}
                {...field}
              />
            )}
          />
        </Box>
        <H5>Additional Income</H5>
        <Box className={styles.grid}>
          <Controller
            name="additionalIncome.monthlyIncome#"
            control={control}
            render={({ field }) => (
              <Input
                label="Income"
                options={CurrencyOptions}
                regexp={regexp.number}
                errorMessage={errors?.additionalIncome ? errors.additionalIncome['monthlyIncome#']?.message : ''}
                hasError={errors?.additionalIncome && !!errors?.additionalIncome['monthlyIncome#']}
                placeholder="Enter Your Income"
                {...field}
              />
            )}
          />
          <Controller
            name="additionalIncome.sourceName#"
            control={control}
            render={({ field }) => (
              <Input
                label="Source"
                placeholder="Enter The Source"
                errorMessage={errors?.additionalIncome ? errors.additionalIncome['sourceName#']?.message : ''}
                hasError={errors?.additionalIncome && !!errors?.additionalIncome['sourceName#']}
                {...field}
              />
            )}
          />
        </Box>
        {!!incomeGroupIdList.length && (
          <div className={styles.previousIncome}>
            {incomeGroupIdList.map(({ id }) => (
              <div className={styles.incomeWrap} key={id}>
                <div className={styles.closeBox}>
                  <SvgIcon icon={'close'} className={styles.closeIcon} onClick={() => deleteincomeGroupId(id)} />
                </div>
                <Box className={styles.grid}>
                  <Controller
                    name={`additionalIncome.monthlyIncom#${id}`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        label="Income"
                        options={CurrencyOptions}
                        regexp={regexp.number}
                        selectedOption={CurrencyOptions[0]}
                        errorMessage={errors?.additionalIncome ? errors.additionalIncome[`monthlyIncom#${id}`]?.message : ''}
                        placeholder="Enter Your Income"
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name={`additionalIncome.sourceName#${id}`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        label="Source"
                        placeholder="Enter The Source"
                        errorMessage={errors?.additionalIncome ? errors.additionalIncome[`sourceName#${id}`]?.message : ''}
                        hasError={errors?.additionalIncome && !!errors?.additionalIncome[`sourceName#${id}`]}
                        {...field}
                      />
                    )}
                  />
                </Box>
              </div>
            ))}
          </div>
        )}
        <Button
          className={styles.addIncomebtn}
          disabled={incomeGroupIdList.length === 5}
          type={'button'}
          variant="outlined"
          size="full-lg"
          label="+ Add Additional  Income Source"
          onClick={addIncomeGroup}
        />
      </Box>
    </div>
  );
};

export default TenantIncome;
