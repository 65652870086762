import { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Button from 'components/shared/Button';
import * as process from 'process';
import { useNavigate } from 'react-router-dom';

import CheckoutForm from './CheckoutForm/CheckoutForm';
import styles from './Payments.module.scss';
interface PaymentsProps {
  returnUrl: string;
  id?: string;
  secret?: string;
  hasSecret?: boolean;
  shouldNotify?: boolean;
  notifyCallback?: () => void;
}

const Payments = ({ returnUrl, id, secret = '', hasSecret, notifyCallback, shouldNotify = false }: PaymentsProps) => {
  const [clientSecret, setClientSecret] = useState('');
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_API_KEY as string);
  const navigate = useNavigate();

  useEffect(() => {
    if (!hasSecret) {
      fetch(`${process.env.REACT_APP_BASE_URL}/api/application/${id}/create-payment`, {
        method: 'POST',
        body: JSON.stringify({}),
        headers: {
          'x-access-token': localStorage.getItem('token') || '',
        },
      }).then(async result => {
        const res = await result.json();
        setClientSecret(res?.clientSecret);
      });
    }
  }, []);
  if (!clientSecret && !secret) {
    return (
      <Box className={styles.continueBtn}>
        <Button
          color={'primary-blue'}
          variant="contained"
          size="full-lg"
          type="submit"
          label={'Continue'}
          onClick={() => navigate(returnUrl)}
        />
      </Box>
    );
  }

  return (
    <Elements stripe={stripePromise} options={{ clientSecret: secret ? secret : clientSecret }}>
      <CheckoutForm returnUrl={returnUrl} shouldNotify={shouldNotify} notifyCallback={notifyCallback} />
    </Elements>
  );
};

export default Payments;
