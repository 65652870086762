import { type FunctionComponent, type HTMLAttributes, memo, useContext } from 'react';

import { Box } from '@mui/material';
import cx from 'classnames';
import { useNavigate } from 'react-router-dom';

import styles from './NotificationSection.module.scss';
import SvgIcon from '../../components/shared/utils/SvgIcon';
import { B3 } from '../../components/Typography/Body';
import { SidebarContext } from '../SideBar';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  notificationCount?: number;
  isActive?: boolean;
  linkTo: string;
}

const NotificationSection: FunctionComponent<Props> = ({
  className,
  notificationCount,
  isActive = false,
  linkTo,
  ...restProps
}) => {
  const { isCollapsed } = useContext(SidebarContext);
  const navigate = useNavigate();

  return (
    <Box
      {...restProps}
      onClick={() => navigate(linkTo)}
      className={cx(
        styles.notificationItem,
        {
          [styles.collapsedWrap as string]: isCollapsed,
          [styles.active as string]: isActive,
          [styles.withRtl as string]: false,
        },
        className
      )}
    >
      <div className={styles.content}>
        <SvgIcon
          icon="barNotification"
          className={cx(styles.notificationIcon, { [styles.collapsedIcon as string]: !isCollapsed })}
        />
        {!isCollapsed && (
          <B3 bold className={cx(styles.title, { [styles.activeTitle as string]: isActive })}>
            Notifications
          </B3>
        )}
      </div>
      {!!notificationCount && (
        <p className={cx(styles.notificationCount, { [styles.collapsedCount as string]: isCollapsed })}>{notificationCount}</p>
      )}
    </Box>
  );
};

export default Object.assign(memo(NotificationSection), { displayName: 'NotificationSection' });
