import React from 'react';

import { Box } from '@mui/material';
import { Input } from 'components/shared/FormElements';
import Autocomplete from 'components/shared/FormElements/Autocomplete/Autocomplete';
import GoogleAddressBox from 'components/shared/GoogleAddressBox';
import { B3 } from 'components/Typography/Body';
import { H5 } from 'components/Typography/Headlines';
import { Controller, type UseFormReturn, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import styles from './PropertyDetails.module.scss';
import { findKeyByValue } from '../../../../common/utils/utils';
import ImageUploader from '../../../../components/shared/ImageUploader';
import { deleteImagePropertyAction } from '../../store/actions';
import { propertySelector } from '../../store/selectors';
import { propertyAddressName, propertyTypeOptions } from '../constants';
import { type IForm } from '../types';
export interface PropertyDetailsProps {
  form: UseFormReturn<IForm>;
}
const PropertyDetails = ({ form }: PropertyDetailsProps) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = form;
  const dispatch = useDispatch();
  const imageUrls = useWatch({ control, name: 'imageUrls' });
  const imageCover = useWatch({ control, name: 'imageCover' });
  const property = useSelector(propertySelector);

  const handleDeleteImage = (value: string) => {
    if (property?.gallery) {
      const image = findKeyByValue(property.gallery, value);
      const keys = Object.getOwnPropertyNames(image);
      const key = keys[0];
      dispatch(deleteImagePropertyAction({ key, path: image ? image[key] : '', propertyId: property._id }));
    }
  };

  return (
    <div className={styles.propertyDetails}>
      <H5>Property details</H5>
      <B3 className={styles.desc}>Add information about your property</B3>
      <Box className={styles.inputBox}>
        <Controller
          name="propertyName"
          control={control}
          render={({ field }) => (
            <Input
              label={'Property Name*'}
              className="full-size"
              type="text"
              errorMessage={errors.propertyName?.message}
              hasError={!!errors.propertyName}
              {...field}
            />
          )}
        />
        <Controller
          name={'propertyType'}
          control={control}
          render={({ field }) => (
            <Autocomplete
              errorMessage={errors.propertyType?.message}
              hasError={!!errors.propertyType}
              label={'Property Type*'}
              options={propertyTypeOptions}
              {...field}
            />
          )}
        />
        <GoogleAddressBox key={2} form={form} names={propertyAddressName} />
      </Box>
      <Box className={styles.imageCoverBox}>
        <H5>Upload Gallery</H5>
        <B3 className={styles.desc}>Add your cover photo</B3>
        <ImageUploader
          isBig
          maxSize={2000000}
          imageUrls={imageCover}
          fileName="cover"
          setImageUrls={value => setValue('imageCover', value)}
          deleteImage={handleDeleteImage}
        />
      </Box>
      <Box className={styles.imageUrlsBox}>
        <H5>More photos</H5>
        <B3 className={styles.desc}>Add up to 20 high quality photos of your property</B3>
        <ImageUploader
          maxSize={2000000}
          multiple
          limit={20}
          imageUrls={imageUrls}
          setImageUrls={value => setValue('imageUrls', value)}
          deleteImage={handleDeleteImage}
        />
      </Box>
    </div>
  );
};

export default PropertyDetails;
