import * as React from 'react';

import Highcharts, { type Options } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import styles from './Chart.module.scss';
interface ChartProps {
  type: any;
  data: any;
  text: string;
  colors?: string[];
  categories?: string[];
  legend?: boolean;
  legendTitle?: string;
  layout?: any;
  showDataLabels?: boolean;
  itemWidth?: number | undefined;
}

const Chart: React.FC<ChartProps> = ({
  type,
  data,
  text,
  colors,
  legend = true,
  legendTitle = '',
  layout = 'vertical',
  showDataLabels = true,
  itemWidth,
}: ChartProps) => {
  const chartOptions: Options = {
    colors: colors,
    chart: {
      type: type,
    },
    xAxis: {
      type: 'category',
      height: 100,
      top: 100,
      title: {
        text: null,
      },
      gridLineWidth: 1,
      lineWidth: 0,
    },
    title: {
      text: text,
    },
    legend: {
      enabled: legend,
      labelFormat: '{y} {name}',
      layout: layout,
      title: {
        text: legendTitle ? legendTitle : '',
      },
      width: 240,
      itemWidth: itemWidth || undefined,
      useHTML: true,
    },
    plotOptions: {
      pie: {
        showInLegend: true,
        dataLabels: {
          enabled: showDataLabels,
        },
      },
      bar: {
        borderRadius: '50%',
        dataLabels: {
          enabled: false,
        },
        groupPadding: 0.1,
      },
    },
    series: data,
    credits: {
      enabled: false,
    },
  };

  return (
    <div className={styles.content}>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

export default Chart;
