import request from '../../../services/request';
import {
  type ILogIn,
  type IRegistration,
  type IForgotPassword,
  type IVerifyOtp,
  type IResendOtp,
  type IResendEmail,
  type IVerifyForgotPassword,
  type IActivate,
  type IResetPassword,
} from '../store/types';

const END_POINTS = {
  logIn: 'api/account/login',
  getUser: 'api/account/me',
  create: 'api/account/sign-up',
  verifyOtp: 'api/account/verify-otp',
  confirmEmailOtp: 'api/account/confirm-email-otp',
  resendOtp: 'api/account/resend-otp',
  resendEmail: 'api/account/resend-email',
  forgotPassword: 'api/account/forgot-password',
  verifyForgotPassword: 'api/account/forgot-password-verify',
  resetPassword: 'api/account/reset-password',
  activateEmail: 'api/account/confirm-email',
  activationToken: 'api/account/activation-token',
  inviteSignUp: 'api/account/invite-sign-up',
  createFirebaseToken: 'api/account/create-firebase-token',
};

export function logIn(payload: ILogIn) {
  return request({
    method: 'POST',
    url: `${END_POINTS.logIn}`,
    data: { ...payload },
  });
}
export function getUser() {
  return request({
    url: `${END_POINTS.getUser}`,
  });
}
export function registration(payload: IRegistration) {
  const { email, password, phone, firstname, lastName, middleName } = payload;
  return request({
    url: `${END_POINTS.create}`,
    method: 'POST',
    data: { email, password, phone, fullName: `${firstname} ${lastName} ${middleName}` },
  });
}
export function inviteSignUp(payload: IRegistration) {
  const { email, password, phone, firstname, lastName, middleName, passwordToken } = payload;
  return request({
    url: `${END_POINTS.inviteSignUp}`,
    method: 'POST',
    data: { email, password, phone, fullName: `${firstname} ${lastName} ${middleName}`, passwordToken },
  });
}
export function verifyOtp(payload: IVerifyOtp) {
  return request({
    url: `${END_POINTS.verifyOtp}`,
    method: 'POST',
    data: { ...payload },
  });
}
export function resendOtp(payload: IResendOtp) {
  return request({
    url: `${END_POINTS.resendOtp}`,
    method: 'POST',
    data: { ...payload },
  });
}
export function confirmEmailOtp(payload: IResendOtp) {
  return request({
    url: `${END_POINTS.confirmEmailOtp}`,
    method: 'POST',
    data: { ...payload },
  });
}
export function resendEmail({ passwordToken }: IResendEmail) {
  return request({
    url: `${END_POINTS.resendEmail}`,
    headers: {
      'password-token': passwordToken,
    },
  });
}
export function forgotPassword(payload: IForgotPassword) {
  const { email } = payload;
  return request({
    url: `${END_POINTS.forgotPassword}`,
    method: 'POST',
    data: { email },
  });
}
export function verifyForgotPassword(payload: IVerifyForgotPassword) {
  return request({
    url: `${END_POINTS.verifyForgotPassword}`,
    method: 'POST',
    data: { ...payload },
  });
}
export function resetPassword(payload: IResetPassword) {
  return request({
    url: `${END_POINTS.resetPassword}`,
    method: 'POST',
    data: { ...payload },
  });
}
export function activateEmail({ token }: IActivate) {
  return request({
    url: `${END_POINTS.activateEmail}/${token}`,
  });
}
export function activationTokenService(payload: { id: string }) {
  return request({
    url: `${END_POINTS.activationToken}/${payload.id}`,
  });
}
export async function createFirebaseToken(firebaseToken: string) {
  return request({
    method: 'POST',
    url: `${END_POINTS.createFirebaseToken}`,
    data: { firebaseToken },
  });
}
