import { useCallback, useEffect, useMemo } from 'react';

import { useMediaQuery } from '@mui/material';
import Button from 'components/shared/Button';
import Loading from 'components/shared/Loading';
import StatusLine from 'components/shared/StatusLine';
import TableCard from 'components/shared/TableCard';
import { B2, B3 } from 'components/Typography/Body';
import { H3 } from 'components/Typography/Headlines';
import { routes } from 'constants/routes';
import { userSelector } from 'modules/Auth/store/selectors';
import { getLeaseAction } from 'modules/Lease/store/actions';
import leaseSlice from 'modules/Lease/store/leaseSlice';
import { leaseListSelector, leaseLoadingSelector } from 'modules/Lease/store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import styles from './Maintenance.module.scss';
import MaintenenceCard from './MaintenenceCard';
import { getMaintenenceList } from './store/actions';
import maintenenceSlice from './store/maintenanceSlice';
import { maintenenceCountSelector, maintenenceListSelector, maintenenceQueryOptionsSelector } from './store/selector';
import PrivateAccess from '../../components/PrivateAccess';
import StatusTag from '../../components/shared/StatusTag';
import { userRole } from '../../constants/userStatus';

const Maintenance = () => {
  const dispatch = useDispatch();
  const { page, size } = useSelector(maintenenceQueryOptionsSelector);
  const maintenenceList = useSelector(maintenenceListSelector);
  const leaseList = useSelector(leaseListSelector);
  const maintenenceCount = useSelector(maintenenceCountSelector);
  const loading = useSelector(leaseLoadingSelector);
  const user = useSelector(userSelector);
  const navigate = useNavigate();
  const isMobileScreen = useMediaQuery('(max-width: 600px)');
  const memoColumns = useMemo(() => {
    return [
      {
        Header: 'Title',
        id: 'title',
        title: 'Title',
        width: '140',
        Cell: ({ row }: any) => {
          return (
            <StatusLine type={row.original.status}>
              <div>
                <B3 bold>{row.original.title}</B3>
              </div>
            </StatusLine>
          );
        },
      },
      {
        Header: 'Property ',
        id: 'property ',
        title: 'Property ',
        isSorted: true,
        Cell: ({ row }: any) => {
          return (
            <B3 bold>
              {`Unit ${row.original?.unit?.unitNumber}, 
                ${row.original?.property?.propertyName} - 
                ${row.original?.property?.googleApi?.address?.value}`}
            </B3>
          );
        },
      },
      {
        Header: 'Tenant name',
        id: 'tenantName',
        title: 'Tenant name',
        Cell: ({ row }: any) => {
          return <B3 bold>{row.original?.tenant?.fullName}</B3>;
        },
      },
      {
        Header: 'Assigned manager',
        id: 'Assigned manager',
        title: 'Assigned manager',
        Cell: ({ row }: any) => {
          return <B3 bold>{row.original?.assignee?.managerId.fullName}</B3>;
        },
      },
      {
        Header: 'Priority',
        id: 'priority',
        width: '70',
        title: 'Priority',
        Cell: ({ row }: any) => {
          return <StatusTag label={row.original.priority || undefined} type={row.original.priority} />;
        },
      },
      {
        Header: 'Status',
        id: 'status',
        width: '70',
        title: 'Status',
        Cell: ({ row }: any) => {
          return <StatusTag label={row.original.status} type={row.original.status} />;
        },
      },
    ];
  }, []);

  useEffect(() => {
    return () => {
      if (!location.pathname.includes(routes.maintenance)) {
        dispatch(leaseSlice.actions.clearLeaseList());
        dispatch(maintenenceSlice.actions.clearMaintenanceList());
      }
    };
  }, []);

  useEffect(() => {
    dispatch(getLeaseAction({ page, size }));
    dispatch(getMaintenenceList({ page, size }));
  }, [page, size]);

  const chouseRedirectUrlByRole = useCallback(
    (role: string | undefined) => {
      const urlByRole: Record<userRole, string> = {
        [userRole.TENANT]:
          leaseList && leaseList.length > 0 ? `${routes.assingMaintenence}?unitId=${leaseList[0].unitId}` : routes.selectProperty,
        [userRole.LANDLOARD]: routes.selectProperty,
        [userRole.MANAGER]: routes.selectProperty,
      };
      return urlByRole[role as userRole];
    },
    [leaseList]
  );
  return (
    <div className={styles.lease}>
      <div className={styles.header}>
        <H3>Maintenance</H3>

        <PrivateAccess roles={[userRole.TENANT, userRole.LANDLOARD, userRole.MANAGER]}>
          <Button
            variant="contained"
            disabled={userRole.TENANT && (!leaseList || leaseList.length === 0)}
            size="ds"
            type="button"
            label="Create Request"
            onClick={() => navigate(chouseRedirectUrlByRole(user?.role))}
          />
        </PrivateAccess>
      </div>
      {!!maintenenceList?.length && (
        <div>
          {isMobileScreen ? (
            maintenenceList.map(maintenance => <MaintenenceCard key={maintenance._id} maintenance={maintenance} />)
          ) : (
            <TableCard
              className={styles.leaseTabel}
              columns={memoColumns}
              data={maintenenceList || []}
              defaultPageSize={size}
              totalItems={maintenenceCount}
              setPageSize={size => dispatch(getMaintenenceList({ page: 1, size }))}
              defaultPage={page}
              onRowClick={(row: { original: any }) => {
                return navigate(
                  `${routes.maintenanceDetails}?unitId=${row?.original?.unit._id}&maintenanceId=${row.original._id}`
                );
              }}
              fetchData={({ pageSize, pageIndex }) => dispatch(getMaintenenceList({ page: pageIndex, size: `${pageSize}` }))}
              hidePagination={false}
            />
          )}
        </div>
      )}
      {loading && <Loading isBig />}
      {!maintenenceList?.length && !loading && (
        <div className={styles.notFound}>
          <B2>You currently don't have Maintenance.</B2>
        </div>
      )}
    </div>
  );
};

export default Maintenance;
