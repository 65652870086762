import React, { useEffect } from 'react';

import BackButton from 'components/shared/BackButton/BackButton';
import Loading from 'components/shared/Loading';
import Pagination from 'components/shared/Pagination/Pagination';
import PropertyCard from 'components/shared/PropertyCard';
import { B1, B2 } from 'components/Typography/Body';
import { H3 } from 'components/Typography/Headlines';
import { routes } from 'constants/routes';
import { getPropertiesWithActiveLeasedUnitsAction } from 'modules/Properties/store/actions';
import propertiesSlice from 'modules/Properties/store/propertiesSlice';
import {
  countSelector,
  propertiesListSelector,
  propertyLoadingSelector,
  propertyQueryOptionsSelector,
} from 'modules/Properties/store/selectors';
import { useDispatch, useSelector } from 'react-redux';

import styles from './SelectProperty.module.scss';

const SelectProperty = () => {
  const dispatch = useDispatch();
  const propertiesList = useSelector(propertiesListSelector);
  const { page, size } = useSelector(propertyQueryOptionsSelector);
  const count = useSelector(countSelector);
  const loading = useSelector(propertyLoadingSelector);

  useEffect(() => {
    dispatch(getPropertiesWithActiveLeasedUnitsAction({ size, page }));
  }, [size, page]);

  return (
    <div className={styles.properties}>
      <div className={styles.action}>
        <BackButton to={routes.maintenance} />
        <H3>Select Property</H3>
        {/* <B3>Please choose the property or specific unit for which you wish to create a maintenance.</B3> */}
      </div>
      {loading && <Loading isBig />}
      {propertiesList &&
        !loading &&
        propertiesList.map(property => (
          <PropertyCard key={property._id} property={property} hideActions action="Select Unit" isMaintenance />
        ))}
      <Pagination
        page={page}
        pageCount={count}
        gotoPage={page => dispatch(propertiesSlice.actions.setQueryOptions({ page, size }))}
        setPageSize={size => dispatch(propertiesSlice.actions.setQueryOptions({ page: 1, size }))}
        pageSize={size}
        className={styles.paggination}
      />
      {count === 0 && !loading && (
        <div className={styles.noFound}>
          <B1 bold>No Property found</B1>
          <B2 className={styles.desc}>You have not created any Property yet</B2>
        </div>
      )}
    </div>
  );
};

export default SelectProperty;
