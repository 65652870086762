import { type FC, memo, type PropsWithChildren, type ReactElement, type ReactNode } from 'react';

import cx from 'classnames';
import { type NavLink } from 'react-router-dom';

import styles from './UserMenuItem.module.scss';
import MenuItem from '../MenuItem';
import MenuLink from '../MenuLink';

interface LinkProps {
  className: string;
  children: Props['children'];
  title: string;
  href?: string;
  to?: string;
}

export interface Props {
  title?: string;
  isOffline?: boolean;
  className?: string;
  firstName?: string;
  lastName?: string;
  children?: ReactNode | ReactElement;
  linkTo?: string;
  href?: string;
  linkComponent?: typeof NavLink | FC<PropsWithChildren<LinkProps>>;
}

const UserMenuItem: FC<Props> = ({ children, linkComponent, linkTo, href, className, ...restProps }) => {
  return (
    <MenuItem {...restProps} className={cx(styles.userMenuItem, className, { [styles.withRtl as string]: false })}>
      {({ isCollapsed }) => (
        <MenuLink
          className={styles.userMenuLink}
          linkComponent={linkComponent}
          isCollapsed={isCollapsed}
          linkTo={linkTo}
          href={href}
        >
          {/*<UserIcon firstName={firstName} lastName={lastName} isOffline={!isOnline} />*/}
          {!isCollapsed && (
            <div className={styles.details}>
              <div className={styles.label}>{children}</div>
            </div>
          )}
        </MenuLink>
      )}
    </MenuItem>
  );
};

export default memo(UserMenuItem);
