import * as Yup from 'yup';

export const addPropertySchema = Yup.object().shape({
  account_number: Yup.string()
    .required('Required field')
    .min(8, 'Please enter min 8 characters')
    .max(12, 'Please enter max 12 characters'),
  account_holder_name: Yup.string().required('Required field').trim().min(2, 'Please enter min 2 characters'),
  routing_number: Yup.string().required('Required field').min(9, 'Please enter 9 characters').max(9, 'Please enter 9 characters'),
  account_name: Yup.string().required('Required field').trim().min(2, 'Please enter min 2 characters'),
  confirmAccountNumber: Yup.string()
    .required('Please confirm your Account Number')
    .oneOf([Yup.ref('account_number'), ''], 'Account Number Do Not Match'),
});
