import React, { useCallback } from 'react';

import { Box, Modal, Typography } from '@mui/material';
import Button from 'components/shared/Button';
import SvgIcon from 'components/shared/utils/SvgIcon';
import { H5 } from 'components/Typography/Headlines';
import { profileLoadingSelector } from 'modules/Profile/store/selectors';
import { useDispatch, useSelector } from 'react-redux';

import styles from './DeleteConfirmation.module.scss';
import modalNames from '../../constants/modalNames';
import { type RootState } from '../../store/setupStore';
import { modalsSlice } from '../index';
import { checkModalVisibility, getModalByName } from '../store/selectors';

const DeleteComfirmation = ({ id, onDelete, modalText, modalTitle }: any) => {
  const dispatch = useDispatch();
  const loading = useSelector(profileLoadingSelector);
  const isModalVisible = useSelector((store: RootState) => checkModalVisibility(store, modalNames.DELETE_SMT));
  const data: any = useSelector((store: RootState) => getModalByName(store, modalNames.DELETE_SMT));

  const handleModalClose = useCallback(() => {
    dispatch(modalsSlice.actions.hideModal(modalNames.DELETE_SMT));
  }, [dispatch]);

  const handleDelete = (id: any) => {
    dispatch(onDelete(id));
  };

  if (!data) {
    return null;
  }

  return (
    <Modal onClose={handleModalClose} open={isModalVisible} className={styles.modal}>
      <Box className={styles.deleteComfirmationModal}>
        <header className={styles.header}>
          <H5 bold className={styles.title}>
            {modalTitle}
          </H5>
        </header>
        <SvgIcon icon="close" className={styles.close} onClick={handleModalClose} />
        <Typography className={styles.content}>{modalText}</Typography>
        <Box className={styles.buttons}>
          <Button
            variant="contained"
            size="ds"
            type="button"
            label="delete"
            disabled={loading}
            loading={loading}
            onClick={() => handleDelete(id)}
          />
          <Button variant="outlined" size="ds" type="button" label="Cancel" onClick={handleModalClose} />
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteComfirmation;
