import React, { useState, useEffect, useRef } from 'react';

import { Box, Grid } from '@mui/material';
import cn from 'classnames';
import { routes } from 'constants/routes';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';

import styles from './PageTopSide.module.scss';
import logo from '../../assets/images/rxLogo.png';
import { sideBarLinks } from '../../constants/sideBarLinks';
import { activeUsers } from '../../constants/userStatus';
import { userSelector } from '../../modules/Auth/store/selectors';
import Header from '../Header';
import PrivateAccess from '../PrivateAccess';
import SvgIcon from '../shared/utils/SvgIcon';
import { type ILink } from '../SideBar/types';
import { B3, B2 } from '../Typography/Body';

const InnerLink = ({ to, icon, title, onClick }: ILink) => {
  return (
    <NavLink to={to} className={({ isActive }) => cn(styles.link, { [styles.active]: isActive })} onClick={onClick}>
      <div className={styles.wrap}>
        <div className={styles.svgIcon}>
          <SvgIcon icon={icon} className={styles.icon} />
        </div>
        <B3 bold className={styles.text}>
          {title}
        </B3>
        <div className={styles.line} />
      </div>
    </NavLink>
  );
};

const PageTopSide = () => {
  const [isOpen, setIsOpen] = useState(false);
  const user = useSelector(userSelector);
  const token = localStorage.getItem('token');
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  return (
    <Grid item xs={12} className={cn(styles.topSide, { [styles.header]: isOpen })}>
      <div>
        <Box className={cn(styles.headlineBox, { [styles.unauthorisedheadlineBox]: !token })}>
          <Link to={routes.home}>
            <img src={logo} className={styles.whiteLogo} alt="RentX logo" />
          </Link>
          <div className={cn(styles.topSideText, { [styles.unauthorisedText]: !token })}>
            <B2 className={styles.title} fontFamily="Lexend, sans-serif">
              The Potential of Your Property
            </B2>
          </div>
          <div className={styles.header}>
            <Header />
            {user && activeUsers.includes(user?.status) && (
              <Box className={cn(styles.menuButton)} onClick={() => setIsOpen(!isOpen)} ref={menuRef}>
                <SvgIcon icon={isOpen ? 'close' : 'menu'} className={styles.menuIcon} />
              </Box>
            )}
          </div>
        </Box>
        <div className={cn(styles.hided, { [styles.back]: isOpen })}>
          <div className={cn(styles.block, { [styles.isOpen]: isOpen, [styles.hide]: !isOpen })}>
            {sideBarLinks.map(({ to, icon, title, roles, isPrivate }) =>
              isPrivate ? (
                <PrivateAccess key={to} roles={roles}>
                  <InnerLink to={to} icon={icon} title={title} onClick={() => setIsOpen(false)} />
                </PrivateAccess>
              ) : (
                <InnerLink key={to} to={to} icon={icon} title={title} onClick={() => setIsOpen(false)} />
              )
            )}
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default PageTopSide;
