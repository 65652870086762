import { createSlice } from '@reduxjs/toolkit';

import { type TenantsDataModel, type TenantDataModel, type getTenantsListReqModel } from './types';

const initialState: TenantsDataModel = {
  isLoading: false,
  tenants: null,
  queryOptions: {
    page: 1,
    size: '5',
    sortBy: {
      id: '',
      desc: false,
    },
  },
};

const tenantsSlice = createSlice({
  name: 'tenant',
  initialState,
  reducers: {
    setLoading: (state: TenantsDataModel, { payload }) => {
      state.isLoading = payload;
    },
    addTenantSuccess: (state: TenantsDataModel, { payload }) => {
      if (state.tenants?.items) {
        state.tenants.items = [payload, ...state.tenants.items];
      } else {
        state.tenants = { items: payload, count: '1' };
      }
    },
    setTenantData: (
      state: TenantsDataModel,
      { payload }: { payload: { data: { items: TenantDataModel[]; count: string }; queryOptions: getTenantsListReqModel } }
    ) => {
      state.tenants = payload.data;
      state.queryOptions = {
        ...state.queryOptions,
        size: payload.queryOptions.limit,
        page: payload.queryOptions.page,
      };
    },
  },
});

export default tenantsSlice;
