import { formatDate } from 'common/utils/utils';
import { DateFormat } from 'constants/dateFormat';

import { type IGetAccountingQuery } from './types';

export const accountingFilterAdapter = (data: IGetAccountingQuery) => {
  const adaptedData = {
    propertyId: data?.propertyId,
    startDate: data.startDate ? formatDate(data.startDate, DateFormat.YYYYMMDD) : data.startDate,
    endDate: data.endDate ? formatDate(data.endDate, DateFormat.YYYYMMDD) : data.startDate,
  };
  return adaptedData;
};
