import { useEffect, useMemo } from 'react';

import { useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import styles from './LandloardPayment.module.scss';
import PaymentCard from './PaymentCard';
import { formatDate } from '../../../common/utils/utils';
import PrivateAccess from '../../../components/PrivateAccess';
import Button from '../../../components/shared/Button';
import Loading from '../../../components/shared/Loading';
import StatusLine from '../../../components/shared/StatusLine';
import StatusTag from '../../../components/shared/StatusTag';
import TableCard from '../../../components/shared/TableCard';
import { B3 } from '../../../components/Typography/Body';
import { H4 } from '../../../components/Typography/Headlines';
import { routes } from '../../../constants/routes';
import { userRole } from '../../../constants/userStatus';
import { getTenantPaymentListAction } from '../store/actions';
import { paymentLoadingSelector, paymentStoryListSelector, queryOptionsSelector } from '../store/selectors';

const LandloardPayment = () => {
  const dispatch = useDispatch();
  const paymentStoryList = useSelector(paymentStoryListSelector);
  const queryOptions = useSelector(queryOptionsSelector);
  const isMobileScreen = useMediaQuery('(max-width: 600px)');
  const loading = useSelector(paymentLoadingSelector);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getTenantPaymentListAction({ status: '', page: queryOptions.page, size: queryOptions.size }));
  }, [queryOptions.page, queryOptions.size]);

  const memoColumns = useMemo(() => {
    return [
      {
        Header: 'Payer',
        id: 'landlord',
        title: 'Pay To',
        width: '110',
        Cell: ({ row }: any) => {
          return (
            <StatusLine type={row.original.status === 'PAID' || row.original.status === 'RETURNED' ? 'SIGNED' : 'REJECTED'}>
              <div>
                <B3 bold>{row.original?.tenant?.fullName}</B3>
              </div>
            </StatusLine>
          );
        },
      },
      {
        Header: 'Property name ',
        id: 'Property name ',
        title: 'Property name ',
        isSorted: true,
        Cell: ({ row }: any) => {
          return (
            <B3 bold>
              {`Unit ${row.original?.unit?.unitNumber}, 
                ${row.original?.property?.propertyName} - 
                ${row.original?.property?.googleApi?.address?.value}`}
            </B3>
          );
        },
      },
      {
        Header: 'Due Date',
        id: 'Due Date',
        width: '60',
        title: 'Due Date',
        Cell: ({ row }: any) => {
          return <B3 bold>{formatDate(row.original.dueDate)}</B3>;
        },
      },
      {
        Header: 'Category',
        id: 'Category',
        title: 'Category',
        width: '70',
        Cell: ({ row }: any) => {
          return <B3 bold>{row.original.type}</B3>;
        },
      },
      {
        Header: 'Status',
        id: 'Status',
        width: '70',
        title: 'Status',
        Cell: ({ row }: any) => {
          return <StatusTag label={row.original.status || undefined} type={row.original.status} />;
        },
      },
      {
        Header: 'Total Price',
        id: 'Total Price',
        width: '70',
        title: 'Total Price',
        Cell: ({ row }: any) => {
          return <B3 bold>{`$${row.original.amount}`}</B3>;
        },
      },
    ];
  }, []);

  return (
    <PrivateAccess roles={[userRole.LANDLOARD]} isPage>
      <div className={styles.header}>
        <H4 bold>Payment</H4>
        <Button
          variant="contained"
          size="ds"
          type="button"
          label="Return Deposit"
          onClick={() => navigate(routes.returnDeposit)}
        />
      </div>
      <div>
        {!!paymentStoryList?.items?.length && (
          <div>
            {isMobileScreen ? (
              paymentStoryList?.items.map(payment => <PaymentCard key={payment._id} account={payment} />)
            ) : (
              <TableCard
                totalItems={paymentStoryList.count}
                setPageSize={size => dispatch(getTenantPaymentListAction({ page: 1, size }))}
                defaultPageSize={queryOptions.size}
                columns={memoColumns}
                data={paymentStoryList.items}
                defaultPage={queryOptions.page}
                fetchData={({ pageSize, pageIndex }) =>
                  dispatch(getTenantPaymentListAction({ page: pageIndex, size: `${pageSize}` }))
                }
                hidePagination={false}
                onRowClick={(row: { original: { _id: string } }) => navigate(`${routes.landloardPayment}/${row?.original?._id}`)}
              />
            )}
          </div>
        )}
        {loading && <Loading isBig />}
      </div>
    </PrivateAccess>
  );
};

export default LandloardPayment;
