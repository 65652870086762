import React, { useEffect } from 'react';

import Button from 'components/shared/Button';
import Loading from 'components/shared/Loading';
import Pagination from 'components/shared/Pagination/Pagination';
import PropertyCard from 'components/shared/PropertyCard';
import { B1, B2 } from 'components/Typography/Body';
import { H3 } from 'components/Typography/Headlines';
import { routes } from 'constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import styles from './Properties.module.scss';
import { getPropertiesAction } from './store/actions';
import propertiesSlice from './store/propertiesSlice';
import { countSelector, propertiesListSelector, propertyLoadingSelector, propertyQueryOptionsSelector } from './store/selectors';
import PrivateAccess from '../../components/PrivateAccess';
import { userRole, UStatus } from '../../constants/userStatus';
import { userStatusSelector } from '../Auth/store/selectors';

const Properties = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const propertiesList = useSelector(propertiesListSelector);
  const { page, size } = useSelector(propertyQueryOptionsSelector);
  const status = useSelector(userStatusSelector);
  const count = useSelector(countSelector);
  const loading = useSelector(propertyLoadingSelector);

  useEffect(() => {
    dispatch(getPropertiesAction({ size, page }));
  }, [size, page]);

  return (
    <PrivateAccess roles={[userRole.LANDLOARD, userRole.MANAGER]} isPage>
      <div className={styles.properties}>
        <div className={styles.action}>
          <H3>Properties</H3>
          <PrivateAccess roles={[userRole.LANDLOARD]}>
            <Button
              className={styles.createPropertyBtn}
              onClick={() => navigate(routes.createProperty)}
              variant="contained"
              disabled={status === UStatus.REGISTERED}
              label="Create Properties"
              size="md"
            />
          </PrivateAccess>
        </div>
        {loading && <Loading isBig />}
        {propertiesList && !loading && propertiesList.map(property => <PropertyCard key={property._id} property={property} />)}
        <Pagination
          page={page}
          pageCount={count}
          gotoPage={page => dispatch(propertiesSlice.actions.setQueryOptions({ page, size }))}
          setPageSize={size => dispatch(propertiesSlice.actions.setQueryOptions({ page: 1, size }))}
          pageSize={size}
          className={styles.paggination}
        />
        {count === 0 && !loading && (
          <div className={styles.noFound}>
            <B1 bold>No Property found</B1>
            <B2 className={styles.desc}>You have not created any Property yet</B2>
          </div>
        )}
      </div>
    </PrivateAccess>
  );
};

export default Properties;
