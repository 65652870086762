import { Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { PageLeftSide } from './index';
import styles from '../../Auth.module.scss';

const Layout = () => {
  return (
    <Grid container direction="row" className={styles.loginPage}>
      <PageLeftSide />
      <Outlet />
    </Grid>
  );
};
export default Layout;
