import React, { useMemo, useEffect } from 'react';

import { useMediaQuery } from '@mui/material';
import { Box } from '@mui/material';
import DeleteConfirmation from 'modals/DeleteConfirmation';
import { modalsSelector } from 'modals/store/selectors';
import { deleteBankAccountAction } from 'modules/Profile/store/actions';
import { useDispatch, useSelector } from 'react-redux';

import styles from './BankAccount.module.scss';
import Button from '../../../components/shared/Button';
import Table from '../../../components/shared/Table';
import SvgIcon from '../../../components/shared/utils/SvgIcon';
import { B3 } from '../../../components/Typography/Body';
import modalNames from '../../../constants/modalNames';
import { modalsSlice } from '../../../modals';
import BankAccountCard from '../BankAccountCard';
import { getBankAccountAction } from '../store/actions';
import { profileBankAccountsSelector } from '../store/selectors';

const BankAccount = ({ bankAccountError, setBankAccountError }: any) => {
  const dispatch = useDispatch();
  const isMobileScreen = useMediaQuery('(max-width: 600px)');
  const bankAccounts = useSelector(profileBankAccountsSelector);
  const modals = useSelector(modalsSelector);
  const modalText = 'Are you sure you want to delete this Bank Account?';
  const modalTitle = 'Delete Account';

  const memoColumns = useMemo(() => {
    return [
      {
        Header: 'Account Name',
        id: 'account_name',
        title: 'Account Name',
        Cell: ({ row }: any) => {
          return <B3 bold>{row.original.account_name}</B3>;
        },
      },
      {
        Header: 'Account Holder Name',
        id: 'account_holder_name',
        title: 'Account Name',
        Cell: ({ row }: any) => {
          return <B3 bold>{row.original.account_holder_name}</B3>;
        },
      },
      {
        Header: 'Routing Number',
        id: 'routing_number',
        title: 'Routing Number',
        isSorted: true,
        width: 100,
        Cell: ({ row }: any) => {
          return <B3 bold>{row.original.routing_number}</B3>;
        },
      },
      {
        Header: 'Account Number',
        id: 'account_number',
        title: 'Account Number',
        width: 100,
        Cell: ({ row }: any) => {
          return <B3 bold>{row.original.account_number}</B3>;
        },
      },
      {
        Header: ' ',
        id: ' ',
        title: ' ',
        width: 30,
        Cell: ({ row }: any) => {
          return (
            <>
              <Box onClick={() => handleDeleteComfirmationModal(row.original._id)}>
                <SvgIcon icon={'trash'} className={styles.deleteIcon} />
              </Box>
            </>
          );
        },
      },
    ];
  }, []);

  useEffect(() => {
    dispatch(getBankAccountAction());
  }, []);

  const handleDeleteComfirmationModal = (id: string) => {
    dispatch(modalsSlice.actions.showModal({ modalName: modalNames.DELETE_SMT, data: id }));
  };

  const handleAddBankAccount = () => {
    setBankAccountError(false);
    dispatch(modalsSlice.actions.showModal({ modalName: modalNames.ADD_BANK_ACCOUNT, data: { title: 'Bank Account' } }));
  };

  return (
    <div className={styles.bankAccount}>
      <DeleteConfirmation
        id={modals.get(modalNames.DELETE_SMT)}
        onDelete={deleteBankAccountAction}
        modalText={modalText}
        modalTitle={modalTitle}
      />
      {!!bankAccounts?.length && (
        <div>
          {isMobileScreen ? (
            bankAccounts.map((bankAccount: any) => <BankAccountCard key={bankAccount._id} bankAccount={bankAccount} />)
          ) : (
            <Table className={styles.leaseTabel} columns={memoColumns} data={bankAccounts || []} totalItems={5} hidePagination />
          )}
        </div>
      )}
      <Button
        onClick={handleAddBankAccount}
        className={styles.button}
        variant={bankAccountError ? 'error' : 'outlined'}
        label="+ Add New Bank Account "
        size="md"
        type="button"
      />
    </div>
  );
};

export default BankAccount;
