export const acquaintanceDoc = {
  evolue: {
    title: 'Evolve Realty & Development',
    desc: [
      '1. Combined monthly gross income-to-rent ratio = 3x the amount of the rent.',
      '2. No more than a total of 25% past due negative accounts in the last 12 months.',
      '3. A screening score of at least 7 or a Fico score of not less than 650(depending on which site is used to run credit).',
      '4. No unpaid collections.',
      '5. No bankruptcies.',
      '6. No evictions or tenant collections.',
      '7. Three years of successful recent verifiable rental/owners history with a non-relative.',
      '8. Three years of continuous employment.',
      '9. Applicants that need a co-signer or guarantor may not qualify for the move-in special.',
      '10. Short term leases may not qualify for move-in special unless noted on the offer to rent.',
    ],
  },
  guarantors: {
    title: 'Guarantors Welcome',
    desc: [
      '1. Must make 4x the amount of rent.',
      '2. Be a homeowner with successful owner history.',
      '3 Be related.',
      // eslint-disable-next-line max-len
      '4. Must fill out separate application, show proof of ID and proof of income. (*Residing out of state applicants must notarized).',
    ],
  },
  acceptable: {
    title: 'Acceptable Documentation',
    desc: [
      '1. Government issued Identification.',
      '1. Social security card or other information suitable to run a credit report may be accepted.',
      // eslint-disable-next-line max-len
      '3. Proof of Income (May require a combination of):a) Two Current/Most Recent Paycheck Stubsb) Three Months Most Recent Bank Statementsc) Statement of Award, current year tax return or other income documentationd) Notarized letter of support from parent or guardiane) All other sources of legal, verifiable income.',
    ],
  },
};
