import { Box } from '@mui/material';
import { formatDate } from 'common/utils/utils';
import Card from 'components/shared/Card';
import StatusTag from 'components/shared/StatusTag';
import { B3 } from 'components/Typography/Body';

import styles from './PaymentPageCard.module.scss';

const PaymentPageCard = ({ payment }: any) => {
  return (
    <Card className={styles.paymentCard}>
      <Card.Content>
        <Box>
          <div className={styles.cardItem}>
            <B3 bold className={styles.cardItemtitle}>
              Pay To
            </B3>
            <p className={styles.cardItemInfo}>{payment?.landlord?.fullName}</p>
          </div>
          <div className={styles.cardItem}>
            <B3 bold className={styles.cardItemtitle}>
              Property Name
            </B3>
            <p className={styles.cardItemInfo}>
              {payment?.property?.propertyName} | {payment?.unit?.unitNumber}
            </p>
          </div>
          <div className={styles.cardItem}>
            <B3 bold className={styles.cardItemtitle}>
              Due Date
            </B3>
            <p className={styles.cardItemInfo}>{formatDate(payment?.dueDate)}</p>
          </div>
          <div className={styles.cardItem}>
            <B3 bold className={styles.cardItemtitle}>
              Category
            </B3>
            <p className={styles.cardItemInfo}>{payment?.type}</p>
          </div>
          <div className={styles.cardItem}>
            <B3 bold className={styles.cardItemtitle}>
              Status
            </B3>
            <StatusTag label={payment?.status} type={payment?.status} />
          </div>
          <div className={styles.cardItem}>
            <B3 bold className={styles.cardItemtitle}>
              Total Paid
            </B3>
            <p className={styles.cardItemInfo}>{payment?.amount}$</p>
          </div>
        </Box>
      </Card.Content>
    </Card>
  );
};

export default PaymentPageCard;
