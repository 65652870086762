import { useEffect } from 'react';

import Chart from 'components/shared/Chart';
import { useDispatch, useSelector } from 'react-redux';

import styles from './Maintenance.module.scss';
import { getDashboardMaintenanceAction } from '../../../store/actions';
import { dashboardMaintenanceSelector } from '../../../store/selectors';

interface MaintenanceProps {
  title: string;
}

const Maintenance: React.FC<MaintenanceProps> = ({ title }) => {
  const dispatch = useDispatch();
  const maintenance = useSelector(dashboardMaintenanceSelector);

  useEffect(() => {
    dispatch(getDashboardMaintenanceAction());
  }, []);

  function transformObjectToArray(obj: any) {
    const result = [];
    for (const key in obj) {
      const name = key.charAt(0).toUpperCase() + key.slice(1);
      result.push({ name: name, y: obj[key] });
    }

    return result;
  }

  const data: any = {
    content: [
      {
        minPointSize: 10,
        innerSize: '85%',
        zMin: 0,
        name: 'Maintenance',
        borderRadius: 10,
        data: transformObjectToArray(maintenance),
      },
    ],
    colors: ['#E3296C', '#FDBF03', '#142B6F', '#2C5F9C'],
    legendTitle: '',
    itemWidth: 0,
    layout: 'vertical',
    title: 'Maintenance',
    showDataLabels: true,
  };

  return (
    <div className={styles.chart}>
      <Chart
        type="pie"
        data={data.content}
        text={title}
        colors={data.colors}
        legendTitle={data.legendTitle}
        layout={data.layout}
        showDataLabels={data.showDataLabels}
        itemWidth={data.itemWidth}
      />
    </div>
  );
};

export default Maintenance;
