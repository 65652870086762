import { Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';

import styles from './index.module.scss';
import logoText from '../../../../assets/images/logoText.png';
import logoX from '../../../../assets/images/logoX.png';

const ListingsLayout = () => {
  return (
    <Grid container direction="row" className={styles.listingsLayout}>
      <header className={styles.header}>
        <div className={styles.logo}>
          <img width={97} height={31} src={logoText} alt="RentX logo" />
          <img width={29} height={31} src={logoX} alt="RentX logo" />
        </div>
      </header>
      <div className={styles.container}>
        <Outlet />
      </div>
    </Grid>
  );
};
export default ListingsLayout;
