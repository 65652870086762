import { useMemo } from 'react';

import { FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import cn from 'classnames';
import SvgIcon from 'components/shared/utils/SvgIcon';
import { B1, B3 } from 'components/Typography/Body';
import { Controller, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

import styles from './DetectInformation.module.scss';
import { userSelector } from '../../../modules/Auth/store/selectors';
export interface UserInfoProps {
  form: any;
  disabled?: boolean;
  name: string;
  skipErrors?: boolean;
  content: {
    first: {
      name: string;
      title: string;
      description: string;
      icon: string;
    };
    second: {
      name: string;
      title: string;
      description: string;
      icon: string;
    };
  };
}
const DetectInformation = ({ form, name, content, skipErrors, disabled = false }: UserInfoProps) => {
  const { control, setValue, clearErrors } = form;
  const accountType = useWatch({ control, name });
  const user = useSelector(userSelector);
  const isActiveFirstColumn = useMemo(() => {
    return accountType === content.first.name;
  }, [accountType]);

  return (
    <div className={styles.detectInformation}>
      <FormControl component="fieldset" className={styles.form} disabled={disabled}>
        <Controller
          rules={{ required: true }}
          control={control}
          name={name}
          render={({ field }) => (
            <RadioGroup {...field} className={styles.group}>
              <button
                className={styles.button}
                disabled={disabled || (user?.profile?.accountType === 'COMPANY' && name === 'accountType')}
                onClick={() => {
                  if (!skipErrors) {
                    clearErrors();
                  }
                  if (!disabled) {
                    setValue(name, content.first.name, { shouldDirty: true });
                  }
                }}
                type={'button'}
              >
                <div className={cn(styles.wrap, { [styles.activeBorder]: isActiveFirstColumn })}>
                  <B1 className={styles.title}>
                    <SvgIcon
                      icon={content.first.icon}
                      className={cn(styles.tenantsIcon, { [styles.active]: isActiveFirstColumn })}
                    />
                    {content.first.title}
                  </B1>
                  <B3 className={styles.desc}>{content.first.description}</B3>
                </div>
                <FormControlLabel
                  className={styles.radio}
                  value={content.first.name}
                  control={<Radio color={'warning'} />}
                  label=""
                />
              </button>
              <button
                className={styles.button}
                disabled={disabled}
                onClick={() => {
                  if (skipErrors) {
                    clearErrors();
                  }
                  setValue(name, content.second.name, { shouldDirty: true });
                }}
                type={'button'}
              >
                <div className={cn(styles.wrap, { [styles.activeBorder]: !isActiveFirstColumn })}>
                  <B1 className={styles.title}>
                    <SvgIcon
                      icon={content.second.icon}
                      className={cn(styles.tenantsIcon, { [styles.active]: !isActiveFirstColumn })}
                    />
                    {content.second.title}
                  </B1>
                  <B3 className={styles.desc}>{content.second.description}</B3>
                </div>
                <FormControlLabel
                  className={styles.radio}
                  value={content.second.name}
                  control={<Radio color={'warning'} />}
                  label=""
                />
              </button>
            </RadioGroup>
          )}
        />
      </FormControl>
    </div>
  );
};

export default DetectInformation;
