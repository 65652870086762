import request from '../../../services/request';
import { type IDeleteImage } from '../CreateListings/types';
import { type IGetListingsQuery, type IInviteToApply, type IStatus } from '../store/types';

const END_POINTS = {
  createListings: 'api/listing/create',
  updateListings: 'api/listing/update',
  getApplicationCountById: 'api/application/listing/',
  getListings: 'api/listing',
  getPublicListings: 'api/listing/public',
  uploadImages: 'api/document/upload-listing',
  updateStatus: 'api/listing',
  deleteImage: 'api/document/listing',
  inviteToApply: 'api/listing/invite-applicant',
};

export function createListings({ requestData, listingId }: any) {
  return request({
    method: listingId ? 'PUT' : 'POST',
    url: listingId ? `${END_POINTS.updateListings}/${listingId}` : `${END_POINTS.createListings}`,
    data: requestData,
  });
}

export function getListings({ id, hasPets, propertyType, page, size, status, isPublic }: IGetListingsQuery) {
  return request({
    url: `${isPublic ? END_POINTS.getPublicListings : END_POINTS.getListings}?landlord=${id || ''}&status=${
      status === 'ALL' ? '' : status
    }&hasPets=${hasPets || ''}&page=${page}&limit=${size}&propertyType=${
      propertyType === 'All' || !propertyType ? '' : propertyType
    }`,
  });
}
export function getListingById(id: string) {
  return request({
    url: `${END_POINTS.getListings}/${id}`,
  });
}
export function inviteToApply(payload: IInviteToApply) {
  return request({
    url: `${END_POINTS.inviteToApply}`,
    method: 'POST',
    data: payload,
  });
}

export function statusUpdate(payload: IStatus) {
  return request({
    method: 'PATCH',
    url: `${END_POINTS.updateStatus}?status=${payload.activeTab === 'ALL' ? '' : payload.activeTab}&limit=${payload?.size}&page=${
      payload?.page
    }`,
    data: { _id: payload._id, status: payload.status },
  });
}
export function deleteImage(payload: IDeleteImage) {
  return request({
    method: 'PATCH',
    url: `${END_POINTS.deleteImage}`,
    data: payload,
  });
}

export function uploadUnitsImagesServices(payload: { images: File[]; unitID: string }) {
  const formData = new FormData();
  payload.images.forEach((image: File | string) => {
    if (typeof image !== 'string') {
      formData.append(`files`, image, image.name);
    }
  });

  formData.append('listingId', payload.unitID);

  return request({
    method: 'POST',
    url: `${END_POINTS.uploadImages}`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function getApplicationCountById(id: string) {
  return request({
    url: `${END_POINTS.getApplicationCountById}${id}`,
  });
}
