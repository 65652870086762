import { useMemo } from 'react';

import { Chip } from '@mui/material';

import { statusColors } from '../../../constants/colors';
import { userStatus } from '../../../constants/userStatus';
import { type IStatus } from '../../../types';

interface StatusTagProps {
  type?: IStatus;
  size?: 'small' | 'medium';
  label?: string;
  value?: string;
  className?: string;
  styles?: { [key: string]: string | number };
  onClick?: () => void;
}
const StatusTag = ({ type = 'END', size = 'small', label = 'END', value, styles, className, onClick }: StatusTagProps) => {
  const style = useMemo(() => {
    const defaultStyle = {
      border: 'none',
      minWidth: '90px',
      ...styles,
    };

    return {
      ...statusColors[type],
      ...defaultStyle,
    };
  }, [type]);

  return (
    <Chip
      className={className}
      label={value || userStatus[label]}
      variant="outlined"
      style={style}
      size={size}
      onClick={onClick}
    />
  );
};

export default StatusTag;
