import dayjs from 'dayjs';

import { accountTypeValues } from '../constants';
import { type IForm } from '../types';

export const profileData = (data: IForm, isCreate: boolean) => {
  const {
    accountType,
    address,
    address2,
    country,
    city,
    state,
    zipCode,
    dateOfBirth,
    SSN,
    firstName,
    lastName,
    middleName,
    companyName,
    companyAddress,
    structure,
    businessAddress2,
    businessCountry,
    businessCity,
    businessState,
    businessZip,
    weatherSsn,
    businessName,
    EIN,
    DBAName,
    businessStartDate,
    physicalBusinessAddress,
    USALocated,
    SSNORITIN,
    useDbn,
    isSameAddress,
  } = data;

  let emails: string | unknown[] = [];
  let phones: string | unknown[] = [];
  if (data?.emails) {
    emails = [...Object.values(data?.emails)];
  }
  if (data?.userPhones) {
    phones = [...Object.values(data?.userPhones || [])];
  }
  const businessDetails = {
    companyName: companyName,
    companyAddress: {
      id: companyAddress?.place_id,
      value: companyAddress?.label,
    },
    physicalBusinessAddress: isSameAddress
      ? null
      : {
          id: physicalBusinessAddress?.place_id,
          value: physicalBusinessAddress?.label,
        },
    companyAddress2: isSameAddress ? null : businessAddress2,
    country: isSameAddress ? null : businessCountry?.code,
    city: isSameAddress ? null : businessCity,
    state: isSameAddress ? null : businessState,
    zipCode: isSameAddress ? null : businessZip,
    weatherSsn: weatherSsn,
    useDbn: useDbn === 'Yes',
    dateOfBirth: dateOfBirth ? dayjs(dateOfBirth) : '',
    structure: structure?.code,
    EIN: SSNORITIN ? '' : EIN,
    sameAsPhysical: isSameAddress,
    SSNORITIN,
    DBAName,
    businessName,
    businessStartDate: businessStartDate ? dayjs(businessStartDate) : businessStartDate,
    USALocated,
  };

  return {
    accountType,
    address: {
      value: address?.label,
      id: address?.place_id,
    },
    address2,
    country: country?.code,
    city,
    state,
    zipCode,
    dateOfBirth: dateOfBirth ? new Date(dateOfBirth) : '',
    SSN,
    firstName,
    ...(accountType === accountTypeValues.COMPANY && { sameAsPhysical: isSameAddress }),
    lastName,
    middleName,
    emails,
    phones,
    ...(accountType === accountTypeValues.COMPANY && { businessDetails }),
    ...(!data.imageCover?.length && isCreate && { profilePic: null }),
    ...(!data.organizationArticle?.length && isCreate && { organizationArticle: null }),
    ...(!data.identity?.length && isCreate && { identity: null }),
  };
};

const convertPhonesData = (phones: { [key: string]: any }) => {
  const map = new Map();
  const unitsKey = Object.keys(phones);
  const convetPhoneModel: { [key: string]: string } = { phoneNumber: 'number', phoneType: 'type' };
  unitsKey.forEach(el => {
    const [name, index] = el.split('#');
    map.set(index || '1', { ...map.get(index || '1'), [convetPhoneModel[name]]: phones[el]?.value || phones[el] });
  });
  return [...map.values()];
};
export const tenantDataAdapter = (data: any, isCreate: boolean) => {
  return {
    ...(!data.imageCover?.length && isCreate && { profilePic: null }),
    firstName: data?.firstName,
    lastName: data?.lastName,
    middleName: data?.middleName,
    suffix: data?.suffix?.value,
    phones: data.phones ? convertPhonesData(data.phones) : [],
    dateOfBirth: data.dateOfBirth && new Date(data.dateOfBirth),
    SSN: data.SSN,
  };
};
