import React, { useEffect, useMemo } from 'react';

import Button from 'components/shared/Button';
import Loading from 'components/shared/Loading';
import StatusLine from 'components/shared/StatusLine';
import TableCard from 'components/shared/TableCard';
import { B1, B2, B3 } from 'components/Typography/Body';
import { H3 } from 'components/Typography/Headlines';
import { routes } from 'constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import styles from './Lease.module.scss';
import LeaseCard from './LeaseCard/LeaseCard';
import { getLeaseAction } from './store/actions';
import leaseSlice from './store/leaseSlice';
import { leaseCountSelector, leaseListSelector, leaseLoadingSelector, leaseQueryOptionsSelector } from './store/selectors';
import { formatDate } from '../../common/utils/utils';
import PrivateAccess from '../../components/PrivateAccess';
import StatusTag from '../../components/shared/StatusTag';
import { userRole } from '../../constants/userStatus';

const Lease = () => {
  const dispatch = useDispatch();
  const { page, size } = useSelector(leaseQueryOptionsSelector);
  const leaseList = useSelector(leaseListSelector);
  const leaseCount = useSelector(leaseCountSelector);
  const loading = useSelector(leaseLoadingSelector);
  const navigate = useNavigate();
  const memoColumns = useMemo(() => {
    return [
      {
        Header: 'Lease',
        id: 'Lease',
        title: 'Lease',
        width: '140',
        Cell: ({ row }: any) => {
          return (
            <StatusLine type={row.original.status}>
              <div>
                <B3 bold>{`${row.original.property_info.propertyName} | Unit ${row.original.unitNumber}`}</B3>
              </div>
            </StatusLine>
          );
        },
      },
      {
        Header: 'Tenant name',
        id: 'name',
        title: 'Tenant name',
        isSorted: true,
        Cell: ({ row }: any) => {
          return <B3 bold>{row.original.fullName}</B3>;
        },
      },
      {
        Header: 'Lease Status',
        id: 'status',
        title: 'Lease Status',
        Cell: ({ row }: any) => {
          return <StatusTag label={row.original.eSignatureStatus} type={row.original.eSignatureStatus} />;
        },
      },
      {
        Header: 'eSignature Status',
        id: 'eSignature Status',
        title: 'eSignature Status',
        Cell: ({ row }: any) => {
          return <StatusTag label={row.original.status} type={row.original.status} />;
        },
      },
      {
        Header: 'Start Date',
        id: 'Start Date',
        width: '70',
        title: 'Start Date',
        Cell: ({ row }: any) => {
          return <B3 bold>{formatDate(row.original.startDate)}</B3>;
        },
      },
      {
        Header: 'End Date',
        id: 'End Date',
        width: '70',
        title: 'End Date',
        Cell: ({ row }: any) => {
          return <B3 bold>{formatDate(row.original.endDate)}</B3>;
        },
      },
    ];
  }, []);

  useEffect(() => {
    dispatch(getLeaseAction({ page, size }));
  }, [page, size]);

  useEffect(() => {
    return () => {
      if (!location.pathname.includes(routes.lease)) {
        dispatch(leaseSlice.actions.clearLeaseList());
      }
    };
  }, []);

  const columnsData = useMemo(() => {
    return leaseList;
  }, [leaseList]);
  return (
    <div className={styles.lease}>
      <div className={styles.header}>
        <H3>Lease</H3>

        <PrivateAccess roles={[userRole.LANDLOARD, userRole.MANAGER]}>
          <Button variant="contained" size="ds" type="button" label="Add Lease" onClick={() => navigate(routes.leaseProperty)} />
        </PrivateAccess>
      </div>
      {!!leaseList?.length && (
        <div>
          {leaseList.map(lease => (
            <LeaseCard key={lease._id} lease={lease} />
          ))}
          <TableCard
            className={styles.leaseTabel}
            columns={memoColumns}
            data={columnsData || []}
            defaultPageSize={size}
            totalItems={leaseCount}
            setPageSize={size => dispatch(getLeaseAction({ page: 1, size }))}
            defaultPage={page}
            onRowClick={(row: { original: { _id: string } }) => navigate(`${routes.lease}/${row?.original?._id}`)}
            fetchData={({ pageSize, pageIndex }) => dispatch(getLeaseAction({ page: pageIndex, size: `${pageSize}` }))}
            hidePagination={false}
          />
        </div>
      )}
      {loading && <Loading isBig />}
      {!leaseList?.length && !loading && (
        <div className={styles.notFound}>
          <B1 bold>No Lease Found</B1>
          <B2>You currently have no active leases.</B2>
        </div>
      )}
    </div>
  );
};

export default Lease;
