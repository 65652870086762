import { type TaskState } from './types';
import { type IForm } from '../types';

export const createTaskRequestData = (payload: IForm, taskCreateData: TaskState, managerAndLandlordsData: any) => {
  const property = taskCreateData.properties.find(el => el.units.find(el => el._id === payload?.property?.value));
  const manager = managerAndLandlordsData.find((el: any) => el.email === payload.manager?.value);
  const [selectedTime] = [
    { key: 'hasFirstTime', value: 'Any Time' },
    { key: 'hasSecondTime', value: '8AM -12PM' },
    { key: 'hasThirdTime', value: '12PM -4PM' },
    { key: 'hasFourthTime', value: '4PM -8PM' },
  ].filter(({ key }: { key: string }) => payload[key]);
  return {
    dueDate: payload?.availableDate,
    category: categoriAdapter(payload.category as any[]),
    title: payload.title,
    description: payload.description,
    priority: payload.requestPriority,
    property: property?._id,
    unit: payload.property?.value,
    convenientTime: selectedTime?.value,
    assignee: {
      email: manager.email,
      managerId: manager._id,
    },
  };
};

const categoriAdapter = (categories: any[]) => {
  const result = categories.reduce((acc, currentItem) => {
    if (typeof currentItem === 'object') {
      acc.push(currentItem?.label);
    } else {
      acc.push(currentItem);
    }
    return acc;
  }, []);
  return result;
};

export const assigneManager = (manager: { _id: string; email: string; fullName: string; maintenanceId: string }) => {
  return {
    managerId: manager._id,
    email: manager.email,
    maintenanceId: manager.maintenanceId,
  };
};
