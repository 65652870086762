import { forwardRef, memo, useMemo } from 'react';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Checkbox as MuiCheckbox, type CheckboxProps } from '@mui/material';
import cn from 'classnames';

import styles from './Checkbox.module.scss';
import SvgIcon from '../../utils/SvgIcon';

type checkboxProps = {
  type?: 'default' | 'radio' | 'circle';
} & CheckboxProps;

const Checkbox = ({ type = 'default', className, ...rest }: Partial<checkboxProps>) => {
  const checkbox = useMemo(() => {
    switch (type) {
      case 'radio': {
        return {
          icon: <RadioButtonUncheckedIcon />,
          checkedIcon: <RadioButtonCheckedIcon color={'warning'} />,
        };
      }
      case 'circle': {
        return {
          icon: <RadioButtonUncheckedIcon />,
          checkedIcon: <CheckCircleOutlineIcon />,
        };
      }
      default: {
        return {
          icon: <SvgIcon icon="unchecked" className={styles.unchecked} />,
          checkedIcon: <SvgIcon icon="checked" className={styles.checked} />,
        };
      }
    }
  }, [type]);
  return (
    <MuiCheckbox className={cn(styles.checkbox, className)} icon={checkbox.icon} checkedIcon={checkbox.checkedIcon} {...rest} />
  );
};

export default Object.assign(memo(forwardRef(Checkbox)), { displayName: 'Checkbox' });
