import { useEffect } from 'react';

import Chart from 'components/shared/Chart';
import { useDispatch, useSelector } from 'react-redux';

import styles from './RentalListings.module.scss';
import { getRentalListingsAction } from '../../../store/actions';
import { rentalListingsSelector } from '../../../store/selectors';

interface RentalListingsProps {
  title: string;
}

const RentalListings: React.FC<RentalListingsProps> = ({ title }) => {
  const dispatch = useDispatch();
  const rentalListings = useSelector(rentalListingsSelector);

  useEffect(() => {
    dispatch(getRentalListingsAction());
  }, []);

  function transformObjectToArray(data: any) {
    const published: any = [];
    const unpublished: any = [];
    for (const key in data) {
      published.push({
        name: 'Published',
        y: data[key].published,
      });
      unpublished.push({
        name: 'Unpublished',
        y: data[key].unpublished,
      });
    }

    return published.concat(unpublished);
  }

  const data: any = {
    content: [
      {
        name: 'Rental Listings',
        data: transformObjectToArray(rentalListings),
      },
    ],
    colors: ['#E3296C', '#1D3273', '#2C5F9C', '#FDBF03'],
    legendTitle: `<div style="display:flex; justify-content: space-between; width: 225px;">
                    <span>Vacant Units</span>
                    <span>Occupated Units</span>
                </div>`,
    itemWidth: 120,
    layout: 'horizontal',
    title: 'Rental Listings',
    showDataLabels: false,
  };

  return (
    <div className={styles.chart}>
      <Chart
        type="pie"
        data={data.content}
        text={title}
        colors={data.colors}
        legendTitle={data.legendTitle}
        layout={data.layout}
        showDataLabels={data.showDataLabels}
        itemWidth={data.itemWidth}
      />
    </div>
  );
};

export default RentalListings;
