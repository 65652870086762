import toast from 'react-hot-toast';
import { call, type ForkEffect, put, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import chatSlice from './chatSlice';
import { sendMessage, getChat } from '../ChatService';

function* sendMessageSaga({ payload }: ReturnType<typeof actions.sendMessage>) {
  yield put(chatSlice.actions.setLoading(true));
  try {
    yield call(sendMessage as any, payload);
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(chatSlice.actions.setLoading(false));
  }
}

function* getChatSaga({ payload }: ReturnType<typeof actions.getChat>) {
  yield put(chatSlice.actions.setLoading(true));
  try {
    const { data } = yield call(getChat as any, payload);

    yield put(chatSlice.actions.getChat(data.items));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(chatSlice.actions.setLoading(false));
  }
}

export function* watchChatSaga(): Generator<ForkEffect> {
  yield takeLatest(actions.sendMessage.type, sendMessageSaga);
  yield takeLatest(actions.getChat.type, getChatSaga);
}
