import dayjs from 'dayjs';

import { type IForm } from '../types';

export const dataAdapter = (data: IForm) => {
  const {
    unitId,
    amount,
    propertyId,
    insurance,
    deposit,
    leaseDuration,
    startDate,
    endDate,
    nextDueDate,
    rent,
    tenant,
    gracePeriod,
    isSigned,
  } = data;

  const adaptedData: any = {
    unitId,
    amount: +amount,
    propertyId,
    deposit: +deposit,
    leaseDuration,
    startDate: dayjs(startDate),
    endDate: dayjs(endDate),
    rent: +rent,
    tenant: tenant._id,
    gracePeriod: +gracePeriod,
    signedStatus: isSigned ? 'SIGNED' : 'UNSIGNED',
    ...(!isSigned && { insurance }),
  };

  if (isSigned && nextDueDate) {
    adaptedData.nextDueDate = dayjs(nextDueDate);
  }
  return adaptedData;
};

export const setToSignAdapter = (data: any) => {
  const {
    _id,
    unitId,
    propertyId,
    leaseDuration,
    startDate,
    endDate,
    deposit,
    rent,
    tenant,
    gracePeriod,
    isSigned,
    amount,
    insurance,
  } = data;
  return {
    _id,
    unitId,
    propertyId,
    leaseDuration,
    startDate: dayjs(startDate),
    endDate: dayjs(endDate),
    deposit: +deposit,
    rent: +rent,
    tenant: tenant._id,
    gracePeriod: +gracePeriod,
    signedStatus: isSigned ? 'SIGNED' : 'UNSIGNED',
    amount: +amount,
    insurance,
  };
};

export const leaseUpdateAdapter = (data: any, dirtyFields: any) => {
  const newData: any = {};
  const fields = Object.keys(dirtyFields);
  fields.forEach(el => {
    newData[el] = data[el];
    if (el === 'startDate') {
      newData['endDate'] = data['endDate'];
    }
  });
  return newData;
};
