import { createSlice } from '@reduxjs/toolkit';

import { type HelpState } from './types';

const initialState: HelpState = {
  isLoading: false,
  categoriItems: [],
};

const helpSlice = createSlice({
  name: 'help',
  initialState,
  reducers: {
    setLoading: (state: HelpState, { payload }) => {
      state.isLoading = payload;
    },
    setItems: (state: HelpState, { payload }) => {
      state.categoriItems = payload.data;
    },
  },
});

export default helpSlice;
