export const defaultValues = {
  paidDate: null,
  amountPaid: '',
  method: 'CASH',
};

export const paymentMethods = [
  { label: 'Cash', value: 'CASH' },
  { label: 'Card', value: 'CARD' },
];
