import { createSlice } from '@reduxjs/toolkit';

import { type ListingsState } from './types';

const initialState: ListingsState = {
  appCount: 0,
  isLoading: false,
  uploadActiveId: '',
  listings: null,
  listing: null,
  queryOptions: {
    page: 1,
    size: '5',
  },
};

const listingsSlice = createSlice({
  name: 'listings',
  initialState,
  reducers: {
    setLoading: (state: ListingsState, { payload }) => {
      state.isLoading = payload;
    },
    setSmileLoading: (state: ListingsState, { payload }) => {
      state.uploadActiveId = payload;
    },
    setQueryOptions: (state: ListingsState, { payload }) => {
      state.queryOptions = payload;
    },
    getListingsSuccessAction: (state: ListingsState, { payload }) => {
      state.listings = payload;
    },
    updateListingStatus: (state: ListingsState, { payload }) => {
      if (state.listing) {
        state.listing.listingStatus = payload.status;
      }
    },
    getListingByIdSuccessAction: (state: ListingsState, { payload }) => {
      state.listing = payload;
    },
    clearListing: (state: ListingsState) => {
      state.listing = null;
    },
    clearListings: (state: ListingsState) => {
      state.listings = null;
    },
    getApplicationCountByIdSuccessAction: (state: ListingsState, { payload }) => {
      state.appCount = payload.count;
    },
  },
});

export default listingsSlice;
