import { useEffect } from 'react';

import Loading from 'components/shared/Loading';
import Pagination from 'components/shared/Pagination';
import { B1, B2 } from 'components/Typography/Body';
import { userSelector } from 'modules/Auth/store/selectors';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import { getApplicantList } from './store/actions';
import { applicantDataSelector, applicantLoadingSelector, applicantQueryOptionsSelector } from './store/selector';
import ApplicationCard from './TenantApplicationCard';
import styles from './TenantApplicationModule.scss';
import PrivateAccess from '../../components/PrivateAccess';
import { userRole } from '../../constants/userStatus';

const TenantApplication = () => {
  const dispatch = useDispatch();
  const { page, size } = useSelector(applicantQueryOptionsSelector);
  const loading = useSelector(applicantLoadingSelector);
  const applicantData = useSelector(applicantDataSelector);
  const user = useSelector(userSelector);

  useEffect(() => {
    dispatch(getApplicantList({ limit: size, page: page }));
  }, []);
  return (
    <PrivateAccess roles={[userRole.TENANT]} isPage>
      <div className={styles.items}>
        {!!applicantData?.items.length &&
          applicantData?.items.map(applicant => <ApplicationCard key={applicant._id} applicant={applicant} />)}
      </div>
      {!applicantData?.count && !loading && user?._id && (
        <div className={styles.notFound}>
          <B1 bold>No Application found</B1>
          <B2>You don't have any Application.</B2>
        </div>
      )}
      <Pagination
        page={page}
        pageCount={Number(applicantData?.count)}
        gotoPage={page => dispatch(getApplicantList({ page, limit: size }))}
        setPageSize={size => dispatch(getApplicantList({ limit: size, page: 1 }))}
        pageSize={size}
        className={styles.paggination}
      />
      {loading && <Loading isBig />}
    </PrivateAccess>
  );
};

export default TenantApplication;
