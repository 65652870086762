import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import styles from './BackButton.module.scss';
import { B3 } from '../../Typography/Body';
import SvgIcon from '../utils/SvgIcon';

export interface BackButtonProps {
  to: any;
  className?: string;
}

const BackButton = ({ to, className }: BackButtonProps) => {
  const navigate = useNavigate();
  return (
    <B3 className={cn(styles.backButton, className)} onClick={() => navigate(to)}>
      <SvgIcon icon={'back'} className={styles.backIcon} />
      Back
    </B3>
  );
};

export default BackButton;
