import UserCard from 'components/shared/UserCard';
import { useSelector } from 'react-redux';

import styles from './Owners.module.scss';
import { leaseSelector } from '../../store/selectors';

const Owners = () => {
  const lease = useSelector(leaseSelector);
  return (
    <div className={styles.owners}>
      {lease && (
        <>
          <UserCard
            email={lease.account?.email}
            name={lease.account?.fullName}
            type={'Landlord'}
            src={`${process.env.REACT_APP_BASE_URL}/api/document?path=${lease.account?.profile?.profilePic}`}
          />

          <UserCard
            email={lease?.email || ''}
            name={lease.fullName}
            type={'Tenant'}
            src={`${process.env.REACT_APP_BASE_URL}/api/document?path=${lease.tenant?.profile?.profilePic}`}
          />
        </>
      )}
    </div>
  );
};

export default Owners;
