import React from 'react';

import ImageUploader from 'components/shared/ImageUploader';
import { B1, B3 } from 'components/Typography/Body';
import { useWatch } from 'react-hook-form';

import styles from './Documents.module.scss';

interface DocumentsProps {
  form: any;
}

const Documents = ({ form }: DocumentsProps) => {
  const {
    setValue,
    control,
    formState: { errors },
  } = form;
  const documents = useWatch({ control, name: 'documents' });

  return (
    <div className={styles.documents}>
      <B1 bold>Documents*</B1>
      <B3 className={styles.desc}>Upload files (Maximum of 10)</B3>
      <ImageUploader
        uploaderText={'Upload Card Front image here'}
        multiple
        fileTypeText={'(jpg, png, fdf)'}
        accept=".pdf, .jpeg, .png"
        isBig
        limit={3}
        imageUrls={documents}
        setImageUrls={value => {
          setValue('documents', value.length ? value : null);
        }}
        hasErrorBlock={!!errors?.documents}
        message={errors?.documents?.message || null}
      />
    </div>
  );
};

export default Documents;
