import { userRole } from 'constants/userStatus';

export enum AplicationSteps {
  CONGRATULATIONS = 'done',
  BEFOREBEGIN = 1,
  PERSONALINFORMATION,
  RESIDENCEADDRESS,
  OCCUPANTS,
  YOURINCOME,
  INCOMEVEREFICATIONS,
  ATTACHDOCUMENTS,
  PAYFEES,
  REVIEWANDCONFIRM,
}

const ProgressBarSteps = {
  [AplicationSteps.BEFOREBEGIN]: 'Before you begin',
  [AplicationSteps.PERSONALINFORMATION]: 'Personal information',
  [AplicationSteps.RESIDENCEADDRESS]: 'Where You`ve lived',
  [AplicationSteps.OCCUPANTS]: 'Co-Applicants/ Occupants',
  [AplicationSteps.YOURINCOME]: 'Your income',
  [AplicationSteps.INCOMEVEREFICATIONS]: 'Income verefication',
  [AplicationSteps.ATTACHDOCUMENTS]: 'Attach documents',
  [AplicationSteps.PAYFEES]: 'Pay fees',
  [AplicationSteps.REVIEWANDCONFIRM]: 'Review and confirm',
};

export const ProgressBarStepsList = [
  ProgressBarSteps[AplicationSteps.BEFOREBEGIN],
  ProgressBarSteps[AplicationSteps.PERSONALINFORMATION],
  ProgressBarSteps[AplicationSteps.RESIDENCEADDRESS],
  ProgressBarSteps[AplicationSteps.OCCUPANTS],
  ProgressBarSteps[AplicationSteps.YOURINCOME],
  ProgressBarSteps[AplicationSteps.INCOMEVEREFICATIONS],
  ProgressBarSteps[AplicationSteps.ATTACHDOCUMENTS],
  ProgressBarSteps[AplicationSteps.PAYFEES],
  ProgressBarSteps[AplicationSteps.REVIEWANDCONFIRM],
];

export const beforeDefaultProps = {
  step: '1',
  applyingAs: userRole.TENANT,
  login: {
    email: null,
    password: null,
  },
  signUp: {
    email: '',
    password: null,
    confirmPassword: null,
    confirmCode: null,
    check: false,
  },
  hasTenant: false,
  firstName: '',
  lastName: '',
  middleName: '',
  email: null,
  confirmEmail: null,
  SSN: '',
  address: null,
  address1: null,
  'country#': '',
  'city#': '',
  'state#': '',
  'zipCode#': '',
  'residedFrom#': null,
  personRefFirstName: '',
  personRefAddress: '',
  personRefRelationship: '',
  personRefPhoneNumber: '',
  hasPet: false,
  hasVehicle: false,
  aboveNumber: '',
  underNumber: '',
  employerName: '',
  employerPhone: '',
  monthlyIncome: '',
  sourceName: '',
  monthlyExpense: '',
  positionHeld: '',
  yearsWorked: '',
  others: [],
  IDPhoto: null,
  proofOfIncome: null,
  cardNumber: '',
};

export const pageList = ['login', 'signup', 'confirm'];
