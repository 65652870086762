import { type AxiosResponse } from 'axios';
import request from 'services/request';

const END_POINTS = {
  getCategory: 'api/category',
  getPropertyByUnitId: 'api/maintenance/getLeaseByunitId',
  uploadDocuments: 'api/document/upload-task',
  createTask: 'api/task/create',
  getTask: 'api/task',
  updateTaskStatus: 'api/task/update',
  assignManager: 'api/maintenance/assignee',
  getManager: 'api/account?role=MANAGER',
  getProperty: 'api/property',
  getManagersAndLandlords: 'api/account/company-id',
};

export function getCategory(): Promise<AxiosResponse<any, any>> {
  return request({
    method: 'GET',
    url: END_POINTS.getCategory,
  });
}

export function getPropertyByUnitId(payload: { id: string }): Promise<AxiosResponse<any, any>> {
  return request({
    method: 'GET',
    url: `${END_POINTS.getPropertyByUnitId}/${payload.id}`,
  });
}

export function uploadDocuments(document: File[], taskId: string) {
  const formData = new FormData();
  !!document.length &&
    document.forEach((file: File) => {
      formData.append(`files`, file, file.name);
    });
  formData.append('taskId', taskId);
  return request({
    method: 'POST',
    url: END_POINTS.uploadDocuments,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function createTask(payload: any): Promise<AxiosResponse<any, any>> {
  return request({
    method: 'POST',
    url: END_POINTS.createTask,
    data: { ...payload },
  });
}

export function getTaskList(payload: any): Promise<AxiosResponse<any, any>> {
  return request({
    method: 'GET',
    url: `${END_POINTS.getTask}?limit=${payload.size}&page=${payload.page}`,
  });
}

export function getTaskDetails(payload: any): Promise<AxiosResponse<any, any>> {
  return request({
    method: 'GET',
    url: `${END_POINTS.getTask}/${payload.taskId}`,
  });
}

export function updateTaskStatus(payload: { taskId: string; status: string }): Promise<AxiosResponse<any, any>> {
  return request({
    method: 'PUT',
    url: `${END_POINTS.updateTaskStatus}/${payload.taskId}`,
    data: { status: payload.status },
  });
}

export function assignManager(payload: {
  managerId: string;
  email: string;
  maintenanceId: string;
}): Promise<AxiosResponse<any, any>> {
  return request({
    method: 'POST',
    url: `${END_POINTS.assignManager}/${payload.maintenanceId}`,
    data: { assignee: { managerId: payload.managerId, email: payload.email } },
  });
}

export function getManagers(): Promise<AxiosResponse<any, any>> {
  return request({
    method: 'GET',
    url: END_POINTS.getManager,
  });
}

export function getManagersAndLandlords(): Promise<AxiosResponse<any, any>> {
  return request({
    method: 'GET',
    url: END_POINTS.getManagersAndLandlords,
  });
}

export function getPropertis(): Promise<AxiosResponse<any, any>> {
  return request({
    method: 'GET',
    url: END_POINTS.getProperty,
  });
}
