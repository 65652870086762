import request from '../../../services/request';
import type { IGetNotificationsQuery, IStatus, IArchive, IFlagged } from '../store/types';

const END_POINTS = {
  getNotifications: 'api/notification',
  updateNotificationStatus: 'api/notification',
  archiveNotification: 'api/notification/update',
  isFlaggedNotification: 'api/notification/update',
};

export async function getNotifications({ userId, page, size }: IGetNotificationsQuery) {
  return request({
    method: 'GET',
    url: `${END_POINTS.getNotifications}?to=${userId}&page=${page}&limit=${size}`,
  });
}

export async function updateNotificationStatus({ notificationId, status, page, size }: IStatus) {
  return request({
    method: 'PATCH',
    url: `${END_POINTS.updateNotificationStatus}/${notificationId}?page=${page}&limit=${size}`,
    data: { status },
  });
}

export async function archiveNotification({ notificationId, archive, page, size }: IArchive) {
  return request({
    method: 'PUT',
    url: `${END_POINTS.archiveNotification}/${notificationId}?page=${page}&limit=${size}`,
    data: { archive },
  });
}

export async function isFlaggedNotification({ notificationId, isFlagged, page, size }: IFlagged) {
  return request({
    method: 'PUT',
    url: `${END_POINTS.isFlaggedNotification}/${notificationId}?page=${page}&limit=${size}`,
    data: { isFlagged },
  });
}
