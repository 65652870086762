import { useEffect } from 'react';

import cn from 'classnames';
import { formatDate } from 'common/utils/utils';
import BackButton from 'components/shared/BackButton/BackButton';
import SvgIcon from 'components/shared/utils/SvgIcon';
import { B3 } from 'components/Typography/Body';
import { DateFormat } from 'constants/dateFormat';
import modalNames from 'constants/modalNames';
import { routes } from 'constants/routes';
import MarkAsPaidModal from 'modals/MarkAsPaid';
import modalsSlice from 'modals/store/modalsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import styles from './PaymentDetails.module.scss';
import Button from '../../../../components/shared/Button';
import { H4 } from '../../../../components/Typography/Headlines';
import { getPaymentByIdAction } from '../../store/actions';
import { singlePaymentSelector } from '../../store/selectors';

const PaymentDetails = () => {
  const dispatch = useDispatch();
  const singlePayment = useSelector(singlePaymentSelector);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getPaymentByIdAction(id));
    }
  }, [dispatch, id]);

  const markAsPaid = () => {
    dispatch(modalsSlice.actions.showModal({ modalName: modalNames.MARK_AS_PAID }));
  };

  return (
    <div>
      <BackButton to={routes.landloardPayment} />
      <div className={styles.header}>
        <H4 bold>Payment</H4>
        {singlePayment?.type !== 'DEPOSIT' && singlePayment?.status !== 'PAID' ? (
          <Button variant="contained" size="ds" type="button" label="Mark as Paid" onClick={markAsPaid} />
        ) : (
          ''
        )}
        {singlePayment?.type === 'DEPOSIT' && singlePayment?.status === 'PAID' ? (
          <Button variant="contained" size="ds" type="button" label="Return Deposit" onClick={markAsPaid} />
        ) : (
          ''
        )}
      </div>
      <div className={styles.details}>
        <div className={styles.personInfo}>
          <B3 className={styles.title}>
            <SvgIcon icon={'profile'} className={styles.profile} />
            Payer Name
          </B3>
          <B3 className={styles.value}>{singlePayment?.tenant?.fullName}</B3>
        </div>
        <div className={styles.personInfo}>
          <B3 className={styles.title}>
            <SvgIcon icon={'properties'} className={styles.profile} />
            Property Name
          </B3>
          <B3 className={styles.value}>{singlePayment?.property?.propertyName}</B3>
        </div>
        <div className={styles.personInfo}>
          <B3 className={styles.title}>
            <SvgIcon icon={'accounting'} className={cn(styles.profile, styles.iconLight)} />
            Due date
          </B3>
          <B3 className={styles.value}>{formatDate(singlePayment?.dueDate, DateFormat.DDMMYYYY)}</B3>
        </div>
        <div className={styles.personInfo}>
          <B3 className={styles.title}>Category</B3>
          <B3 className={styles.value}>{singlePayment?.type}</B3>
        </div>
        <div className={styles.personInfo}>
          <B3 className={styles.title}>Status</B3>
          <B3 className={styles.value}>{singlePayment?.status}</B3>
        </div>
        <div className={styles.personInfo}>
          <B3 className={styles.title}>
            <SvgIcon icon={'properties'} className={styles.profile} />
            {singlePayment?.status !== 'PAID' ? 'To pay' : 'To paid'}
          </B3>
          <B3 className={styles.value}>{singlePayment?.amount}</B3>
        </div>
      </div>
      <MarkAsPaidModal paymentId={singlePayment?._id} amount={singlePayment?.amount} type={singlePayment?.type} />
    </div>
  );
};

export default PaymentDetails;
