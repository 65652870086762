import { useCallback } from 'react';

import { Modal } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import styles from './SuccessRegisteredModal.module.scss';
import logo from '../../assets/images/logo.png';
import registered from '../../assets/images/registered.png';
import SvgIcon from '../../components/shared/utils/SvgIcon';
import modalNames from '../../constants/modalNames';
import { type RootState } from '../../store/setupStore';
import { modalsSlice } from '../index';
import { checkModalVisibility } from '../store/selectors';

const SuccessRegisteredModal = () => {
  const dispatch = useDispatch();
  const isModalVisible = useSelector((store: RootState) => checkModalVisibility(store, modalNames.SUCCESS_REGISTERED_MODAL));

  const handleModalClose = useCallback(() => {
    dispatch(modalsSlice.actions.hideModal(modalNames.SUCCESS_REGISTERED_MODAL));
  }, [dispatch]);

  return (
    <Modal open={isModalVisible} onClose={handleModalClose} className={styles.modal}>
      <div className={styles.successRegisteredModal}>
        <header className={styles.header}>
          <img width={156} height={32} src={logo} alt="RentX logo" />
          <SvgIcon icon="close" width={14} height={14} className={styles.close} onClick={handleModalClose} />
        </header>
        <div className={styles.content}>
          <img width={74} height={100} src={registered} alt="Registered" />
          <p className={styles.congratulations}>Congratulations</p>
          <span className={styles.description}>
            You have registered on the site. Please go to your <span className={styles.email}>E-mail address</span> to complete
            the verification
          </span>
        </div>
      </div>
    </Modal>
  );
};

export default SuccessRegisteredModal;
