const googleAddres = ['address', 'city', 'country', 'state', 'zipCode', 'address1'];

export const getDataForUpdate = (data: any, dirtyFields: any) => {
  const newData: any = { imageCover: data.imageCover, imageUrls: data?.imageUrls };
  const fields = Object.keys(dirtyFields);
  fields.forEach(el => {
    newData[el] = data[el];
    if (googleAddres.includes(el)) {
      newData['city'] = data['city'];
      newData['country'] = data['country'];
      newData['state'] = data['state'];
      newData['zipCode'] = data['zipCode'];
      newData['address1'] = data['address1'];
      newData['address'] = data['address'];
    }
    if (el === 'amenity') {
      newData['amenityItems'] = data['amenityItems'];
    }
    if (el === 'amenityItems') {
      newData['amenity'] = data['amenity'];
    }
    if (el === 'feature') {
      newData['featureItems'] = data['featureItems'];
    }
    if (el === 'featureItems') {
      newData['feature'] = data['feature'];
    }
  });

  const havePets = data.hasPets === 'Yes';
  const rentersInsurance = data.isRentersInsurance === 'Yes';

  if (
    dirtyFields?.laundry ||
    dirtyFields?.petDescription ||
    dirtyFields?.petWeight ||
    dirtyFields?.hasPets ||
    dirtyFields?.isRentersInsurance
  ) {
    newData['other'] = {
      isRentersInsurance: rentersInsurance,
      hasPets: havePets,
      laundry: data.laundry,
      ...(havePets && { petDescription: data.petDescription }),
      ...(havePets && { petWeight: data.petWeight }),
    };
  }

  return newData;
};
