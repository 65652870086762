import dayjs from 'dayjs';
export const createLeaseDefaultValue = {
  isSigned: true,
  insurance: 'YES',
  leaseDuration: null,
  startDate: dayjs(new Date()),
  nextDueDate: dayjs(new Date()),
  deposit: '',
  tenant: null,
};
