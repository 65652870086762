import { useState, useEffect } from 'react';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Button from 'components/shared/Button';
import { B1, B3 } from 'components/Typography/Body';
import { routes } from 'constants/routes';
import { userSelector } from 'modules/Auth/store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import styles from './Tasks.module.scss';
import PrivateAccess from '../../../components/PrivateAccess';
import { userRole } from '../../../constants/userStatus';
import { getDashboardTasksAction } from '../store/actions';
import { dashboardTasksSelector } from '../store/selectors';

const Tasks = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const user = useSelector(userSelector);
  const taskList: any = useSelector(dashboardTasksSelector);

  useEffect(() => {
    if (user?.role === 'MANAGER') {
      dispatch(getDashboardTasksAction({ page: 1, size: 3, _id: user?._id }));
    } else {
      dispatch(getDashboardTasksAction({ page: 1, size: 3 }));
    }
  }, [user]);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
    if (newValue === 1) {
      dispatch(getDashboardTasksAction({ page: 1, size: 3, _id: user?._id }));
    } else {
      dispatch(getDashboardTasksAction({ page: 1, size: 3 }));
    }
  };

  return (
    <div>
      {taskList ? (
        <div className={styles.card}>
          <div className={styles.cardTitle}>
            <h4>Tasks</h4>
            <button
              className={styles.viewAllButton}
              onClick={() => {
                navigate(routes.tasks);
              }}
            >
              View all
            </button>
          </div>
          <div className={styles.tasks}>
            <div className={styles.tabsWrap}>
              <PrivateAccess roles={[userRole.LANDLOARD]}>
                <Tabs
                  className={styles.tabs}
                  value={value}
                  variant="scrollable"
                  scrollButtons="auto"
                  onChange={handleChange}
                  classes={{
                    indicator: styles.indicator,
                  }}
                  textColor="inherit"
                >
                  <Tab label="All tasks" />

                  <Tab label="Assigned To Me" />
                </Tabs>
              </PrivateAccess>
            </div>
            {taskList.length
              ? taskList.map((item: any) => {
                  return (
                    <div className={styles.taskItem} key={item._id}>
                      <div className={styles.taskItemText}>
                        <B1>{item.title}</B1>
                        <B3 className={styles.B3}>{item.description}</B3>
                      </div>
                      <Button
                        variant="contained"
                        type="button"
                        label="View"
                        onClick={() => navigate(`${routes.taskDetails}?taskId=${item._id}&unitId=${item?.unit?._id}`)}
                      />
                    </div>
                  );
                })
              : ''}
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
export default Tasks;
