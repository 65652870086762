import React, { memo } from 'react';

import MuPagination from '@mui/material/Pagination';
import { makeStyles, createStyles } from '@mui/styles';
import cn from 'classnames';

import './Pagination.module.scss';
import styles from './Pagination.module.scss';
import Select from '../FormElements/Select';

type PaginationProps = {
  gotoPage: (page: number) => void;
  page: number;
  pageSize: string;
  pageCount: number;
  setPageSize?: (size: string) => void;
  className?: string;
};
const pageSizeOptions = [
  { code: '5', value: '5' },
  { code: '10', value: '10' },
  { code: '15', value: '15' },
];

const Pagination: React.FC<PaginationProps> = ({ gotoPage, page, setPageSize, pageCount, pageSize, className }) => {
  const useStyles = makeStyles(() =>
    createStyles({
      root: {
        display: 'flex !important',
        justifyContent: 'flex-end',
        '& .Mui-selected': {
          backgroundColor: 'white !important',
          color: 'var(--primary-blue) !important ',
          border: '1px solid var(--primary-blue) !important',
        },
        '& .MuiPaginationItem-page': {
          backgroundColor: 'white !important',
        },
        '& .MuiPaginationItem-previousNext': {
          backgroundColor: 'white !important',
        },
      },
    })
  );
  const classes = useStyles();

  if (!pageCount) {
    return null;
  }

  return (
    <div className={cn(styles.pagination, className)}>
      <Select width={65} options={pageSizeOptions} value={pageSize} onChange={setPageSize} />
      <MuPagination
        count={Math.ceil(pageCount / +pageSize)}
        variant="outlined"
        shape="rounded"
        color={'primary'}
        classes={{ root: classes.root }}
        page={page}
        onChange={(e, page) => gotoPage(page)}
      />
    </div>
  );
};

export default memo(Pagination);
