import { createAction } from '@reduxjs/toolkit';

import {
  type getInviteTokenDataModel,
  type DeleteFile,
  type UploadDocument,
  type createApplicationData,
  type registerDataModel,
  type confirmEmailOtpDataModel,
  type applicationLoginDataModel,
  type getUnitData,
  type plaidRetrieveBalanceDataModel,
} from './types';

export const getUnit = createAction<getUnitData>('getUnit');
export const createApplication = createAction<createApplicationData>('createApplication');
export const getApplicationById = createAction<string>('getApplicationById');
export const documentUpload = createAction<UploadDocument>('documentUpload');
export const deleteFile = createAction<DeleteFile>('deleteFile');
export const getApplicationInviteToken = createAction<getInviteTokenDataModel>('getApplicationInviteToken');
export const registerByInvite = createAction<registerDataModel>('registerByInvite');
export const emailVerify = createAction<registerDataModel>('emailVerify');
export const confirmEmailOtp = createAction<confirmEmailOtpDataModel>('confirmEmailOtp');
export const applicationLogin = createAction<applicationLoginDataModel>('applicationLogin');
export const plaidVerification = createAction<string>('plaidVerification');
export const plaidRetrieveBalance = createAction<plaidRetrieveBalanceDataModel>('plaidRetrieveBalance');
export const payByCard = createAction<any>('payByCard');
export const paymentAttachMethod = createAction<any>('paymentAttachMethod');
export const confirmPaymentAction = createAction<any>('confirmApplicationPaymentAction');
