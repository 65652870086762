import { createSlice } from '@reduxjs/toolkit';

import { type getApplicantListReqModel, type ApplicantDataModel, type TenantApplicationResDataModel } from './types';

const initialState: ApplicantDataModel = {
  isLoading: false,
  data: null,
  queryOptions: {
    page: 1,
    size: '5',
    sortBy: {
      id: '',
      desc: false,
    },
  },
};

const tenantApplicationSlice = createSlice({
  name: 'tenantApplication',
  initialState,
  reducers: {
    setLoading: (state: ApplicantDataModel, { payload }) => {
      state.isLoading = payload;
    },
    setApplicantData: (
      state: ApplicantDataModel,
      { payload }: { payload: { data: TenantApplicationResDataModel; queryOptions: getApplicantListReqModel } }
    ) => {
      state.data = payload.data;
      state.queryOptions = {
        ...state.queryOptions,
        size: payload.queryOptions.limit,
        page: payload.queryOptions.page,
      };
    },
    setPageSize: (state: ApplicantDataModel, { payload }) => {
      state.queryOptions.size = payload;
    },
  },
});

export default tenantApplicationSlice;
