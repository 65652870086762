import React from 'react';
import { forwardRef, memo } from 'react';

import MuiAutocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import cn from 'classnames';

import styles from './Autocomplete.module.scss';
import '../Input/Input.scss';
import Input from '../Input';

export type values = { label: string; value?: string | number; code?: string } | any;

export interface AutocompleteProps {
  isMultiple?: boolean;
  options: values[];
  errorMessage?: string;
  hasError?: boolean;
  label?: string;
  placeholder?: string;
  classNames?: string;
  disableClearable?: boolean;
  onAutocompleteChange?: () => void;
  onInputChange?: unknown;
  inputValue?: string;
  loading?: boolean;
  multiline?: boolean;
  setInputValue?: (value: string) => void;
}

const Autocomplete = ({
  isMultiple = false,
  errorMessage,
  hasError,
  options,
  placeholder,
  label,
  classNames,
  disableClearable,
  onAutocompleteChange,
  onChange,
  inputValue,
  loading,
  setInputValue,
  autoComplete = 'new-password',
  multiline = false,
  ...rest
}: AutocompleteProps & any) => {
  return (
    <MuiAutocomplete
      className={cn(styles.autocomplete, classNames, {
        ['autocomplete-wrapper']: isMultiple,
        ['input-wrapper']: !isMultiple,
      })}
      disableClearable={disableClearable}
      multiple={isMultiple}
      {...rest}
      value={rest?.value || null}
      onChange={(x, v: values) => {
        const deletedValues = rest?.value?.length
          ? rest?.value?.filter((selectedValue: any) => !v?.includes(selectedValue))
          : null;
        if (onAutocompleteChange) {
          onAutocompleteChange(v, deletedValues?.length ? deletedValues[0] : null);
        }
        onChange(v);
      }}
      id={label}
      options={options}
      renderOption={(props: any, option: values, state: { inputValue: string }) => {
        const isActive = state.inputValue === option.label;
        return (
          <Box
            component="li"
            sx={{
              color: isActive ? 'var(--additional-white)' : 'var(--primary-black)',
              backgroundColor: isActive ? 'var(--primary-blue)' : 'inherit',
              '&:hover': {
                color: 'var(--additional-white)',
                backgroundColor: 'rgba(var(--secondary-hover-bl),0.7)' + ' !important',
              },
            }}
            {...props}
          >
            {option?.code && (
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                alt=""
              />
            )}
            {option.label}
          </Box>
        );
      }}
      renderInput={params => (
        <Input
          {...params}
          placeholder={placeholder}
          label={label}
          error={hasError}
          helperText={errorMessage}
          multiline={multiline}
          autoComplete={autoComplete}
        />
      )}
      noOptionsText={
        <div>
          {isMultiple ? (
            <Box className={styles.noOption}>
              {loading ? (
                'Loading...'
              ) : (
                <Box
                  onClick={() => {
                    if (setInputValue) {
                      setInputValue('');
                    }
                    if (inputValue) {
                      const value = [...rest.value, { label: inputValue, value: inputValue }];
                      if (onAutocompleteChange) {
                        onAutocompleteChange(value);
                      }
                      onChange(value);
                    }
                  }}
                >
                  {inputValue || 'No option'}
                </Box>
              )}
            </Box>
          ) : (
            'No option'
          )}
        </div>
      }
    />
  );
};

export default Object.assign(memo(forwardRef(Autocomplete)), { displayName: 'Autocomplete' });
