import React from 'react';

import { Avatar, Box } from '@mui/material';
import Card from 'components/shared/Card';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import styles from './PublicListingsCard.module.scss';
import inactive from '../../../assets/images/inactive.png';
import { formatDate } from '../../../common/utils/utils';
import PrivateAccess from '../../../components/PrivateAccess';
import Button from '../../../components/shared/Button';
import SvgIcon from '../../../components/shared/utils/SvgIcon';
import { B2, B3 } from '../../../components/Typography/Body';
import { H5 } from '../../../components/Typography/Headlines';
import modalNames from '../../../constants/modalNames';
import { routes } from '../../../constants/routes';
import { userRole } from '../../../constants/userStatus';
import { modalsSlice } from '../../../modals';

interface PublicListingsProps {
  listing: any;
}
const PublicListingsCard = ({ listing }: PublicListingsProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem('token');

  return (
    <Card className={styles.listingsCard}>
      <Card.Content className={styles.content}>
        <div className={styles.imageWrap}>
          <Avatar
            className={styles.image}
            src={
              listing?.gallery?.cover
                ? `${process.env.REACT_APP_BASE_URL}/api/document?path=${listing?.gallery?.cover}`
                : inactive
            }
          />
        </div>
        <div className={styles.info}>
          <div>
            <div className={styles.price}>
              <H5 className={styles.rent}>{`$${listing.rent}`}</H5>
              <B2 className={styles.availableFrom}>Available {formatDate(listing.availableFrom)}</B2>
            </div>

            <H5 className={styles.title}>{listing.title}</H5>
            <B2
              className={styles.address}
              onClick={() =>
                dispatch(modalsSlice.actions.showModal({ modalName: modalNames.LOCATION_MODAL, data: listing?.property_info }))
              }
            >
              <SvgIcon icon={'location'} className={styles.locationIcon} />
              {listing?.property_info?.googleApi?.address?.value}
            </B2>
            <div className={styles.policy}>
              <B3 bold>Pet Policy:</B3>
              <B3>{listing?.property_info?.other?.petWeight ? 'allowed' : 'Pet are not allowed'}</B3>
            </div>
            <div>
              <B2 bold>{`Square feet: ${listing?.leaseDetail?.unitSize || listing?.unit_info?.unitSize} | ${
                listing?.leaseDetail?.bedrooms || listing?.unit_info?.bedrooms
              } 
              Bedroom | ${listing?.leaseDetail?.bathrooms || listing?.unit_info?.bathrooms} Bathroom | Parking spot ${
                listing?.leaseDetail?.parkingSpot || listing?.unit_info?.parkingSpot
              }`}</B2>
            </div>
          </div>
          <Box className={styles.buttons}>
            {token ? (
              <PrivateAccess roles={[userRole.TENANT]}>
                <Button
                  variant="contained"
                  size="full-sm"
                  type="button"
                  label={'Apply now'}
                  onClick={() => navigate(`${routes.application}/1?id=${listing._id}`)}
                />
              </PrivateAccess>
            ) : (
              <Button
                variant="contained"
                size="full-sm"
                type="button"
                label={'Apply now'}
                onClick={() => navigate(`${routes.application}/1?id=${listing._id}`)}
              />
            )}
            <Button
              variant="outlined"
              size="full-sm"
              type="button"
              label="View Details"
              onClick={() => navigate(`${routes.publicListings}/${listing._id}`)}
            />
          </Box>
        </div>
      </Card.Content>
    </Card>
  );
};

export default PublicListingsCard;
