import { createSlice } from '@reduxjs/toolkit';

import { type propertyResDataModel, type MaintenenceState, type maintenanceDetails } from './types';

const initialState: MaintenenceState = {
  queryOptions: {
    page: 1,
    size: '5',
    sortBy: {
      id: '',
      desc: false,
    },
  },
  count: 0,
  isLoading: false,
  categoriItems: [],
  categoriOption: [],
  property: null,
  others: [],
  maintenances: [],
  maintenance: null,
  assigned: [],
};

const maintenenceSlice = createSlice({
  name: 'maintenance',
  initialState,
  reducers: {
    setLoading: (state: MaintenenceState, { payload }) => {
      state.isLoading = payload;
    },
    clearItems: (state: MaintenenceState) => {
      state.categoriOption = [];
    },
    setItems: (state: MaintenenceState, { payload }) => {
      if (payload.query.type === 'REGULAR') {
        state.categoriItems = payload.data;
      }
      if (payload.query.type === 'EXTRA') {
        state.categoriOption = payload.data;
      }
    },
    setPropertyData: (state: MaintenenceState, { payload }: { payload: propertyResDataModel }) => {
      state.property = payload;
    },
    setDocumentsData: (state: MaintenenceState, { payload }: { payload: any }) => {
      state.others = payload;
    },
    setMaintenenceData: (state: MaintenenceState, { payload }: { payload: any }) => {
      state.property = payload;
    },
    setMaintenenceList: (state: MaintenenceState, { payload }: { payload: any }) => {
      state.maintenances = payload.data.items;
      state.count = payload.data.count;
      state.queryOptions = { ...state.queryOptions, ...payload.queryOptions };
    },
    setMaintenanceDetails: (state: MaintenenceState, { payload }: { payload: maintenanceDetails }) => {
      state.maintenance = payload;
    },
    clearMaintenanceList: (state: MaintenenceState) => {
      state.maintenances = null;
      state.queryOptions = initialState.queryOptions;
    },
    setAssigned: (state: MaintenenceState, { payload }: { payload: any }) => {
      state.assigned = payload;
    },
  },
});

export default maintenenceSlice;
