import { useMemo } from 'react';

import { Box } from '@mui/material';
import { FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import Checkbox from 'components/shared/FormElements/Checkbox';
import DatePicker from 'components/shared/FormElements/DatePicker/DatePicker';
import GoogleAddressBox from 'components/shared/GoogleAddressBox';
import ImageUploader from 'components/shared/ImageUploader';
import { B1, B2 } from 'components/Typography/Body';
import { H5 } from 'components/Typography/Headlines';
import dayjs from 'dayjs';
import { Controller, useWatch, type UseFormReturn } from 'react-hook-form';

import styles from './BusinessInfo.module.scss';
import { accountTypeValues, businessAddressName } from '../constants';
import { type IForm } from '../types';

export interface BusinessInfoProps {
  form: UseFormReturn<IForm>;
}
const BusinessInfo = ({ form }: BusinessInfoProps) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = form;
  const isSameAddress = useWatch({ control, name: 'isSameAddress' });
  const accountType = useWatch({ control, name: 'accountType' });
  const isPersonal = useMemo(() => {
    return accountType === accountTypeValues.PERSONAL;
  }, [accountType]);
  const identity = useWatch({ control, name: 'identity' });
  const organizationArticle = useWatch({ control, name: 'organizationArticle' });
  const defaultDate = dayjs(new Date());

  if (isPersonal) {
    return null;
  }
  return (
    <div className={styles.businessInfo}>
      <Box className={styles.inputBox}>
        <Box className={styles.checkboxGrid}>
          <Controller
            name="isSameAddress"
            control={control}
            render={({ field }) => <Checkbox {...field} checked={isSameAddress} />}
          />

          <B2 bold className={styles.title}>
            My physical business address is the same as my home address
          </B2>
        </Box>
        {!isSameAddress && <GoogleAddressBox key={1} form={form} names={businessAddressName} />}
      </Box>
      <H5 bold>Business registration</H5>
      <B1 bold>When did you start doing business?</B1>
      <Box className={styles.inputBox}>
        <Box className={styles.grid}>
          <Controller
            name="businessStartDate"
            control={control}
            render={({ field }) => (
              <DatePicker
                label="start doing business*"
                {...field}
                maxDate={defaultDate}
                errorMessage={errors.businessStartDate?.message}
                hasError={!!errors.businessStartDate}
              />
            )}
          />
        </Box>
      </Box>
      <B1 bold>Are your primary business operations located in the United States?</B1>
      <FormControl component="fieldset" className={styles.detectInformation}>
        <Controller
          control={control}
          name="USALocated"
          render={({ field }) => (
            <RadioGroup {...field} className={styles.group}>
              <FormControlLabel className={styles.radio} value={true} control={<Radio color={'warning'} />} label="Yes" />
              <FormControlLabel className={styles.radio} value={false} control={<Radio color={'warning'} />} label="No" />
            </RadioGroup>
          )}
        />
      </FormControl>
      <H5 bold className={styles.bussinesTitle}>
        Upload Business Document*
      </H5>
      <ImageUploader
        fileTypeText="(jpg, png, pdf)"
        isBig
        imageUrls={identity}
        setImageUrls={(value: File[]) => setValue('identity', value)}
        accept={'image/png, image/jpeg, .pdf'}
        hasErrorBlock={!!errors?.identity}
        message={errors?.identity?.message || null}
      />
      <B2 className={styles.desc}>Article of Organization</B2>
      <ImageUploader
        fileTypeText="(jpg, png, pdf)"
        isBig
        imageUrls={organizationArticle}
        setImageUrls={(value: File[]) => setValue('organizationArticle', value)}
        accept={'image/png, image/jpeg, .pdf'}
        hasErrorBlock={!!errors?.organizationArticle}
        message={errors?.organizationArticle?.message || null}
      />
    </div>
  );
};

export default BusinessInfo;
