import React from 'react';

import { Box } from '@mui/material';
import { Input } from 'components/shared/FormElements';
import Autocomplete from 'components/shared/FormElements/Autocomplete';
import { B1, B3 } from 'components/Typography/Body';
import { Controller, type UseFormReturn, useWatch } from 'react-hook-form';

import styles from './LeaseDetail.module.scss';
import { regexp } from '../../../../constants/regexp';
import { leasDurationOption } from '../../../../constants/utils';
import { listingType, rentDurationOption } from '../constants';
import { type IForm } from '../types';

interface LeaseDetailProps {
  form: UseFormReturn<IForm>;
}
const LeaseDetail = ({ form }: LeaseDetailProps) => {
  const {
    control,
    formState: { errors },
  } = form;

  const listingPropertyType = useWatch({ control, name: 'listingPropertyType' });

  return (
    <div className={styles.leaseDetail}>
      <B1>Lease Detail</B1>
      <B3 className={styles.desc}>Add rent details specific to this unit</B3>
      <Box className={styles.inputBox}>
        <Box className={styles.grid}>
          <Controller
            name="leaseDuration"
            control={control}
            render={({ field }) => (
              <Autocomplete
                label="Lease Duration*"
                autoComplete="off"
                options={leasDurationOption}
                errorMessage={errors?.leaseDuration?.message}
                hasError={!!errors?.leaseDuration}
                {...field}
              />
            )}
          />
          <Controller
            name="rentDuration"
            control={control}
            render={({ field }) => (
              <Autocomplete
                autoComplete="off"
                label="Rent Duration*"
                options={rentDurationOption}
                errorMessage={errors?.rentDuration?.message}
                hasError={!!errors?.rentDuration}
                {...field}
              />
            )}
          />
        </Box>
        <Box className={styles.grid}>
          <Controller
            name="deposit"
            control={control}
            render={({ field }) => (
              <Input
                label="Deposit*"
                regexp={regexp.number}
                errorMessage={errors?.deposit?.message}
                hasError={!!errors?.deposit}
                {...field}
              />
            )}
          />
          <Controller
            name="rent"
            control={control}
            render={({ field }) => (
              <Input
                label="Rent*"
                regexp={regexp.number}
                errorMessage={errors?.rent?.message}
                hasError={!!errors?.rent}
                {...field}
              />
            )}
          />
        </Box>
        {listingPropertyType !== listingType.ENTIRE_PLACE && (
          <>
            <Box className={styles.grid}>
              <Controller
                name="parkingSpot"
                control={control}
                render={({ field }) => (
                  <Input
                    label="Parking spot(s)*"
                    regexp={regexp.number}
                    errorMessage={errors?.parkingSpot?.message}
                    hasError={!!errors?.parkingSpot}
                    {...field}
                  />
                )}
              />
              <Controller
                name="bedrooms"
                control={control}
                render={({ field }) => (
                  <Input
                    label="Bedroom(s)*"
                    regexp={regexp.number}
                    errorMessage={errors?.bedrooms?.message}
                    hasError={!!errors?.bedrooms}
                    {...field}
                  />
                )}
              />
            </Box>
            <Box className={styles.grid}>
              <Controller
                name="unitSize"
                control={control}
                render={({ field }) => (
                  <Input
                    label="Unit Size (sq.ft.)*"
                    regexp={regexp.numericalDigits}
                    errorMessage={errors?.unitSize?.message}
                    hasError={!!errors?.unitSize}
                    {...field}
                  />
                )}
              />
              <Controller
                name="bathrooms"
                control={control}
                render={({ field }) => (
                  <Input
                    label="Bathrooms*"
                    regexp={regexp.number}
                    errorMessage={errors?.bathrooms?.message}
                    hasError={!!errors?.bathrooms}
                    {...field}
                  />
                )}
              />
            </Box>
          </>
        )}
      </Box>
    </div>
  );
};
export default LeaseDetail;
