import { routes } from 'constants/routes';
import { useNavigate } from 'react-router-dom';

import styles from './Reminder.module.scss';
import Button from '../shared/Button';
import Card from '../shared/Card/index';
import { B2, B4 } from '../Typography/Body';

interface IProps {
  text: string;
  note?: string;
  buttonText: string;
  redirectUrl: any;
}

const Reminder = ({ text, note, buttonText, redirectUrl }: IProps) => {
  const navigate = useNavigate();

  const url = routes[redirectUrl as keyof typeof routes];

  const handleRedirect = () => {
    if (url) {
      navigate(url);
    } else {
      console.error(`Invalid redirectUrl: ${redirectUrl}`);
    }
  };

  return (
    <Card>
      <Card.Content>
        <div className={styles.card}>
          <div className={styles.cardText}>
            <B2>{text}</B2>
            {note && <B4>{`NOTE: ${note}`}</B4>}
          </div>
          <Button className={styles.cardButton} variant="contained" type="button" label={buttonText} onClick={handleRedirect} />
        </div>
      </Card.Content>
    </Card>
  );
};

export default Reminder;
