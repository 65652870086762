import toast from 'react-hot-toast';
import { type ForkEffect, call, takeLatest, put, select } from 'redux-saga/effects';

import * as actions from './actions';
import { addManager, getManagerList } from './ManagersService.ts';
import managersSlice from './managersSlice';
import { managerListDataSelector } from './selector';
import { type ManagerstListResModel, type ManagerDataModel } from './types';

function* getManagerListSaga({ payload }: ReturnType<typeof actions.getManagersList>) {
  yield put(managersSlice.actions.setLoading(true));
  try {
    const { data }: { data: { items: ManagerDataModel[]; count: string } } = yield call(getManagerList, payload);

    yield put(managersSlice.actions.setManagerData({ data, queryOptions: payload }));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(managersSlice.actions.setLoading(false));
  }
}

function* addManagerSaga({ payload }: ReturnType<typeof actions.addManagerAction>) {
  try {
    const { data }: { data: ManagerDataModel[] } = yield call(addManager, payload);
    const managerData: ManagerstListResModel = yield select(managerListDataSelector);
    const newData = { items: [data[0], ...managerData.items] as ManagerDataModel[], count: `${Number(managerData.count) + 1}` };
    yield put(managersSlice.actions.addManager(newData));
    toast.success('manager has been successfully invited.');
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  }
}

export function* watchManagerSaga(): Generator<ForkEffect> {
  yield takeLatest(actions.getManagersList.type, getManagerListSaga);
  yield takeLatest(actions.addManagerAction.type, addManagerSaga);
}
