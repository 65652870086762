import { useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionDetails, AccordionSummary } from '@mui/material';
import MuAccordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import cn from 'classnames';

import styles from './Accordion.module.scss';
import { B2 } from '../../Typography/Body';

interface AccordionProps {
  title: string;
  isOpen?: boolean;
  children: any;
}

const Accordion = ({ isOpen = false, title, children }: AccordionProps) => {
  const [open, setOpen] = useState(isOpen);
  return (
    <MuAccordion
      className={cn(styles.accordion, { [styles.openAccordion]: open })}
      expanded={open}
      onChange={() => setOpen(!open)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
        <B2 className={styles.title}>{title}</B2>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{children}</Typography>
      </AccordionDetails>
    </MuAccordion>
  );
};

export default Accordion;
