import Button from 'components/shared/Button';
import modalNames from 'constants/modalNames';
import { modalsSlice } from 'modals';
import { useDispatch, useSelector } from 'react-redux';

import styles from './AddManager.module.scss';
import { userSelector } from '../../../modules/Auth/store/selectors';
import { addManagerAction } from '../store/actions';
import { type IAddManager } from '../store/types';

const AddManager = () => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);

  return (
    <div className={styles.addTenant}>
      <Button
        variant="contained"
        size="sm"
        type="button"
        disabled={user?.status !== 'ACTIVE'}
        label="Add Manager"
        onClick={() =>
          dispatch(
            modalsSlice.actions.showModal({
              modalName: modalNames.ADD_USER_MODAL,
              data: {
                title: 'Add Manager',
                addUser: (data: IAddManager) => {
                  return dispatch(addManagerAction(data));
                },
              },
            })
          )
        }
      />
    </div>
  );
};

export default AddManager;
