import { userRole } from 'constants/userStatus';
import { authSlice } from 'modules/Auth';
import toast from 'react-hot-toast';
import { call, type ForkEffect, put, takeLatest, select } from 'redux-saga/effects';

import * as actions from './actions';
import applicationSlice from './aplicationSlice';
import {
  createApplicationRequestData,
  customizeEmailVerifyData,
  customizeRegisteredDataByInvite,
  paymentCreateDataAdapter,
} from './dataAdapter';
import { customizeFilesName } from './helpers';
import { applicationDataSelector } from './selectors';
import { routes } from '../../../constants/routes';
import { type AccountDataModel, type ApplicationsDataModel } from '../../types/applicationTypes';
import {
  applicationLogin,
  confirmEmailOtp,
  confirmPaymentService,
  createApplication,
  createPaymentMethod,
  deleteFile,
  emailVerify,
  getApplicationById,
  getToken,
  getUnitsById,
  plaidRetrieveBalance,
  plaidVerification,
  registerByInvite,
  uploadImagesAndFilesServices,
} from '../applicationServices';
import { AplicationSteps } from '../constants';

function* getApplicatonsSaga({ payload }: ReturnType<typeof actions.getUnit>) {
  yield put(applicationSlice.actions.setLoading(true));
  try {
    if (payload.listingId) {
      const { data } = yield call(getUnitsById, payload.listingId);
      yield put(applicationSlice.actions.getUnitById(data));
    }
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(applicationSlice.actions.setLoading(false));
  }
}

function* registerApplicationByInvitationSaga({ payload }: ReturnType<typeof actions.registerByInvite>) {
  yield put(applicationSlice.actions.setLoading(true));
  try {
    const requestData = customizeRegisteredDataByInvite(payload.data);
    const { data }: { data: { account: AccountDataModel; token: string } } = yield call(registerByInvite, requestData);
    if (data.token && data.account) {
      yield put(applicationSlice.actions.setAccountToken(data.account));
      localStorage.setItem('token', data.token);
      localStorage.removeItem('passwordToken');
      localStorage.removeItem('email');
      payload.navigate(`${routes.application}/${AplicationSteps.PERSONALINFORMATION}?id=${payload.id}`);
    }
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  }
}

function* emailVerifySaga({ payload }: ReturnType<typeof actions.emailVerify>) {
  yield put(applicationSlice.actions.setLoading(true));
  try {
    const requestData = customizeEmailVerifyData(payload.data);
    const { data }: { data: { uuid: string; email: string } } = yield call(emailVerify, requestData);
    // localStorage.setItem('uuid', data.uuid);
    const response = { ...data, timer: Date.now(), isDisabled: true };
    localStorage.setItem('registeredUser', JSON.stringify(response));

    payload.navigate(`${routes.application}/${AplicationSteps.BEFOREBEGIN}?id=${payload.id}&auth=confirm`);
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(applicationSlice.actions.setLoading(false));
  }
}

function* confirmEmailOtpSaga({ payload }: ReturnType<typeof actions.confirmEmailOtp>) {
  yield put(applicationSlice.actions.setLoading(true));
  try {
    const { data }: { data: { account: AccountDataModel; token: string } } = yield call(confirmEmailOtp, payload.data);
    localStorage.removeItem('uuid');
    localStorage.removeItem('id');
    localStorage.removeItem('registeredUser');
    localStorage.setItem('token', data.token);
    payload.navigate(`${routes.application}/${AplicationSteps.PERSONALINFORMATION}?id=${payload.id}`);
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(applicationSlice.actions.setLoading(false));
  }
}

function* getTenantInviteTokenSaga({ payload }: ReturnType<typeof actions.getApplicationInviteToken>) {
  try {
    if (payload.inviteToken) {
      const { data } = yield call(getToken, payload.inviteToken);
      yield put(applicationSlice.actions.setApplicationData(data?.application));
      yield put(authSlice.actions.setUser(data?.account));
      localStorage.setItem('passwordToken', data.account?.passwordToken);
      localStorage.setItem('id', data?.application?._id);
      localStorage.setItem('email', data.account?.email);
      payload.navigate(`${routes.application}/${AplicationSteps.BEFOREBEGIN}?auth=signup&id=${data.application?.listing}`);
    }
    // yield put(applicationSlice.actions.getUnitById(data));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(applicationSlice.actions.setLoading(false));
  }
}

function* applicationLoginSaga({ payload }: ReturnType<typeof actions.applicationLogin>) {
  yield put(applicationSlice.actions.setLoading(true));
  try {
    const { data } = yield call(applicationLogin, payload.data.login);
    if (data.token) {
      if (data.account && data.account?.role === userRole.LANDLOARD) {
        throw new Error('This email address use for your landlord account. Please enter a different email address.');
      }
      yield put(authSlice.actions.setUser(data.account));
      localStorage.setItem('token', data.token);
      payload.navigate(`${routes.application}/${AplicationSteps.PERSONALINFORMATION}?id=${payload.id}`);
    }
  } catch (error: any) {
    toast.error(error.message ? error.message : error?.response?.data?.message);
  } finally {
    yield put(applicationSlice.actions.setLoading(false));
  }
}

function* createApplicationSaga({ payload }: ReturnType<typeof actions.createApplication>) {
  yield put(applicationSlice.actions.setLoading(true));
  try {
    // if (payload.isDirty) {
    if (Number(payload.data.step) === AplicationSteps.ATTACHDOCUMENTS && payload.documents) {
      const files = customizeFilesName(payload.documents).filter(file => !!file) as File[];
      const id = localStorage.getItem('id');

      if (files.length && id) {
        const { data } = yield uploadImagesAndFilesServices({
          files,
          applicationId: id,
        });
        yield put(applicationSlice.actions.setDocumentsData(data));
        yield put(applicationSlice.actions.setStep(`${AplicationSteps.ATTACHDOCUMENTS}`));
      }
    }
    const applicationData: ApplicationsDataModel = yield select(applicationDataSelector);
    const requestData = createApplicationRequestData(payload, applicationData);
    const { data }: { data: ApplicationsDataModel } = yield call(createApplication, requestData);

    yield put(applicationSlice.actions.setApplicationData(data));
    yield put(applicationSlice.actions.setPlaidLinkToken(null));
    Number(data.step) === AplicationSteps.PERSONALINFORMATION && localStorage.setItem('id', data._id);
    toast.success('Congratulations! Your information has been successfully saved.');
    payload.navigate(`${routes.application}/${payload.nextStep}?id=${payload.id}`);
    if (payload.nextStep === AplicationSteps.CONGRATULATIONS) {
      localStorage.removeItem('id');
    }
    // } else {
    //   yield put(applicationSlice.actions.setStep(payload.nextStep as any));
    //   payload.navigate(`${routes.application}/${payload.nextStep}?id=${payload.id}`);
    // }
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(applicationSlice.actions.setLoading(false));
  }
}

function* getApplicationByIdSaga({ payload }: ReturnType<typeof actions.getApplicationById>) {
  yield put(applicationSlice.actions.setLoading(true));
  try {
    const { data }: { data: ApplicationsDataModel } = yield call(getApplicationById, payload);
    yield put(applicationSlice.actions.setApplicationData(data));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(applicationSlice.actions.setLoading(false));
  }
}

function* deleteFileSaga({ payload }: ReturnType<typeof actions.deleteFile>) {
  try {
    const { data }: { [key: string]: any } = yield call(deleteFile, payload);
    yield put(applicationSlice.actions.setApplicationData(data));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  }
}

function* plaidVerificationSaga({ payload }: ReturnType<typeof actions.plaidVerification>) {
  yield put(applicationSlice.actions.setLoading(true));
  try {
    const { data } = yield call(plaidVerification, payload);
    yield put(applicationSlice.actions.setPlaidLinkToken(data.link_token));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(applicationSlice.actions.setLoading(false));
  }
}

function* plaidRetrieveBalanceSaga({ payload }: ReturnType<typeof actions.plaidRetrieveBalance>) {
  yield put(applicationSlice.actions.setLoading(true));
  try {
    const { data } = yield call(plaidRetrieveBalance, payload);
    console.log(data);
    yield put(applicationSlice.actions.setPlaidLinkToken(null));
    yield put(applicationSlice.actions.setPlaidSuccess(true));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(applicationSlice.actions.setLoading(false));
  }
}

function* paymentAttachMethodSaga({ payload }: ReturnType<typeof actions.paymentAttachMethod>) {
  try {
    const adaptedPaymentCreateData = paymentCreateDataAdapter(payload);
    yield call(createPaymentMethod, adaptedPaymentCreateData);
    yield put(applicationSlice.actions.setPaymentSuccess(true));

    toast.success('Your payment has been successfully saved.');
    payload.CARD_OPTIONS.disabled = true;
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  }
}

function* confirmPaymentSaga({ payload }: ReturnType<typeof actions.confirmPaymentAction>) {
  try {
    yield call(confirmPaymentService, payload);
  } catch (error: any) {
    console.log(error);
  }
}

export function* watchApplicationSaga(): Generator<ForkEffect> {
  yield takeLatest(actions.getUnit.type, getApplicatonsSaga);
  yield takeLatest(actions.createApplication.type, createApplicationSaga);
  yield takeLatest(actions.getApplicationById.type, getApplicationByIdSaga);
  yield takeLatest(actions.deleteFile.type, deleteFileSaga);
  yield takeLatest(actions.getApplicationInviteToken.type, getTenantInviteTokenSaga);
  yield takeLatest(actions.registerByInvite.type, registerApplicationByInvitationSaga);
  yield takeLatest(actions.emailVerify.type, emailVerifySaga);
  yield takeLatest(actions.confirmEmailOtp.type, confirmEmailOtpSaga);
  yield takeLatest(actions.applicationLogin, applicationLoginSaga);
  yield takeLatest(actions.plaidVerification, plaidVerificationSaga);
  yield takeLatest(actions.plaidRetrieveBalance, plaidRetrieveBalanceSaga);
  yield takeLatest(actions.paymentAttachMethod, paymentAttachMethodSaga);
  yield takeLatest(actions.confirmPaymentAction, confirmPaymentSaga);
}
