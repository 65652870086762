import { createAction } from '@reduxjs/toolkit';

import { type IDeletePropertyImage, type IQuery, type IUnit } from '../types';

export const getPropertiesAction = createAction<IQuery>('getPropertiesAction');
export const getPropertiesWithActiveLeasedUnitsAction = createAction<IQuery>('getPropertiesWithActiveLeasedUnitsAction');
export const updatePropertyUnitAction = createAction<{ data: IUnit; id: string }>('updatePropertyUnitAction');
export const addPropertyUnitAction = createAction<{ data: IUnit; id: string }>('addPropertyUnitAction');
export const deleteUnitAction = createAction<{ unitsId: string; propertyId: string }>('deleteUnitAction');
export const deleteImagePropertyAction = createAction<IDeletePropertyImage>('deleteImagePropertyAction');
