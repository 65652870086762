import { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import PrivateAccess from 'components/PrivateAccess';
import Button from 'components/shared/Button';
import { Input } from 'components/shared/FormElements';
import ImageUploader from 'components/shared/ImageUploader';
import { H5 } from 'components/Typography/Headlines';
import { userRole } from 'constants/userStatus';
import { deleteFile } from 'modules/Application/store/actions';
import { userSelector } from 'modules/Auth/store/selectors';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// import Categories from './Categories';
import { createHelpDefaultData } from './constants';
import styles from './Help.module.scss';
import { createHelp, getHelpItems } from './store/actions';
// import { helpItemsSelector } from './store/selector';
import { type IForm } from './types';
import { helpSchema } from './validations/helpSchema';

const Help = () => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  // const helpItems = useSelector(helpItemsSelector);
  const navigate = useNavigate();

  const form = useForm<IForm>({
    defaultValues: { ...createHelpDefaultData },
    resolver: yupResolver<any>(helpSchema),
    mode: 'all',
  });

  const {
    reset,
    control,
    setValue,
    formState: { errors },
  } = form;

  const others = useWatch({ control, name: 'others' }) as File[];

  const onSubmit = (formData: any) => {
    dispatch(createHelp({ formData, navigate }));
    reset();
  };
  useEffect(() => {
    if (user?._id) {
      dispatch(getHelpItems());
    }
  }, [user]);

  useEffect(() => {
    reset();
  }, []);

  const handleDeleteFile = (value: any, controlName?: string): void => {
    const id = localStorage.getItem('id');
    const deleteFileReqData: any = {
      applicationId: id || '',
      key: controlName || '',
      path: value,
    };
    dispatch(deleteFile(deleteFileReqData));
  };

  return (
    <PrivateAccess roles={[userRole.LANDLOARD, userRole.MANAGER, userRole.TENANT]} isPage>
      <div className={styles.createHelp}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className={styles.container}>
            {/* <Categories form={form} title="Categories" description="Select any categories" items={helpItems} name="category" /> */}
            <H5 className={styles.title}>Title*</H5>
            <Box>
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <Input
                    placeholder="Enter Title"
                    className="full-size"
                    errorMessage={errors.title?.message}
                    hasError={!!errors.title}
                    {...field}
                  />
                )}
              />
            </Box>
            <Box className={styles.desc}>
              <H5 className={styles.descTitle}>Description*</H5>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <Input
                    placeholder="Enter Description"
                    multiline
                    rows={5}
                    className="full-size"
                    errorMessage={errors?.description?.message}
                    hasError={!!errors?.description}
                    {...field}
                  />
                )}
              />
            </Box>
            <Box className={styles.documentBox}>
              <H5 className={styles.documentUploaderTitle}>Add Photos</H5>
              <ImageUploader
                isBig
                maxSize={999999}
                multiple
                imageUrls={others}
                accept=".jpeg, .png"
                controlName="others"
                uploaderText="Uploading image"
                setImageUrls={(value: File[]) => setValue('others', value)}
                deleteImage={handleDeleteFile}
                fileTypeText={'(jpg, png, pdf)'}
                hasErrorBlock={true}
                disabled={others?.length === 20}
              />
            </Box>
            <Box>
              <Button
                color={'primary-blue'}
                className={styles.btn}
                variant="contained"
                size="full-lg"
                type="submit"
                label={'Submit'}
              />
            </Box>
          </div>
        </form>
      </div>
    </PrivateAccess>
  );
};

export default Help;
