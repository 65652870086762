import React, { useCallback } from 'react';

import { Box, Modal } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import styles from './PaymentMetodsModal.module.scss';
import Button from '../../components/shared/Button';
import SvgIcon from '../../components/shared/utils/SvgIcon';
import { B1, B4 } from '../../components/Typography/Body';
import { H4 } from '../../components/Typography/Headlines';
import modalNames from '../../constants/modalNames';
import { setPaymentMethodsAction } from '../../modules/PaymentPage/store/actions';
import { type RootState } from '../../store/setupStore';
import { modalsSlice } from '../index';
import { checkModalVisibility, getModalByName } from '../store/selectors';

const PaymentMetodsModal = () => {
  const dispatch = useDispatch();
  const modalData: any = useSelector((store: RootState) => getModalByName(store, modalNames.PAYMENT_METHODS_MODAL));

  const isModalVisible = useSelector((store: RootState) => checkModalVisibility(store, modalNames.PAYMENT_METHODS_MODAL));

  const handleModalClose = useCallback(() => {
    dispatch(modalsSlice.actions.hideModal(modalNames.PAYMENT_METHODS_MODAL));
  }, [dispatch]);
  const handleActivate = () => {
    dispatch(setPaymentMethodsAction(modalData));
  };

  return (
    <Modal open={isModalVisible} onClose={handleModalClose} className={styles.modal}>
      <div className={styles.successRegisteredModal}>
        <header className={styles.header}>
          <H4>Payment</H4>
          <SvgIcon icon="close" width={14} height={14} className={styles.close} onClick={handleModalClose} />
        </header>
        <div className={styles.content}>
          <B1>You have activated automatic payment</B1>
          <B4>Ayment will be via this payment method</B4>
          <Box className={styles.buttons}>
            <Button variant="outlined" size="ds" type="button" label="Cancel" onClick={handleModalClose} />
            <Button variant="contained" size="ds" type="button" label="Save" onClick={() => handleActivate()} />
          </Box>
        </div>
      </div>
    </Modal>
  );
};

export default PaymentMetodsModal;
