import { useCallback, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { getRandomId, unregisterFormById } from 'common/utils/utils';
import Button from 'components/shared/Button/Button';
import { Input } from 'components/shared/FormElements';
import Checkbox from 'components/shared/FormElements/Checkbox/Checkbox';
import SvgIcon from 'components/shared/utils/SvgIcon';
import { B1 } from 'components/Typography/Body';
import { H5 } from 'components/Typography/Headlines';
import { regexp } from 'constants/regexp';
import { hasVehiclesDataSelector, vehiclesDataSelector } from 'modules/Application/store/selectors';
import { type IForm } from 'modules/Application/types';
import { type vehiclesDataModel } from 'modules/types/applicationTypes';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

import styles from './Vehicles.module.scss';

const Vehicles = () => {
  const [vehiclesGroupId, setVehiclesGroupId] = useState<{ id: string }[]>([]);
  const vehiclesData = useSelector(vehiclesDataSelector);
  const hasVehicles = useSelector(hasVehiclesDataSelector);

  const {
    control,
    unregister,
    setValue,
    formState: { errors },
  } = useFormContext<IForm>();
  const isVehicles = useWatch({ control, name: 'hasVehicle' });

  const deleteVehicles = useCallback(
    (id: string) => {
      unregisterFormById(
        [`vehicles.mark${id}`, `vehicles.model${id}`, `vehicles.year${id}`, `vehicles.color${id}`, `vehicles.license${id}`],
        unregister
      );
      const newVehiclesGroup = vehiclesGroupId.filter(petsGroup => petsGroup.id !== id);
      setVehiclesGroupId(newVehiclesGroup);
    },
    [vehiclesGroupId, unregister]
  );

  const addVehicles = useCallback(() => {
    const newVehiclesGroup = [...vehiclesGroupId, { id: getRandomId() }];
    setVehiclesGroupId(newVehiclesGroup);
  }, [vehiclesGroupId]);

  useEffect(() => {
    setValue('hasVehicle', !!hasVehicles);
    if (vehiclesData) {
      const [mainVehicleGroup, ...aditionalVehiclesGroup] = vehiclesData || [];
      setValue('vehicles.mark#', mainVehicleGroup?.mark);
      setValue('vehicles.model#', mainVehicleGroup?.model);
      setValue('vehicles.year#', mainVehicleGroup?.year);
      setValue('vehicles.color#', mainVehicleGroup?.color);
      setValue('vehicles.license#', mainVehicleGroup?.license);
      const newVehicles: { id: string }[] = [];
      if (aditionalVehiclesGroup) {
        aditionalVehiclesGroup.forEach((vehicle: vehiclesDataModel) => {
          const id = getRandomId();
          setValue(`vehicles.mark${id}`, vehicle.mark);
          setValue(`vehicles.model${id}`, vehicle.model);
          setValue(`vehicles.year${id}`, vehicle.year);
          setValue(`vehicles.color${id}`, vehicle.color);
          setValue(`vehicles.license${id}`, vehicle.license);
          newVehicles.push({ id });
        });
      }
      setVehiclesGroupId(newVehicles);
    }
  }, [vehiclesData]);

  return (
    <div className={styles.container}>
      <H5 className={styles.vehiclesTitle}>Vehicle</H5>
      <Box className={styles.inputBox}>
        <Box className={styles.checkboxGrid}>
          <Controller
            name="hasVehicle"
            control={control}
            render={({ field }) => <Checkbox {...field} checked={!!isVehicles} />}
          />
          <B1 bold className={!isVehicles ? `${styles.vehicleText} ${styles.vehicleTextUnchacked}` : styles.vehicleText}>
            I have one or more vehicles
          </B1>
        </Box>
        {!!isVehicles && (
          <div className={styles.vehicleGroup}>
            <Box className={styles.inputBox}>
              <Box className={styles.grid}>
                <Controller
                  name={'vehicles.mark#'}
                  control={control}
                  render={({ field }) => (
                    <Input
                      label="Make"
                      placeholder="Enter your Make"
                      errorMessage={errors?.vehicles ? errors.vehicles['mark#']?.message : ''}
                      hasError={errors?.vehicles && !!errors.vehicles['mark#']}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name={'vehicles.model#'}
                  control={control}
                  render={({ field }) => (
                    <Input
                      label="Model"
                      placeholder="Enter your Vehicle Model"
                      errorMessage={errors?.vehicles ? errors.vehicles['model#']?.message : ''}
                      hasError={errors?.vehicles && !!errors.vehicles['model#']}
                      {...field}
                    />
                  )}
                />
              </Box>
              <Box className={styles.grid}>
                <Controller
                  name={'vehicles.year#'}
                  control={control}
                  render={({ field }) => (
                    <Input
                      label="Year"
                      placeholder="Enter your Vehicle Year"
                      errorMessage={errors?.vehicles ? errors.vehicles['year#']?.message : ''}
                      hasError={errors?.vehicles && !!errors.vehicles['year#']}
                      regexp={regexp.number}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name={'vehicles.color#'}
                  control={control}
                  render={({ field }) => (
                    <Input
                      label="Color"
                      placeholder="Enter your Vehicle Color"
                      errorMessage={errors?.vehicles ? errors.vehicles['color#']?.message : ''}
                      hasError={errors?.vehicles && !!errors.vehicles['color#']}
                      {...field}
                    />
                  )}
                />
              </Box>
              <Box className={styles.grid}>
                <Controller
                  name={'vehicles.license#'}
                  control={control}
                  render={({ field }) => <Input label="License #" placeholder="Enter your Vehicle License " {...field} />}
                />
              </Box>
              {!!vehiclesGroupId.length && (
                <div className={styles.vehicle}>
                  {vehiclesGroupId.map(({ id }) => (
                    <div className={styles.vehicleWrap} key={id}>
                      <div className={styles.closeBox}>
                        <SvgIcon icon={'close'} className={styles.closeIcon} onClick={() => deleteVehicles(id)} />
                      </div>
                      <Box className={styles.inputBox}>
                        <Box className={styles.grid}>
                          <Controller
                            name={`vehicles.mark${id}`}
                            control={control}
                            render={({ field }) => (
                              <Input
                                label="Make"
                                placeholder="Enter your Make"
                                errorMessage={errors?.vehicles ? errors.vehicles[`mark${id}`]?.message : ''}
                                hasError={errors?.vehicles && !!errors.vehicles[`mark${id}`]}
                                {...field}
                              />
                            )}
                          />
                          <Controller
                            name={`vehicles.model${id}`}
                            control={control}
                            render={({ field }) => (
                              <Input
                                label="Model"
                                errorMessage={errors?.vehicles ? errors.vehicles[`model${id}`]?.message : ''}
                                hasError={errors?.vehicles && !!errors.vehicles[`model${id}`]}
                                placeholder="Enter your Vehicle Model"
                                {...field}
                              />
                            )}
                          />
                        </Box>
                        <Box className={styles.grid}>
                          <Controller
                            name={`vehicles.year${id}`}
                            control={control}
                            render={({ field }) => (
                              <Input
                                label="Year"
                                errorMessage={errors?.vehicles ? errors.vehicles[`year${id}`]?.message : ''}
                                hasError={errors?.vehicles && !!errors.vehicles[`year${id}`]}
                                placeholder="Enter your Vehicle Year"
                                regexp={regexp.number}
                                {...field}
                              />
                            )}
                          />
                          <Controller
                            name={`vehicles.color${id}`}
                            control={control}
                            render={({ field }) => (
                              <Input
                                label="Color"
                                placeholder="Enter your Vehicle Color"
                                errorMessage={errors?.vehicles ? errors.vehicles[`color${id}`]?.message : ''}
                                hasError={errors?.vehicles && !!errors.vehicles[`color${id}`]}
                                {...field}
                              />
                            )}
                          />
                        </Box>
                        <Box className={styles.grid}>
                          <Controller
                            name={`vehicles.license${id}`}
                            control={control}
                            render={({ field }) => (
                              <Input label="License #" placeholder="Enter your Vehicle License " {...field} />
                            )}
                          />
                        </Box>
                      </Box>
                    </div>
                  ))}
                </div>
              )}
              <Button
                className={styles.addVehicle}
                disabled={vehiclesGroupId.length === 5}
                type={'button'}
                variant="outlined"
                size="full-lg"
                label="+ Add new vehicle"
                onClick={addVehicles}
              />
            </Box>
          </div>
        )}
      </Box>
    </div>
  );
};

export default Vehicles;
