import styles from './AccessDenied.module.scss';
import accessDinaed from '../../assets/images/accessDinaed.png';
import { B1 } from '../Typography/Body';
const AccessDenied = () => {
  return (
    <div className={styles.accessDenied}>
      <img src={accessDinaed} alt={'Access Denied'} />
      <B1 bold>You don't have permissions to</B1>
      <B1> access this page.</B1>
    </div>
  );
};

export default AccessDenied;
