import { routes } from 'constants/routes';
import toast from 'react-hot-toast';
import { type ForkEffect, call, put, takeEvery, takeLatest, select } from 'redux-saga/effects';

import * as actions from './actions';
import { createTaskRequestData } from './dataAdapter';
import { taskDataSelector, managersAndLandlordsSelector } from './selector';
import taskSlice from './taskSlice';
import {
  createTask,
  getCategory,
  getTaskDetails,
  getTaskList,
  getManagers,
  getPropertis,
  getPropertyByUnitId,
  updateTaskStatus,
  uploadDocuments,
  getManagersAndLandlords,
} from './tasksRequestService';
import { type taskDetails, type TaskState, type propertyResDataModel, type propertyDataModel } from './types';

function* getItemsSaga({ payload }: ReturnType<typeof actions.getItems>) {
  yield put(taskSlice.actions.setLoading(true));
  try {
    const { data } = yield call(getCategory);
    yield put(taskSlice.actions.setItems({ data: data.items, query: payload }));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(taskSlice.actions.setLoading(false));
  }
}

function* getPropertyByUnitIdSaga({ payload }: ReturnType<typeof actions.getProperty>) {
  yield put(taskSlice.actions.setLoading(true));
  try {
    const { data }: { data: propertyResDataModel } = yield call(getPropertyByUnitId, payload);
    yield put(taskSlice.actions.setPropertyData(data));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(taskSlice.actions.setLoading(false));
  }
}

function* createTaskSaga({ payload }: ReturnType<typeof actions.createTask>) {
  yield put(taskSlice.actions.setLoading(true));
  try {
    const taskCreateData: TaskState = yield select(taskDataSelector);
    const managerAndLandlordsData: TaskState = yield select(managersAndLandlordsSelector);
    const requestData = createTaskRequestData(payload.formData, taskCreateData, managerAndLandlordsData);
    const { data }: { data: any } = yield call(createTask, requestData);
    if (payload.formData?.others?.length) {
      const { docData } = yield uploadDocuments(payload.formData.others, data._id);
      yield put(taskSlice.actions.setDocumentsData(docData));
    }
    yield put(taskSlice.actions.setTaskData(data));
    toast.success('Congratulations! Your information has been successfully saved.');
    payload.navigate(routes.tasks);
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(taskSlice.actions.setLoading(false));
  }
}

function* getTasksListSaga({ payload }: ReturnType<typeof actions.getTasksList>) {
  yield put(taskSlice.actions.setLoading(true));
  try {
    const { data }: { data: any } = yield call(getTaskList, payload);
    yield put(taskSlice.actions.setTasksList({ data, queryOptions: payload }));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(taskSlice.actions.setLoading(false));
  }
}

function* getTaskDetailsSaga({ payload }: ReturnType<typeof actions.getTaskDetails>) {
  yield put(taskSlice.actions.setLoading(true));
  try {
    const { data }: { data: taskDetails } = yield call(getTaskDetails, payload);
    yield put(taskSlice.actions.setTaskDetails(data));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(taskSlice.actions.setLoading(false));
  }
}

function* updatetTaskStatusSaga({ payload }: any) {
  yield put(taskSlice.actions.setLoading(true));
  try {
    yield call(updateTaskStatus, payload);
    toast.success('Congratulations! Your information has been successfully saved.');
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(taskSlice.actions.setLoading(false));
  }
}

function* getManagerSaga() {
  yield put(taskSlice.actions.setLoading(true));
  try {
    const { data }: { data: any[] } = yield call(getManagers);
    yield put(taskSlice.actions.setManagers(data));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(taskSlice.actions.setLoading(false));
  }
}

function* getManagersAndLandlordsSaga() {
  yield put(taskSlice.actions.setLoading(true));
  try {
    const { data }: { data: any[] } = yield call(getManagersAndLandlords);
    yield put(taskSlice.actions.setManagersAndLandlords(data));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(taskSlice.actions.setLoading(false));
  }
}

function* getPropertiesSaga() {
  yield put(taskSlice.actions.setLoading(true));
  try {
    const { data }: { data: { count: number; items: propertyDataModel[] } } = yield call(getPropertis);
    yield put(taskSlice.actions.setPropertyList({ data }));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(taskSlice.actions.setLoading(false));
  }
}

export function* watchTaskSaga(): Generator<ForkEffect> {
  yield takeEvery(actions.getItems.type, getItemsSaga);
  yield takeEvery(actions.getProperty.type, getPropertyByUnitIdSaga);
  yield takeLatest(actions.createTask.type, createTaskSaga);
  yield takeLatest(actions.getTasksList.type, getTasksListSaga);
  yield takeLatest(actions.getTaskDetails.type, getTaskDetailsSaga);
  yield takeLatest(actions.updatetTaskStatus.type, updatetTaskStatusSaga);
  // yield takeLatest(actions.managerAssign.type, assignManagerSaga);
  yield takeLatest(actions.getManager.type, getManagerSaga);
  yield takeLatest(actions.getProperties.type, getPropertiesSaga);
  yield takeLatest(actions.getManagersAndLandlords.type, getManagersAndLandlordsSaga);
}
