import React, { useEffect, useMemo } from 'react';

import BackButton from 'components/shared/BackButton/BackButton';
import Loading from 'components/shared/Loading';
import Slider from 'components/shared/Slider';
import { H3, H4 } from 'components/Typography/Headlines';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import styles from './ViewProperty.module.scss';
import Button from '../../../components/shared/Button';
import UserCard from '../../../components/shared/UserCard';
import SvgIcon from '../../../components/shared/utils/SvgIcon';
import { B1, B2, B3 } from '../../../components/Typography/Body';
import modalNames from '../../../constants/modalNames';
import { routes } from '../../../constants/routes';
import { modalsSlice } from '../../../modals';
import { stripeObjectSelector } from '../../Auth/store/selectors';
import { getPropertiesAction } from '../store/actions';
import propertiesSlice from '../store/propertiesSlice';
import { propertyLoadingSelector, propertySelector } from '../store/selectors';
import UnitCard from '../UnitCard';
import { getUtilitiesIconByName } from '../utils';

const ViewProperty = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const loading = useSelector(propertyLoadingSelector);
  const property = useSelector(propertySelector);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const stripeObject = useSelector(stripeObjectSelector);
  const bankAccountName = useMemo(() => {
    if (stripeObject?.bankAccounts?.length) {
      const value = stripeObject.bankAccounts.find(el => el.bank_account_id === property?.payoutBankAccount);
      return `${value?.account_name} ( ${value?.account_number} )`;
    }
    return '-';
  }, [stripeObject, property]);

  useEffect(() => {
    if (params?.id) {
      dispatch(getPropertiesAction({ unit: params.id }));
    }
    return () => {
      dispatch(propertiesSlice.actions.clearProperty());
    };
  }, [params]);

  const images = useMemo(() => {
    const newImages: {
      original: string;
      thumbnail?: string;
      originalHeight?: number;
      thumbnailHeight?: number;
    }[] = [];
    if (property?.gallery) {
      const links = Object.keys(property?.gallery).filter(el => el !== 'cover');
      if (property?.gallery?.cover) {
        newImages.push({
          original: `${baseUrl}/api/document?path=${property?.gallery?.cover}`,
          thumbnail: `${baseUrl}/api/document?path=${property?.gallery?.cover}`,
          originalHeight: 500,
          thumbnailHeight: 80,
        });
      }

      links.forEach(element => {
        newImages.push({
          original: `${baseUrl}/api/document?path=${property?.gallery[element]}`,
          thumbnail: `${baseUrl}/api/document?path=${property?.gallery[element]}`,
          originalHeight: 500,
          thumbnailHeight: 80,
        });
      });
    }
    return newImages;
  }, [property?.gallery]);
  return (
    <div className={styles.viewProperty}>
      <BackButton to={routes.properties} />
      <H3>Property Details</H3>
      {property && (
        <>
          <div className={styles.gallery}>
            <Slider images={images} />
            <div className={styles.title}>
              <div>
                <H4 className={styles.propertyName}>{property?.propertyName}</H4>
                <B2
                  className={styles.address}
                  onClick={() =>
                    dispatch(modalsSlice.actions.showModal({ modalName: modalNames.LOCATION_MODAL, data: property }))
                  }
                >
                  <SvgIcon icon={'location'} className={styles.locationIcon} />
                  {property.googleApi?.address?.value}
                </B2>
                {property?.googleApi?.address2 && (
                  <B2 className={styles.address}>
                    <SvgIcon icon={'location'} className={styles.locationIcon} />
                    {property.googleApi.address2}
                  </B2>
                )}
              </div>
              <Button
                variant="contained"
                className={styles.editButton}
                size="full-sm"
                type="button"
                label="Edit Property"
                onClick={() => navigate(`${routes.createProperty}?unit=${property?._id}`)}
              />
            </div>
          </div>
          {property?.units &&
            property.units.map(unit => (
              <UnitCard key={unit._id} unit={unit} propertyId={property._id} isLast={property?.units?.length === 1} />
            ))}
          {!!property?.amenities?.length && (
            <div className={styles.container}>
              <B1 bold className={styles.title}>
                Amenities
              </B1>
              <div className={styles.items}>
                {property.amenities.map(amenate => (
                  <div key={amenate._id} className={styles.item}>
                    {amenate.name}
                  </div>
                ))}
              </div>
            </div>
          )}
          {!!property?.features?.length && (
            <div className={styles.container}>
              <B1 bold className={styles.title}>
                Features
              </B1>
              <div className={styles.items}>
                {property.features.map(feature => (
                  <div key={feature._id} className={styles.item}>
                    {feature.name}
                  </div>
                ))}
              </div>
            </div>
          )}
          {!!property?.utilities?.length && (
            <div className={styles.container}>
              <B1 bold className={styles.title}>
                Utilities
              </B1>
              <div className={styles.utilities}>
                {property.utilities.map(utiliti => (
                  <div key={utiliti._id} className={styles.utiliti}>
                    <B2 bold>
                      <div className={styles.iconWrap}>
                        <div className={styles.circle}>
                          <SvgIcon icon={getUtilitiesIconByName(utiliti?.utility?.name)} className={styles.untilitiIcon} />
                        </div>

                        <div className={styles.name}>{utiliti?.utility?.name}</div>
                      </div>
                    </B2>
                    <B3>{utiliti?.payer?.toLowerCase()}</B3>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className={styles.container}>
            <B1 bold className={styles.title}>
              Other information
            </B1>
            <div className={styles.otherInfo}>
              <B2 className={styles.label}>Renters insurance</B2>
              <B2>{property?.other?.isRentersInsurance ? 'Yes' : 'No'}</B2>
            </div>
            <div className={styles.otherInfo}>
              <B2 className={styles.label}>Pets allowed</B2>
              <B2>{property?.other?.hasPets ? 'Yes' : 'No'}</B2>
            </div>
            <div className={styles.otherInfo}>
              <B2 className={styles.label}>Laundry</B2>
              <B2>{property?.other?.laundry}</B2>
            </div>
            <div className={styles.otherInfo}>
              <B2 className={styles.label}>Primary Bank Account</B2>
              <B2>{bankAccountName}</B2>
            </div>
          </div>
        </>
      )}
      {!!property?.owners?.length && (
        <div className={styles.grid}>
          {property?.owners.map(owner => (
            <UserCard key={owner._id} email={owner.email} name={owner.fullName} type={'Property owner'} />
          ))}
        </div>
      )}
      {!!property?.managers?.length && (
        <div className={styles.grid}>
          {property?.managers.map(manager => (
            <UserCard key={manager._id} email={manager.email} name={manager?.fullName} type={'Property manager'} />
          ))}
        </div>
      )}
      {loading && <Loading isBig />}
    </div>
  );
};

export default ViewProperty;
