import { useEffect } from 'react';

import ApplicationCard from 'components/shared/ApplicationCard';
import { B1 } from 'components/Typography/Body';
import { H4 } from 'components/Typography/Headlines';
import { getListingByIdAction } from 'modules/Listings/store/actions';
import { listingSelector } from 'modules/Listings/store/selectors';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import Registered from './../../../../assets/images/registered.png';
import styles from './Congratulations.module.scss';

const Congratulations = () => {
  const dispatch = useDispatch();
  const listing = useSelector(listingSelector);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    searchParams.get('id') && dispatch(getListingByIdAction(searchParams.get('id') as string));
  }, []);

  return (
    <div className={styles.container}>
      <ApplicationCard listing={listing} />
      <div className={styles.congratulations}>
        <img src={Registered} alt="" className={styles.registeredImg}></img>
        <H4 className={styles.congratulationsTitle}>Congratulations</H4>
        <H4 className={styles.congratulationsInfo}>your rental application has been approved!</H4>
        <B1 className={styles.congratulationsText}>
          We are thrilled to inform you that your rental application has been successful! Your application has met all the
          necessary criteria, and we are delighted to welcome you as a tenant in our property. Please feel free to reach out to us
          if you have any questions or need further information. We look forward to having you as part of our community.
        </B1>
      </div>
    </div>
  );
};

export default Congratulations;
