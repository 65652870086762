import Menu from './Menu';
import MenuItem from './MenuItem';
import MenuLink from './MenuLink';
import NotificationSection from './NotificationSection';
import SideBar from './SideBar';
import Content from './SideBarContent';
import Footer from './SideBarFooter';
import Header from './SideBarHeader';
import UserMenuItem from './UserMenuItem';

export default Object.assign(SideBar, {
  Header,
  Content,
  Footer,
  MenuItem,
  MenuLink,
  UserMenuItem,
  NotificationSection,
  Menu,
});

export { type Props as SideBarProps } from './SideBar';
