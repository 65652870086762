import { userRole } from './userStatus';
import { type IRoles } from '../common/types';

export const sideBarLinks: { isPrivate: boolean; to: string; title: string; roles: IRoles; icon: string }[] = [
  {
    isPrivate: true,
    to: '/properties',
    title: 'Properties',
    roles: [userRole.LANDLOARD, userRole.MANAGER],
    icon: 'properties',
  },
  {
    isPrivate: true,
    to: '/applicationList',
    title: 'Application',
    roles: [userRole.TENANT],
    icon: 'applications',
  },
  {
    isPrivate: true,
    to: '/listings',
    title: 'Listings',
    roles: [userRole.LANDLOARD, userRole.MANAGER],
    icon: 'listings',
  },
  {
    isPrivate: true,
    to: '/listingsView',
    title: 'Listings',
    roles: [userRole.TENANT],
    icon: 'listings',
  },
  {
    isPrivate: true,
    to: '/applicants',
    title: 'Applicants',
    roles: [userRole.LANDLOARD, userRole.MANAGER],
    icon: 'applications',
  },
  {
    isPrivate: true,
    to: '/lease',
    title: 'Lease',
    roles: [userRole.LANDLOARD, userRole.MANAGER, userRole.TENANT],
    icon: 'lease',
  },
  {
    isPrivate: true,
    to: '/payment',
    title: 'Payment',
    roles: [userRole.TENANT],
    icon: 'list',
  },
  {
    isPrivate: true,
    to: '/paymentList',
    title: 'Payment',
    roles: [userRole.LANDLOARD],
    icon: 'list',
  },
  {
    isPrivate: true,
    to: '/tenants',
    title: 'Tenants',
    roles: [userRole.LANDLOARD, userRole.MANAGER],
    icon: 'tenants',
  },
  {
    isPrivate: true,
    to: '/managers',
    title: 'Managers',
    roles: [userRole.LANDLOARD],
    icon: 'tenants',
  },
  {
    isPrivate: true,
    to: '/maintenance',
    title: 'Maintenance',
    roles: [userRole.LANDLOARD, userRole.MANAGER, userRole.TENANT],
    icon: 'maintenance',
  },
  {
    isPrivate: true,
    to: '/accounting',
    title: 'Accounting',
    roles: [userRole.LANDLOARD],
    icon: 'accounting',
  },
  {
    isPrivate: true,
    to: '/tasks',
    title: 'Tasks',
    roles: [userRole.LANDLOARD, userRole.MANAGER],
    icon: 'tasks',
  },
  // {
  //   isPrivate: true,
  //   to: '/documents',
  //   title: 'Documents',
  //   roles: [userRole.LANDLOARD, userRole.MANAGER],
  //   icon: 'documents',
  // },
  {
    isPrivate: true,
    to: '/notifications',
    title: 'Notifications',
    roles: [],
    icon: 'barNotification',
  },
  {
    isPrivate: true,
    to: '/report',
    title: 'Report',
    roles: [],
    icon: 'report',
  },
  {
    isPrivate: true,
    to: '/analytics',
    title: 'Analytics',
    roles: [],
    icon: 'analytics',
  },
  {
    isPrivate: true,
    to: '/settings',
    title: 'Settings',
    roles: [],
    icon: 'barSettings',
  },
  {
    isPrivate: true,
    to: '/help',
    title: 'Help',
    roles: [userRole.LANDLOARD, userRole.MANAGER, userRole.TENANT],
    icon: 'help',
  },
];
