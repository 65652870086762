import React, { useCallback, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Avatar } from '@mui/material';
import BackButton from 'components/shared/BackButton/BackButton';
import Button from 'components/shared/Button';
import ImageDialog from 'components/shared/ImageDialog';
import SvgIcon from 'components/shared/utils/SvgIcon';
import { B3 } from 'components/Typography/Body';
import { H4 } from 'components/Typography/Headlines';
import { routes } from 'constants/routes';
import dayjs from 'dayjs';
import { userSelector } from 'modules/Auth/store/selectors';
import { useForm } from 'react-hook-form';
import { Document, Page } from 'react-pdf';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { editLeaseSchema } from './validations/editLeaseSchema';
import styles from './ViewLease.module.scss';
import Loading from '../../../components/shared/Loading';
import { userRole } from '../../../constants/userStatus';
import Details from '../components/Details';
import Inshurance from '../components/Inshurance';
import LateFee from '../components/LateFee';
import Owners from '../components/Owners';
import UtilitiesLease from '../components/UtilitiesLease';
import { activeStatuses, leaseDefaultValues } from '../constants';
import { getLeaseByIdAction, sentToSignLeaseAction, updateLeaseAction } from '../store/actions';
import leaseSlice from '../store/leaseSlice';
import { leaseLoadingSelector, leaseSelector } from '../store/selectors';
import { type IForm } from '../types';

const ViewLease = () => {
  const form = useForm<IForm>({ defaultValues: leaseDefaultValues, resolver: yupResolver<any>(editLeaseSchema) });
  const lease = useSelector(leaseSelector);
  const loading = useSelector(leaseLoadingSelector);
  const user = useSelector(userSelector);
  const navigate = useNavigate();
  const [openFullScreen, setOpenFullScreen] = useState(false);
  const [screenImage, setScreenImage] = useState('');
  const isValidStatus =
    activeStatuses.includes(lease?.eSignatureStatus) && (user?.role === userRole.LANDLOARD || user?.role === userRole.MANAGER);

  const dispatch = useDispatch();
  const params = useParams();
  const {
    reset,
    handleSubmit,
    formState: { dirtyFields },
  } = form;
  const onSubmit = (isSave: boolean, data: IForm) => {
    if (isSave) {
      dispatch(updateLeaseAction({ data: { ...data }, navigate, id: params?.id || '', dirtyFields }));
    } else {
      data.tenant = { _id: lease.tenant._id };
      data._id = params.id;
      dispatch(sentToSignLeaseAction({ data: { ...lease, ...data }, navigate }));
    }
  };

  const handleOpenFullScreen = (el: string) => {
    setScreenImage(el);
    setOpenFullScreen(true);
  };

  const handleCloseFullScreen = () => {
    setOpenFullScreen(false);
  };

  const customizeFileName = useCallback(
    (file: File | string) => {
      return (
        (typeof file === 'string' && file.toLowerCase().endsWith('.pdf')) ||
        (file instanceof File && !file.type.includes('image'))
      );
    },
    [lease?.documents]
  );

  useEffect(() => {
    if (params.id) {
      dispatch(getLeaseByIdAction(params.id));
    }
    return () => {
      dispatch(leaseSlice.actions.clearLease());
    };
  }, [params.id]);

  useEffect(() => {
    if (lease) {
      const { leaseDuration, startDate, endDate, nextDueDate, rent, deposit, insurance, gracePeriod, amount } = lease;
      reset({
        leaseDuration,
        startDate: dayjs(new Date(startDate)),
        endDate: dayjs(new Date(endDate)),
        nextDueDate: dayjs(new Date(nextDueDate)),
        rent,
        deposit,
        insurance,
        gracePeriod,
        amount,
      });
    }
  }, [lease]);

  return (
    <>
      <BackButton to={routes.lease} />
      <form className={styles.viewLease}>
        {lease && (
          <>
            {' '}
            <div className={styles.header}>
              {lease && (
                <div>
                  <H4>{lease.property_info?.googleApi.address.value}</H4>
                  <H4 bold>{`( ${lease.property_info?.propertyType.label} ) - ${lease?.unit_info?.unitNumber}`}</H4>
                </div>
              )}
              <Box className={styles.buttons}>
                {isValidStatus && (
                  <>
                    <Button
                      variant="contained"
                      size="full-nm"
                      type="button"
                      label="Send to sign"
                      onClick={handleSubmit((data: IForm) => onSubmit(false, data))}
                    />
                    <Button
                      variant="contained"
                      size="full-nm"
                      type="button"
                      label="Save"
                      onClick={handleSubmit((data: IForm) => onSubmit(true, data))}
                    />
                  </>
                )}

                <Button
                  className={styles.edit}
                  variant="outlined"
                  size="full-nm"
                  type="button"
                  label="Cancel"
                  onClick={() => navigate(routes.lease)}
                />
              </Box>
            </div>
            <Details form={form} isValidStatus={isValidStatus} signed={lease?.eSignatureStatus === 'SIGNED'} />
            <Owners />
            <UtilitiesLease utilites={lease?.property_info?.utilities || []} />
            <LateFee form={form} isValidStatus={isValidStatus} />
            {isValidStatus && <Inshurance form={form} isValidStatus={isValidStatus} />}
            {lease?.eSignatureStatus === 'SIGNED' ? (
              <div className={styles.documents}>
                <B3 className={styles.title}>
                  <SvgIcon icon={'applications'} className={styles.profile} />
                  Photo:
                </B3>

                <Box className={styles.fileBox}>
                  {lease?.documents &&
                    Object.values(lease?.documents)?.map((el: any, index: number) => (
                      <div>
                        {customizeFileName(el) ? (
                          <>
                            <div className={styles.pdfDocumentContainer}>
                              <div className={styles.file}>
                                <Document
                                  onClick={() => handleOpenFullScreen(el)}
                                  file={`${process.env.REACT_APP_BASE_URL}/api/document?path=${el}`}
                                >
                                  <Page pageNumber={1} width={230} />
                                </Document>
                              </div>
                              <div className={styles.fileBlock}></div>
                              <div className={styles.fileName}>
                                <span className={styles.docInfo}>{el}</span>
                              </div>
                            </div>
                          </>
                        ) : (
                          <Avatar
                            className={styles.image}
                            onClick={() => handleOpenFullScreen(el)}
                            key={el as string}
                            src={`${process.env.REACT_APP_BASE_URL}/api/document?path=${el}`}
                            alt={`Uploaded ${index + 1}`}
                          />
                        )}
                      </div>
                    ))}
                  {openFullScreen &&
                    screenImage &&
                    (customizeFileName(screenImage) ? (
                      <ImageDialog
                        imageUrl={`${process.env.REACT_APP_BASE_URL}/api/document?path=${screenImage}`}
                        onClose={handleCloseFullScreen}
                        pdf={true}
                      />
                    ) : (
                      <ImageDialog
                        imageUrl={`${process.env.REACT_APP_BASE_URL}/api/document?path=${screenImage}`}
                        onClose={handleCloseFullScreen}
                      />
                    ))}
                </Box>
              </div>
            ) : (
              ''
            )}
          </>
        )}

        {loading && <Loading isBig />}
      </form>
    </>
  );
};

export default ViewLease;
