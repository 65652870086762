import { useEffect } from 'react';

import Accordion from 'components/shared/Accordion';
import { B1, B2, B3 } from 'components/Typography/Body';
import { H4 } from 'components/Typography/Headlines';
import { getApplicationInviteToken } from 'modules/Application/store/actions';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import ApplicationLogin from './ApplicationLogin';
import ApplicationSignUp from './ApplicationSignUp';
import styles from './BeforeBegin.module.scss';
import { acquaintanceDoc } from './constants';

const BeforeBegin = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const inviteToken = searchParams.get('token');
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    if (inviteToken) {
      dispatch(getApplicationInviteToken({ inviteToken, navigate }));
    }
  }, []);

  const signUpAsTenantOrSignIn = (isSignUp: boolean): void => {
    const activeQuery = !isSignUp ? 'login' : 'signup';
    searchParams.set('auth', activeQuery);
    setSearchParams(searchParams);
  };

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <H4 bold>Rental Application</H4>
        {/* <B3 className={styles.desc}>Evolve Realty & Development</B3> */}
        <B2 className={styles.desc}>
          Please be prepared to pay the application fee as outlined in the property listing. In addition to this rental
          application, you will also be required to provide a copy of a valid form of identification and proof of income.
        </B2>
        <B2 className={styles.desc}>
          To complete this rental application, you must be prepared to provide 3 years of residential history as well as contact
          information for your rental references. You will also be asked to provide information on your monthly income, and please
          note that most properties require that applicant combined gross income is at least three (3) times the monthly rent
          amount.
        </B2>
        <B2 className={styles.desc}> Each resident over the age of 18 must submit a separate rental application.</B2>
        <div className={styles.accordion}>
          {/* <Accordion title={acquaintanceDoc.evolue.title}>
            {acquaintanceDoc.evolue.desc.map(el => (
              <B3 key={el}>{el}</B3>
            ))}
          </Accordion> */}
        </div>
        <div className={styles.accordion}>
          <Accordion title={acquaintanceDoc.guarantors.title}>
            {acquaintanceDoc.guarantors.desc.map(el => (
              <B3 key={el}>{el}</B3>
            ))}
          </Accordion>
        </div>
        <div className={styles.accordion}>
          <Accordion title={acquaintanceDoc.acceptable.title}>
            {acquaintanceDoc.acceptable.desc.map(el => (
              <B3 key={el}>{el}</B3>
            ))}
          </Accordion>
        </div>
        <B1 bold className={styles.fee}>
          Application Fee: $35.00
        </B1>
        {searchParams.get('auth') === 'signup' || searchParams.get('auth') === 'confirm' ? (
          <div>
            <ApplicationSignUp signIn={signUpAsTenantOrSignIn} />
            {/* {searchParams.get('auth') === 'confirm' && <ConfirmEmail />} */}
          </div>
        ) : (
          <div>
            <ApplicationLogin signUp={signUpAsTenantOrSignIn} />
          </div>
        )}
      </div>
      {/* <div className={styles.info}>
        <RentApplying />
      </div> */}
    </div>
  );
};

export default BeforeBegin;
