import { type FC, memo, type PropsWithChildren, type ReactElement, type ReactNode, useContext } from 'react';

import { Box } from '@mui/material';
import cx from 'classnames';

import styles from './Menu.module.scss';
import SvgIcon from '../../components/shared/utils/SvgIcon';
import { SidebarContext } from '../SideBar';

type ChildrenFunction = (api: { isCollapsed: boolean; isActive?: boolean; isHovered?: boolean }) => ReactNode;

export interface Props {
  children: ChildrenFunction | ReactNode;
  className?: string;
  handleMenuClick: (id: string | number, isOpen: boolean) => void;
  id: string | number;
  isOpen: boolean;
  isActive: boolean;
  title: string;
  icon: ReactElement;
}

const Menu: FC<PropsWithChildren<Props>> = ({ className, children, title, icon, id, handleMenuClick, isOpen, isActive }) => {
  const { isCollapsed } = useContext(SidebarContext);

  return (
    <div
      className={cx(
        styles.menu,
        {
          [styles.collapsed as string]: isCollapsed,
          [styles.active as string]: isActive,
        },
        className
      )}
    >
      <Box
        className={cx(styles.content, { [styles.activeMenu as string]: isActive })}
        onClick={() => handleMenuClick(id, isOpen)}
      >
        <div className={styles.wrap}>
          <span className={cx(styles.icon, { [styles.activeIcon as string]: isActive })}>{icon}</span>
          {!isCollapsed && <span className={styles.title}>{title}</span>}
        </div>
        <span className={styles.arrow}>{!isCollapsed && <SvgIcon icon={'home'} />}</span>
      </Box>
      {isOpen && !isCollapsed && <div className={styles.menuContent}>{children}</div>}
    </div>
  );
};

export default Object.assign(memo(Menu), { displayName: 'Menu' });
