import toast from 'react-hot-toast';
import { call, type ForkEffect, put, takeLatest, select } from 'redux-saga/effects';

import * as actions from './actions';
import { profileData, tenantDataAdapter } from './dataAdapter';
import profileSlice from './profileSlice';
import modalNames from '../../../constants/modalNames';
import { routes } from '../../../constants/routes';
import { modalsSlice } from '../../../modals';
import { type RootState } from '../../../store/setupStore';
import authSlice from '../../Auth/store/authSlice';
import { userSelector } from '../../Auth/store/selectors';
import * as profileServices from '../profileServices';

function* createProfileSaga({ payload }: ReturnType<typeof actions.createProfileAction>) {
  yield put(profileSlice.actions.setLoading(true));
  try {
    const user: RootState = yield select(userSelector);
    const requestData = profileData(payload.data, !!user.profile);
    const { data } = yield call(profileServices.createProfile, {
      requestData,
      imageCover: payload.data?.imageCover,
      organizationArticle: payload.data?.organizationArticle,
      identity: payload.data?.identity,
      user,
    });

    yield put(authSlice.actions.setUser({ ...user, status: 'ACTIVE', profile: data }));
    payload.navigate(routes.home);
    toast.success('Congratulations! Your information has been successfully saved.');
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(profileSlice.actions.setLoading(false));
  }
}
function* createTenantProfileSaga({ payload }: ReturnType<typeof actions.createTenantProfileAction>) {
  yield put(profileSlice.actions.setLoading(true));
  try {
    const user: RootState = yield select(userSelector);
    const requestData = tenantDataAdapter(payload.data, !!user.profile);
    const { data } = yield call(profileServices.createTenantProfile, {
      requestData,
      imageCover: payload.data?.imageCover,
      organizationArticle: payload.data?.organizationArticle,
      identity: payload.data?.identity,
      user,
    });

    yield put(authSlice.actions.setUser({ ...user, status: 'ACTIVE', profile: data }));
    payload.navigate(routes.home);
    toast.success('Congratulations! Your information has been successfully saved.');
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(profileSlice.actions.setLoading(false));
  }
}
function* addBankAccountActionSaga({ payload }: ReturnType<typeof actions.addBankAccountAction>) {
  yield put(profileSlice.actions.setLoading(true));
  try {
    const { data } = yield call(profileServices.addBankAccountService, {
      account_number: payload.account_number,
      account_holder_name: payload.account_holder_name,
      routing_number: payload.routing_number,
      account_name: payload.account_name,
    });
    yield put(profileSlice.actions.setBankAccounts(data.stripeObject.bankAccounts));
    yield put(modalsSlice.actions.hideModal(modalNames.ADD_BANK_ACCOUNT));
    toast.success('Your bank account has been successfully saved.');
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(profileSlice.actions.setLoading(false));
  }
}
function* getBankAccountActionSaga() {
  yield put(profileSlice.actions.setLoading(true));
  try {
    const { data } = yield call(profileServices.getBankAccountService);
    yield put(profileSlice.actions.setBankAccounts(data.stripeObject.bankAccounts));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(profileSlice.actions.setLoading(false));
  }
}

function* deleteBankAccountSaga({ payload }: ReturnType<typeof actions.deleteBankAccountAction>) {
  yield put(profileSlice.actions.setLoading(true));
  try {
    yield call(profileServices.deleteBankAccountService, { id: payload });
    yield call(getBankAccountActionSaga);
    yield put(modalsSlice.actions.hideModal(modalNames.DELETE_SMT));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(profileSlice.actions.setLoading(false));
  }
}

export function* watchProfileSaga(): Generator<ForkEffect> {
  yield takeLatest(actions.createProfileAction.type, createProfileSaga);
  yield takeLatest(actions.createTenantProfileAction.type, createTenantProfileSaga);
  yield takeLatest(actions.addBankAccountAction.type, addBankAccountActionSaga);
  yield takeLatest(actions.getBankAccountAction.type, getBankAccountActionSaga);
  yield takeLatest(actions.deleteBankAccountAction.type, deleteBankAccountSaga);
}
