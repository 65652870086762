import React from 'react';

import { Box } from '@mui/system';
import Card from 'components/shared/Card';
import { B3 } from 'components/Typography/Body';
import { routes } from 'constants/routes';
import { useNavigate } from 'react-router-dom';

import styles from './TasksCard.module.scss';
import StatusTag from '../../../components/shared/StatusTag';

const TasksCard = ({ task }: any) => {
  const navigate = useNavigate();

  return (
    <Card className={styles.leaseCard}>
      <Card.Content className={styles.leaseCardContent}>
        <Box onClick={() => navigate(`${routes.taskDetails}?taskId=${task._id}&unitId=${task?.unit?._id}`)}>
          <div className={styles.cardItem}>
            <B3 bold className={styles.cardItemtitle}>
              Title
            </B3>
            <p className={styles.cardItemInfo}>{task?.title}</p>
          </div>
          <div className={styles.cardItem}>
            <B3 bold className={styles.cardItemtitle}>
              Property
            </B3>
            <p className={styles.cardItemInfo}>
              {`${task?.property?.propertyType?.label || ''} ${task?.property?.propertyType?.label ? '|' : ''} ${
                task?.unit?.unitNumber || ''
              } ${task?.property?.propertyType?.label ? '|' : ''} ${task?.property?.googleApi?.address?.value || ''}`}
            </p>
          </div>
          {/* <div className={styles.cardItem}>
            <B3 bold className={styles.cardItemtitle}>
              Tenant name
            </B3>
            <p className={styles.cardItemInfo}>{task?.tenant?.fullName}</p>
          </div> */}
          <div className={styles.cardItem}>
            <B3 bold className={styles.cardItemtitle}>
              Assigned manager
            </B3>
            <p className={styles.cardItemInfo}>{task?.assignee?.email}</p>
          </div>
          <div className={styles.cardItem}>
            <B3 bold className={styles.cardItemtitle}>
              Priority
            </B3>
            <StatusTag label={task?.priority} type={task?.priority} />
          </div>
          <div className={styles.cardItem}>
            <B3 bold className={styles.cardItemtitle}>
              Status
            </B3>
            <StatusTag label={task?.status} type={task?.status} />
          </div>
        </Box>
      </Card.Content>
    </Card>
  );
};

export default TasksCard;
