import {
  type occupantsDataModel,
  type ResidentialDataModel,
  type PersonalInformationDataModel,
  type yourIncomeDataModel,
} from '../../types/applicationTypes';
import { AplicationSteps } from '../constants';
import { type IForm } from '../types';

type addCurrentStepDataModel = {
  [AplicationSteps.RESIDENCEADDRESS]: (data: IForm) => { residential: ResidentialDataModel };
  [AplicationSteps.PERSONALINFORMATION]: (data: IForm) => { personalInformation: PersonalInformationDataModel };
  [AplicationSteps.OCCUPANTS]: (data: IForm) => { coApplicants: occupantsDataModel };
  [AplicationSteps.YOURINCOME]: (data: IForm) => { yourIncome: yourIncomeDataModel };
};
export const addCurrentStepData: addCurrentStepDataModel = {
  [AplicationSteps.RESIDENCEADDRESS]: (data: IForm): { residential: ResidentialDataModel } => {
    return {
      residential: {
        residentialHistory: convertGroupsData(data),
        personRefFirstName: data.personRefFirstName,
        personRefAddress: data.personRefAddress,
        personRefRelationship: data.personRefRelationship,
        personRefPhoneNumber: data.personRefPhoneNumber,
      },
    };
  },
  [AplicationSteps.PERSONALINFORMATION]: (data: IForm): { personalInformation: PersonalInformationDataModel } => {
    return {
      personalInformation: {
        firstName: data.firstName,
        lastName: data.lastName,
        middleName: data.middleName,
        suffix: data?.suffix?.value,
        email: data?.email,
        phones: data.phones ? convertPhonesData(data.phones) : [],
        dateOfBirth: data.dateOfBirth && new Date(data.dateOfBirth),
        SSN: data.SSN ? data.SSN : undefined,
      },
    };
  },
  [AplicationSteps.OCCUPANTS]: (data: IForm): { coApplicants: occupantsDataModel } => {
    return {
      coApplicants: {
        hasPet: data.hasPet,
        pets: data.hasPet ? convertGroupsData(data.pets || {}) : [],
        hasVehicle: data.hasVehicle,
        vehicles: data.hasVehicle ? convertGroupsData(data.vehicles || {}) : [],
        aboveNumber: data.aboveNumber,
        underNumber: data.underNumber,
        moveIn: data.moveIn && new Date(data.moveIn),
      },
    };
  },
  [AplicationSteps.YOURINCOME]: (data: IForm): { yourIncome: yourIncomeDataModel } => {
    return {
      yourIncome: {
        employerName: data.employerName || '',
        employerPhone: data.employerPhone || '',
        monthlyIncome: data.monthlyIncome || '',
        monthlyExpense: data.monthlyExpense || '',
        positionHeld: data.positionHeld || '',
        yearsWorked: data.yearsWorked || '',
        additionalIncome: data.additionalIncome ? convertGroupsData(data.additionalIncome || {}) : [],
      },
    };
  },
};

const convertGroupsData = (address: { [key: string]: any }) => {
  const map = new Map();
  const unitKey = Object.keys(address).filter(el => el.includes('#'));
  unitKey.forEach(el => {
    const [name, index] = el.split('#');
    map.set(index || '1', { ...map.get(index || '1'), [name]: address[el] });
  });
  return [...map.values()];
};

const convertPhonesData = (phones: { [key: string]: any }) => {
  const map = new Map();
  const unitsKey = Object.keys(phones);
  const convetPhoneModel: { [key: string]: string } = { phoneNumber: 'number', phoneType: 'type' };
  unitsKey.forEach(el => {
    const [name, index] = el.split('#');
    map.set(index || '1', { ...map.get(index || '1'), [convetPhoneModel[name]]: phones[el]?.value || phones[el] });
  });
  return [...map.values()];
};

export const customizeFilesName = (files: { IDPhoto?: File[]; proofOfIncome?: File[]; others?: File[] }) => {
  const IDPhoto = files?.IDPhoto?.length ? renameFileName(files.IDPhoto, 'IDPhoto') : [];
  const proofOfIncome = files?.proofOfIncome?.length ? renameFileName(files.proofOfIncome, 'proofOfIncome') : [];
  const others = files?.others?.length ? renameFileName(files.others, 'others') : [];
  const combineFiles = [...IDPhoto, ...proofOfIncome, ...others];

  return combineFiles.filter(el => !!el);
};

const renameFileName = (fileArray: File[] | string[], prefix: string) => {
  if (!fileArray) {
    return [];
  }
  return fileArray.map((file, index) => {
    if (typeof file !== 'string') {
      const filename = file.name;
      const extensionIndex = filename.lastIndexOf('.');
      const extension = filename.slice(extensionIndex);
      const suffix = index + 1;
      const newFileName = `${prefix}-${suffix}${extension}`;
      return new File([file], newFileName, { type: file.type });
    }
    return;
  });
};
