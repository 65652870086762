import { Box } from '@mui/system';
import { formatDate } from 'common/utils/utils';
import Card from 'components/shared/Card';
import StatusTag from 'components/shared/StatusTag';
import { B3 } from 'components/Typography/Body';
import { routes } from 'constants/routes';
import { useNavigate } from 'react-router-dom';

import styles from './PaymentCard.module.scss';

const PaymentCard = (payment: any) => {
  payment = payment?.account;
  const navigate = useNavigate();
  return (
    <Card className={styles.paymentCard}>
      <Card.Content>
        <Box onClick={() => navigate(`${routes.landloardPayment}/${payment?._id}`)}>
          <div className={styles.cardItem}>
            <B3 bold className={styles.cardItemtitle}>
              Payer
            </B3>
            <p className={styles.cardItemInfo}>{payment?.tenant?.fullName}</p>
          </div>
          <div className={styles.cardItem}>
            <B3 bold className={styles.cardItemtitle}>
              Property Name
            </B3>
            <p className={styles.cardItemInfo}>{payment?.property?.propertyName}</p>
          </div>
          <div className={styles.cardItem}>
            <B3 bold className={styles.cardItemtitle}>
              Due Date
            </B3>
            <p className={styles.cardItemInfo}>{formatDate(payment?.dueDate)}</p>
          </div>
          <div className={styles.cardItem}>
            <B3 bold className={styles.cardItemtitle}>
              Category
            </B3>
            <p className={styles.cardItemInfo}>{payment?.type}</p>
          </div>
          <div className={styles.cardItem}>
            <B3 bold className={styles.cardItemtitle}>
              Status
            </B3>
            <StatusTag label={payment?.status} type={payment?.status} />
          </div>
          <div className={styles.cardItem}>
            <B3 bold className={styles.cardItemtitle}>
              Total Paid
            </B3>
            <p className={styles.cardItemInfo}>{payment?.amount}$</p>
          </div>
        </Box>
      </Card.Content>
    </Card>
  );
};

export default PaymentCard;
