import { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@mui/material';
import cn from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { getUrlParams } from '../../../../common/utils/utils';
import Button from '../../../../components/shared/Button';
import { Input } from '../../../../components/shared/FormElements';
import Checkbox from '../../../../components/shared/FormElements/Checkbox';
import PhoneInput from '../../../../components/shared/FormElements/PhoneInput';
import { B2 } from '../../../../components/Typography/Body';
import { H3 } from '../../../../components/Typography/Headlines';
import { routes } from '../../../../constants/routes';
import { userStatus, UStatus } from '../../../../constants/userStatus';
import styles from '../../Auth.module.scss';
import { activationTokenAction, inviteSignUpAction, registrationAction } from '../../store/actions';
import { activationUserSelector, authLoadingSelector, registeredUserSelector, userSelector } from '../../store/selectors';
import { type IRegistration } from '../../store/types';
import { registrationSchema } from '../../validations/validations';
import { defaultValues } from '../Registration/constants';

const RegistrationRightSide = () => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(registrationSchema),
    defaultValues,
  });
  const dispatch = useDispatch();
  const urlParams = getUrlParams();
  const isLoading = useSelector(authLoadingSelector);
  const activationUser = useSelector(activationUserSelector);
  const user = useSelector(userSelector);
  const registeredUser = useSelector(registeredUserSelector);
  const navigate = useNavigate();

  if (user?.status === UStatus.REGISTERED) {
    navigate(routes.profile);
  }
  const onSubmit = (data: IRegistration) => {
    if (data.check) {
      if (activationUser && urlParams.token) {
        dispatch(inviteSignUpAction({ ...data, passwordToken: activationUser.passwordToken }));
      } else {
        dispatch(registrationAction(data));
      }
    }
  };

  useEffect(() => {
    if (user?.status === userStatus.PENDING || registeredUser) {
      navigate(urlParams.token ? `${routes.confirmAccount}?token=${urlParams.token}` : routes.confirmAccount);
    }
  }, [navigate, user, registeredUser]);

  useEffect(() => {
    if (urlParams.token) {
      dispatch(activationTokenAction({ id: urlParams.token }));
    }
  }, [urlParams.token]);

  useEffect(() => {
    if (activationUser) {
      setValue('email', activationUser?.email.trim());
      setValue('phone', activationUser.phone);
      setValue('firstname', activationUser.fullName);
    }
  }, [activationUser]);

  return (
    <Grid item xs={10} sm={6} className={cn(styles.registration, styles.signInRight)}>
      <div className={styles.container}>
        <Box className={styles.headlineBox}>
          <H3 color="var(--additional-grey-1)">Sign Up</H3>
          <B2 color="var(--additional-grey-2)" bold>
            Please enter your details
          </B2>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box className={styles.inputBox}>
            <Box className={styles.grid}>
              <Controller
                name="firstname"
                control={control}
                render={({ field }) => (
                  <Input
                    className="full-size"
                    type="text"
                    label="First Name*"
                    placeholder="Enter your first name"
                    errorMessage={errors.firstname?.message}
                    hasError={!!errors.firstname}
                    {...field}
                  />
                )}
              />
              <Controller
                name="middleName"
                control={control}
                render={({ field }) => (
                  <Input className="full-size" type="text" label="Middle name" placeholder="Enter your middle name" {...field} />
                )}
              />
            </Box>

            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <Input
                  className="full-size"
                  type="text"
                  label="Last Name*"
                  placeholder="Enter your last name"
                  {...field}
                  errorMessage={errors.lastName?.message}
                  hasError={!!errors.lastName}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Input
                  className="full-size"
                  label="Email*"
                  placeholder="Enter your email"
                  disabled={!!activationUser?.email}
                  {...field}
                  errorMessage={errors.email?.message}
                  hasError={!!errors.email}
                />
              )}
            />
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <PhoneInput
                  {...field}
                  label="Phone Number*"
                  placeholder="Enter your phone"
                  errorMessage={errors.phone?.message}
                  hasError={!!errors.phone}
                />
              )}
            />
            <Box className={styles.grid}>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <Input
                    className="full-size"
                    type="password"
                    autoComplete={'new-password'}
                    label="Password*"
                    placeholder="Enter your password"
                    errorMessage={errors.password?.message}
                    hasError={!!errors.password}
                    {...field}
                  />
                )}
              />
              <Controller
                name="confirmPassword"
                control={control}
                render={({ field }) => (
                  <Input
                    className="full-size"
                    type="password"
                    label="Repeat Password*"
                    placeholder="Enter your repeat password"
                    errorMessage={errors.confirmPassword?.message}
                    hasError={!!errors.confirmPassword}
                    {...field}
                  />
                )}
              />
            </Box>
          </Box>
          <Box className={styles.termsAndConditionsBox}>
            <Controller name="check" control={control} render={({ field }) => <Checkbox {...field} />} />

            <B2 bold color="var(--additional-grey-2)" className={cn({ [styles.redText]: errors?.check })}>
              I agree to the{' '}
              <Link
                to={`${process.env.REACT_APP_LANDING_PAGE_URL}/termsOfUse/landlord`}
                className={cn({ [styles.redText]: errors?.check })}
              >
                Term & Conditions
              </Link>
            </B2>
          </Box>
          <Box className={styles.buttonBox}>
            <Button
              variant="contained"
              label="Continue"
              size={'full-lg'}
              type="submit"
              disabled={isLoading}
              loading={isLoading}
            />
          </Box>
        </form>
      </div>
    </Grid>
  );
};

export default RegistrationRightSide;
