import { lazy } from 'react';

import { routes } from 'constants/routes';
import Applicants from 'modules/Applicants';
import ApplicantDetails from 'modules/Applicants/ApplicantDetails';
import Steps from 'modules/Application/Steps/Steps';
import Help from 'modules/Help';
import Home from 'modules/Home';
import CreateListings from 'modules/Listings/CreateListings';
import AssignMaintenence from 'modules/Maintenance/AssignMaintenance/AssignMaintenence';
import MaintenanceDetails from 'modules/Maintenance/MaintenanceDetails';
import SelectProperty from 'modules/Maintenance/SelectProperty';
import Profile from 'modules/Profile';
import CreateProperty from 'modules/Properties/CreateProperty';
import Settings from 'modules/Settings';
import AssignTask from 'modules/Tasks/AssignTask';
import TaskDetails from 'modules/Tasks/TaskDetails';
import { Route } from 'react-router-dom';

const ConfirmAccount = lazy(() => import('modules/Auth/components/ConfirmAcccount'));
const ForgotPassword = lazy(() => import('modules/Auth/components/ForgotPassword'));
const VerifyForgotPassword = lazy(() => import('modules/Auth/components/VerifyForgotPassword'));
const Login = lazy(() => import('modules/Auth/components/Login'));
const NewPassword = lazy(() => import('modules/Auth/components/NewPassword'));
const Registration = lazy(() => import('modules/Auth/components/Registration'));
const Verification = lazy(() => import('modules/Auth/components/Verification'));

import { type IRoutes } from './types';
import Accounting from '../../modules/Accounting';
import Analytics from '../../modules/Analytics';
import Documents from '../../modules/Documents';
import Lease from '../../modules/Lease';
import ChooseProperty from '../../modules/Lease/ChooseProperty';
import CreateLease from '../../modules/Lease/CreateLease';
import ViewLease from '../../modules/Lease/ViewLease';
import Listings from '../../modules/Listings';
import UnitsList from '../../modules/Listings/UnitsList';
import ViewListing from '../../modules/Listings/ViewListing';
import Maintenance from '../../modules/Maintenance';
import Managers from '../../modules/Managers';
import Notifications from '../../modules/Notifications';
import PaymentPage from '../../modules/PaymentPage';
import LandloardPayment from '../../modules/PaymentPage/LandloardPayment';
import PaymentDetails from '../../modules/PaymentPage/LandloardPayment/PaymentDetails';
import ReturnDeposite from '../../modules/PaymentPage/LandloardPayment/ReturnDeposit';
import ViewPayment from '../../modules/PaymentPage/ViewPayment';
import Properties from '../../modules/Properties';
import ViewProperty from '../../modules/Properties/ViewProperty';
import PublicListings from '../../modules/PublicListings';
import ViewPublicListing from '../../modules/PublicListings/ViewPublicListing';
import TenantApplication from '../../modules/TenantApplication';
import Tenants from '../../modules/Tenants';

// eslint-disable-next-line import/order
import Tasks from 'modules/Tasks';

const Units = lazy(() => import('modules/Units'));
const Groups = lazy(() => import('modules/Groups'));
// const Tasks = lazy(() => import('modules/Tasks'));
const Report = lazy(() => import('modules/Report'));

const appRoutes: IRoutes[] = [
  //SideBar

  { path: routes.home, name: 'Home', component: Home },
  { path: routes.properties, name: 'Properties', component: Properties },
  { path: routes.lease, name: 'Lease', component: Lease },
  { path: routes.createLease, name: 'CreateLease', component: CreateLease },
  { path: routes.viewLease, name: 'ViewLease', component: ViewLease },
  { path: routes.leaseProperty, name: 'ChooseProperty', component: ChooseProperty },
  { path: `${routes.properties}/:id`, name: 'Properties', component: ViewProperty },
  { path: routes.createProperty, name: 'CreateProperties', component: CreateProperty },
  { path: routes.units, name: 'Unites', component: Units },
  { path: routes.groups, name: 'Groups', component: Groups },
  { path: routes.listings, name: 'Listings', component: Listings },
  { path: routes.tenantApplication, name: 'TenantApplication', component: TenantApplication },
  { path: `${routes.listings}/:id`, name: 'ViewListing', component: ViewListing },
  { path: `${routes.listingsCreate}/:id`, name: 'listingsUnits', component: UnitsList },
  { path: `${routes.listingsCreate}/:id/:unit`, name: 'ListingsCreate', component: CreateListings },
  { path: `${routes.listingsView}`, name: 'listingsView', component: PublicListings },
  { path: `${routes.listingsView}/:id`, name: 'listingsView', component: ViewPublicListing },
  { path: routes.tenants, name: 'Tenants', component: Tenants },
  { path: routes.managers, name: 'Managers', component: Managers },
  { path: routes.maintenance, name: 'Maintenance', component: Maintenance },
  { path: routes.selectProperty, name: 'SelectProperty', component: SelectProperty },
  { path: routes.assingTask, name: 'AssignTask', component: AssignTask },
  { path: routes.assingMaintenence, name: 'AssignMaintenence', component: AssignMaintenence },
  { path: routes.maintenanceDetails, name: 'MaintenanceDetails', component: MaintenanceDetails },
  { path: routes.maintenance, name: 'maintenence', component: Maintenance },
  { path: routes.accounting, name: 'Accounting', component: Accounting },
  { path: routes.tasks, name: 'Tasks', component: Tasks },
  { path: routes.taskDetails, name: 'taskDetails', component: TaskDetails },
  { path: routes.documents, name: 'Documents', component: Documents },
  { path: routes.notifications, name: 'Notifications', component: Notifications },
  { path: routes.report, name: 'Report', component: Report },
  { path: routes.analytics, name: 'Analytics', component: Analytics },
  { path: routes.settings, name: 'Settings', component: Settings },
  { path: routes.help, name: 'Help', component: Help },
  { path: routes.profile, name: 'Profile', component: Profile },
  { path: routes.applicants, name: 'Applicants', component: Applicants },
  { path: `${routes.applicants}/:id`, name: 'Applicants', component: ApplicantDetails },
  { path: `${routes.paymentPage}`, name: 'PaymentPage', component: PaymentPage },
  { path: `${routes.landloardPayment}`, name: 'LandloardPayment', component: LandloardPayment },
  { path: routes.returnDeposit, name: 'ReturnDeposite', component: ReturnDeposite },
  { path: `${routes.landloardPayment}/:id`, name: 'PaymentDetails', component: PaymentDetails },
  { path: `${routes.paymentPage}/:id`, name: 'ViewPayment', component: ViewPayment },
];

const authRoutes: IRoutes[] = [
  { path: 'login', name: 'Login', component: Login },
  { path: 'registration', name: 'Properties', component: Registration },
  { path: 'confirm-account', name: 'Unites', component: ConfirmAccount },
  { path: 'verification', name: 'Verification', component: Verification },
  { path: 'reset-password', name: 'Groups', component: NewPassword },
  { path: 'forgot-password', name: 'Listings', component: ForgotPassword },
  { path: 'verify-forgot-password', name: 'VerifyForgotPassword', component: VerifyForgotPassword },
];

export const applicationRoutes: IRoutes = { path: ':step', name: 'application', component: Steps };
export const loginSignUpRoutes: IRoutes = { path: ':step', name: 'beforeBegin', component: Steps };

const RouteRenderer = (route: IRoutes) =>
  route.component && <Route key={route.name} path={route.path} element={<route.component />} />;

export { appRoutes, authRoutes, RouteRenderer };
