import Accordion from 'components/shared/Accordion';
import { B3 } from 'components/Typography/Body';

import styles from './EvicationDetails.module.scss';

const EvicationDetails = () => {
  const numbers = ['9097RF6773IKL', '998UI6745FG098', '092332444ER5VF787'];
  return (
    <div>
      {numbers.map(number => (
        <div key={number} className={styles.accordion}>
          <Accordion title={number}>
            <div className={styles.container}>
              <div className={styles.info}>
                <B3 className={styles.title}>Court File Number</B3>
                <B3 className={styles.value}>9097RF6773IKL</B3>
              </div>
              <div className={`${styles.info} ${styles.personMailInfo}`}>
                <B3 className={styles.title}>Address</B3>
                <B3 className={styles.value}>Portland</B3>
              </div>
              <div className={styles.info}>
                <B3 className={styles.title}>State</B3>
                <B3 className={styles.value}>OR</B3>
              </div>
              <div className={styles.info}>
                <B3 className={styles.title}>Country</B3>
                <B3 className={styles.value}>
                  <B3 className={styles.value}>N/A</B3>
                </B3>
              </div>
              <div className={styles.info}>
                <B3 bold className={styles.title}>
                  EVENTS
                </B3>
              </div>
              <div className={styles.info}>
                <B3 className={styles.title}>Record Reported Date</B3>
                <B3 className={styles.value}>01/30/2013</B3>
              </div>
              <div className={styles.info}>
                <B3 className={styles.title}>Filling Date</B3>
                <B3 className={styles.value}>05/05/2017</B3>
              </div>
              <div className={styles.info}>
                <B3 className={styles.title}>Action type</B3>
                <B3 className={styles.value}>Forcible Entry/Detainer</B3>
              </div>
              <div className={styles.info}>
                <B3 className={styles.title}>Filling type</B3>
                <B3 className={styles.value}>Forcible Entry</B3>
              </div>
              <div className={styles.info}>
                <B3 className={styles.title}>Plaintiff</B3>
                <B3 className={styles.value}>N/A</B3>
              </div>
              <div className={styles.info}>
                <B3 className={styles.title}>Amount</B3>
                <B3 className={styles.value}>N/A</B3>
              </div>
              <div className={styles.info}>
                <B3 className={styles.title}>Release Date</B3>
                <B3 className={styles.value}>N/A</B3>
              </div>
              <div className={styles.info}>
                <B3 className={styles.title}>Internal Record ID</B3>
                <B3 className={styles.value}>9089878777</B3>
              </div>
            </div>
          </Accordion>
        </div>
      ))}
    </div>
  );
};

export default EvicationDetails;
