import React, { useEffect, useMemo } from 'react';

import BackButton from 'components/shared/BackButton/BackButton';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import styles from './ViewPublicListing.module.scss';
import { formatDate } from '../../../common/utils/utils';
import PrivateAccess from '../../../components/PrivateAccess';
import Button from '../../../components/shared/Button';
import Loading from '../../../components/shared/Loading';
import Slider from '../../../components/shared/Slider';
import StatusTag from '../../../components/shared/StatusTag';
import SvgIcon from '../../../components/shared/utils/SvgIcon';
import { B1, B2 } from '../../../components/Typography/Body';
import { H3, H5 } from '../../../components/Typography/Headlines';
import modalNames from '../../../constants/modalNames';
import { routes } from '../../../constants/routes';
import { userRole } from '../../../constants/userStatus';
import { modalsSlice } from '../../../modals';
import { listingTypeValue } from '../../Listings/CreateListings/constants';
import { getListingByIdAction } from '../../Listings/store/actions';
import listingsSlice from '../../Listings/store/listingsSlice';
import { listingSelector, listingsLoadingSelector } from '../../Listings/store/selectors';

type Item = {
  _id: string;
  name: string;
  type?: string;
  createdAt: string;
  updatedAt: string;
  isLendloard?: boolean;
  utility?: { name: string; _id?: string };
  payer?: string;
  label?: string;
  value?: string;
};

const ViewPublicListing = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const dispatch = useDispatch();
  const { id } = useParams();
  const listing = useSelector(listingSelector);
  const loading = useSelector(listingsLoadingSelector);
  const token = localStorage.getItem('token');

  const navigate = useNavigate();
  useEffect(() => {
    if (id) {
      dispatch(getListingByIdAction(id));
    }
  }, [id]);
  const property = listing?.propertyId || [];

  useEffect(() => {
    return () => {
      dispatch(listingsSlice.actions.clearListing());
    };
  }, []);

  const utilitesList = useMemo(() => {
    const landloardUtilities: Item[] = [];
    const tenantUtilities: Item[] = [];
    if (property?.utilities?.length) {
      property.utilities.forEach((el: any) => {
        if (el.payer === 'LANDLORD') {
          landloardUtilities.push(el);
        } else {
          tenantUtilities.push(el);
        }
      });
    }

    return { landloardUtilities, tenantUtilities };
  }, [property.utilities]);

  const images = useMemo(() => {
    const newImages: {
      original: string;
      thumbnail?: string;
      originalHeight?: number;
      thumbnailHeight?: number;
    }[] = [];
    if (listing?.gallery) {
      const links = Object.keys(listing?.gallery).filter(el => el !== 'cover');
      if (listing?.gallery?.cover) {
        newImages.push({
          original: `${baseUrl}/api/document?path=${listing?.gallery?.cover}`,
          thumbnail: `${baseUrl}/api/document?path=${listing?.gallery?.cover}`,
          originalHeight: 500,
          thumbnailHeight: 80,
        });
      }

      links.forEach(element => {
        newImages.push({
          original: `${baseUrl}/api/document?path=${listing?.gallery[element]}`,
          thumbnail: `${baseUrl}/api/document?path=${listing?.gallery[element]}`,
          originalHeight: 500,
          thumbnailHeight: 80,
        });
      });
    }
    return newImages;
  }, [listing?.gallery]);

  return (
    <div className={styles.viewPropertyListing}>
      <BackButton to={-1} className={styles.backButton} />
      <H3 className={styles.title}>Listing</H3>
      {listing && (
        <div className={styles.content}>
          <Slider images={images} className={styles.slide} />
          <div className={styles.property}>
            <div className={styles.info}>
              <div className={styles.avelable}>
                <H5 bold>{`${listing?.propertyId?.propertyName}`}</H5>
                <B2 className={styles.availableFrom}>Available: {formatDate(listing.availableFrom)}</B2>
              </div>

              <B2
                className={styles.address}
                onClick={() =>
                  dispatch(modalsSlice.actions.showModal({ modalName: modalNames.LOCATION_MODAL, data: listing?.propertyId }))
                }
              >
                <SvgIcon icon={'location'} className={styles.locationIcon} />
                {listing?.propertyId?.googleApi?.address?.value}
              </B2>

              <H5 bold className={styles.monthlyRent}>
                {`$${listing?.unitId?.monthlyRent}`}
              </H5>
            </div>
          </div>
          <div className={styles.wrap}>
            <B2 className={styles.item}>Kind of property</B2>
            <B2 bold>{listingTypeValue[listing?.listingPropertyType]}</B2>
          </div>
          <div className={styles.wrap}>
            <B2 className={styles.item}>Lease duration</B2>
            <B2 bold>{`${listing.leaseDuration.label}`}</B2>
          </div>
          <div className={styles.wrap}>
            <B2 className={styles.item}>Rent duration</B2>
            <B2 bold>{`${listing.rentDuration.label}`}</B2>
          </div>
          <div className={styles.wrap}>
            <B2 className={styles.item}>Deposit</B2>
            <B2 bold>{`${listing.deposit}$`}</B2>
          </div>
          <div className={styles.wrap}>
            <B2 className={styles.item}>Rent</B2>
            <B2 bold>{`${listing.rent}$`}</B2>
          </div>
          <>
            <div className={styles.wrap}>
              <B2 className={styles.item}>Parking spot(s)*</B2>
              <B2 bold>{listing?.leaseDetail?.parkingSpot || listing?.unitId?.parkingSpot}</B2>
            </div>
            <div className={styles.wrap}>
              <B2 className={styles.item}>Unit Size (sq.ft.)*</B2>
              <B2 bold>{listing?.leaseDetail?.unitSize || listing?.unitId?.unitSize} </B2>
            </div>
            <div className={styles.wrap}>
              <B2 className={styles.item}>Bedroom(s)*</B2>
              <B2 bold>{listing?.leaseDetail?.bedrooms || listing?.unitId?.bedrooms}</B2>
            </div>
            <div className={styles.wrap}>
              <B2 className={styles.item}>Bathrooms*</B2>
              <B2 bold>{listing?.leaseDetail?.bathrooms || listing?.unitId?.bathrooms}</B2>
            </div>
          </>
          <div className={styles.box}>
            <B1 bold className={styles.title}>
              Utilities
            </B1>
            <div className={styles.wraper}>
              <B2 className={styles.name}>Landlord</B2>
              <div className={styles.items}>
                {utilitesList.landloardUtilities.map(el => (
                  <StatusTag
                    value={el?.utility?.name || ''}
                    size={'medium'}
                    key={el?.utility?.name || ''}
                    styles={{ minWidth: '50px' }}
                  />
                ))}
              </div>
            </div>
            <div className={styles.wraper}>
              <B2 className={styles.name}>Tenant</B2>
              <div className={styles.items}>
                {utilitesList.tenantUtilities.map(el => (
                  <StatusTag
                    value={el?.utility?.name || ''}
                    size={'medium'}
                    key={el?.utility?.name || ''}
                    styles={{ minWidth: '50px' }}
                  />
                ))}
              </div>
            </div>
          </div>
          {!!property?.amenities?.length && (
            <div className={styles.container}>
              <B1 bold className={styles.title}>
                Amenities
              </B1>
              <div className={styles.tags}>
                {property.amenities.map((amenate: any) => (
                  <StatusTag
                    value={amenate?.name || ''}
                    size={'medium'}
                    key={amenate?.name || ''}
                    styles={{ minWidth: '50px' }}
                  />
                ))}
              </div>
            </div>
          )}
          {!!property?.features?.length && (
            <div className={styles.container}>
              <B1 bold className={styles.title}>
                Features
              </B1>
              <div className={styles.tags}>
                {property.features.map((feature: any) => (
                  <StatusTag
                    value={feature?.name || ''}
                    size={'medium'}
                    key={feature?.name || ''}
                    styles={{ minWidth: '50px' }}
                  />
                ))}
              </div>
            </div>
          )}
          <B1 bold className={styles.label}>
            Description
          </B1>
          <B2 className={styles.desc}>{listing.description}</B2>

          <B1 className={styles.petDescription}>
            <B1 bold>Pet Policy:</B1>
            <B1>
              {listing?.propertyId?.other?.hasPets
                ? listing?.propertyId?.other?.petDescription
                  ? listing?.propertyId?.other?.petDescription
                  : 'Yes'
                : 'No'}
            </B1>
          </B1>
        </div>
      )}
      {listing && (
        <>
          {token ? (
            <PrivateAccess roles={[userRole.TENANT]}>
              <Button
                variant="contained"
                size="full-sm"
                type="button"
                label={'Apply now'}
                onClick={() => navigate(`${routes.application}/1?id=${listing._id}`)}
              />
            </PrivateAccess>
          ) : (
            <Button
              variant="contained"
              size="full-sm"
              type="button"
              label={'Apply now'}
              onClick={() => navigate(`${routes.application}/1?id=${listing._id}`)}
            />
          )}
        </>
      )}
      {loading && <Loading isBig />}
    </div>
  );
};
export default ViewPublicListing;
