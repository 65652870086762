import toast from 'react-hot-toast';
import { type ForkEffect, call, takeLatest, put } from 'redux-saga/effects';

import * as actions from './actions';
import { addTenantService, getTenantList } from './TenantsService';
import tenantsSlice from './tenantsSlice';
import { type TenantDataModel } from './types';

function* getTenantListSaga({ payload }: ReturnType<typeof actions.getTenantsList>) {
  yield put(tenantsSlice.actions.setLoading(true));
  try {
    const { data }: { data: { items: TenantDataModel[]; count: string } } = yield call(getTenantList, payload);
    yield put(tenantsSlice.actions.setTenantData({ data, queryOptions: payload }));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(tenantsSlice.actions.setLoading(false));
  }
}
function* addTenantSaga({ payload }: ReturnType<typeof actions.addTenantAction>) {
  try {
    const { data } = yield call(addTenantService, payload);
    yield put(tenantsSlice.actions.addTenantSuccess(data));
    toast.success('tenant has been successfully invited.');
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  }
}

export function* watchTenantSaga(): Generator<ForkEffect> {
  yield takeLatest(actions.getTenantsList.type, getTenantListSaga);
  yield takeLatest(actions.addTenantAction.type, addTenantSaga);
}
