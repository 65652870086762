import request from 'services/request';

import { type IDeletePropertyImage, type IQuery, type IUnit } from '../types';

const END_POINTS = {
  getProperty: 'api/property',
  getPropertyWithActiveLeasedUnitsServices: 'api/property/active-leased-units',
  updatePropertyUnit: 'api/unit/update',
  addPropertyUnit: 'api/unit/create',
  deletePropertyUnit: 'api/property/delete',
  deletePropertyImage: 'api/document/property',
};

export function getPropertyServices(payload: IQuery) {
  return request({
    url: payload?.unit
      ? `${END_POINTS.getProperty}/${payload.unit}`
      : `${END_POINTS.getProperty}?limit=${payload?.size}&page=${payload?.page}`,
  });
}

export function getPropertyWithActiveLeasedUnitsServices(payload: IQuery) {
  return request({
    url: `${END_POINTS.getPropertyWithActiveLeasedUnitsServices}?limit=${payload?.size}&page=${payload?.page}`,
  });
}

export function updatePropertyUnit(payload: { id: string; data: IUnit }) {
  return request({
    method: 'PUT',
    url: `${END_POINTS.updatePropertyUnit}/${payload.id}`,
    data: payload.data,
  });
}
export function addPropertyUnit(payload: { id: string; data: IUnit }) {
  return request({
    method: 'POST',
    url: `${END_POINTS.addPropertyUnit}/${payload.id}`,
    data: payload.data,
  });
}
export function deletePropertyUnit(payload: { propertyId: string; unitsId: string }) {
  return request({
    method: 'POST',
    url: `${END_POINTS.deletePropertyUnit}/${payload.propertyId}`,
    data: { unitsId: payload.unitsId },
  });
}

export function deletePropertyImage(payload: IDeletePropertyImage) {
  return request({
    method: 'PATCH',
    url: `${END_POINTS.deletePropertyImage}`,
    data: payload,
  });
}
