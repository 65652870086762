import React, { type ReactNode } from 'react';

import styles from './StatusLine.module.scss';
import { lineColors } from '../../../constants/colors';
import { type IStatus } from '../../../types';
interface StatusLineProps {
  children: ReactNode;
  type: IStatus;
}
const StatusLine = ({ children, type }: StatusLineProps) => {
  return (
    <div className={styles.statusLine}>
      <div className={styles.line} style={lineColors[type]} />
      <div className={styles.children}>{children}</div>
    </div>
  );
};

export default StatusLine;
