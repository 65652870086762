/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
import { useEffect, useState } from 'react';

import riLogo from 'assets/images/riLogo.png';
import Button from 'components/shared/Button';
import Checkbox from 'components/shared/FormElements/Checkbox';
import AttachDocumentDetails from 'components/shared/Tenant/AttachDocumentsDetails/AttachDocumentsDetails';
import CoApplicantsOccupants from 'components/shared/Tenant/CoApplicantsOccupantsDetails/CoApplicantsOccupantsDetails';
import PersonalInformationDetails from 'components/shared/Tenant/PersonalInformationDetails';
import RessidenceAddressDetails from 'components/shared/Tenant/ResidenceAddressDetails/ResidenceAddressDetails';
import YourIncomeDetails from 'components/shared/Tenant/YourIncomeDetails';
import { B2 } from 'components/Typography/Body';
import { H3 } from 'components/Typography/Headlines';
import { confirmPaymentAction, getApplicationById } from 'modules/Application/store/actions';
import { applicationDataSelector } from 'modules/Application/store/selectors';
import { type IForm } from 'modules/Application/types';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import styles from './ReviewAndConfirm.module.scss';
let isFatched = false;
const ReviewAndConfirm = () => {
  const [isOpen, setIsOpen] = useState(false);
  const applicationData = useSelector(applicationDataSelector);
  const dispatch = useDispatch();
  const { control } = useFormContext<IForm>();
  const isAgree = useWatch({ control, name: 'isAgree' });
  useEffect(() => {
    const userId = localStorage.getItem('id');
    if (userId) {
      dispatch(getApplicationById(userId));
    }
  }, []);

  useEffect(() => {
    const userId = localStorage.getItem('id');
    if (userId && !isFatched) {
      isFatched = true;
      dispatch(confirmPaymentAction(userId));
    }
  }, []);

  const giveConsent = () => {
    setIsOpen(true);
  };

  return (
    <div>
      <div className={styles.container}>
        {isOpen && (
          <div className={styles.alertContanier}>
            <div className={styles.alertCard}>
              <div className={styles.alertHeader}>
                <div className={styles.header}>
                  <img src={riLogo} alt="" className={styles.img} />
                  <span onClick={() => setIsOpen(false)}>x</span>
                </div>
              </div>
              <div className={styles.alertContent}>
                <H3 className={styles.title}>Consent Agreement</H3>
                <B2 className={styles.text}>
                  This application is made for the purpose of procuring rental of the herein described premises, and for credit
                  clearance. Everything that I have stated in this application is correct to the best of my knowledge. I
                  understand that you will retain this application whether or not it is approved. You are authorized to
                  investigate my credit, financial, litigation and rental history. I authorize you to obtain reports that may
                  include credit reports, investigative consumer reports, unlawful detainer (eviction) reports, bad check
                  searches, social security number verification and previous tenant and employment history.
                </B2>
                <B2>
                  I hereby agree to release and hold harmless the property, its owners, Rentx Inc, their agents and employees from
                  any and all liability, legal proceedings and costs including attorney’s fees arising out of either the
                  verification of the information contained on this application form or the release of this information to the
                  other parties. All of the above data and information set forth herein including, but not limited to the
                  statement of my assets, income and financial condition is warranted to be true and accurate and to fully and
                  currently state my financial conditions as of the date of this application.
                </B2>
              </div>
              <div className={styles.alertActions}>
                <label>
                  <Controller
                    name="isAgree"
                    control={control}
                    render={({ field }) => <Checkbox {...field} checked={!!isAgree} />}
                  />{' '}
                  I agree to the proposed terms
                </label>
                <div className={styles.alertBtn}>
                  <Button
                    variant="contained"
                    size="full-lg"
                    type="submit"
                    label="Continue"
                    disabled={!isAgree || applicationData.isLoading}
                    loading={applicationData.isLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <B2 className={styles.title}>Personal information</B2>
        <PersonalInformationDetails />
      </div>
      <div className={styles.container}>
        <B2 className={styles.title}>Where You've lived</B2>
        <RessidenceAddressDetails />
      </div>
      <div className={styles.container}>
        <B2 className={styles.title}>Co- Applicants/Occupants</B2>
        <CoApplicantsOccupants />
      </div>
      <div className={styles.container}>
        <B2 className={styles.title}>Income</B2>
        <YourIncomeDetails applicationData={applicationData} />
      </div>
      <div className={styles.container}>
        <B2 className={styles.title}>Attach documents</B2>
        <AttachDocumentDetails />
        <Button variant="contained" size="full-lg" type="button" label="Continue" onClick={giveConsent} />
      </div>
    </div>
  );
};

export default ReviewAndConfirm;
