import { useParams } from 'react-router-dom';

import AttachDocuments from './AttachDocuments/AttachDocuments';
import BeforeBegin from './BeforeBegin';
import IncomeVerefication from './IncomeVerefication/IncomeVerefication';
import Occupants from './Occupants/Occupantas';
import Payment from './Payment/Payment';
import PersonlInformation from './PersonalInformation/PersonalInformation';
import ResidanceAddress from './ResidenceAddress/ResidanceAddress';
import ReviewAndConfirm from './ReviewAndConfirm/ReviewAndConfirm';
import TenantIncome from './TenantIncome/TenantIncome';
import { AplicationSteps } from '../constants';

const Steps = () => {
  const { step } = useParams();

  switch (step) {
    case `${AplicationSteps.BEFOREBEGIN}`:
      return <BeforeBegin />;
    case `${AplicationSteps.PERSONALINFORMATION}`:
      return <PersonlInformation />;
    case `${AplicationSteps.RESIDENCEADDRESS}`:
      return <ResidanceAddress />;
    case `${AplicationSteps.OCCUPANTS}`:
      return <Occupants />;
    case `${AplicationSteps.YOURINCOME}`:
      return <TenantIncome />;
    case `${AplicationSteps.INCOMEVEREFICATIONS}`:
      return <IncomeVerefication />;
    case `${AplicationSteps.ATTACHDOCUMENTS}`:
      return <AttachDocuments />;
    case `${AplicationSteps.PAYFEES}`:
      return <Payment />;
    case `${AplicationSteps.REVIEWANDCONFIRM}`:
      return <ReviewAndConfirm />;
    // case `${AplicationSteps.CONGRATULATIONS}`:
    //   return <Congratulations />;
    default:
      return <BeforeBegin />;
  }
};

export default Steps;
