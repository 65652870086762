import { useEffect, useState } from 'react';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box } from '@mui/material';
import Tab from '@mui/material/Tab';
import Reminder from 'components/Reminder';
import Accordion from 'components/shared/Accordion';
import BackButton from 'components/shared/BackButton/BackButton';
import Button from 'components/shared/Button';
import AttachDocumentDetails from 'components/shared/Tenant/AttachDocumentsDetails/AttachDocumentsDetails';
import CoApplicantsOccupants from 'components/shared/Tenant/CoApplicantsOccupantsDetails/CoApplicantsOccupantsDetails';
import CreditDetails from 'components/shared/Tenant/CreditDetails';
import CriminalDetails from 'components/shared/Tenant/CriminalDetails';
import EvicationDetails from 'components/shared/Tenant/EvicationDetails';
import PersonalInformation from 'components/shared/Tenant/PersonalInformationDetails';
import RessidenceAddressDetails from 'components/shared/Tenant/ResidenceAddressDetails/ResidenceAddressDetails';
import YourIncomeDetails from 'components/shared/Tenant/YourIncomeDetails';
import { H3 } from 'components/Typography/Headlines';
import { routes } from 'constants/routes';
import { getApplicationById } from 'modules/Application/store/actions';
import { applicationDataSelector } from 'modules/Application/store/selectors';
import { getListingByIdAction } from 'modules/Listings/store/actions';
import { listingSelector } from 'modules/Listings/store/selectors';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';

import styles from './ApplicantDetails.module.scss';
import PrivateAccess from '../../../components/PrivateAccess';
import ApplicationCard from '../../../components/shared/ApplicationCard';
import { userRole } from '../../../constants/userStatus';
import { ContractStatus } from '../constants';
import { confirmApplicant } from '../store/actions';

const ApplicantDetails = () => {
  const [value, setValue] = useState('0');
  const path = useParams();
  const dispatch = useDispatch();
  const listing = useSelector(listingSelector);
  const applicationData = useSelector(applicationDataSelector);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const applicantId = searchParams.get('id');
    path && dispatch(getListingByIdAction(path.id as string));
    applicantId && dispatch(getApplicationById(applicantId));
  }, []);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };
  return (
    <PrivateAccess roles={[userRole.LANDLOARD, userRole.MANAGER]} isPage>
      <div className={styles.container}>
        <BackButton to={routes.applicants} />
        <div className={styles.labelContent}>
          <H3 className={styles.label}>Application Details</H3>
          <Box className={styles.buttons}>
            <Button
              variant="error"
              size="nm"
              type="button"
              label="Reject"
              disabled={applicationData?.status === ContractStatus.REJECT}
              onClick={() => dispatch(confirmApplicant({ id: searchParams.get('id'), action: ContractStatus.REJECT }))}
            />
            <Button
              className={styles.edit}
              variant="contained"
              size="nm"
              type="button"
              label="Confirm"
              disabled={applicationData?.status === ContractStatus.CONFIRM}
              onClick={() => dispatch(confirmApplicant({ id: searchParams.get('id'), action: ContractStatus.CONFIRM }))}
            />
          </Box>
        </div>
        {applicationData?.status === ContractStatus.CONFIRM && (
          <Reminder
            text="To complete this process, please proceed to the lease page after confirming the application."
            buttonText="Get started"
            redirectUrl="lease"
          />
        )}
        <ApplicationCard listing={listing} />
        <TabContext value={value}>
          <div className={styles.applicantDetails}>
            <div className={styles.tabsWrap}>
              <TabList
                className={styles.tabs}
                variant="scrollable"
                scrollButtons="auto"
                onChange={handleChange}
                classes={{
                  indicator: styles.indicator,
                }}
                textColor="inherit"
              >
                <Tab label="Application information" value="0" />
                <Tab label="Credit Report" value="1" />
                <Tab label="Evication Report" value="2" />
                <Tab label="Criminal Report" value="3" />
              </TabList>
            </div>
            <TabPanel value="0">
              <div className={styles.accordion}>
                <Accordion title="Personal Information">
                  <PersonalInformation />
                </Accordion>
              </div>
              <div className={styles.accordion}>
                <Accordion title="Where You've lived">
                  <RessidenceAddressDetails />
                </Accordion>
              </div>
              <div className={styles.accordion}>
                <Accordion title="Co- Applicants/Occupants">
                  <CoApplicantsOccupants />
                </Accordion>
              </div>
              <div className={styles.accordion}>
                <Accordion title="Your income">
                  <YourIncomeDetails applicationData={applicationData} />
                </Accordion>
              </div>
              <div className={styles.accordion}>
                <Accordion title="Attach documents">
                  <AttachDocumentDetails />
                </Accordion>
              </div>
            </TabPanel>
            <TabPanel value="1">
              <CreditDetails />
            </TabPanel>
            <TabPanel value="2">
              <EvicationDetails />
            </TabPanel>
            <TabPanel value="3">
              <CriminalDetails />
            </TabPanel>
          </div>
        </TabContext>
      </div>
    </PrivateAccess>
  );
};

export default ApplicantDetails;
