import { createSlice } from '@reduxjs/toolkit';

import { type propertyResDataModel, type TaskState, type taskDetails } from './types';

const initialState: TaskState = {
  queryOptions: {
    page: 1,
    size: '5',
    sortBy: {
      id: '',
      desc: false,
    },
  },
  count: 0,
  isLoading: false,
  categoriItems: [],
  categoriOption: [],
  property: null,
  others: [],
  tasks: [],
  task: null,
  managers: [],
  managersAndLandlords: [],
  properties: [],
};

const taskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    setLoading: (state: TaskState, { payload }) => {
      state.isLoading = payload;
    },
    clearItems: (state: TaskState) => {
      state.categoriOption = [];
    },
    setItems: (state: TaskState, { payload }) => {
      if (payload.query.type === 'REGULAR') {
        state.categoriItems = payload.data;
      }
      if (payload.query.type === 'EXTRA') {
        state.categoriOption = payload.data;
      }
    },
    setPropertyData: (state: TaskState, { payload }: { payload: propertyResDataModel }) => {
      state.property = payload;
    },
    setDocumentsData: (state: TaskState, { payload }: { payload: any }) => {
      state.others = payload;
    },
    setTaskData: (state: TaskState, { payload }: { payload: any }) => {
      state.property = payload;
    },
    setTasksList: (state: TaskState, { payload }: { payload: any }) => {
      state.tasks = payload.data.items;
      state.count = payload.data.count;
      state.queryOptions = { ...state.queryOptions, ...payload.queryOptions };
    },
    setTaskDetails: (state: TaskState, { payload }: { payload: taskDetails }) => {
      state.task = payload;
    },
    setManagers: (state: TaskState, { payload }: { payload: any }) => {
      state.managers = payload.items;
    },
    setManagersAndLandlords: (state: TaskState, { payload }: { payload: any }) => {
      state.managersAndLandlords = payload;
    },
    setPropertyList: (state: TaskState, { payload }) => {
      state.properties = payload?.data?.items;
    },
    clearTasksList: (state: TaskState) => {
      state.tasks = null;
      state.queryOptions = initialState.queryOptions;
    },
    clearTask: (state: TaskState) => {
      state.task = null;
    },
  },
});

export default taskSlice;
