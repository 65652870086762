import { createSlice } from '@reduxjs/toolkit';

import { type NotificationsState } from './types';

const initialState: NotificationsState = {
  isLoading: false,
  notifications: null,
  notification: null,
  count: {
    totalNotifications: 0,
    unReadNotifications: 0,
  },
  queryOptions: {
    page: 1,
    size: '5',
  },
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setLoading: (state: NotificationsState, { payload }) => {
      state.isLoading = payload;
    },
    setQueryOptions: (state: NotificationsState, { payload }) => {
      state.queryOptions = payload;
    },
    getNotificationsSuccessAction: (state: NotificationsState, { payload }) => {
      state.notifications = payload;
      state.count = payload.count;
    },
    updateNotificationStatus: (state: NotificationsState, { payload }) => {
      if (state.notification) {
        state.notification.status = payload.status;
      }
    },
    archiveNotification: (state: NotificationsState, { payload }) => {
      if (state.notification) {
        state.notification.archive = payload.archive;
      }
    },
    isFlaggedNotification: (state: NotificationsState, { payload }) => {
      if (state.notification) {
        state.notification.isFlagged = payload.isFlagged;
      }
    },
  },
});

export default notificationsSlice;
