import { type ReactNode } from 'react';

import cn from 'classnames';

import styles from './Content.module.scss';

export interface ContentProps {
  children: ReactNode;
  className?: string;
}

const Content = ({ children, className }: ContentProps) => {
  return <div className={cn(styles.content, className)}>{children}</div>;
};

export default Content;
