import { Currency } from 'constants/currency';

export const autocompleteOptions = {
  suffix: [
    { label: ' Jr', value: 'JR' },
    { label: 'Ir', value: 'IR' },
    { label: 'II', value: 'II' },
    { label: 'III', value: 'III' },
    { label: 'IV', value: 'IV' },
    { label: 'V', value: 'V' },
  ],
  phoneType: [
    { label: ' Mobile', value: 'MOBILE' },
    { label: 'Home', value: 'HOME' },
    { label: 'Office', value: 'OFFICE' },
  ],
};

export const ResidanceAddressName = (id: string) => ({
  address: { name: `address${id}`, label: 'Address*' },
  country: { name: `country${id}`, label: 'Country' },
  address1: { name: `address1${id}`, label: 'Address Name 2' },
  state: { name: `state${id}`, label: 'State/Provance/Region' },
  zipCode: { name: `zipCode${id}`, label: 'Zip/Postal Cod' },
  city: { name: `city${id}`, label: ' City' },
});

export const BillingAddressName = {
  address: { name: `billingAddress`, label: 'Address*' },
  country: { name: `billingCountry`, label: 'Country' },
  address1: { name: `billingAddress1`, label: 'Address Name 2' },
  state: { name: `billingState`, label: 'State/Provance/Region' },
  zipCode: { name: `billingZipCode`, label: 'Zip/Postal Cod' },
  city: { name: `billingCity`, label: ' City' },
};

export const CurrencyOptions = [{ label: Currency.USD, value: 'usd' }];
