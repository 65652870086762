import React, { useEffect, useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import BackButton from 'components/shared/BackButton/BackButton';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { createLeaseDefaultValue } from './constants';
import styles from './CreateLease.module.scss';
import { createLeaseSchema } from './validations/createLeaseSchema';
import { scrollToFirstErrorInput } from '../../../common/utils/utils';
import Button from '../../../components/shared/Button';
import Loading from '../../../components/shared/Loading';
import PropertyViewCard from '../../../components/shared/PropertyViewCard';
import StatusTag from '../../../components/shared/StatusTag';
import UserCard from '../../../components/shared/UserCard';
import { H4 } from '../../../components/Typography/Headlines';
import { routes } from '../../../constants/routes';
import { userSelector } from '../../Auth/store/selectors';
import { getPropertiesAction } from '../../Properties/store/actions';
import { propertyLoadingSelector, propertySelector } from '../../Properties/store/selectors';
import { type IUnit } from '../../Properties/types';
import Details from '../components/Details';
import Documents from '../components/Documents';
import Inshurance from '../components/Inshurance';
import LateFee from '../components/LateFee';
import Tenants from '../components/Tenants';
import UtilitiesLease from '../components/UtilitiesLease';
import { createLeaseAction, sentToSignLeaseAction } from '../store/actions';
import { leaseLoadingSelector } from '../store/selectors';
import { type IForm } from '../types';

const CreateLease = () => {
  const dispatch = useDispatch();
  const loading = useSelector(propertyLoadingSelector);
  const leaseloading = useSelector(leaseLoadingSelector);
  const property = useSelector(propertySelector);
  const navigate = useNavigate();
  const form = useForm({ defaultValues: { ...createLeaseDefaultValue }, resolver: yupResolver<any>(createLeaseSchema) });
  const {
    setValue,
    control,
    formState: { errors },
  } = form;
  const isSigned = useWatch({ control, name: 'isSigned' });
  const user = useSelector(userSelector);
  const params = useParams();

  useEffect(() => {
    scrollToFirstErrorInput(errors);
  }, [errors]);

  const onSubmit = (isSave: boolean, data: IForm) => {
    if (isSave) {
      dispatch(createLeaseAction({ data: { ...data, unitId: params?.unit, propertyId: params?.id }, navigate }));
    } else {
      dispatch(sentToSignLeaseAction({ data: { ...data, unitId: params?.unit, propertyId: params?.id }, navigate }));
    }
  };

  useEffect(() => {
    dispatch(getPropertiesAction({ unit: params?.id }));
  }, [params?.id]);

  const unit: IUnit | null | undefined = useMemo(() => {
    if (property) {
      return property.units.find(el => el._id === params?.unit);
    }
    return null;
  }, [property]);

  return (
    <>
      <BackButton to={routes.leaseProperty} />
      <form className={styles.createLease}>
        {(loading || leaseloading) && <Loading isBig />}
        {property && (
          <>
            <div className={styles.header}>
              <div>
                <H4>{property.googleApi.address.value}</H4>
                <H4 bold>{`( ${property.propertyType.label} ) - ${unit?.unitNumber}`}</H4>
              </div>
              <Box className={styles.buttons}>
                {!isSigned && (
                  <Button
                    variant="contained"
                    size="full-nm"
                    type="button"
                    label="Send to sign"
                    onClick={form.handleSubmit((data: IForm) => onSubmit(false, data))}
                  />
                )}
                <Button
                  variant="contained"
                  size="full-nm"
                  type="submit"
                  label="Save"
                  onClick={form.handleSubmit((data: IForm) => onSubmit(true, data))}
                />
                <Button
                  className={styles.edit}
                  variant="outlined"
                  size="full-nm"
                  type="button"
                  label="Cancel"
                  onClick={() => navigate(routes.leaseProperty)}
                />
              </Box>
            </div>
            <div className={styles.status}>
              <StatusTag
                label={'UNSIGNED'}
                styles={{ minWidth: '120px' }}
                size={'medium'}
                type={isSigned ? 'END' : 'ACTIVE'}
                className={styles.item}
                onClick={() => setValue('isSigned', false)}
              />
              <StatusTag
                label={'SIGNED'}
                type={!isSigned ? 'END' : 'ACTIVE'}
                styles={{ minWidth: '120px' }}
                size={'medium'}
                className={styles.item}
                onClick={() => setValue('isSigned', true)}
              />
            </div>
            <Details form={form} signed={isSigned} />
            <PropertyViewCard
              property={property}
              monthlyRent={unit?.monthlyRent || ''}
              to={`${routes.properties}/${property._id}`}
            />
            {property?.companyId && (
              <UserCard
                email={property?.companyId?.companyCreator.email}
                name={property?.companyId?.companyCreator.fullName}
                type="Landlord"
                className={styles.userCard}
                src={`${process.env.REACT_APP_BASE_URL}/api/document?path=${user?.profile?.profilePic}`}
              />
            )}
            <Tenants form={form} />
            <UtilitiesLease utilites={property.utilities} />
            <LateFee form={form} />
            {!isSigned && <Inshurance form={form} />}
            {isSigned && <Documents form={form} />}
          </>
        )}
      </form>
    </>
  );
};

export default CreateLease;
