import { useEffect, useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, useMediaQuery } from '@mui/material';
import Loading from 'components/shared/Loading';
import TableCard from 'components/shared/TableCard';
import { B1, B2 } from 'components/Typography/Body';
import dayjs from 'dayjs';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import styles from './Accounting.module.scss';
import AccountingCard from './AccountingCard';
import { accountingValues } from './constants';
import { getAccountingAction } from './store/actions';
import { accountingSelector, accountingLoadingSelector } from './store/selectors';
import { type IForm } from './types';
import { accountingSchema } from './validations/accountingSchema';
import PrivateAccess from '../../components/PrivateAccess';
import Button from '../../components/shared/Button';
import Autocomplete from '../../components/shared/FormElements/Autocomplete/Autocomplete';
import DatePicker from '../../components/shared/FormElements/DatePicker/DatePicker';
import { userRole } from '../../constants/userStatus';
import { getPropertiesAction } from '../Properties/store/actions';
import { propertiesListSelector } from '../Properties/store/selectors';

const Accounting = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { ...accountingValues },
    resolver: yupResolver<IForm>(accountingSchema),
  });
  const dispatch = useDispatch();
  const maxDate = dayjs(new Date());
  const minDate = useWatch({ control, name: 'startDate' });
  const isMobileScreen = useMediaQuery('(max-width: 600px)');
  const loading = useSelector(accountingLoadingSelector);
  const propertiesList = useSelector(propertiesListSelector);
  const accountingList = useSelector(accountingSelector);

  useEffect(() => {
    dispatch(getPropertiesAction({ size: '10000', page: 1 }));
    dispatch(getAccountingAction({ propertyId: '', startDate: null, endDate: null }));
  }, []);

  const memoColumns = useMemo(() => {
    const columns = [
      {
        Header: 'Property Name',
        id: 'propertyName',
        title: 'Property Name',
        Cell: ({ row }: any) => {
          return (
            <div className={styles.accounting}>
              <B1 bold className={styles.propertyName}>
                {`${row.original.propertyName} - ${row.original.propertyAddress}`}
              </B1>
            </div>
          );
        },
      },
      {
        Header: 'Rent Income',
        id: 'rentIncome',
        title: 'Rent Income',
        Cell: ({ row }: any) => {
          return (
            <div className={styles.accounting}>
              <B1 bold className={styles.rentIncome}>
                {row.original.rentIncome}
              </B1>
            </div>
          );
        },
      },
      {
        Header: 'Late Fee Income',
        id: 'lateFee',
        title: 'Late Fee Income',
        Cell: ({ row }: any) => {
          return (
            <div className={styles.accounting}>
              <B1 bold className={styles.lateFeeIncome}>
                {row.original.lateFeeIncome}
              </B1>
            </div>
          );
        },
      },
      {
        Header: 'Deposit Income',
        id: 'depositIncome',
        title: 'Deposit Income',
        Cell: ({ row }: any) => {
          return (
            <div className={styles.accounting}>
              <B1 bold className={styles.depositIncome}>
                {row.original.depositIncome}
              </B1>
            </div>
          );
        },
      },
      {
        Header: 'Total Income',
        id: 'totalIncome',
        title: 'Total Income',
        Cell: ({ row }: any) => {
          return (
            <div className={styles.accounting}>
              <B1 bold className={styles.totalIncome}>
                {row.original.totalIncome}
              </B1>
            </div>
          );
        },
      },
    ];
    return columns;
  }, []);

  const onSubmit = (data: IForm) => {
    dispatch(
      getAccountingAction({
        propertyId: data.property?.value || '',
        startDate: data.startDate,
        endDate: data.endDate,
      })
    );
  };

  return (
    <PrivateAccess roles={[userRole.LANDLOARD]} isPage>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.filters}>
        <Box className={styles.inputBox}>
          <Box className={styles.grid}>
            <Controller
              name="property"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  autoComplete={'off'}
                  label="Property"
                  options={
                    propertiesList?.length ? [...propertiesList.map(el => ({ value: el._id, label: el.propertyName }))] : []
                  }
                  errorMessage={errors?.property?.message}
                  hasError={!!errors?.property}
                  {...field}
                />
              )}
            />
            <Box className={styles.grid}>
              <Controller
                name="startDate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    label="Start Date*"
                    {...field}
                    errorMessage={errors.startDate?.message}
                    hasError={!!errors.startDate}
                    maxDate={maxDate}
                  />
                )}
              />
              <Controller
                name="endDate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    label="End Date*"
                    {...field}
                    errorMessage={errors?.endDate?.message}
                    hasError={!!errors.endDate}
                    // maxDate={maxDate}
                    minDate={minDate}
                  />
                )}
              />
            </Box>
          </Box>
        </Box>
        <div className={styles.action}>
          <Button variant="contained" size="ds" label="Search" type="submit" />
        </div>
      </form>
      <div>
        {!!accountingList?.length && (
          <div>
            {isMobileScreen ? (
              accountingList.map(account => <AccountingCard key={account.propertyId} account={account} />)
            ) : (
              <TableCard className={styles.applicantTabel} columns={memoColumns} data={accountingList} />
            )}
          </div>
        )}
        {loading && <Loading isBig />}
        {!accountingList?.length && !loading && (
          <div className={styles.emptyContainer}>
            <B1 bold>No Accounting Found</B1>
            <B2>You're yet to receive any accounting</B2>
          </div>
        )}
      </div>
    </PrivateAccess>
  );
};

export default Accounting;
