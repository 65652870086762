import { createSlice } from '@reduxjs/toolkit';

import { type ProfileState } from './types';

const initialState: ProfileState = {
  isLoading: false,
  bankAccounts: null,
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setLoading: (state: ProfileState, { payload }) => {
      state.isLoading = payload;
    },
    setBankAccounts: (state: ProfileState, { payload }) => {
      state.bankAccounts = payload;
    },
  },
});

export default profileSlice;
