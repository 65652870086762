import { createSlice } from '@reduxjs/toolkit';

import { type AuthState } from './types';

const initialState: AuthState = {
  isLoading: false,
  user: null,
  registeredUser: null,
  activationUser: null,
  isVerify: false,
  error: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state: AuthState, { payload }) => {
      state.user = payload;
    },
    setActivationUser: (state: AuthState, { payload }) => {
      state.activationUser = payload;
    },
    setLoading: (state: AuthState, { payload }) => {
      state.isLoading = payload;
    },
    setIsVerify: (state: AuthState, { payload }) => {
      state.isVerify = payload;
    },
    setRegisteredUser: (state: AuthState, { payload }) => {
      state.registeredUser = payload;
    },
    clearAuthState: () => {
      return initialState;
    },
    setError: (state: AuthState, { payload }) => {
      state.error = payload;
    },
  },
});

export default authSlice;
