import React, { useEffect, useMemo, useState } from 'react';

import { B3 } from 'components/Typography/Body';
import { H5 } from 'components/Typography/Headlines';
import { Controller, type UseFormReturn, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import styles from './PrimaryManager.module.scss';
import useDebouncedCallback from '../../../../common/utils/utils';
import Autocomplete from '../../../../components/shared/FormElements/Autocomplete';
import { getManagersList } from '../../../Managers/store/actions';
import { managerSelector } from '../../../Managers/store/selector';
import { type IForm } from '../types';

interface PrimaryManagerProps {
  form: UseFormReturn<IForm>;
}
const PrimaryManager = ({ form }: PrimaryManagerProps) => {
  const {
    control,
    formState: { errors },
  } = form;
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');
  const manager = useSelector(managerSelector);
  const managerEmail = useWatch({ name: 'managerEmail', control });

  useEffect(() => {
    dispatch(getManagersList({ limit: '10000', page: 1 }));
  }, []);

  const handleSearch = useDebouncedCallback(
    (val: string) => {
      if (val.length) {
        // dispatch(getItems({ type: 'EXTRA', name: name, value: val }));
      } else {
        // dispatch(createPropertiesSlice.actions.clearItems(name));
      }
    },
    400,
    []
  );

  const handleValueChange = useDebouncedCallback(
    (val: string) => {
      setInputValue(val);
    },
    500,
    []
  );

  const filteredOptions = useMemo(() => {
    const options = manager?.data?.items
      ? manager?.data?.items.map(item => {
          return {
            account: item._id,
            email: item.email,
          };
        })
      : [];
    const newOptions = options.filter(item => !managerEmail.map((item: any) => item.email).includes(item.email));

    return newOptions.map(el => ({ ...el, label: el.email }));
  }, [manager, managerEmail]);

  return (
    <div className={styles.primaryManager}>
      <H5 className={styles.title}>Who will be the primary manager of this property?</H5>
      <B3 className={styles.desc}>
        If the staff member has not yet been added as a user in your account, they can be added to the account, then as the
        manager later through the property's summary details.
      </B3>
      <Controller
        name="managerEmail"
        control={control}
        render={({ field }) => (
          <Autocomplete
            isMultiple
            inputValue={inputValue}
            setInputValue={setInputValue}
            className={styles.input}
            label={'Manager Email'}
            loading={false}
            onAutocompleteChange={() => {
              setInputValue('');
            }}
            onInputChange={(e: unknown, newInputValue: string) => {
              handleSearch(newInputValue);
              handleValueChange(newInputValue);
            }}
            options={filteredOptions}
            errorMessage={errors.managerEmail?.message}
            hasError={!!errors.managerEmail}
            {...field}
            value={field.value || []}
          />
        )}
      />
    </div>
  );
};

export default PrimaryManager;
