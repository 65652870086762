import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import cn from 'classnames';
import { Controller, type UseFormReturn } from 'react-hook-form';

import styles from './PropertySwitcher.module.scss';
import { B1, B3 } from '../../../../components/Typography/Body';
import { listingType } from '../constants';
import { type IForm } from '../types';

interface PropertySwitcherProps {
  form: UseFormReturn<IForm>;
}
const PropertySwitcher = ({ form }: PropertySwitcherProps) => {
  const { control, setValue } = form;
  const handleSwitch = (value: string) => {
    setValue('listingPropertyType', value);
  };
  return (
    <div className={styles.propertySwitcher}>
      <FormControl component="fieldset" className={styles.form}>
        <Controller
          control={control}
          name={'listingPropertyType'}
          render={({ field }) => (
            <RadioGroup {...field} className={styles.group}>
              <button
                className={styles.button}
                onClick={() => {
                  handleSwitch(listingType.ENTIRE_PLACE);
                }}
                type={'button'}
              >
                <FormControlLabel
                  className={styles.radio}
                  value={listingType.ENTIRE_PLACE}
                  control={<Radio color="warning" />}
                  label=""
                />
                <div className={cn(styles.wrap, { [styles.activeBorder]: false })}>
                  <B1 bold className={styles.title}>
                    Entire Place
                  </B1>
                  <B3 className={styles.desc}>Tenants have the whole place for themselves </B3>
                </div>
              </button>
              <button
                className={styles.button}
                onClick={() => {
                  handleSwitch(listingType.PRIVATE_ROOM);
                }}
                type="button"
              >
                <FormControlLabel
                  className={styles.radio}
                  value={listingType.PRIVATE_ROOM}
                  control={<Radio color="warning" />}
                  label=""
                />
                <div className={cn(styles.wrap, { [styles.activeBorder]: false })}>
                  <B1 bold className={styles.title}>
                    Private Room
                  </B1>
                  <B3 className={styles.desc}>
                    Tenants have their own privat room.Common areas may be shared with other tenants
                  </B3>
                </div>
              </button>
              <button
                className={styles.button}
                onClick={() => {
                  handleSwitch(listingType.SHARED_ROOM);
                }}
                type="button"
              >
                <FormControlLabel
                  className={styles.radio}
                  value={listingType.SHARED_ROOM}
                  control={<Radio color="warning" />}
                  label=""
                />
                <div className={cn(styles.wrap, { [styles.activeBorder]: false })}>
                  <B1 bold className={styles.title}>
                    Shared Room
                  </B1>
                  <B3 className={styles.desc}>Tenants share dormitories with others</B3>
                </div>
              </button>
            </RadioGroup>
          )}
        />
      </FormControl>
    </div>
  );
};
export default PropertySwitcher;
