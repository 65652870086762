import { Box } from '@mui/material';
import { B1 } from 'components/Typography/Body';
import { Controller } from 'react-hook-form';

import styles from './LateFee.module.scss';
import { Input } from '../../../../components/shared/FormElements';
import { CurrencyOptions } from '../../../../constants/currency';
import { regexp } from '../../../../constants/regexp';

interface LateFeeProps {
  form: any;
  isValidStatus?: boolean;
}

const LateFee = ({ form, isValidStatus = true }: LateFeeProps) => {
  const {
    control,
    formState: { errors },
  } = form;
  return (
    <div className={styles.lateFee}>
      <B1 bold>Late fee</B1>
      <Box className={styles.inputBox}>
        <Box className={styles.grid}>
          <Controller
            name="gracePeriod"
            control={control}
            render={({ field }) => (
              <Input
                label="Grace Period*"
                // selectedOption={CurrencyOptions[0]}
                disabled={!isValidStatus}
                regexp={regexp.number}
                errorMessage={errors.gracePeriod?.message}
                hasError={!!errors.gracePeriod}
                placeholder="Select Fixed amount"
                {...field}
              />
            )}
          />
          <Controller
            name="amount"
            control={control}
            render={({ field }) => (
              <Input
                label="Amount*"
                options={CurrencyOptions}
                // selectedOption={CurrencyOptions[0]}
                regexp={regexp.number}
                disabled={!isValidStatus}
                errorMessage={errors.amount?.message}
                hasError={!!errors.amount}
                placeholder="Enter Amount"
                {...field}
              />
            )}
          />
        </Box>
      </Box>
    </div>
  );
};

export default LateFee;
