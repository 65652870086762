import React, { memo, useEffect, useState } from 'react';

import cx from 'classnames';
import { useTable, useSortBy, useRowSelect, usePagination, useFlexLayout } from 'react-table';

import styles from './TableCard.module.scss';
import { B3 } from '../../Typography/Body';
import Pagination from '../Pagination';
import SvgIcon from '../utils/SvgIcon';

export interface Props {
  className?: string;
  defaultPage?: number;
  defaultPageSize?: string;
  size?: 'sm' | 'md' | 'lg';
  columns: any[];
  data: any[];
  defaultSortBy?: { id: string; desc: boolean }[];
  onSort?: (props: { pageSize: number; sortBy: any }) => void;
  fetchData?: (props: { pageSize: string | number; pageIndex: number }) => void;
  totalItems?: number;
  onRowClick?: (row: any, id: number, e: any) => void;
  setPageSize?: (size: string) => void;
  hidePagination?: boolean;
  hiddenColumns?: string[];
}

const TableCard: React.FC<Props> = ({
  className,
  data,
  columns,
  size,
  onSort,
  setPageSize,
  defaultSortBy,
  defaultPageSize,
  fetchData,
  defaultPage = 1,
  onRowClick = () => ({}),
  totalItems = 0,
  hidePagination = false,
  hiddenColumns = [],
}) => {
  const [pageCount, setPageCount] = useState(0);
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30,
      width: 100,
      maxWidth: 300,
    }),
    []
  );

  const tableProps = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: defaultSortBy ?? [],
        pageIndex: defaultPage ?? 0,
        pageSize: defaultPageSize ?? 10,
        hiddenColumns,
      },
      manualSortBy: true,
      disableSortRemove: true,
      manualPagination: true,
      pageCount,
      defaultColumn,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    useFlexLayout
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    state: { pageSize, sortBy },
  } = tableProps;

  useEffect(() => {
    if (defaultPage === 0) {
      gotoPage(defaultPage);
    }
  }, [defaultPage]);

  useEffect(() => {
    setPageCount(Math.ceil(totalItems / pageSize));
  }, [totalItems, pageSize]);

  useEffect(() => {
    onSort?.({ sortBy, pageSize });
  }, [onSort, sortBy, pageSize]);

  const handleRowClick = (row: unknown, event: React.MouseEvent, id: number) => {
    const selection = window?.getSelection()?.toString() ?? '';
    if (selection.length > 0) {
      event.preventDefault();
    } else {
      onRowClick(row, id, event);
    }
  };

  return (
    <div>
      <table {...getTableProps()} className={cx(styles.table, styles[size as string], className)}>
        <>
          <thead>
            {headerGroups.map((headerGroup: any) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps({ title: column.render('Header') }))}>
                    <B3 bold className={styles.headerLabel}>
                      {column.render('Header')}
                      <span>
                        {column.isSorted ? (
                          <SvgIcon
                            className={cx(styles.arrowIcon, { [styles.isSortedIcon]: column.isSortedDesc })}
                            icon={'arrow'}
                          />
                        ) : (
                          ''
                        )}
                      </span>
                    </B3>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row: any) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} onClick={event => handleRowClick(row, event, row.original.id)}>
                  {row.cells.map((cell: any) => {
                    return (
                      <td className={cell.column.className} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </>
      </table>

      {!hidePagination && (
        <div className={styles.paginationWrap}>
          <Pagination
            gotoPage={page => {
              gotoPage(page);
              if (fetchData && defaultPage !== page) {
                fetchData({ pageIndex: page, pageSize: defaultPageSize || 5 });
              }
            }}
            setPageSize={setPageSize}
            page={defaultPage}
            pageSize={`${defaultPageSize}`}
            pageCount={totalItems}
          />
        </div>
      )}
    </div>
  );
};

export default memo(TableCard);
