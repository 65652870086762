import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Hidden } from '@mui/material';
import cn from 'classnames';
import Button from 'components/shared/Button';
import { Input } from 'components/shared/FormElements';
import { B2 } from 'components/Typography/Body';
import { H3 } from 'components/Typography/Headlines';
import { routes } from 'constants/routes';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import styles from '../../Auth.module.scss';
import { logInAction } from '../../store/actions';
import { authLoadingSelector } from '../../store/selectors';
import { type ILogIn } from '../../store/types';
import { loginSchema } from '../../validations/validations';

const LoginRightSide = () => {
  const isLoading = useSelector(authLoadingSelector);
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver<any>(loginSchema),
    defaultValues: {
      email: null,
      password: null,
    },
  });

  const onSubmit = (data: ILogIn) => {
    dispatch(logInAction(data));
  };

  return (
    <Grid item xs={10} sm={6} className={cn(styles.logIn, styles.signInRight)}>
      <div className={styles.container}>
        <Box className={styles.headlineBox}>
          <H3 color="var(--additional-grey-1)">Sign In</H3>
          <Hidden smDown>
            <B2 color="var(--additional-grey-2)" bold>
              Please sign in to your account
            </B2>
          </Hidden>
          <Hidden smUp>
            <B2 color="var(--additional-grey-2)"> Fill in your phone number and password </B2>
          </Hidden>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box className={styles.inputBox}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Input
                  label="Email"
                  placeholder="Enter your email"
                  errorMessage={errors.email?.message}
                  hasError={!!errors.email}
                  {...field}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <Input
                  type="password"
                  label="Password"
                  placeholder="Enter your password"
                  errorMessage={errors.password?.message}
                  hasError={!!errors.password}
                  {...field}
                />
              )}
            />
          </Box>
          <B2 bold className={styles.forgotLink}>
            <Link to={routes.forgotPassword}>Forgot Password?</Link>
          </B2>
          <Box className={styles.buttonBox}>
            <Button variant="contained" label="Log In" size={'full-lg'} type="submit" disabled={isLoading} loading={isLoading} />
            {/*<B1 bold color="var(--additional-grey-3)">*/}
            {/*  or*/}
            {/*</B1>*/}
            {/*<SocialButton type="google" />*/}
            {/*<SocialButton type="facebook" />*/}
          </Box>
          <Box className={styles.createAccountBox}>
            <B2 color="var(--additional-grey-3)" bold>
              Don’t have an account ? <Link to={routes.registration}>Create an account</Link>
            </B2>
          </Box>
        </form>
      </div>
    </Grid>
  );
};

export default LoginRightSide;
