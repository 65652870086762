import cn from 'classnames';
import SvgIcon from 'components/shared/utils/SvgIcon';
import { B3 } from 'components/Typography/Body';
import {
  hasPetsDataSelector,
  hasVehiclesDataSelector,
  occupantsAboveNumberSelector,
  occupantsUnderNumberSelector,
  petsDataSelector,
  vehiclesDataSelector,
} from 'modules/Application/store/selectors';
import { type petsDataModel } from 'modules/types/applicationTypes';
import { useSelector } from 'react-redux';

import styles from './CoApplicantsOccupantsDetails.module.scss';

const CoApplicantsOccupants = () => {
  const petsData = useSelector(petsDataSelector);
  const vehiclesData = useSelector(vehiclesDataSelector);
  const hasVehicles = useSelector(hasVehiclesDataSelector);
  const hasPets = useSelector(hasPetsDataSelector);
  const occupantsAboveNumber = useSelector(occupantsAboveNumberSelector);
  const occupantsUnderNumber = useSelector(occupantsUnderNumberSelector);

  return (
    <div className={styles.container}>
      {hasPets && (
        <div className={styles.prevAddress}>
          <B3 className={styles.title}>
            <SvgIcon icon={'pet'} className={styles.profile} />
            Pets
          </B3>
          {petsData?.map((pet: petsDataModel | undefined) => {
            return (
              <div key={pet?.name}>
                <div className={styles.prevAddressRow}>
                  <B3 className={styles.value}>{pet?.breed}</B3>
                  <B3>
                    {`${pet?.name ? pet?.name + `,` : ''} ${pet?.age ? pet?.age + `age,` : ''} ${
                      pet?.weight ? pet?.weight + `kg` : ''
                    }`}
                  </B3>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {hasVehicles && (
        <div className={styles.prevAddress}>
          <B3 className={styles.title}>
            <SvgIcon icon={'vehicles'} className={styles.profile} />
            Vehicles
          </B3>
          {vehiclesData?.map((vehicle: any) => {
            return (
              <div>
                <div className={styles.prevAddressRow} key={vehicle.license}>
                  <B3 className={styles.value}>{vehicle.mark}</B3>

                  <B3>
                    {' '}
                    {`${vehicle?.model ? vehicle?.model + `,` : ''} ${vehicle?.year ? `Year ` + vehicle?.year + `,` : ''} ${
                      vehicle?.color ? vehicle?.color + ',' : ''
                    } ${vehicle?.license ? `License: ` + vehicle?.license : ''}`}
                  </B3>
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div className={cn(styles.personInfo, styles.borderButtonLine)}>
        <B3 className={styles.title}>
          <SvgIcon icon={'users'} className={cn(styles.profile, styles.iconLight)} />
          Adults
        </B3>
        <B3 className={styles.mnorsAdults}>{occupantsAboveNumber} people</B3>
      </div>
      <div className={styles.personInfo}>
        <B3 className={styles.title}>
          <SvgIcon icon={'users'} className={cn(styles.profile, styles.iconLight)} />
          Minors
        </B3>
        <B3 className={styles.mnorsAdults}>{occupantsUnderNumber} people </B3>
      </div>
    </div>
  );
};

export default CoApplicantsOccupants;
