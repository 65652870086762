import * as Yup from 'yup';

import { structureTypes } from '../constants';

const sameAddress = (isSameAddress: boolean, accountType: string, isTenant: boolean) =>
  isTenant ? false : accountType === 'PERSONAL' || isSameAddress;
const businessSameAddress = (isSameAddress: boolean, accountType: string) => !isSameAddress && accountType !== 'PERSONAL';

export const profileSchema = Yup.object().shape({
  address: Yup.object().when(['isSameAddress', 'accountType', 'isTenant'], {
    is: sameAddress,
    then: schema => schema.required('Required field'),
    otherwise: schema => schema.nullable(),
  }),
  city: Yup.string().when(['isSameAddress', 'accountType', 'isTenant'], {
    is: sameAddress,
    then: schema => schema.required('Required field'),
  }),
  country: Yup.object().when(['isSameAddress', 'accountType', 'isTenant'], {
    is: sameAddress,
    then: schema => schema.required('Required field'),
    otherwise: schema => schema.nullable(),
  }),
  state: Yup.string().when(['isSameAddress', 'accountType', 'isTenant'], {
    is: sameAddress,
    then: schema => schema.required('Required field'),
  }),
  zipCode: Yup.string().when(['isSameAddress', 'accountType', 'isTenant'], {
    is: sameAddress,
    then: schema => schema.required('Required field'),
  }),
  firstName: Yup.string().required('Required field'),
  lastName: Yup.string().required('Required field'),
  dateOfBirth: Yup.string()
    .required('Required field')
    .test('is-adult', 'You must be at least 18 years old', function (value) {
      const currentDate = new Date();
      const minimumDate = new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate());
      const dobDate = new Date(value);
      return dobDate <= minimumDate;
    }),
  accountType: Yup.string(),
  SSN: Yup.string().required('Required field').min(9, 'Invalid Social Security Number').max(9, 'Invalid Social Security Number'),
  isSameAddress: Yup.boolean(),
  wheatherSsn: Yup.string(),
  useDbn: Yup.string(),
  companyName: Yup.string().when(['accountType'], {
    is: (accountType: string) => accountType !== 'PERSONAL',
    then: schema => schema.required('Required field'),
  }),
  companyAddress: Yup.object().when(['accountType'], {
    is: (accountType: string) => accountType !== 'PERSONAL',
    then: schema => schema.required('Required field'),
  }),
  structure: Yup.object().when(['accountType'], {
    is: (accountType: string) => accountType !== 'PERSONAL',
    then: schema => schema.required('Required field'),
    otherwise: schema => schema.nullable(),
  }),
  EIN: Yup.string().when(['accountType', 'structure', 'weatherSsn'], {
    is: (accountType: string, structure: any, weatherSsn: string) => {
      if (accountType !== 'PERSONAL' && !!structure?.label && structureTypes.includes(structure?.label)) {
        return true;
      }
      return (
        accountType !== 'PERSONAL' && !!structure?.label && !structureTypes.includes(structure?.label) && weatherSsn === 'EIN'
      );
    },
    then: schema => schema.required('Required field').min(10, 'Invalid EIN Number').max(10, 'Invalid EIN Number'),
  }),
  DBAName: Yup.string().when(['accountType', 'structure', 'useDbn'], {
    is: (accountType: string, structure: { label: string }, useDbn: string) => {
      return useDbn === 'Yes' && !structureTypes.includes(structure?.label) && structure?.label;
    },
    then: schema => schema.required('Required field'),
  }),
  businessName: Yup.string().when(['accountType', 'structure'], {
    is: (accountType: string, structure: { label: string }) =>
      accountType !== 'PERSONAL' && structureTypes.includes(structure?.label),
    then: schema => schema.required('Required field'),
  }),
  SSNORITIN: Yup.string().when(['accountType', 'weatherSsn', 'structure'], {
    is: (accountType: string, weatherSsn: string, structure: { label: string }) => {
      return accountType !== 'PERSONAL' && weatherSsn === 'SSN/ITIN' && !structureTypes.includes(structure?.label);
    },
    then: schema => schema.required('Required field').min(11, 'Invalid SSN/ITIN Number').max(11, 'Invalid SSN/ITIN Number'),
  }),
  physicalBusinessAddress: Yup.object().when(['isSameAddress', 'accountType'], {
    is: businessSameAddress,
    then: schema => schema.required('Required field'),
    otherwise: schema => schema.nullable(),
  }),
  businessCountry: Yup.object().when(['isSameAddress', 'accountType'], {
    is: businessSameAddress,
    then: schema => schema.required('Required field'),
    otherwise: schema => schema.nullable(),
  }),
  businessCity: Yup.string().when(['isSameAddress', 'accountType'], {
    is: businessSameAddress,
    then: schema => schema.required('Required field'),
  }),
  businessState: Yup.string().when(['isSameAddress', 'accountType'], {
    is: businessSameAddress,
    then: schema => schema.required('Required field'),
  }),
  businessZip: Yup.string().when(['isSameAddress', 'accountType'], {
    is: businessSameAddress,
    then: schema => schema.required('Required field'),
  }),
  businessStartDate: Yup.string().when(['accountType'], {
    is: (accountType: string) => accountType !== 'PERSONAL',
    then: schema => schema.required('Required field'),
    otherwise: schema => schema.nullable(),
  }),
  phones: Yup.object().when(['isTenant'], (_value, schema, form: any) => {
    const newSchema: any = {};
    if (form.key === 'phones' && form.parent.isTenant === true) {
      const originalValue = Object.keys(form.originalValue);
      originalValue.forEach((value: string) => {
        if (value.includes('phoneType')) {
          newSchema[value] = Yup.object().required('Required field');
        } else {
          newSchema[value] = Yup.string().required('Required field');
        }
      });
    }
    return schema.shape({
      ...newSchema,
    });
  }),
  identity: Yup.array().when(['accountType'], {
    is: (accountType: string) => accountType !== 'PERSONAL',
    then: schema => schema.required('Required field'),
  }),
  organizationArticle: Yup.array().when(['accountType'], {
    is: (accountType: string) => accountType !== 'PERSONAL',
    then: schema => schema.required('Required field'),
  }),
});
