import React, { useState } from 'react';

import { Box } from '@mui/material';
import cn from 'classnames';
import { B2, B3 } from 'components/Typography/Body';
import { H5 } from 'components/Typography/Headlines';
import { notificationIcons, textColors, borderColors } from 'constants/notificationStatus';
import {
  updateNotificationStatusAction,
  archiveNotificationAction,
  isFlaggedNotificationAction,
} from 'modules/Notifications/store/actions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import styles from './NotificationCard.module.scss';
import { formatHoursAndMinutes } from '../../../common/utils/utils';
import SvgIcon from '../../../components/shared/utils/SvgIcon';
import Card from '../Card';

const NotificationCard = ({ notification }: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const text = notification?.message.body;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  const updateStatus = (status: string) => {
    dispatch(updateNotificationStatusAction({ notificationId: notification._id, status: status }));
  };

  const isFlagged = (isFlagged: boolean) => {
    dispatch(isFlaggedNotificationAction({ notificationId: notification._id, isFlagged }));
  };

  const archive = (archive: boolean) => {
    dispatch(archiveNotificationAction({ notificationId: notification._id, archive }));
  };

  const onCardClick = () => {
    if (notification.status === 'UNREAD' && notification.redirect_url) {
      updateStatus('READ');
      navigate(`${notification.redirect_url}`);
    } else {
      navigate(`${notification.redirect_url}`);
    }
  };

  return (
    <Card className={cn({ [styles.readCard]: notification.status !== 'UNREAD' })}>
      <Card.Content>
        <div className={styles.card}>
          <div className={styles.info} style={borderColors[notification.type]}>
            <Box className={styles.rightSide} onClick={() => onCardClick()}>
              <SvgIcon
                icon={notificationIcons[notification.type]}
                className={styles.notificationIcon}
                stroke={textColors[notification.type].color}
              />
              {notification.status === 'UNREAD' ? (
                <H5 style={textColors[notification.type]} bold>
                  {notification?.message.title}
                </H5>
              ) : (
                <H5 style={textColors[notification.type]}>{notification?.message.title}</H5>
              )}
            </Box>
            {text.length > 50 ? (
              <div className={cn(styles.description, { [styles.readStyle]: notification.status !== 'UNREAD' })}>
                {isReadMore ? text.slice(0, 50) + '...' : text}
                <B2 onClick={toggleReadMore} className={styles.readMore}>
                  {isReadMore && 'Read more'}
                </B2>
              </div>
            ) : (
              <div className={cn({ [styles.readStyle]: notification.status !== 'UNREAD' })}>{text}</div>
            )}
          </div>
          <Box className={styles.date}>
            <B3>{formatHoursAndMinutes(notification.createdAt)}</B3>
          </Box>
          <Box className={styles.action}>
            <SvgIcon
              icon={'ribbon'}
              className={cn(styles.ribbon, { [styles.yellowRibbon]: notification.isFlagged === true })}
              onClick={() => isFlagged(!notification.isFlagged)}
            />
            <SvgIcon icon={'trash'} className={styles.trash} onClick={() => archive(!notification.archive)} />
          </Box>
        </div>
      </Card.Content>
    </Card>
  );
};
export default NotificationCard;
