import React, { useMemo } from 'react';

import { B1, B2 } from 'components/Typography/Body';

import styles from './UtilitiesLease.module.scss';
import StatusTag from '../../../../components/shared/StatusTag';

type Item = {
  _id: string;
  name: string;
  type?: string;
  createdAt: string;
  updatedAt: string;
  isLendloard?: boolean;
  utility?: { name: string; _id?: string };
  payer?: string;
  label?: string;
  value?: string;
};
interface UtilitiesLeaseProps {
  utilites: Item[];
}

const UtilitiesLease = ({ utilites }: UtilitiesLeaseProps) => {
  const utilitesList = useMemo(() => {
    const landloardUtilities: Item[] = [];
    const tenantUtilities: Item[] = [];
    utilites.forEach(el => {
      if (el.payer === 'LANDLORD') {
        landloardUtilities.push(el);
      } else {
        tenantUtilities.push(el);
      }
    });
    return { landloardUtilities, tenantUtilities };
  }, [utilites]);
  return (
    <div className={styles.utilitiesLease}>
      <B1 bold className={styles.title}>
        Utilities
      </B1>
      <div className={styles.wrap}>
        <B2 className={styles.label}>Landlord</B2>
        <div className={styles.items}>
          {utilitesList.landloardUtilities.map(el => (
            <StatusTag
              value={el?.utility?.name || ''}
              size={'medium'}
              key={el?.utility?.name || ''}
              styles={{ minWidth: '50px' }}
            />
          ))}
        </div>
      </div>
      <div className={styles.wrap}>
        <B2 className={styles.label}>Tenant</B2>
        <div className={styles.items}>
          {utilitesList.tenantUtilities.map(el => (
            <StatusTag
              value={el?.utility?.name || ''}
              size={'medium'}
              key={el?.utility?.name || ''}
              styles={{ minWidth: '50px' }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default UtilitiesLease;
