import { createTheme } from '@mui/material';

import { fontFamilies } from './fonts';

export const darkTheme = createTheme({
  typography: {
    fontFamily: fontFamilies.primary,
  },
  palette: {
    mode: 'light',
    'primary-black': {
      main: '#222222',
      contrastText: '#FFFFFF',
    },
    'primary-blue': {
      main: '#0D5D73',
      contrastText: '#FFFFFF',
    },
    'primary-orange': {
      main: '#0D5D73',
      contrastText: '#FFFFFF',
    },
    'default-btn': {
      main: '#142B6F',
      contrastText: '#FFFFFF',
    },
    'secondary-background': {
      main: '#f7f7f8',
    },
    'secondary-hover-bl': {
      contrastText: '#FFFFFF',
      main: '#1389A8',
    },
    'secondary-hover-or': {
      main: '#FFAC30',
    },
    'additional-white': {
      main: '#FFFFFF',
    },
    'additional-grey-1': {
      main: '#4D5567',
    },
    'additional-grey-2': {
      main: '#717785',
    },
    'additional-grey-3': {
      main: '#9499A4',
    },
    'additional-light-grey-1': {
      main: '#FCFCFC',
    },
    'additional-light-grey-2': {
      main: '#EDEEF0',
    },
    'additional-light-grey-3': {
      main: '#A6AAB3',
    },
    'additional-light-grey-4': {
      main: '#CACCD1',
      contrastText: '#FFFFFF',
    },
    'additional-red': {
      main: '#F32424',
    },
  },
});
