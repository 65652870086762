import * as Yup from 'yup';

export const propertyViewSchema = Yup.object().shape({
  unitNumber: Yup.string().required('Required field'),
  unitType: Yup.object().required('Required field'),
  status: Yup.object().required('Required field'),
  unitSize: Yup.string().required('Required field'),
  bedrooms: Yup.string().required('Required field'),
  bathrooms: Yup.string().required('Required field'),
  parkingSpot: Yup.string().required('Required field'),
  monthlyRent: Yup.string().required('Required field'),
  rentDeposit: Yup.string().required('Required field'),
});
