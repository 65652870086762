import { type ReactNode, type FC } from 'react';

import { Typography, type TypographyProps } from '@mui/material';

interface IBodyTypographyParameters extends TypographyProps {
  children: ReactNode;
  bold?: boolean;
}

export const B1: FC<IBodyTypographyParameters> = ({ children, bold = false, ...rest }) => (
  <Typography
    variant="body1"
    fontFamily={bold ? 'montserrat-medium-black' : 'montserrat-regular'}
    fontSize={18}
    lineHeight={'26px'}
    {...rest}
  >
    {children}
  </Typography>
);

export const B2: FC<IBodyTypographyParameters> = ({ children, bold = false, ...rest }) => (
  <Typography
    variant="body1"
    fontFamily={bold ? 'montserrat-medium' : 'montserrat-regular'}
    fontSize={16}
    lineHeight={'24px'}
    {...rest}
  >
    {children}
  </Typography>
);

export const B3: FC<IBodyTypographyParameters> = ({ children, bold = false, ...rest }) => (
  <Typography
    variant="body1"
    fontFamily={bold ? 'montserrat-medium' : 'montserrat-regular'}
    fontSize={14}
    lineHeight={'24px'}
    {...rest}
  >
    {children}
  </Typography>
);

export const B4: FC<IBodyTypographyParameters> = ({ children, bold = false, ...rest }) => (
  <Typography
    variant="body1"
    fontFamily={bold ? 'montserrat-medium' : 'montserrat-regular'}
    fontSize={12}
    lineHeight={'20px'}
    {...rest}
  >
    {children}
  </Typography>
);
