import { Box } from '@mui/material';
import inactive from 'assets/images/inactive.png';
import Card from 'components/shared/Card';
import StatusTag from 'components/shared/StatusTag';
import SvgIcon from 'components/shared/utils/SvgIcon';
import { B2 } from 'components/Typography/Body';
import { H5 } from 'components/Typography/Headlines';
import modalNames from 'constants/modalNames';
import { modalsSlice } from 'modals';
import * as process from 'process';
import { useDispatch } from 'react-redux';
import { type IStatus } from 'types';

import styles from './TenantApplicationCard.module.scss';
import { type tenantApplicationItemDataModel } from '../store/types';

interface CardProps {
  open?: boolean;
  hideActions?: boolean;
  applicant?: tenantApplicationItemDataModel;
}
const ApplicationCard = ({ open = false, applicant }: CardProps) => {
  const dispatch = useDispatch();

  return (
    <Card open={open}>
      <Card.Content>
        <div className={styles.card}>
          <div className={styles.wrap}>
            <div className={styles.imageWrap}>
              <img
                className={styles.image}
                src={
                  applicant?.listing.gallery?.cover
                    ? `${process.env.REACT_APP_BASE_URL}/api/document?path=${applicant?.listing?.gallery?.cover}`
                    : inactive
                }
                alt=""
              />
            </div>
            <div className={styles.info}>
              <div>
                <H5 bold>{`${applicant?.listing?.title}  |   Unit ${applicant?.listing.unitId?.unitNumber}`}</H5>
              </div>
              <B2
                className={styles.address}
                onClick={() =>
                  dispatch(
                    modalsSlice.actions.showModal({ modalName: modalNames.LOCATION_MODAL, data: applicant?.listing.propertyId })
                  )
                }
              >
                <SvgIcon icon={'location'} className={styles.locationIcon} />
                {applicant?.listing.propertyId.googleApi?.address?.value}
              </B2>
              <B2 bold>
                {`Square feet: ${applicant?.listing.unitId?.unitSize} | ${applicant?.listing.unitId?.bedrooms} 
                Bedroom | ${applicant?.listing.unitId?.bathrooms} Bathroom | Parking spot 
                ${applicant?.listing.unitId?.parkingSpot}`}
              </B2>
              <H5 className={styles.price}>{`$${applicant?.listing.rent || ''}`}</H5>
            </div>
          </div>
          <Box className={styles.buttons}>
            <StatusTag
              type={applicant?.status as IStatus}
              label={applicant?.status}
              size={'medium'}
              styles={{ minWidth: '120px' }}
            />
          </Box>
        </div>
      </Card.Content>
    </Card>
  );
};

export default ApplicationCard;
