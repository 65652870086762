import { type FC, type ReactNode, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { type userRole } from '../../constants/userStatus';
import { useIsAuthorized } from '../../hooks/useIsAuthorized';
import { getUserAction } from '../../modules/Auth/store/actions';
import { authLoadingSelector } from '../../modules/Auth/store/selectors';
import AccessDenied from '../AccessDenied';

interface IProps {
  roles: userRole[];
  children: ReactNode;
  isPage?: boolean;
}

const PrivateAccess: FC<IProps> = ({ roles, children, isPage }) => {
  const isAuthorized = useIsAuthorized(roles);
  const loading = useSelector(authLoadingSelector);
  const token = localStorage.getItem('token');
  const dispatch = useDispatch();
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        const newToken = localStorage.getItem('token');
        if (newToken !== token && newToken) {
          dispatch(getUserAction());
        }
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [token]);

  return isAuthorized ? <>{children}</> : isPage && !loading ? <AccessDenied /> : null;
};

export default PrivateAccess;
