import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Avatar } from '@mui/material';
import cn from 'classnames';
// import { formatDate } from 'common/utils/utils';
import PrivateAccess from 'components/PrivateAccess';
import BackButton from 'components/shared/BackButton/BackButton';
import Button from 'components/shared/Button';
import Autocomplete from 'components/shared/FormElements/Autocomplete/Autocomplete';
import ImageDialog from 'components/shared/ImageDialog';
import Loading from 'components/shared/Loading';
import SvgIcon from 'components/shared/utils/SvgIcon';
import { B1, B3 } from 'components/Typography/Body';
import { H3, H5 } from 'components/Typography/Headlines';
import { routes } from 'constants/routes';
import { userRole, type MaintenanceTaskStatus } from 'constants/userStatus';
import { userSelector } from 'modules/Auth/store/selectors';
import {
  getMaintenanceDetails,
  getProperty,
  managerAssign,
  updatetMaintenanceStatus,
  getAssigned,
} from 'modules/Maintenance/store/actions';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { defaultData } from './constants';
import styles from './MaintenanceDetails.module.scss';
import { maintenenceSchema } from './validations/maintenenceSchema';
import Chat from '../../Chat/Chat';
import { maintenanceStatus } from '../constants';
import PropertyCard from '../PropertyCard';
import {
  loadingSelector,
  maintenanceDetailsSelector,
  propertyDataSelector,
  maintenanceAssignedSelector,
} from '../store/selector';

const MaintenanceDetails = () => {
  const [manager, setManager] = useState([] as { label: string; value: string }[]);
  const [openFullScreen, setOpenFullScreen] = useState(false);
  const [screenImage, setScreenImage] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(userSelector);
  const form = useForm({
    defaultValues: { ...defaultData },
    resolver: yupResolver<any>(maintenenceSchema),
    mode: 'all',
  });

  const {
    control,
    reset,
    getValues,
    formState: { errors },
  } = form;

  const loading = useSelector(loadingSelector);
  const propertyData = useSelector(propertyDataSelector);
  const maintenancedetails = useSelector(maintenanceDetailsSelector);
  const [searchParams] = useSearchParams();
  const maintenanceId = searchParams.get('maintenanceId') as string;
  const assigned = useSelector(maintenanceAssignedSelector);

  useEffect(() => {
    dispatch(getMaintenanceDetails({ maintenanceId }));
    dispatch(getProperty({ id: searchParams.get('unitId') as string }));
  }, []);

  useEffect(() => {
    if (maintenancedetails) {
      if (user?.role !== 'TENANT') {
        dispatch(getAssigned({ propertyId: maintenancedetails.property }));
      }
      const status = maintenanceStatus.find(el => el.value === maintenancedetails.status);

      reset({
        ...getValues(),
        status,
      });
    }
  }, [maintenancedetails]);

  useEffect(() => {
    if (assigned) {
      const convertManager = assigned.map(el => {
        return { label: el.fullName, value: el.email };
      });
      setManager(convertManager);
    }
  }, [assigned]);

  const onSubmit = (formData: any) => {
    const data = assigned.find((el: any) => el.email === formData?.manager?.value);
    data && dispatch(managerAssign({ maintenanceId, ...data, navigate }));
  };

  const handleOpenFullScreen = (el: string) => {
    setScreenImage(el);
    setOpenFullScreen(true);
  };

  const handleCloseFullScreen = () => {
    setOpenFullScreen(false);
  };

  return (
    <div className={styles.container}>
      <BackButton to={routes.maintenance} />
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className={styles.titleContent}>
          <H3 className={styles.title}>Maintenance Details</H3>
          <div className={styles.statusButton}>
            <Box>
              <Controller
                name={'status'}
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    label="Status"
                    options={maintenanceStatus}
                    {...field}
                    onAutocompleteChange={(evnt: { label: string; value: string }) => {
                      const currentStatus = maintenanceStatus.find(el => el.label === evnt.label)?.value;
                      dispatch(
                        updatetMaintenanceStatus({
                          maintenanceId,
                          status: currentStatus as MaintenanceTaskStatus,
                        })
                      );
                    }}
                  />
                )}
              />
            </Box>
          </div>
        </div>
        {propertyData && <PropertyCard property={propertyData} />}
        <PrivateAccess roles={[userRole.LANDLOARD, userRole.MANAGER]}>
          <div className={styles.managerWrap}>
            <div className={styles.managerTitle}>
              <H5 className={styles.managerTitle}>whom assigned this task</H5>
            </div>
            <div className={styles.autocomplete}>
              <Controller
                name={'manager'}
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    autoComplete={'off'}
                    label="Manager"
                    options={manager}
                    errorMessage={errors.manager?.message}
                    hasError={!!errors.manager}
                    {...field}
                  />
                )}
              />
            </div>
          </div>
        </PrivateAccess>

        <div className={styles.details}>
          <div className={styles.personInfo}>
            <B3 className={styles.title}>
              <SvgIcon icon={'profile'} className={styles.profile} />
              {maintenancedetails?.landlord?.fullName} / Landlord
            </B3>
            <B3 className={styles.value}>{maintenancedetails?.landlord?.email}</B3>
          </div>
          {maintenancedetails?.assignee && (
            <div className={`${styles.personInfo} ${styles.personMailInfo}`} key={maintenancedetails._id}>
              <B3 className={styles.title}>
                <SvgIcon icon={'profile'} className={styles.profile} />
                {maintenancedetails?.assignee?.managerId?.fullName} / Manager
              </B3>
              <B3>{maintenancedetails?.assignee?.email}</B3>
            </div>
          )}
          <div className={styles.personInfo}>
            <B3 className={styles.title}>
              <SvgIcon icon={'profile'} className={styles.profile} />
              {maintenancedetails?.tenant?.fullName} / Tenent
            </B3>
            <B3 className={styles.value}>{maintenancedetails?.tenant?.email}</B3>
          </div>
          <div className={styles.personInfo}>
            <B3 className={styles.title}>
              <SvgIcon icon={'accounting'} className={cn(styles.profile, styles.iconLight)} />
              Categoris
            </B3>
            {maintenancedetails?.category?.length ? (
              <Box className={styles.fileBox}>
                {maintenancedetails?.category?.length &&
                  maintenancedetails?.category.map(el => {
                    return (
                      <B3 key={el} className={styles.value}>
                        {el}
                      </B3>
                    );
                  })}
              </Box>
            ) : (
              ''
            )}
          </div>
          <div className={styles.personInfo}>
            <B3 className={styles.title}>
              <SvgIcon icon={'calendar'} className={styles.profile} />
              Request priority
            </B3>
            <B3 className={styles.value}>{maintenancedetails?.priority}</B3>
          </div>
          <div className={styles.description}>
            <B1 bold>{maintenancedetails?.title}</B1>
            <B3 className={styles.descriptionTitle}>Description</B3>
            <B3 className={styles.descriptionBody}>{maintenancedetails?.description}</B3>
          </div>
          <div className={styles.documents}>
            <B3 className={styles.title}>
              <SvgIcon icon={'applications'} className={styles.profile} />
              Photo:
            </B3>
            <Box className={styles.fileBox}>
              {maintenancedetails?.gallery &&
                Object.values(maintenancedetails?.gallery)?.map((el: any, index: number) => (
                  <Avatar
                    className={styles.image}
                    onClick={() => handleOpenFullScreen(el)}
                    key={el as string}
                    src={`${process.env.REACT_APP_BASE_URL}/api/document?path=${el}`}
                    alt={`Uploaded ${index + 1}`}
                  />
                ))}
              {openFullScreen && screenImage && (
                <ImageDialog
                  imageUrl={`${process.env.REACT_APP_BASE_URL}/api/document?path=${screenImage}`}
                  onClose={handleCloseFullScreen}
                />
              )}
            </Box>
          </div>
          <div className={styles.button}>
            <PrivateAccess roles={[userRole.LANDLOARD, userRole.MANAGER]}>
              <Button
                className={styles.button}
                variant="contained"
                size="full-lg"
                type="submit"
                label="Save"
                loading={loading}
                disabled={loading}
              />
            </PrivateAccess>
          </div>
        </div>
      </form>
      {maintenancedetails?.chatId && <Chat chatId={maintenancedetails.chatId} />}
      {loading && <Loading isBig />}
    </div>
  );
};
export default MaintenanceDetails;
