import React, { useEffect, useMemo } from 'react';

import { Avatar, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import styles from './PaymentPage.module.scss';
import PaymentPageCard from './PaymentPageCard';
import { getTenantPaymentListAction } from './store/actions';
import {
  newPaymentListSelector,
  paymentLoadingSelector,
  paymentStoryListSelector,
  queryOptionsSelector,
} from './store/selectors';
import inactive from '../../assets/images/inactive.png';
import { formatDate } from '../../common/utils/utils';
import PrivateAccess from '../../components/PrivateAccess';
import Button from '../../components/shared/Button';
import Loading from '../../components/shared/Loading';
import StatusLine from '../../components/shared/StatusLine';
import StatusTag from '../../components/shared/StatusTag';
import TableCard from '../../components/shared/TableCard';
import SvgIcon from '../../components/shared/utils/SvgIcon';
import { B2, B3 } from '../../components/Typography/Body';
import { H4, H5 } from '../../components/Typography/Headlines';
import modalNames from '../../constants/modalNames';
import { routes } from '../../constants/routes';
import { userRole } from '../../constants/userStatus';
import { modalsSlice } from '../../modals';

const PaymentPage = () => {
  const dispatch = useDispatch();
  const newPaymentList = useSelector(newPaymentListSelector);
  const paymentStoryList = useSelector(paymentStoryListSelector);
  const queryOptions = useSelector(queryOptionsSelector);
  const loading = useSelector(paymentLoadingSelector);
  const navigate = useNavigate();
  const isMobileScreen = useMediaQuery('(max-width: 600px)');

  useEffect(() => {
    dispatch(getTenantPaymentListAction({ status: '', page: queryOptions.page, size: queryOptions.size }));
  }, [queryOptions.page, queryOptions.size]);

  useEffect(() => {
    dispatch(getTenantPaymentListAction({ status: 'NEW,OVERDUE' }));
  }, []);

  const memoColumns = useMemo(() => {
    return [
      {
        Header: 'Pay To',
        id: 'landlord',
        title: 'Pay To',
        width: '110',
        Cell: ({ row }: any) => {
          return (
            <StatusLine type={row.original.status === 'PAID' ? 'SIGNED' : 'REJECTED'}>
              <div>
                <B3 bold>{row.original?.landlord?.fullName}</B3>
              </div>
            </StatusLine>
          );
        },
      },
      {
        Header: 'Property name ',
        id: 'Property name ',
        title: 'Property name ',
        isSorted: true,
        Cell: ({ row }: any) => {
          return <B3 bold>{`${row.original?.property?.propertyName} | Unit ${row.original?.unit?.unitNumber}`}</B3>;
        },
      },
      {
        Header: 'Due Date',
        id: 'Due Date',
        width: '60',
        title: 'Due Date',
        Cell: ({ row }: any) => {
          return <B3 bold>{formatDate(row.original.dueDate)}</B3>;
        },
      },
      {
        Header: 'Category',
        id: 'Category',
        title: 'Category',
        width: '70',
        Cell: ({ row }: any) => {
          return <B3 bold>{row.original.type}</B3>;
        },
      },
      {
        Header: 'Status',
        id: 'Status',
        width: '70',
        title: 'Status',
        Cell: ({ row }: any) => {
          return <StatusTag label={row.original.status || undefined} type={row.original.status} />;
        },
      },
      {
        Header: 'Total Paid',
        id: 'Total Paid',
        width: '70',
        title: 'Total Paid',
        Cell: ({ row }: any) => {
          return <B3 bold>{`$${row.original.amount}`}</B3>;
        },
      },
    ];
  }, []);
  console.log(isMobileScreen);

  return (
    <PrivateAccess roles={[userRole.TENANT]} isPage>
      <H4 bold>Payment</H4>
      {!!newPaymentList.count && (
        <div className={styles.items}>
          {newPaymentList.items.map(el => (
            <div key={el._id} className={styles.item}>
              <div className={styles.imageWrap}>
                <Avatar
                  className={styles.image}
                  src={
                    el.property?.gallery?.cover
                      ? `${process.env.REACT_APP_BASE_URL}/api/document?path=${el?.property?.gallery?.cover}`
                      : inactive
                  }
                />
              </div>
              <div>
                <H5 className={styles.type}>{el?.type}</H5>
                <B2
                  className={styles.address}
                  onClick={() =>
                    dispatch(modalsSlice.actions.showModal({ modalName: modalNames.LOCATION_MODAL, data: el.property }))
                  }
                >
                  <SvgIcon icon={'location'} className={styles.locationIcon} />
                  {el?.property?.googleApi?.address?.value}
                </B2>
                <B2 className={styles.paymentDate}>Until {formatDate(el?.dueDate)}</B2>
              </div>
              <div className={styles.info}>
                <H4>{`$ ${el?.amount}`}</H4>
                <B2 className={styles.balance}>Current balance</B2>
                <Button
                  variant="contained"
                  size="ds"
                  type="button"
                  label="Pay Now"
                  onClick={() => navigate(`${routes.paymentPage}/${el._id}`)}
                />
              </div>
            </div>
          ))}
        </div>
      )}
      {isMobileScreen ? (
        paymentStoryList?.items.map(payment => <PaymentPageCard key={payment._id} payment={payment} />)
      ) : (
        <TableCard
          totalItems={paymentStoryList.count}
          setPageSize={size => dispatch(getTenantPaymentListAction({ page: 1, size }))}
          defaultPageSize={queryOptions.size}
          columns={memoColumns}
          data={paymentStoryList.items}
          defaultPage={queryOptions.page}
          fetchData={({ pageSize, pageIndex }) => dispatch(getTenantPaymentListAction({ page: pageIndex, size: `${pageSize}` }))}
          hidePagination={false}
        />
      )}
      {loading && <Loading isBig />}
    </PrivateAccess>
  );
};

export default PaymentPage;
