import React, { useEffect, useMemo } from 'react';

import { Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import styles from './Tenants.module.scss';
import PrivateAccess from '../../../../components/PrivateAccess';
import Button from '../../../../components/shared/Button';
import Autocomplete from '../../../../components/shared/FormElements/Autocomplete/Autocomplete';
import { B1 } from '../../../../components/Typography/Body';
import modalNames from '../../../../constants/modalNames';
import { userRole } from '../../../../constants/userStatus';
import { modalsSlice } from '../../../../modals';
import { addTenantAction, getTenantsList } from '../../../Tenants/store/actions';
import { tenantsSelector } from '../../../Tenants/store/selector';
import { type IAddTenant } from '../../../Tenants/store/types';

interface TenantsProps {
  form: any;
}
const Tenants = ({ form }: TenantsProps) => {
  const dispatch = useDispatch();
  const tenants = useSelector(tenantsSelector);

  useEffect(() => {
    dispatch(getTenantsList({ limit: '5', page: 1 }));
  }, []);

  const options = useMemo(() => {
    if (tenants?.items) {
      return tenants?.items.map(el => ({ ...el, label: el.fullName }));
    }
    return [];
  }, [tenants]);

  const {
    control,
    formState: { errors },
  } = form;

  return (
    <div className={styles.tenants}>
      <div className={styles.action}>
        <B1 bold>Tenant</B1>
        <PrivateAccess roles={[userRole.LANDLOARD]}>
          <Button
            variant="contained"
            size="sm"
            type="button"
            label="Add Tenant"
            onClick={() =>
              dispatch(
                modalsSlice.actions.showModal({
                  modalName: modalNames.ADD_USER_MODAL,
                  data: { title: 'Add Tenant', addUser: (data: IAddTenant) => dispatch(addTenantAction(data)) },
                })
              )
            }
          />
        </PrivateAccess>
      </div>
      <Controller
        name={'tenant'}
        control={control}
        render={({ field }) => (
          <Autocomplete
            {...field}
            label={'Tenant*'}
            placeholder={'Select Tenant'}
            errorMessage={errors?.tenant?.message}
            hasError={!!errors?.tenant}
            options={options}
            autoComplete="off"
          />
        )}
      />
    </div>
  );
};

export default Tenants;
