import React from 'react';

import { Box } from '@mui/material';
import inactive from 'assets/images/inactive.png';
import Button from 'components/shared/Button';
import Card from 'components/shared/Card';
import SvgIcon from 'components/shared/utils/SvgIcon';
import { B2 } from 'components/Typography/Body';
import { H5 } from 'components/Typography/Headlines';
import modalNames from 'constants/modalNames';
import { routes } from 'constants/routes';
import { modalsSlice } from 'modals';
import * as process from 'process';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import styles from './ApplicationCard.module.scss';

interface CardProps {
  open?: boolean;
  hideActions?: boolean;
  listing?: any;
}
const ApplicationCard = ({ open = false, listing }: CardProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Card open={open}>
      <Card.Content>
        <div className={styles.card}>
          <div className={styles.wrap}>
            <div className={styles.imageWrap}>
              <img
                className={styles.image}
                src={
                  listing?.gallery?.cover
                    ? `${process.env.REACT_APP_BASE_URL}/api/document?path=${listing?.gallery?.cover}`
                    : inactive
                }
                alt=""
              />
            </div>
            <div className={styles.info}>
              <div>
                <B2 bold>{`Square feet: ${listing?.unitId?.unitSize || ''} | ${listing?.unitId?.bedrooms || ''} 
              Bedroom | ${listing?.unitId?.bathrooms || ''} Bathroom | Parking spot ${listing?.unitId?.parkingSpot || ''} | ${
                  listing?.unitId?.unitNumber || ''
                } Unit`}</B2>
              </div>
              <B2
                className={styles.address}
                onClick={() =>
                  dispatch(modalsSlice.actions.showModal({ modalName: modalNames.LOCATION_MODAL, data: listing?.propertyId }))
                }
              >
                <SvgIcon icon={'location'} className={styles.locationIcon} />
                {listing?.propertyId.googleApi?.address?.value}
              </B2>
              <H5 className={styles.price}>{`$${listing?.rent || ''}`}</H5>
            </div>
          </div>
          <Box className={styles.buttons}>
            <H5 className={styles.mobilePrice}>{`$${listing?.rent || ''}`}</H5>
            <Button
              className={styles.edit}
              variant="contained"
              size="full-sm"
              type="button"
              label="See  Details"
              onClick={() => navigate(`${routes.publicListings}/${listing?._id}`)}
            />
          </Box>
        </div>
      </Card.Content>
    </Card>
  );
};

export default ApplicationCard;
