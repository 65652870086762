import React, { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import PrivateAccess from 'components/PrivateAccess';
import BackButton from 'components/shared/BackButton/BackButton';
import Button from 'components/shared/Button';
import { Input } from 'components/shared/FormElements';
// import Checkbox from 'components/shared/FormElements/Checkbox';
// import DatePicker from 'components/shared/FormElements/DatePicker';
import ImageUploader from 'components/shared/ImageUploader';
import { B1 } from 'components/Typography/Body';
import { H3, H5 } from 'components/Typography/Headlines';
import { routes } from 'constants/routes';
import { userRole } from 'constants/userStatus';
// import dayjs from 'dayjs';
import { deleteFile } from 'modules/Application/store/actions';
import { userSelector } from 'modules/Auth/store/selectors';
import { getLeaseAction } from 'modules/Lease/store/actions';
import { leaseListSelector, leaseQueryOptionsSelector } from 'modules/Lease/store/selectors';
import { createMaintenence, getItems, getProperty } from 'modules/Maintenance/store/actions';
import { maintenecesItemsSelector, maintenecesOptionsSelector, propertyDataSelector } from 'modules/Maintenance/store/selector';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';

import styles from './AssignMaintenence.module.scss';
import Categories from './Categories';
import { createMaintenenceDefaultData } from './constants';
import { type IForm } from './types';
import { maintenenceSchema } from './validations/maintenenceSchema';
import { scrollToFirstErrorInput } from '../../../common/utils/utils';
import PropertyCard from '../PropertyCard/PropertyCard';

const AssignMaintenence = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector(userSelector);
  const maintenecessItems = useSelector(maintenecesItemsSelector);
  const maintenecesOption = useSelector(maintenecesOptionsSelector);
  const propertyData = useSelector(propertyDataSelector);
  const leaseList = useSelector(leaseListSelector);
  const navigate = useNavigate();
  const { page, size } = useSelector(leaseQueryOptionsSelector);

  const [searchParams] = useSearchParams();

  const form = useForm<IForm>({
    defaultValues: { ...createMaintenenceDefaultData },
    resolver: yupResolver<any>(maintenenceSchema),
    mode: 'all',
  });

  const {
    reset,
    getValues,
    control,
    setValue,
    formState: { errors },
  } = form;
  // const isFirstTime = useWatch({ control, name: 'hasFirstTime' });
  // const isSecondTime = useWatch({ control, name: 'hasSecondTime' });
  // const isThirdTime = useWatch({ control, name: 'hasThirdTime' });
  // const isFourthTime = useWatch({ control, name: 'hasFourthTime' });
  const others = useWatch({ control, name: 'others' }) as File[];

  useEffect(() => {
    scrollToFirstErrorInput(errors);
  }, [errors]);

  const onSubmit = (formData: IForm) => {
    formData.unitId = searchParams.get('unitId');
    if (location.state) {
      formData.propertyId = location.state.propertyId;
    }
    dispatch(createMaintenence({ formData, navigate }));
  };
  useEffect(() => {
    if (user?._id) {
      dispatch(getItems({ type: 'REGULAR', name: 'category' }));
      dispatch(getProperty({ id: searchParams.get('unitId') as string }));
      dispatch(getLeaseAction({ page, size }));
    }
  }, [user]);

  useEffect(() => {
    reset({
      ...getValues(),
      hasFirstTime: true,
    });
  }, []);

  const handleDeleteFile = (value: any, controlName?: string): void => {
    const id = localStorage.getItem('id');
    const deleteFileReqData: any = {
      applicationId: id || '',
      key: controlName || '',
      path: value,
    };
    dispatch(deleteFile(deleteFileReqData));
  };

  // const handleCheckboxChange = (controlName: string, value: boolean) => {
  //   if (value) {
  //     const checkboxFields = ['hasFirstTime', 'hasSecondTime', 'hasThirdTime', 'hasFourthTime'];
  //     checkboxFields.forEach((field: any) => {
  //       if (field !== controlName) {
  //         setValue(field, !value);
  //       }
  //     });
  //   }
  // };
  return (
    <PrivateAccess roles={[userRole.LANDLOARD, userRole.TENANT, userRole.MANAGER]} isPage>
      <div className={styles.createMaintenence}>
        <BackButton to={leaseList && leaseList.length === 1 ? routes.maintenance : routes.selectProperty} />
        <H3 className={styles.title}>What is this request about</H3>
        {propertyData && <PropertyCard property={propertyData} />}
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className={styles.container}>
            <Categories
              form={form}
              label="Add Category"
              title="Categories"
              description="Select any categories"
              items={maintenecessItems}
              options={maintenecesOption}
              name="category"
            />
            <H5 className={styles.title}>Title*</H5>
            <Box>
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <Input
                    placeholder="Enter Title"
                    className="full-size"
                    errorMessage={errors.title?.message}
                    hasError={!!errors.title}
                    {...field}
                  />
                )}
              />
            </Box>
            <Box className={styles.desc}>
              <H5 className={styles.descTitle}>Description</H5>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <Input
                    placeholder="Enter your Maintenance Description"
                    multiline
                    rows={5}
                    className="full-size"
                    errorMessage={errors?.description?.message}
                    hasError={!!errors?.description}
                    {...field}
                  />
                )}
              />
            </Box>
            {/* <Box className={styles.availableDateWrap}>
              <Box>
                <Controller
                  name="availableDate"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      label="Available Date"
                      {...field}
                      className={styles.availableDate}
                      errorMessage={errors?.availableDate?.message}
                      hasError={!!errors.availableDate}
                      minDate={dayjs(new Date())}
                    />
                  )}
                />
              </Box>
              <Box className={styles.checkboxGrid}>
                <Controller
                  name="hasFirstTime"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={!!isFirstTime}
                      onChange={e => {
                        field.onChange(e);
                        handleCheckboxChange('hasFirstTime', e.target.checked);
                      }}
                    />
                  )}
                />
                <B4 className={styles.anyTimeTitle}>Any Time</B4>
              </Box>
              <Box className={styles.checkboxGrid}>
                <Controller
                  name="hasSecondTime"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={!!isSecondTime}
                      onChange={e => {
                        field.onChange(e);
                        handleCheckboxChange('hasSecondTime', e.target.checked);
                      }}
                    />
                  )}
                />
                <B4 className={styles.anyTimeTitle}>8AM -12PM</B4>
              </Box>
              <Box className={styles.checkboxGrid}>
                <Controller
                  name="hasThirdTime"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={!!isThirdTime}
                      onChange={e => {
                        field.onChange(e);
                        handleCheckboxChange('hasThirdTime', e.target.checked);
                      }}
                    />
                  )}
                />
                <B4 className={styles.anyTimeTitle}>12PM -4PM</B4>
              </Box>
              <Box className={styles.checkboxGrid}>
                <Controller
                  name="hasFourthTime"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={!!isFourthTime}
                      onChange={e => {
                        field.onChange(e);
                        handleCheckboxChange('hasFourthTime', e.target.checked);
                      }}
                    />
                  )}
                />
                <B4 className={styles.anyTimeTitle}>4PM -8PMe</B4>
              </Box>
            </Box> */}
            <Box className={styles.documentBox}>
              <H5 className={styles.documentUploaderTitle}>Add Gallery</H5>
              <ImageUploader
                isBig
                maxSize={999999}
                multiple
                imageUrls={others}
                accept=".jpeg, .png"
                controlName="others"
                uploaderText="Uploading documents image"
                setImageUrls={(value: File[]) => setValue('others', value)}
                deleteImage={handleDeleteFile}
                fileTypeText={'(jpg, png, pdf)'}
                hasErrorBlock={true}
                disabled={others?.length === 20}
              />
            </Box>
            <Box>
              <FormControl component="fieldset">
                <H5 bold>Request priority</H5>
                <B1>Select Request Priority</B1>
                <Controller
                  control={control}
                  name="requestPriority"
                  render={({ field }) => (
                    <RadioGroup {...field} className={styles.group}>
                      <FormControlLabel
                        className={styles.radio}
                        value={'LOW'}
                        control={<Radio color={'warning'} />}
                        label="Low"
                      />
                      <FormControlLabel
                        className={styles.radio}
                        value={'NORMAL'}
                        control={<Radio color={'warning'} />}
                        label="Normal"
                      />
                      <FormControlLabel
                        className={styles.radio}
                        value={'HIGH'}
                        control={<Radio color={'warning'} />}
                        label="High"
                      />
                      <FormControlLabel
                        className={styles.radio}
                        value={'CRITICAL'}
                        control={<Radio color={'warning'} />}
                        label="Critical"
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Box>
            <Box>
              <Button
                color={'primary-blue'}
                className={styles.btn}
                variant="contained"
                size="full-lg"
                type="submit"
                label={'Create Request'}
              />
            </Box>
          </div>
        </form>
      </div>
    </PrivateAccess>
  );
};

export default AssignMaintenence;
