import { combineReducers } from '@reduxjs/toolkit';
import accountingSlice from 'modules/Accounting/store/accountingSlice';
import applicantSlice from 'modules/Applicants/store/applicantsSlice';
import applicationSlice from 'modules/Application/store/aplicationSlice';
import chatSlice from 'modules/Chat/store/chatSlice';
import maintenenceSlice from 'modules/Maintenance/store/maintenanceSlice';
import managersSlice from 'modules/Managers/store/managersSlice';
import notificationsSlice from 'modules/Notifications/store/notificationsSlice';
import taskSlice from 'modules/Tasks/store/taskSlice';
import tenantApplicationSlice from 'modules/TenantApplication/store/tenantApplicationSlice';
import tenantsSlice from 'modules/Tenants/store/tenantsSlice';

import { modalsSlice } from '../modals';
import { authSlice } from '../modules/Auth';
import helpSlice from '../modules/Help/store/helpSlice';
import homeSlice from '../modules/Home/store/HomeSlice';
import leaseSlice from '../modules/Lease/store/leaseSlice';
import listingsSlice from '../modules/Listings/store/listingsSlice';
import tenantPaymentSlice from '../modules/PaymentPage/store/tenantPaymentSlice';
import profileSlice from '../modules/Profile/store/profileSlice';
import createPropertiesSlice from '../modules/Properties/CreateProperty/store/createPropertiesSlice';
import propertiesSlice from '../modules/Properties/store/propertiesSlice';

const rootReducer = combineReducers({
  [authSlice.name]: authSlice.reducer,
  [tenantPaymentSlice.name]: tenantPaymentSlice.reducer,
  [modalsSlice.name]: modalsSlice.reducer,
  [profileSlice.name]: profileSlice.reducer,
  [createPropertiesSlice.name]: createPropertiesSlice.reducer,
  [propertiesSlice.name]: propertiesSlice.reducer,
  [listingsSlice.name]: listingsSlice.reducer,
  [applicationSlice.name]: applicationSlice.reducer,
  [applicantSlice.name]: applicantSlice.reducer,
  [leaseSlice.name]: leaseSlice.reducer,
  [tenantsSlice.name]: tenantsSlice.reducer,
  [managersSlice.name]: managersSlice.reducer,
  [maintenenceSlice.name]: maintenenceSlice.reducer,
  [notificationsSlice.name]: notificationsSlice.reducer,
  [taskSlice.name]: taskSlice.reducer,
  [tenantApplicationSlice.name]: tenantApplicationSlice.reducer,
  [accountingSlice.name]: accountingSlice.reducer,
  [chatSlice.name]: chatSlice.reducer,
  [helpSlice.name]: helpSlice.reducer,
  [homeSlice.name]: homeSlice.reducer,
});

export default rootReducer;
