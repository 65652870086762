import toast from 'react-hot-toast';
import { call, type ForkEffect, put, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import authSlice from './authSlice';
import { requestForToken } from '../../../firebase';
import * as authServices from '../apiServices';

function* logInSaga({ payload }: ReturnType<typeof actions.logInAction>) {
  yield put(authSlice.actions.setLoading(true));
  try {
    const { data } = yield call(authServices.logIn, payload);
    if (data.token) {
      localStorage.setItem('token', data.token);
      requestForToken().then((firebaseToken: any) => {
        authServices.createFirebaseToken(firebaseToken);
      });
    }

    if (data.passwordToken) {
      localStorage.setItem('passwordToken', data.passwordToken);
      localStorage.setItem('registeredUser', JSON.stringify(data.account));
    }

    yield put(authSlice.actions.setUser(data.account));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(authSlice.actions.setLoading(false));
  }
}
function* getUserSaga() {
  yield put(authSlice.actions.setLoading(true));
  yield put(authSlice.actions.setUser(null));
  try {
    const { data } = yield call(authServices.getUser);
    yield put(authSlice.actions.setUser(data));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
    localStorage.clear();
  } finally {
    yield put(authSlice.actions.setLoading(false));
  }
}
function* registrationSaga({ payload }: ReturnType<typeof actions.registrationAction>) {
  yield put(authSlice.actions.setLoading(true));
  try {
    const { data } = yield call(authServices.registration, payload);
    const response = { ...data, email: payload.email, timer: Date.now() };
    localStorage.setItem('registeredUser', JSON.stringify(response));
    yield put(authSlice.actions.setRegisteredUser(response));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(authSlice.actions.setLoading(false));
  }
}
function* inviteSignUpSaga({ payload }: ReturnType<typeof actions.inviteSignUpAction>) {
  yield put(authSlice.actions.setLoading(true));
  try {
    const { data } = yield call(authServices.inviteSignUp, payload);
    if (data.token) {
      localStorage.setItem('token', data.token);
    }
    yield put(authSlice.actions.setUser(data.account));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(authSlice.actions.setLoading(false));
  }
}
function* verifyOtpSaga({ payload }: ReturnType<typeof actions.verifyOtpAction>) {
  yield put(authSlice.actions.setLoading(true));
  try {
    const { data } = yield call(authServices.verifyOtp, payload);
    yield put(authSlice.actions.setUser(data.account));
    localStorage.setItem('passwordToken', data.passwordToken);
    localStorage.setItem('registeredUser', '');
    yield put(authSlice.actions.setIsVerify(true));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(authSlice.actions.setLoading(false));
  }
}
function* resendOtpSaga({ payload }: ReturnType<typeof actions.resendOtpAction>) {
  yield put(authSlice.actions.setLoading(true));
  try {
    const { data } = yield call(authServices.resendOtp, payload);
    const response = { ...data, timer: Date.now() };
    localStorage.setItem('registeredUser', JSON.stringify(response));
    yield put(authSlice.actions.setRegisteredUser(response));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(authSlice.actions.setLoading(false));
  }
}
function* confirmEmailOtpSaga({ payload }: ReturnType<typeof actions.confirmEmailOtpAction>) {
  yield put(authSlice.actions.setLoading(true));
  try {
    const { data } = yield call(authServices.confirmEmailOtp, payload);
    if (data.token) {
      localStorage.setItem('token', data.token);
    }

    yield put(authSlice.actions.setUser(data.account));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(authSlice.actions.setLoading(false));
  }
}
function* resendEmailSaga({ payload }: ReturnType<typeof actions.resendEmailAction>) {
  yield put(authSlice.actions.setLoading(true));
  try {
    yield call(authServices.resendEmail, payload);
    toast.success('Resented to your email');
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(authSlice.actions.setLoading(false));
  }
}
function* forgotPasswordSaga({ payload }: ReturnType<typeof actions.forgotPasswordAction>) {
  yield put(authSlice.actions.setLoading(true));
  try {
    const { data } = yield call(authServices.forgotPassword, payload);
    const response = { ...data, timer: Date.now() };
    localStorage.setItem('user', JSON.stringify(response));
    yield put(authSlice.actions.setUser(data));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(authSlice.actions.setLoading(false));
  }
}
function* verifyForgotPasswordSaga({ payload }: ReturnType<typeof actions.verifyForgotPasswordAction>) {
  yield put(authSlice.actions.setLoading(true));
  try {
    const { data } = yield call(authServices.verifyForgotPassword, payload);
    yield put(authSlice.actions.setUser(data.account));
    localStorage.setItem('passwordToken', data.passwordToken);
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(authSlice.actions.setLoading(false));
  }
}
function* resetPasswordSaga({ payload }: ReturnType<typeof actions.resetPasswordAction>) {
  yield put(authSlice.actions.setLoading(true));
  try {
    const { data } = yield call(authServices.resetPassword, payload);
    if (data.token) {
      localStorage.setItem('token', data.token);
    }
    yield put(authSlice.actions.setUser(data.account));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(authSlice.actions.setLoading(false));
  }
}
function* activateSaga({ payload }: ReturnType<typeof actions.activateAction>) {
  yield put(authSlice.actions.setLoading(true));
  try {
    const { data } = yield call(authServices.activateEmail, payload);
    yield put(authSlice.actions.setUser(data.account));
    localStorage.setItem('token', data.token);
    localStorage.setItem('registeredUser', '');
    localStorage.setItem('passwordToken', '');
  } catch (error: any) {
    if (error?.response?.data?.message === 'Token not found.') {
      yield put(authSlice.actions.setError(true));
    } else {
      toast.error(error?.response?.data?.message);
    }
  } finally {
    yield put(authSlice.actions.setLoading(false));
  }
}
function* activationTokenSaga({ payload }: ReturnType<typeof actions.activationTokenAction>) {
  yield put(authSlice.actions.setLoading(true));
  try {
    const { data } = yield call(authServices.activationTokenService, payload);
    yield put(authSlice.actions.setActivationUser(data));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(authSlice.actions.setLoading(false));
  }
}
export function* watchAuthSaga(): Generator<ForkEffect> {
  yield takeLatest(actions.logInAction.type, logInSaga);
  yield takeLatest(actions.getUserAction.type, getUserSaga);
  yield takeLatest(actions.registrationAction.type, registrationSaga);
  yield takeLatest(actions.inviteSignUpAction.type, inviteSignUpSaga);
  yield takeLatest(actions.verifyOtpAction.type, verifyOtpSaga);
  yield takeLatest(actions.resendOtpAction.type, resendOtpSaga);
  yield takeLatest(actions.resendEmailAction.type, resendEmailSaga);
  yield takeLatest(actions.forgotPasswordAction.type, forgotPasswordSaga);
  yield takeLatest(actions.verifyForgotPasswordAction.type, verifyForgotPasswordSaga);
  yield takeLatest(actions.resetPasswordAction.type, resetPasswordSaga);
  yield takeLatest(actions.activateAction.type, activateSaga);
  yield takeLatest(actions.activationTokenAction.type, activationTokenSaga);
  yield takeLatest(actions.confirmEmailOtpAction.type, confirmEmailOtpSaga);
}
