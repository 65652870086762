import React, { useEffect, useMemo } from 'react';

import { H3, H5 } from 'components/Typography/Headlines';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { publicListingsDefaultValues } from './constants';
import Filter from './Filter';
import styles from './PublicListings.module.scss';
import PublicListingsCard from './PublicListingsCard';
import { type IForm } from './types';
import { getUrlParams } from '../../common/utils/utils';
import Loading from '../../components/shared/Loading';
import Pagination from '../../components/shared/Pagination';
import { B1, B2 } from '../../components/Typography/Body';
import { routes } from '../../constants/routes';
import { getListingsAction } from '../Listings/store/actions';
import listingsSlice from '../Listings/store/listingsSlice';
import { listingQueryOptionsSelector, listingsLoadingSelector, listingsSelector } from '../Listings/store/selectors';

const PublicListings = () => {
  const dispatch = useDispatch();
  const form = useForm<IForm>({ defaultValues: publicListingsDefaultValues });
  const listings = useSelector(listingsSelector);
  const loading = useSelector(listingsLoadingSelector);
  const { page, size } = useSelector(listingQueryOptionsSelector);
  const urlParams = getUrlParams();
  const location = useLocation();

  const { control } = form;
  const hasPets = useWatch({ control, name: 'hasPets' });
  const propertyType = useWatch({ control, name: 'propertyType' });

  useEffect(() => {
    if (location.pathname === routes.listingsView) {
      dispatch(getListingsAction({ hasPets, propertyType, page, size, status: 'PUBLISHED' }));
    } else {
      if (urlParams?.id) {
        dispatch(
          getListingsAction({ id: urlParams?.id, hasPets, propertyType, page, size, status: 'PUBLISHED', isPublic: true })
        );
      }
    }
  }, [urlParams?.id, hasPets, propertyType, page, size]);

  const filteredListings = useMemo(() => {
    return listings?.items || null;
  }, [listings]);

  return (
    <div className={styles.publicListings}>
      <Filter form={form} />
      <div>
        <div className={styles.title}>
          <H3>Current Listings</H3>
          {propertyType === 'All' ? <H5>Showing all available listings</H5> : <H5>Showing listings by filter criteria</H5>}
        </div>
        {!!filteredListings?.length &&
          filteredListings.map(listing => <PublicListingsCard listing={listing} key={listing._id} />)}
      </div>
      {loading && <Loading isBig />}
      <Pagination
        page={page}
        pageCount={listings?.totalCount || 0}
        gotoPage={page => dispatch(listingsSlice.actions.setQueryOptions({ page, size }))}
        setPageSize={size => dispatch(listingsSlice.actions.setQueryOptions({ page: 1, size }))}
        pageSize={size}
        className={styles.paggination}
      />
      {!listings?.totalCount && !loading && (
        <div className={styles.notFound}>
          <B1 bold>No Listings found</B1>
          <B2>You don't have any listings to publish.</B2>
        </div>
      )}
    </div>
  );
};

export default PublicListings;
