import { createElement, type FC, type HTMLProps, memo, type PropsWithChildren, type ReactNode } from 'react';

import cx from 'classnames';
import { type NavLink, type NavLinkProps } from 'react-router-dom';

import styles from './MenuLink.module.scss';
import { isNavLinkComponent } from '../utils';

interface LinkProps {
  className: string;
  children: Props['children'];
  title: string;
  href?: string;
  to?: string;
}

export interface Props {
  className?: string;
  children: ReactNode;
  isCollapsed: boolean;
  linkTo?: string | NavLinkProps['to'];
  href?: string;
  linkComponent?: typeof NavLink | FC<PropsWithChildren<LinkProps>>;
  title?: string;
  withoutHover?: boolean;
  onActiveChange?: (isActive: boolean) => void;
  isActive?: boolean;
}

const MenuLink: FC<PropsWithChildren<Props & Partial<NavLinkProps> & HTMLProps<HTMLLinkElement>>> = ({
  children,
  linkComponent,
  linkTo,
  title,
  href,
  className,
  isCollapsed,
  withoutHover,
  onActiveChange,
  isActive: propsIsActive,
  ...restProps
}) => {
  const LinkComponent = linkComponent ? linkComponent : href ?? linkTo ? 'a' : 'div';

  const classNames = cx(styles.link, className, {
    [styles.collapsed as string]: isCollapsed,
    [styles.withoutHover as string]: withoutHover,
  });

  if (LinkComponent === 'a') {
    return createElement<HTMLProps<HTMLLinkElement>>(
      LinkComponent,
      {
        className: cx(classNames, { [styles.active as string]: propsIsActive }),
        href,
        ...(title ? { title } : {}),
        ...restProps,
      },
      children
    );
  }

  if (LinkComponent === 'div') {
    return createElement<HTMLProps<HTMLDivElement>>(
      LinkComponent,
      {
        className: cx(classNames, { [styles.active as string]: propsIsActive }),
        ...(title ? { title } : {}),
        ...(restProps as Partial<HTMLProps<HTMLDivElement>>),
      },
      children
    );
  }

  if (isNavLinkComponent(LinkComponent)) {
    const { ...restLinkProps } = restProps;

    return createElement<NavLinkProps>(
      LinkComponent,
      {
        className: ({ isActive }) => {
          onActiveChange?.(isActive);

          return cx(classNames, { [styles.active as string]: isActive });
        },
        to: linkTo as NavLinkProps['to'],
        ...(title ? { title } : {}),
        ...restLinkProps,
      },
      children
    );
  }

  return null;
};

export default Object.assign(memo(MenuLink), { displayName: 'MenuLink' });
