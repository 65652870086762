import React from 'react';

import cn from 'classnames';

import styles from './AlertCard.module.scss';
import { B2 } from '../../Typography/Body';
import SvgIcon from '../utils/SvgIcon';

export interface AlertCardProps {
  type: 'error' | 'info';
  title: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  classNames?: string;
}

const AlertCard = ({ type, title, classNames, isOpen, setIsOpen }: AlertCardProps) => {
  return isOpen ? (
    <div className={cn(styles.alertCard, classNames)}>
      <div className={cn(styles.line, { [styles[type]]: type })} />
      <B2 bold className={cn(styles.title)}>
        {title}
      </B2>
      <SvgIcon icon={'close'} className={styles.close} onClick={() => setIsOpen(!isOpen)} />
    </div>
  ) : null;
};

export default AlertCard;
