import { useEffect, useState } from 'react';

import { Stepper, Step, StepLabel, Button, Container, StepIcon } from '@mui/material';
import { styled } from '@mui/system';
import cn from 'classnames';
import { B2 } from 'components/Typography/Body';
import { AplicationSteps } from 'modules/constants/application';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import { type ProgressBarMode } from './constants';
import styles from './ProgressBar.module.scss';

export interface ProgressBarProps {
  steps: string[];
  mode: ProgressBarMode;
  setValue: any;
  stepClick?: (index: number) => void;
  disabled: boolean;
}

const CustomStepIcon = styled(StepIcon)(({ active, completed }) => ({
  borderRadius: '50%',
  border: !completed ? '2px solid white' : '',
  fill: active ? '#61B8D0' : completed ? 'white' : '#1094B8',
  fontFamily: 'Montserrat',
  stroke: 'white',
}));

const ProgressBar = ({ steps, mode, setValue, stepClick, disabled }: ProgressBarProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const [rearmostStep, setRearmostStep] = useState(0);
  const { step } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    setValue('step', step);
    step && +step > activeStep && setRearmostStep(+step);
    step && setActiveStep(+step);
  }, [step]);

  const handleStepClick = (stepIndex: number) => {
    setActiveStep(stepIndex);
    const id = searchParams.get('id');
    if (!localStorage.getItem('token')) {
      navigate(`/application/${AplicationSteps.BEFOREBEGIN}?auth=login&id=${id}`);
    } else {
      navigate(`/application/${stepIndex}?id=${id}`);
      stepClick && stepClick(stepIndex);
    }
  };

  return (
    <Container className={styles.container}>
      <B2 className={styles.stepCalc}>{`Step ${activeStep}/${steps.length}`}</B2>
      <div className={styles.progressBarContainer}>
        <Stepper className={styles.stepper} activeStep={activeStep} orientation={mode}>
          {steps.map((label, index) => (
            <Step key={label} completed={index + 1 < activeStep} className={styles.step}>
              <StepLabel
                className={styles.stepLabel}
                StepIconComponent={CustomStepIcon}
                StepIconProps={{ active: index + 1 === rearmostStep, completed: index + 1 < rearmostStep }}
              >
                <Button
                  onClick={() => handleStepClick(index + 1)}
                  className={cn({
                    [styles.disabledStep]: index + 1 >= rearmostStep,
                    [styles.stepLabel]: index + 1 <= rearmostStep,
                  })}
                  disabled={(index === 0 && disabled) || index + 1 > rearmostStep}
                >
                  {label}
                </Button>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    </Container>
  );
};

export default ProgressBar;
