export const createMaintenenceDefaultData = {
  requestPriority: 'LOW',
  hasFirstTime: false,
  hasFourthTime: false,
  hasSecondTime: false,
  hasThirdTime: false,
  title: '',
  category: [],
  others: [],
};
