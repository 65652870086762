import { useEffect, type FC } from 'react';

import { Box } from '@mui/system';
import ImageUploader from 'components/shared/ImageUploader/ImageUploader';
import SvgIcon from 'components/shared/utils/SvgIcon';
import { B2, B3 } from 'components/Typography/Body';
import { H4, H5 } from 'components/Typography/Headlines';
import { deleteFile, getApplicationById } from 'modules/Application/store/actions';
import { documentsDataSelector, hasPlaidVerifyedSelector } from 'modules/Application/store/selectors';
import { type DeleteFile } from 'modules/Application/store/types';
import { type IForm } from 'modules/Application/types';
import { AplicationSteps } from 'modules/constants/application';
import { useFormContext, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import styles from './AttachDocuments.module.scss';

const AttachDocuments: FC<any> = () => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<IForm>();
  const dispatch = useDispatch();

  const IDPhoto = useWatch({ control, name: 'IDPhoto' }) as File[] | string;
  const proofOfIncome = useWatch({ control, name: 'proofOfIncome' }) as File[];
  const others = useWatch({ control, name: 'others' }) as File[];
  const documentsData = useSelector(documentsDataSelector);
  const hasPlaidVerifyed = useSelector(hasPlaidVerifyedSelector);

  useEffect(() => {
    const userId = localStorage.getItem('id');
    if (userId) {
      dispatch(getApplicationById(userId));
    }
  }, []);

  useEffect(() => {
    hasPlaidVerifyed && setValue('plaidVerified', hasPlaidVerifyed);
  }, [hasPlaidVerifyed]);

  useEffect(() => {
    if (documentsData) {
      setValue('IDPhoto', documentsData.IDPhoto);
      setValue('proofOfIncome', documentsData.proofOfIncome);
      setValue('others', documentsData.others);
    }
  }, [documentsData]);

  const handleDeleteFile = (value: any, controlName?: string): void => {
    const id = localStorage.getItem('id');
    const deleteFileReqData: DeleteFile = {
      applicationId: id || '',
      key: controlName || '',
      path: value,
    };
    dispatch(deleteFile(deleteFileReqData));
  };

  return (
    <div className={styles.container}>
      <H4 className={styles.title} bold>
        Rental Application
      </H4>
      <B3 className={styles.desc}>Evolve Realty & Development</B3>
      <H5 className={styles.aploadImageTitle}>Upload Photo ID*</H5>
      <B3 className={styles.text}>Please upload all required documentation including:</B3>
      <B3 className={styles.text}>- Photo ID</B3>
      <B3 className={styles.text}>- Proof of income (e.g. recent pay stubs, bank statements, or tax returns)</B3>
      <Box>
        <Box className={styles.imageUrlsBox}>
          <ImageUploader
            maxSize={2000000}
            isBig
            multiple
            imageUrls={IDPhoto}
            uploaderText={'Upload Card Front image here'}
            fileTypeText={'(jpg, png, pdf)'}
            setImageUrls={(value: File[]) => setValue('IDPhoto', value)}
            disabled={IDPhoto?.length === 2}
            controlName="IDPhoto"
            deleteImage={handleDeleteFile}
            hasErrorBlock={true}
            message={errors?.IDPhoto?.message || null}
          />
        </Box>
        <Box className={styles.documentUrlsBox}>
          <H5 className={styles.documentUploaderTitle}>Proof of Income*</H5>
          <SvgIcon icon={'exclamation'} className={styles.iconVerify} />
          <B2 className={styles.goBack}>
            Income Verification is incomplete.{' '}
            <NavLink
              className={styles.goBackLink}
              to={`/application/${AplicationSteps.INCOMEVEREFICATIONS}?id=6553447cb4d19f6d1ec4119c`}
            >
              Go Back to Income Verification.
            </NavLink>{' '}
          </B2>
          <B3 className={styles.info}>Upload additional offer letters, scholarship information, etc. below</B3>
          <ImageUploader
            isBig
            maxSize={2000000}
            multiple
            imageUrls={proofOfIncome}
            accept=".pdf, .jpeg, .png"
            fileTypeText={'(jpg, png, pdf)'}
            setImageUrls={(value: File[]) => setValue('proofOfIncome', value)}
            controlName="proofOfIncome"
            uploaderText="Uploading documents image"
            disabled={(proofOfIncome?.length || 0) + (others?.length || 0) === 20}
            deleteImage={handleDeleteFile}
            hasErrorBlock={true}
            message={errors?.proofOfIncome?.message || null}
          />
        </Box>
        <Box className={styles.documentUrlsBox}>
          <H5 className={styles.documentUploaderTitle}>Other documents</H5>
          <B3 className={styles.info}>
            *Your property manager may request additional information to verify your income after you submit your rental
            application.
          </B3>
          <ImageUploader
            isBig
            maxSize={2000000}
            multiple
            imageUrls={others}
            accept=".pdf, .jpeg, .png"
            controlName="others"
            uploaderText="Uploading documents image"
            setImageUrls={(value: File[]) => setValue('others', value)}
            deleteImage={handleDeleteFile}
            fileTypeText={'(jpg, png, pdf)'}
            hasErrorBlock={true}
            disabled={(proofOfIncome?.length || 0) + (others?.length || 0) === 20}
          />
        </Box>
      </Box>
    </div>
  );
};

export default AttachDocuments;
