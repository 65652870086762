import { useEffect, useMemo } from 'react';

import { useMediaQuery } from '@mui/material';
import Button from 'components/shared/Button';
import Loading from 'components/shared/Loading';
import StatusLine from 'components/shared/StatusLine';
import TableCard from 'components/shared/TableCard';
import { B1, B3 } from 'components/Typography/Body';
import { H3 } from 'components/Typography/Headlines';
import { routes } from 'constants/routes';
import { getLeaseAction } from 'modules/Lease/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getTasksList } from './store/actions';
import { loadingSelector, taskCountSelector, taskListSelector, taskQueryOptionsSelector } from './store/selector';
import taskSlice from './store/taskSlice';
import styles from './Tasks.module.scss';
import TasksCard from './TasksCard';
import PrivateAccess from '../../components/PrivateAccess';
import StatusTag from '../../components/shared/StatusTag';
import { userRole } from '../../constants/userStatus';
import { userSelector } from '../../modules/Auth/store/selectors';

const Tasks = () => {
  const dispatch = useDispatch();
  const { page, size } = useSelector(taskQueryOptionsSelector);
  const taskList = useSelector(taskListSelector);
  const taskCount = useSelector(taskCountSelector);
  const loading = useSelector(loadingSelector);
  const user = useSelector(userSelector);
  const navigate = useNavigate();
  const isMobileScreen = useMediaQuery('(max-width: 600px)');
  const memoColumns = useMemo(() => {
    return [
      {
        Header: 'Title',
        id: 'title',
        title: 'Title',
        width: '140',
        Cell: ({ row }: any) => {
          return (
            <StatusLine type={row.original.status}>
              <div>
                <B3 bold>{row.original.title}</B3>
              </div>
            </StatusLine>
          );
        },
      },
      {
        Header: 'Property ',
        id: 'property ',
        title: 'Property ',
        isSorted: true,
        Cell: ({ row }: any) => {
          return (
            <B3 bold>
              {`${row.original?.unit?.unitNumber ? `Unit ${row.original?.unit?.unitNumber}, ` : ''}${
                row.original?.property?.propertyName || ''
              }${
                row.original?.property?.googleApi?.address?.value ? ` - ${row.original?.property?.googleApi?.address?.value}` : ''
              }`}
            </B3>
          );
        },
      },
      // {
      //   Header: 'Tenant name',
      //   id: 'tenantName',
      //   title: 'Tenant name',
      //   Cell: ({ row }: any) => {
      //     return <B3 bold>{row.original?.tenant?.fullName}</B3>;
      //   },
      // },
      {
        Header: 'Assigned manager',
        id: 'Assigned manager',
        title: 'Assigned manager',
        Cell: ({ row }: any) => {
          return <B3 bold>{row.original?.assignee?.email}</B3>;
        },
      },
      {
        Header: 'Priority',
        id: 'priority',
        width: '70',
        title: 'Priority',
        Cell: ({ row }: any) => {
          return <StatusTag label={row.original.priority || undefined} type={row.original.priority} />;
        },
      },
      {
        Header: 'Status',
        id: 'status',
        width: '70',
        title: 'Status',
        Cell: ({ row }: any) => {
          return <StatusTag label={row.original.status} type={row.original.status} />;
        },
      },
    ];
  }, []);

  useEffect(() => {
    dispatch(getLeaseAction({ page, size }));
    dispatch(getTasksList({ page, size }));
  }, [page, size]);

  useEffect(() => {
    return () => {
      if (!location.pathname.includes(routes.tasks)) {
        dispatch(taskSlice.actions.clearTasksList());
      }
    };
  }, []);

  return (
    <div className={styles.lease}>
      <div className={styles.header}>
        <H3>Tasks</H3>

        <PrivateAccess roles={[userRole.LANDLOARD, userRole.MANAGER]}>
          <Button
            variant="contained"
            size="ds"
            type="button"
            label="Create Task"
            disabled={user?.status !== 'ACTIVE'}
            onClick={() => navigate(routes.assingTask)}
          />
        </PrivateAccess>
      </div>
      {!!taskList?.length && (
        <div>
          {isMobileScreen ? (
            taskList.map(task => <TasksCard key={task._id} task={task} />)
          ) : (
            <TableCard
              className={styles.leaseTabel}
              columns={memoColumns}
              data={taskList || []}
              defaultPageSize={size}
              totalItems={taskCount}
              setPageSize={size => dispatch(getTasksList({ page: 1, size }))}
              defaultPage={page}
              onRowClick={(row: { original: any }) => {
                return navigate(`${routes.taskDetails}?taskId=${row.original._id}&unitId=${row?.original?.unit?._id}`);
              }}
              fetchData={({ pageSize, pageIndex }) => dispatch(getTasksList({ page: pageIndex, size: `${pageSize}` }))}
              hidePagination={false}
            />
          )}
        </div>
      )}
      {loading && <Loading isBig />}
      {!taskList?.length && !loading && (
        <div className={styles.notFound}>
          <B1 bold>No Tasks Found</B1>
        </div>
      )}
    </div>
  );
};

export default Tasks;
