import toast from 'react-hot-toast';
import { call, type ForkEffect, put, takeLatest } from 'redux-saga/effects';

import accountingSlice from './accountingSlice';
import * as actions from './actions';
import { accountingFilterAdapter } from './dataAdapter';
import { getAccountingService } from '../AccountingService';

function* getAccountingSaga({ payload }: ReturnType<typeof actions.getAccountingAction>) {
  yield put(accountingSlice.actions.setLoading(true));
  try {
    const adaptedData = accountingFilterAdapter(payload);
    const { data } = yield call(getAccountingService as any, adaptedData);
    yield put(accountingSlice.actions.getAccountingSuccess({ data }));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(accountingSlice.actions.setLoading(false));
  }
}

export function* watchAccountingSaga(): Generator<ForkEffect> {
  yield takeLatest(actions.getAccountingAction.type, getAccountingSaga);
}
