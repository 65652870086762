import applicationSlice from 'modules/Application/store/aplicationSlice';
import { type ApplicationsDataModel } from 'modules/types/applicationTypes';
import toast from 'react-hot-toast';
import { type ForkEffect, call, takeLatest, put } from 'redux-saga/effects';

import * as actions from './actions';
import applicantSlice from './applicantsSlice';
import { confirmOrRejectApplicant, getApplicantList } from './applicationRequestService.ts';

function* getApplicantListSaga({ payload }: ReturnType<typeof actions.getApplicantList>) {
  yield put(applicantSlice.actions.setLoading(true));
  try {
    const { data }: { data: { items: ApplicationsDataModel[]; count: string } } = yield call(getApplicantList, payload);

    yield put(applicantSlice.actions.setApplicantData({ data, queryOptions: payload }));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(applicantSlice.actions.setLoading(false));
  }
}

function* confirmOrRejectApplicantSaga({ payload }: ReturnType<typeof actions.confirmApplicant>) {
  yield put(applicantSlice.actions.setLoading(true));
  try {
    yield call(confirmOrRejectApplicant, payload);
    yield put(applicationSlice.actions.setApplicationStatus(payload.action));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(applicantSlice.actions.setLoading(false));
  }
}

function* setPageSize({ payload }: ReturnType<typeof actions.setPageSize>) {
  yield put(applicantSlice.actions.setPageSize(payload[0]));
}

export function* watchApplicantSaga(): Generator<ForkEffect> {
  yield takeLatest(actions.getApplicantList.type, getApplicantListSaga);
  yield takeLatest(actions.confirmApplicant.type, confirmOrRejectApplicantSaga);
  yield takeLatest(actions.rejectApplicant.type, confirmOrRejectApplicantSaga);
  yield takeLatest(actions.setPageSize.type, setPageSize);
}
