export const NotificationStatus: { [key: string]: string } = {
  UNREAD: 'Unread',
  READ: 'Read',
};

export const notificationIcons: any = {
  APPLICATION: 'applications',
  PAYMENT: 'list',
  MAINTENANCE: 'maintenance',
  LEASE: 'lease',
  MESSAGE: 'email',
  TASK: 'email',
};

export const borderColors: any = {
  APPLICATION: {
    borderLeft: '3px solid rgba(var(--flagged))',
  },
  PAYMENT: {
    borderLeft: '3px solid rgba(var(--rejected))',
  },
  MAINTENANCE: {
    borderLeft: '3px solid rgba(var(--incomplete))',
  },
  LEASE: {
    borderLeft: '3px solid rgba(var(--pending))',
  },
  MESSAGE: {
    borderLeft: '3px solid rgba(var(--active))',
  },
  TASK: {
    borderLeft: '3px solid rgba(var(--unsigned))',
  },
};

export const textColors: any = {
  APPLICATION: {
    color: 'rgba(var(--flagged))',
  },
  PAYMENT: {
    color: 'rgba(var(--rejected))',
  },
  MAINTENANCE: {
    color: 'rgba(var(--incomplete))',
  },
  LEASE: {
    color: 'rgba(var(--pending))',
  },
  MESSAGE: {
    color: 'rgba(var(--active))',
  },
  TASK: {
    color: 'rgba(var(--unsigned))',
  },
};
