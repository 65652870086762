import React, { useMemo } from 'react';

import { Box } from '@mui/material';
import Autocomplete from 'components/shared/FormElements/Autocomplete/Autocomplete';
import { B1 } from 'components/Typography/Body';
import dayjs from 'dayjs';
import { Controller, useWatch } from 'react-hook-form';

import styles from './Details.module.scss';
import { Input } from '../../../../components/shared/FormElements';
import DatePicker from '../../../../components/shared/FormElements/DatePicker/DatePicker';
import { regexp } from '../../../../constants/regexp';
import { leasDurationOption } from '../../../../constants/utils';

interface DetailsType {
  form: any;
  isValidStatus?: boolean;
  signed: boolean;
}

const Details = ({ form, isValidStatus = true, signed }: DetailsType) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = form;

  const leaseDuration = useWatch({ control, name: 'leaseDuration' });
  const startDataValue = useWatch({ control, name: 'startDate' });
  const isSigned = useWatch({ control, name: 'isSigned' });

  const minDate = !isSigned && isValidStatus ? dayjs(new Date()) : null;

  const endDataValue = useMemo(() => {
    if (startDataValue && leaseDuration) {
      setValue('endDate', dayjs(new Date(startDataValue)).add(leaseDuration.number, 'month'));
      return dayjs(new Date(startDataValue)).add(leaseDuration.number, 'month');
    }
    return null;
  }, [leaseDuration, startDataValue]);

  return (
    <div className={styles.details}>
      <Box className={styles.inputBox}>
        <B1 bold>Lease details</B1>
        <Box className={styles.grid}>
          <Controller
            name="leaseDuration"
            control={control}
            render={({ field }) => (
              <Autocomplete
                autoComplete={'off'}
                label="Lease Duration*"
                disabled={!isValidStatus}
                options={leasDurationOption}
                errorMessage={errors?.leaseDuration?.message}
                hasError={!!errors?.leaseDuration}
                {...field}
              />
            )}
          />
          <Box className={styles.grid}>
            <Controller
              name="startDate"
              control={control}
              render={({ field }) => (
                <DatePicker
                  className={styles.calendar}
                  label="Start date*"
                  {...field}
                  disabled={!isValidStatus}
                  minDate={minDate}
                  errorMessage={errors?.startDate?.message}
                  hasError={!!errors.startDate}
                />
              )}
            />
            <Controller
              name="endDate"
              control={control}
              render={({ field }) => (
                <DatePicker {...field} className={styles.calendar} label="End date*" disabled value={endDataValue} />
              )}
            />
          </Box>
        </Box>
        <Box className={styles.grid}>
          <Controller
            name="deposit"
            control={control}
            render={({ field }) => (
              <Input
                label="Deposit*"
                disabled={!isValidStatus}
                regexp={regexp.number}
                errorMessage={errors?.deposit?.message}
                hasError={!!errors?.deposit}
                {...field}
              />
            )}
          />
          <Box className={styles.grid}>
            <Controller
              name="rent"
              control={control}
              render={({ field }) => (
                <Input
                  label="Rent*"
                  disabled={!isValidStatus}
                  regexp={regexp.number}
                  errorMessage={errors?.rent?.message}
                  hasError={!!errors?.rent}
                  {...field}
                />
              )}
            />
            {signed && (
              <Controller
                name="nextDueDate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    className={styles.calendar}
                    label="Next due date*"
                    {...field}
                    disabled={!isValidStatus}
                    errorMessage={errors?.nextDueDate?.message}
                    hasError={!!errors.nextDueDate}
                  />
                )}
              />
            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Details;
