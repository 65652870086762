import toast from 'react-hot-toast';
import { call, type ForkEffect, put, select, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import createListingsData from './dataAdapter';
import listingsSlice from './listingsSlice';
import { listingQueryOptionsSelector } from './selectors';
import { routes } from '../../../constants/routes';
import { type RootState } from '../../../store/setupStore';
import * as listingsService from '../listingsService';
import { uploadUnitsImagesServices } from '../listingsService';

function* createListingsSaga({ payload }: ReturnType<typeof actions.createListingsAction>) {
  yield put(listingsSlice.actions.setLoading(true));
  try {
    const images = [...(payload?.imageCover ? payload.imageCover : []), ...(payload?.imageUrls ? payload.imageUrls : [])];
    const { data } = yield call(listingsService.createListings, {
      requestData: createListingsData(payload),
      listingId: payload.listingId,
    });

    if (!images.every((element: any) => typeof element === 'string')) {
      yield call(uploadUnitsImagesServices, {
        images,
        unitID: data._id,
      });
    }
    payload.navigate(routes.listings);
    toast.success('Congratulations! Your information has been successfully saved.');
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(listingsSlice.actions.setLoading(false));
  }
}
function* getListingsSaga({ payload }: ReturnType<typeof actions.getListingsAction>) {
  yield put(listingsSlice.actions.setLoading(true));

  try {
    const { data } = yield call(listingsService.getListings, payload);
    yield put(listingsSlice.actions.setQueryOptions({ page: payload.page, size: payload.size }));
    yield put(listingsSlice.actions.getListingsSuccessAction(data));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(listingsSlice.actions.setLoading(false));
  }
}
function* deleteImageSaga({ payload }: ReturnType<typeof actions.deleteImageAction>) {
  try {
    yield call(listingsService.deleteImage, payload);
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  }
}
function* getListingByIdSaga({ payload }: ReturnType<typeof actions.getListingByIdAction>) {
  yield put(listingsSlice.actions.setLoading(true));
  try {
    const { data } = yield call(listingsService.getListingById, payload);
    yield put(listingsSlice.actions.getListingByIdSuccessAction(data));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(listingsSlice.actions.setLoading(false));
  }
}
function* inviteToApplySaga({ payload }: ReturnType<typeof actions.inviteToApplyAction>) {
  yield put(listingsSlice.actions.setLoading(true));
  try {
    yield call(listingsService.inviteToApply, payload);
    toast.success('Your Invite has been sent successfully');
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(listingsSlice.actions.setLoading(false));
  }
}
function* getApplicationCountByIdSaga({ payload }: ReturnType<typeof actions.getApplicationCountByIdAction>) {
  yield put(listingsSlice.actions.setLoading(true));
  try {
    const { data } = yield call(listingsService.getApplicationCountById, payload);
    yield put(listingsSlice.actions.getApplicationCountByIdSuccessAction(data));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(listingsSlice.actions.setLoading(false));
  }
}
function* statusUpdateActionSaga({ payload }: ReturnType<typeof actions.statusUpdateAction>) {
  yield put(listingsSlice.actions.setSmileLoading(payload._id));
  const listingQueryOptions: RootState = yield select(listingQueryOptionsSelector);
  try {
    const { data } = yield call(listingsService.statusUpdate, { ...payload, ...listingQueryOptions });
    if (payload?.isSingle) {
      yield put(listingsSlice.actions.updateListingStatus(payload));
    } else {
      yield put(listingsSlice.actions.getListingsSuccessAction(data));
    }
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(listingsSlice.actions.setSmileLoading(''));
  }
}
export function* watchListingsSaga(): Generator<ForkEffect> {
  yield takeLatest(actions.createListingsAction.type, createListingsSaga);
  yield takeLatest(actions.getListingsAction.type, getListingsSaga);
  yield takeLatest(actions.getListingByIdAction.type, getListingByIdSaga);
  yield takeLatest(actions.statusUpdateAction.type, statusUpdateActionSaga);
  yield takeLatest(actions.deleteImageAction.type, deleteImageSaga);
  yield takeLatest(actions.inviteToApplyAction.type, inviteToApplySaga);
  yield takeLatest(actions.getApplicationCountByIdAction.type, getApplicationCountByIdSaga);
}
