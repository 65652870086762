import { createSlice } from '@reduxjs/toolkit';

import { type chatState } from './types';

const initialState: chatState = {
  isLoading: false,
  data: [],
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setLoading: (state: chatState, { payload }) => {
      state.isLoading = payload.items;
    },
    getChat: (state: chatState, { payload }) => {
      state.data = payload;
    },
  },
});

export default chatSlice;
