import React, { useCallback, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Modal } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import styles from './AddBankAccount.module.scss';
import { defaultValues } from './constants';
import { type IForm } from './types';
import { addPropertySchema } from './validations/addPropertySchema';
import Button from '../../components/shared/Button';
import { Input } from '../../components/shared/FormElements';
import SvgIcon from '../../components/shared/utils/SvgIcon';
import { H5 } from '../../components/Typography/Headlines';
import modalNames from '../../constants/modalNames';
import { regexp } from '../../constants/regexp';
import { addBankAccountAction } from '../../modules/Profile/store/actions';
import { profileLoadingSelector } from '../../modules/Profile/store/selectors';
import { type RootState } from '../../store/setupStore';
import { modalsSlice } from '../index';
import { checkModalVisibility, getModalByName } from '../store/selectors';
const AddBankAccount = () => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IForm>({ defaultValues, resolver: yupResolver(addPropertySchema) });
  const dispatch = useDispatch();
  const loading = useSelector(profileLoadingSelector);
  const isModalVisible = useSelector((store: RootState) => checkModalVisibility(store, modalNames.ADD_BANK_ACCOUNT));
  const modalData: any = useSelector((store: RootState) => getModalByName(store, modalNames.ADD_BANK_ACCOUNT));

  const handleModalClose = useCallback(() => {
    reset();
    dispatch(modalsSlice.actions.hideModal(modalNames.ADD_BANK_ACCOUNT));
  }, [dispatch]);

  const onSubmit = (data: IForm) => {
    dispatch(addBankAccountAction(data));
  };

  useEffect(() => {
    reset();
  }, [isModalVisible]);

  return (
    <Modal open={isModalVisible} onClose={handleModalClose} className={styles.modal}>
      <div className={styles.addRentalOwnerModal}>
        <header className={styles.header}>
          <H5 bold>{modalData?.title}</H5>
          <SvgIcon icon="close" className={styles.close} onClick={handleModalClose} />
        </header>
        <form className={styles.content}>
          <Controller
            name="routing_number"
            control={control}
            render={({ field }) => (
              <Input
                label="Routing Number*"
                regexp={regexp.number}
                errorMessage={errors.routing_number?.message}
                hasError={!!errors.routing_number}
                {...field}
              />
            )}
          />
          <Controller
            name="account_number"
            control={control}
            render={({ field }) => (
              <Input
                label="Account Number*"
                errorMessage={errors.account_number?.message}
                hasError={!!errors.account_number}
                regexp={regexp.number}
                placeholder="Enter Account Number"
                {...field}
              />
            )}
          />
          <Controller
            name="confirmAccountNumber"
            control={control}
            render={({ field }) => (
              <Input
                label="Confirm Account Number*"
                errorMessage={errors.confirmAccountNumber?.message}
                hasError={!!errors.confirmAccountNumber}
                regexp={regexp.number}
                placeholder="Confirm Account Number"
                {...field}
              />
            )}
          />
          <Controller
            name="account_name"
            control={control}
            render={({ field }) => (
              <Input
                label="Account Name*"
                multiline
                errorMessage={errors.account_name?.message}
                hasError={!!errors.account_name}
                {...field}
              />
            )}
          />
          <Controller
            name="account_holder_name"
            control={control}
            render={({ field }) => (
              <Input
                label="Account Holder Name*"
                multiline
                errorMessage={errors.account_holder_name?.message}
                hasError={!!errors.account_holder_name}
                {...field}
              />
            )}
          />

          <Box className={styles.buttons}>
            <Button variant="outlined" size="ds" type="button" label="Cancel" onClick={handleModalClose} />
            <Button
              variant="contained"
              size="ds"
              type="button"
              label="Create"
              onClick={handleSubmit(onSubmit)}
              disabled={loading}
              loading={loading}
            />
          </Box>
        </form>
      </div>
    </Modal>
  );
};

export default AddBankAccount;
