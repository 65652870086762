import { createAction } from '@reduxjs/toolkit';

import { type IForm } from '../types';

export const createProfileAction = createAction<{ data: IForm; navigate: (to: string) => void; dirtyFields: any }>(
  'createProfileAction'
);
export const createTenantProfileAction = createAction<{ data: IForm; navigate: (to: string) => void; dirtyFields: any }>(
  'createTenantProfileAction'
);

export const addBankAccountAction = createAction<{
  account_number: string;
  account_holder_name: string;
  routing_number: string;
  confirmAccountNumber: string;
  account_name: string;
}>('addBankAccountAction');

export const getBankAccountAction = createAction('getBankAccountAction');

export const deleteBankAccountAction = createAction('deleteBankAccountAction');
