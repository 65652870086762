import request from '../../../services/request';
import { type plaidRetrieveBalanceDataModel, type DeleteFile } from '../store/types';

const END_POINTS = {
  getUnits: (id: string | null) => `api/listing/${id}`,
  createApplication: 'api/application',
  getApplication: (id: string | null) => `api/application/${id}`,
  uploadFiles: 'api/document/upload-application',
  deleteFile: 'api/document/application',
  getToken: 'api/account/tenant/activate-application/',
  registerByInvite: 'api/account/invite-sign-up',
  emailVerify: 'api/account/email-verify',
  confirmemailOtp: 'api/account/confirm-email-otp',
  login: 'api/account/login',
  plaidVerification: (id: string) => `api/application/${id}/plaid/link-token/BALANCE`,
  plaidRetrieveBalance: (id: string) => `api/application/${id}/plaid/retrieve/BALANCE`,
  createPayMethod: (id: string) => `api/application/${id}/create-payment`,
  confirmPayMethod: (id: string) => `api/application/${id}/confirm-payment`,
};

export function getUnitsById(id: string | null) {
  // const id = localStorage.getItem('id');
  return request({
    method: 'GET',
    url: END_POINTS.getUnits(id),
  });
}

export function getToken(token: string) {
  return request({
    method: 'GET',
    url: `${END_POINTS.getToken}` + `${token}`,
  });
}

export function createApplication(payload: any) {
  return request({
    method: 'POST',
    url: END_POINTS.createApplication,
    data: { ...payload },
  });
}

export function getApplicationById(id: string) {
  return request({
    method: 'GET',
    url: END_POINTS.getApplication(id),
  });
}

export function uploadImagesAndFilesServices(payload: { files: File[]; applicationId: string }) {
  const formData = new FormData();
  payload.files?.forEach((file: File) => {
    formData.append(`files`, file, file.name);
  });
  formData.append('applicationId', payload.applicationId);
  return request({
    method: 'POST',
    url: END_POINTS.uploadFiles,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function deleteFile(payload: DeleteFile) {
  return request({
    method: 'PATCH',
    url: END_POINTS.deleteFile,
    data: { ...payload },
  });
}

export function registerByInvite(payload: any) {
  return request({
    method: 'POST',
    url: END_POINTS.registerByInvite,
    data: { ...payload },
  });
}

export function emailVerify(payload: any) {
  return request({
    method: 'POST',
    url: END_POINTS.emailVerify,
    data: { ...payload },
  });
}

export function confirmEmailOtp(payload: any) {
  return request({
    method: 'POST',
    url: END_POINTS.confirmemailOtp,
    data: { ...payload },
  });
}

export function applicationLogin(payload: any) {
  return request({
    method: 'POST',
    url: END_POINTS.login,
    data: { ...payload },
  });
}

export function plaidVerification(payload: string) {
  return request({
    method: 'GET',
    url: END_POINTS.plaidVerification(payload),
  });
}

export function plaidRetrieveBalance(payload: plaidRetrieveBalanceDataModel) {
  return request({
    method: 'POST',
    url: END_POINTS.plaidRetrieveBalance(payload.applicationId),
    data: { publicToken: payload.publicToken },
  });
}

export function createPaymentMethod(payload: any) {
  return request({
    method: 'POST',
    url: END_POINTS.createPayMethod(payload.id),
    data: { id: payload.paymentId },
  });
}
export function confirmPaymentService(payload: any) {
  return request({
    method: 'POST',
    url: END_POINTS.confirmPayMethod(payload),
  });
}
