import { createSlice } from '@reduxjs/toolkit';

import { type HomeState } from './types';

const initialState: HomeState = {
  rentalListings: {},
  rentalAplication: {},
  tenantsIncome: {},
  dashboardMaintenance: {},
  expiringLeases: {},
  dashboardTasks: {},
};

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    getRentalListings: (state: HomeState, { payload }) => {
      state.rentalListings = payload;
    },
    getRentalAplication: (state: HomeState, { payload }) => {
      state.rentalAplication = payload;
    },
    getTenantsIncome: (state: HomeState, { payload }) => {
      state.tenantsIncome = payload;
    },
    getdashboardMaintenance: (state: HomeState, { payload }) => {
      state.dashboardMaintenance = payload;
    },
    getExpiringLeases: (state: HomeState, { payload }) => {
      state.expiringLeases = payload;
    },
    getDashboardTasks: (state: HomeState, { payload }) => {
      state.dashboardTasks = payload;
    },
  },
});

export default homeSlice;
