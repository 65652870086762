import toast from 'react-hot-toast';
import { call, type ForkEffect, put, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import { dataAdapter, leaseUpdateAdapter, setToSignAdapter } from './dataAdapter';
import leaseSlice from './leaseSlice';
import { routes } from '../../../constants/routes';
import {
  createLeaseService,
  getLeaseService,
  getLeaseByIdService,
  uploadDocumentServices,
  sentToSignLeaseService,
  updateLeaseService,
  getLeaseByUnitIdService,
} from '../leaseServices';

function* getLeaseSaga({ payload }: ReturnType<typeof actions.getLeaseAction>) {
  yield put(leaseSlice.actions.setLoading(true));
  try {
    const { data } = yield call(getLeaseService, payload);
    yield put(leaseSlice.actions.getLeaseListSuccess({ data, queryOptions: payload }));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(leaseSlice.actions.setLoading(false));
  }
}
function* getLeaseByIdSaga({ payload }: ReturnType<typeof actions.getLeaseByIdAction>) {
  yield put(leaseSlice.actions.setLoading(true));
  try {
    const { data } = yield call(getLeaseByIdService, payload);
    yield put(leaseSlice.actions.getLeaseByIdSuccess(data));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(leaseSlice.actions.setLoading(false));
  }
}
function* createLeaseSaga({ payload }: ReturnType<typeof actions.createLeaseAction>) {
  yield put(leaseSlice.actions.setLoading(true));
  try {
    const images = [...(payload.data?.documents ? payload.data.documents : [])];
    const { data } = yield call(createLeaseService, dataAdapter(payload.data));
    if (images.length) {
      yield call(uploadDocumentServices, {
        images,
        id: data._id,
      });
    }
    payload.navigate(routes.lease);
    toast.success('Your Lease has been successfully created.');
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(leaseSlice.actions.setLoading(false));
  }
}
function* sentToSignLeaseSaga({ payload }: ReturnType<typeof actions.sentToSignLeaseAction>) {
  yield put(leaseSlice.actions.setLoading(true));
  try {
    yield call(sentToSignLeaseService, setToSignAdapter(payload.data));

    payload.navigate(routes.lease);
    toast.success('Your Lease has been successfully sented to Sign.');
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(leaseSlice.actions.setLoading(false));
  }
}
function* updateLeaseSaga({ payload }: ReturnType<typeof actions.updateLeaseAction>) {
  yield put(leaseSlice.actions.setLoading(true));
  try {
    yield call(updateLeaseService, {
      data: leaseUpdateAdapter(payload.data, payload.dirtyFields),
      id: payload.id,
    });

    payload.navigate(routes.lease);
    toast.success('Your Lease has been successfully updated');
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(leaseSlice.actions.setLoading(false));
  }
}
function* getLeaseByUnitIdSaga({ payload }: ReturnType<typeof actions.getLeaseByUnitIdAction>) {
  yield put(leaseSlice.actions.setLoading(true));
  try {
    const { data } = yield call(getLeaseByUnitIdService, payload);
    yield put(leaseSlice.actions.getLeaseByUnitIdSuccess(data));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(leaseSlice.actions.setLoading(false));
  }
}
export function* watchLeaseSaga(): Generator<ForkEffect> {
  yield takeLatest(actions.getLeaseAction.type, getLeaseSaga);
  yield takeLatest(actions.createLeaseAction.type, createLeaseSaga);
  yield takeLatest(actions.getLeaseByIdAction.type, getLeaseByIdSaga);
  yield takeLatest(actions.sentToSignLeaseAction.type, sentToSignLeaseSaga);
  yield takeLatest(actions.updateLeaseAction.type, updateLeaseSaga);
  yield takeLatest(actions.getLeaseByUnitIdAction.type, getLeaseByUnitIdSaga);
}
