import dayjs from 'dayjs';
const createListingsData = (data: any) => {
  const {
    title,
    description,
    startFrom,
    availableFrom,
    rent,
    deposit,
    listingPropertyType,
    unitSize,
    parkingSpot,
    bedrooms,
    bathrooms,
    leaseDuration,
    rentDuration,
    unit,
    id,
  } = data;

  return {
    title,
    startFrom: dayjs(startFrom),
    availableFrom: dayjs(availableFrom),
    description,
    listingPropertyType: listingPropertyType,
    unitId: unit,
    propertyId: id,
    leaseDuration,
    rentDuration,
    deposit: +deposit,
    rent: +rent,
    ...(listingPropertyType !== 'ENTIRE_PLACE' && {
      leaseDetail: {
        parkingSpot: +parkingSpot,
        unitSize: +unitSize,
        bedrooms: +bedrooms,
        bathrooms: +bathrooms,
      },
    }),
  };
};

export default createListingsData;
