import { applicantsStatus } from 'constants/userStatus';

export enum ContractStatus {
  REJECT = 'REJECTED',
  CONFIRM = 'CONFIRMED',
}

export const applicantStatus = [
  { label: 'Confirmed' },
  { label: 'Incomplete', value: applicantsStatus.INCOMPLETE },
  { label: 'New', value: applicantsStatus.NEW },
  { label: 'Pending', value: applicantsStatus.PENDING },
  { label: 'Rejected', value: applicantsStatus.REJECTED },
];

export const statusValue: { [key: string]: applicantsStatus } = {
  Confirmed: applicantsStatus.CONFIRMED,
  Incomplete: applicantsStatus.INCOMPLETE,
  New: applicantsStatus.NEW,
  Pending: applicantsStatus.PENDING,
  Rejected: applicantsStatus.REJECTED,
};
