/* eslint-disable max-len */
import cn from 'classnames';
import { formatDate } from 'common/utils/utils';
import SvgIcon from 'components/shared/utils/SvgIcon';
import { B3 } from 'components/Typography/Body';
import { DateFormat } from 'constants/dateFormat';
import { regexp } from 'constants/regexp';
import { residentialDataSelector } from 'modules/Application/store/selectors';
import { type ResidentialDataModel } from 'modules/types/applicationTypes';
import { useSelector } from 'react-redux';

import styles from './ResidenceAddressDetails.module.scss';

const RessidenceAddressDetails = () => {
  const residentialData: ResidentialDataModel | null | undefined = useSelector(residentialDataSelector);
  return (
    <div className={styles.container}>
      <div className={styles.sectionMain}>
        <B3 bold className={styles.title}>
          <SvgIcon icon={'location'} className={styles.profile} />
          Address
        </B3>
        <div className={styles.address}>
          <B3 className={styles.date}>
            {residentialData &&
              !!residentialData?.residentialHistory.length &&
              formatDate(residentialData.residentialHistory[0].residedFrom, DateFormat.DDMMYYYY)}
          </B3>

          <B3 className={styles.title}>
            {residentialData && residentialData?.residentialHistory?.length && (
              <>
                <SvgIcon icon={'location'} className={styles.profile} />
                <B3
                  className={styles.location}
                >{`${residentialData?.residentialHistory[0]?.address.label} ${residentialData.residentialHistory[0]?.country?.label} ${residentialData.residentialHistory[0]?.city}`}</B3>
              </>
            )}
          </B3>
        </div>
      </div>
      {residentialData?.residentialHistory && residentialData?.residentialHistory.length > 1 && (
        <div className={styles.prevAddress}>
          <B3 bold className={styles.title}>
            <SvgIcon icon={'location'} className={styles.profile} />
            Previous Addressess
          </B3>
          {residentialData?.residentialHistory.map((addressGroup: any, index: number) => {
            return (
              <div>
                {!!index && (
                  <div className={styles.prevAddressRow}>
                    <B3 className={styles.date}>{formatDate(addressGroup.residedFrom, DateFormat.DDMMYYYY)}</B3>
                    <B3 className={styles.title}>
                      <SvgIcon icon={'location'} className={styles.profile} />
                      {`${addressGroup?.address.label} ${addressGroup?.country.label} ${addressGroup?.city}`}
                    </B3>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
      <div className={styles.refAddress}>
        <B3 bold className={styles.title}>
          <SvgIcon icon={'profile'} className={styles.profile} />
          Personal references
        </B3>
        <div className={styles.refAddressRow}>
          <B3 className={styles.nameValue}>{residentialData?.personRefFirstName}</B3>
          <B3>{residentialData?.personRefRelationship}</B3>
        </div>
        <div className={styles.refAddressRow}>
          <B3 className={styles.title}>
            <SvgIcon icon={'phone'} className={cn(styles.profile, styles.iconLight)} />
            phone: {regexp.formatPhoneNumber(residentialData?.personRefPhoneNumber)}
          </B3>
          <B3 className={styles.title}>
            <SvgIcon icon={'location'} className={styles.profile} />
            {residentialData?.personRefAddress}
          </B3>
        </div>
      </div>
    </div>
  );
};

export default RessidenceAddressDetails;
