import React, { useEffect } from 'react';

import Loading from 'components/shared/Loading';
import PropertyCard from 'components/shared/PropertyCard';
import { B1, B2, B3 } from 'components/Typography/Body';
import { H3 } from 'components/Typography/Headlines';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import styles from './UnitsList.module.scss';
import Pagination from '../../../components/shared/Pagination/Pagination';
import { getPropertiesAction } from '../../Properties/store/actions';
import propertiesSlice from '../../Properties/store/propertiesSlice';
import {
  countSelector,
  propertiesListSelector,
  propertyLoadingSelector,
  propertyQueryOptionsSelector,
  propertySelector,
} from '../../Properties/store/selectors';

const UnitsList = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const property = useSelector(propertySelector);
  const propertyList = useSelector(propertiesListSelector);
  const { page, size } = useSelector(propertyQueryOptionsSelector);
  const count = useSelector(countSelector);
  const propertyLoading = useSelector(propertyLoadingSelector);
  const isAllProperty = params.id === 'all';

  useEffect(() => {
    if (params?.id) {
      dispatch(getPropertiesAction({ page, size, ...(!isAllProperty && { unit: params.id }) }));
    }
  }, [params, page, size]);

  return (
    <div className={styles.unitsList}>
      <H3>Create Listing</H3>
      <B3 className={styles.desc}>Select the property or unit you want to list or create a new one</B3>
      {propertyLoading && <Loading isBig />}
      {property && !isAllProperty && <PropertyCard hideActions property={property} open />}
      {!!propertyList?.length &&
        isAllProperty &&
        propertyList.map(property => <PropertyCard key={property._id} hideActions property={property} open />)}
      <Pagination
        page={page}
        pageCount={count}
        gotoPage={page => dispatch(propertiesSlice.actions.setQueryOptions({ page, size }))}
        setPageSize={size => dispatch(propertiesSlice.actions.setQueryOptions({ page: 1, size }))}
        pageSize={size}
        className={styles.paggination}
      />
      {!count && !propertyLoading && (
        <div className={styles.notFound}>
          <B1 bold>No Property found</B1>
          <B2 className={styles.desc}>You have not created any Property yet</B2>
        </div>
      )}
    </div>
  );
};

export default UnitsList;
