import React from 'react';

import icons from '../../../assets/icons/icons.svg';

export interface ISvgIcon {
  icon: string;
  width?: number;
  height?: number;
  className?: string;
  stroke?: string;
  onClick?: () => void;
}

const SvgIcon = ({ icon, className, width, height, onClick, stroke }: ISvgIcon) => {
  return (
    <svg className={`${className ? className : ''} icon ${icon}`} width={width} height={height} onClick={onClick}>
      <use xlinkHref={`${icons}#${icon}`} {...(stroke ? { stroke } : {})} />
    </svg>
  );
};

export default SvgIcon;
