import Button from 'components/shared/Button';
import modalNames from 'constants/modalNames';
import { modalsSlice } from 'modals';
import { useDispatch, useSelector } from 'react-redux';

import styles from './AddTenant.module.scss';
import { userSelector } from '../../../modules/Auth/store/selectors';
import { addTenantAction } from '../store/actions';
import { type IAddTenant } from '../store/types';

const AddTenant = () => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);

  return (
    <div className={styles.addTenant}>
      <Button
        variant="contained"
        size="sm"
        type="button"
        disabled={user?.status !== 'ACTIVE'}
        label="Add Tenant"
        onClick={() =>
          dispatch(
            modalsSlice.actions.showModal({
              modalName: modalNames.ADD_USER_MODAL,
              data: { title: 'Add Tenant', addUser: (data: IAddTenant) => dispatch(addTenantAction(data)) },
            })
          )
        }
      />
    </div>
  );
};

export default AddTenant;
