import { type RootState } from '../../../store/setupStore';

export const userSelector = (state: RootState) => state.auth.user;
export const stripeObjectSelector = (state: RootState) => state.auth.user?.stripeObject;
export const userStatusSelector = (state: RootState) => state.auth.user?.status || null;
export const registeredUserSelector = (state: RootState) => state.auth.registeredUser;
export const activationUserSelector = (state: RootState) => state.auth.activationUser;
export const authLoadingSelector = (state: RootState) => state.auth.isLoading;
export const isVerifySelector = (state: RootState) => state.auth.isVerify;
export const tokenErrorSelector = (state: RootState) => state.auth.error;
