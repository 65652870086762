import dayjs from 'dayjs';

import { businessStructureOptions } from './constants';
import { countryes } from '../../constants/countryes';

export const getCompanyDetails = (details: any) => {
  const {
    companyName,
    companyAddress,
    sameAsPhysical,
    structure,
    EIN,
    DBAName,
    businessName,
    USALocated,
    businessStartDate,
    city,
    state,
    zipCode,
    companyAddress2,
    physicalBusinessAddress,
    country,
    weatherSsn,
    SSNORITIN,
    useDbn,
  } = details;

  const isSameAddress = sameAsPhysical === 'true';
  return {
    companyName,
    companyAddress: {
      description: companyAddress?.value,
      label: companyAddress?.value,
      place_id: companyAddress?.id,
    },
    physicalBusinessAddress: isSameAddress
      ? null
      : {
          description: physicalBusinessAddress?.value,
          label: physicalBusinessAddress?.value,
          place_id: physicalBusinessAddress?.id,
        },
    isSameAddress,
    structure: businessStructureOptions.find(el => el.code === structure),
    EIN,
    DBAName,
    businessName,
    USALocated,
    weatherSsn,
    SSNORITIN,
    useDbn: useDbn === 'true' ? 'Yes' : 'No',
    businessCity: city || '',
    businessStartDate: dayjs(businessStartDate),
    businessState: state || '',
    businessZip: zipCode || '',
    businessAddress2: companyAddress2,
    businessCountry: country ? countryes.find(el => el.code === country) : null,
  };
};

export const getPersonalDetails = (user: any) => {
  const {
    accountType,
    firstName,
    lastName,
    middleName,
    address,
    state,
    address2,
    SSN,
    dateOfBirth,
    city,
    country,
    zipCode,
    profilePic,
    organizationArticle,
    identity,
    suffix,
  } = user.profile;

  return {
    ...(suffix && { suffix: { label: suffix, value: suffix } }),
    firstName,
    imageCover: profilePic && [profilePic],
    organizationArticle: organizationArticle && [organizationArticle],
    identity: identity && [identity],
    lastName,
    accountType,
    middleName,
    phone: user.phone,
    email: user.email,
    address: { description: address?.value, label: address?.value, place_id: address?.id },
    state,
    SSN,
    city,
    zipCode,
    country: { code: country, label: country },
    dateOfBirth: dayjs(dateOfBirth),
    address2: address2,
  };
};
