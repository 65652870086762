import React, { useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import cn from 'classnames';
import Button from 'components/shared/Button';
import Loading from 'components/shared/Loading';
import { H4 } from 'components/Typography/Headlines';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { countTitle, filterLinksTitle } from './constants';
import styles from './Listings.module.scss';
import ListingsCard from './ListingsCard';
import { getListingsAction } from './store/actions';
import listingsSlice from './store/listingsSlice';
import { listingQueryOptionsSelector, listingsLoadingSelector, listingsSelector } from './store/selectors';
import { type listingStatus } from './store/types';
import PrivateAccess from '../../components/PrivateAccess';
import Pagination from '../../components/shared/Pagination/Pagination';
import SvgIcon from '../../components/shared/utils/SvgIcon';
import { B1, B2, B3 } from '../../components/Typography/Body';
import { routes } from '../../constants/routes';
import { userRole } from '../../constants/userStatus';
import { userSelector } from '../Auth/store/selectors';

const Listings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(listingsLoadingSelector);
  const user = useSelector(userSelector);
  const listings = useSelector(listingsSelector);
  const [activeTab, setActiveTab] = useState<listingStatus>('ALL');
  const { page, size } = useSelector(listingQueryOptionsSelector);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (user?._id) {
      dispatch(getListingsAction({ id: user._id, page, size, status: activeTab }));
    }
  }, [user, page, size]);

  useEffect(() => {
    return () => {
      dispatch(listingsSlice.actions.clearListings());
    };
  }, []);

  const count = useMemo(() => {
    return listings?.count[countTitle[activeTab]] || 0;
  }, [activeTab, listings?.count]);

  const filteredListings = useMemo(() => {
    if (listings?.items) {
      if (activeTab === 'ALL') {
        return listings?.items;
      }
      return listings?.items.filter(el => el.listingStatus === activeTab);
    } else {
      return [];
    }
  }, [activeTab, listings?.items]);

  return (
    <div className={styles.listings}>
      <div className={styles.actionsWrap}>
        <div className={styles.actions}>
          <H4 bold>Listings</H4>{' '}
          <Box className={styles.buttons}>
            <Button
              variant="outlined"
              size="full-sm"
              type="button"
              label="Public View"
              onClick={() => window.open(`${routes.publicListings}?id=${user?._id}`, '_blank')}
            />
            <PrivateAccess roles={[userRole.LANDLOARD, userRole.MANAGER]}>
              <Button
                onClick={() => navigate(`${routes.listingsCreate}/all`)}
                variant="contained"
                size="full-sm"
                type="button"
                label={'Create listing'}
              />
            </PrivateAccess>
          </Box>
        </div>
        <div className={cn(styles.links, { [styles.filterLinks]: isOpen === true })}>
          {Object.keys(filterLinksTitle).map((item: any) => (
            <B1
              onClick={() => {
                setIsOpen(false);
                if (filterLinksTitle[item] && listings?.count[countTitle[item]] > 0) {
                  dispatch(getListingsAction({ id: user?._id || '', page: 1, size, status: item }));
                  setActiveTab(item);
                }
              }}
              className={cn(styles.link, { [styles.activeTab]: activeTab === item })}
              key={item}
              bold
            >{`${filterLinksTitle[item]} (${listings?.count ? listings.count[countTitle[item]] : 0})`}</B1>
          ))}
        </div>
      </div>
      <div className={styles.filter}>
        <Box className={cn(styles.filterItems, { [styles.isActive]: isOpen === true })} onClick={() => setIsOpen(!isOpen)}>
          <B3 bold>Filter</B3>
          <SvgIcon icon={'filter'} className={cn(styles.filterIcon)} />
        </Box>
      </div>
      <div className={styles.items}>
        {!!filteredListings.length &&
          filteredListings.map(listing => <ListingsCard key={listing._id} listing={listing} activeTab={activeTab} />)}
      </div>
      {!count && !loading && user?._id && (
        <div className={styles.notFound}>
          <B1 bold>No Listings found</B1>
          <B2>You don't have any listings.</B2>
        </div>
      )}
      <Pagination
        page={page}
        pageCount={count}
        gotoPage={page => dispatch(listingsSlice.actions.setQueryOptions({ page, size }))}
        setPageSize={size => dispatch(listingsSlice.actions.setQueryOptions({ page: 1, size }))}
        pageSize={size}
        className={styles.paggination}
      />
      {loading && <Loading isBig />}
    </div>
  );
};

export default Listings;
