import React, { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import BackButton from 'components/shared/BackButton/BackButton';
import ImageUploader from 'components/shared/ImageUploader';
import { B3 } from 'components/Typography/Body';
import { H4, H5 } from 'components/Typography/Headlines';
import { routes } from 'constants/routes';
import { getLeaseByUnitIdAction } from 'modules/Lease/store/actions';
import { leaseByUnitId } from 'modules/Lease/store/selectors';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { createListingsDefaultValues } from './constants';
import styles from './CreateListings.module.scss';
import GroupInfo from './GroupInfo';
import LeaseDetail from './LeaseDetail';
import PropertySwitcher from './PropertySwitcher';
import { type IForm } from './types';
import { getFormValuesByListing } from './utils';
import { createListingSchema } from './validations/propertySchema';
import { findKeyByValue, getUrlParams, scrollToFirstErrorInput } from '../../../common/utils/utils';
import Button from '../../../components/shared/Button';
import Loading from '../../../components/shared/Loading';
import { createListingsAction, deleteImageAction, getListingByIdAction } from '../store/actions';
import { listingSelector, listingsLoadingSelector } from '../store/selectors';
const CreateListings = () => {
  const urlParams = getUrlParams();
  const params = useParams<{ unit: string | undefined; id: string | undefined }>();
  const dispatch = useDispatch();
  const form = useForm({
    defaultValues: { ...createListingsDefaultValues },
    resolver: yupResolver<any>(createListingSchema),
    mode: 'all',
  });
  const {
    getValues,
    reset,
    formState: { errors },
  } = form;
  const listing = useSelector(listingSelector);
  const loading = useSelector(listingsLoadingSelector);
  const lease = useSelector(leaseByUnitId);
  const navigate = useNavigate();
  const { setValue, control, handleSubmit } = form;
  const imageUrls = useWatch({ control, name: 'imageUrls' });
  const imageCover = useWatch({ control, name: 'imageCover' });
  const onSubmit = (data: IForm) => {
    dispatch(createListingsAction({ ...data, ...params, navigate, listingId: urlParams?.listingId }));
  };

  useEffect(() => {
    scrollToFirstErrorInput(errors);
  }, [errors]);

  useEffect(() => {
    if (urlParams?.listingId) {
      dispatch(getListingByIdAction(urlParams.listingId));
    }
  }, [urlParams?.listingId]);

  useEffect(() => {
    if (params.unit) {
      dispatch(getLeaseByUnitIdAction(params.unit));
    }
  }, [params.unit]);

  useEffect(() => {
    if (listing && urlParams?.listingId) {
      reset({ ...getValues(), ...getFormValuesByListing(listing) });
    }
  }, [listing, urlParams?.listingId]);

  const handleImageDelate = (value: any) => {
    if (listing?.gallery) {
      const image = findKeyByValue(listing.gallery, value);
      const keys = Object.getOwnPropertyNames(image);
      const key = keys[0];
      dispatch(deleteImageAction({ key, path: image ? image[key] : '', listingId: listing._id }));
    }
  };

  return (
    <div className={styles.createListings}>
      <BackButton to={routes.listingsCreateAll} />
      <Box className={styles.infoBox}>
        <H4>Let's Create Listing</H4>
        <B3>Add information you would like to share on the Listing</B3>
      </Box>
      <Box className={styles.images}>
        <Box className={styles.imageCoverBox}>
          <H5>Upload Gallery</H5>
          <B3 className={styles.desc}>Add your cover photo</B3>
          <ImageUploader
            isBig
            imageUrls={imageCover}
            maxSize={2000000}
            fileName="cover"
            setImageUrls={value => setValue('imageCover', value)}
            deleteImage={handleImageDelate}
          />
        </Box>
        <Box className={styles.imageUrlsBox}>
          <H5>More photos</H5>
          <B3 className={styles.desc}>Add up to 20 high quality photos of your property</B3>
          <ImageUploader
            maxSize={2000000}
            multiple
            limit={20}
            imageUrls={imageUrls}
            setImageUrls={value => setValue('imageUrls', value)}
            deleteImage={handleImageDelate}
          />
        </Box>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.infoBox}>
          <GroupInfo form={form} leaseEndDate={lease?.items[0]?.endDate} />
          <PropertySwitcher form={form} />
          <LeaseDetail form={form} />
        </div>
        <Button variant="contained" size="full-lg" type="submit" label="Save" loading={loading} disabled={loading} />
      </form>
      {loading && <Loading isBig />}
    </div>
  );
};

export default CreateListings;
