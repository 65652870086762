export enum AplicationSteps {
  CONGRATULATIONS = 'done',
  BEFOREBEGIN = 1,
  PERSONALINFORMATION,
  RESIDENCEADDRESS,
  OCCUPANTS,
  YOURINCOME,
  INCOMEVEREFICATIONS,
  ATTACHDOCUMENTS,
  PAYFEES,
  REVIEWANDCONFIRM,
}
