import request from 'services/request';

import { type IQuery } from '../store/types';

const END_POINTS = {
  getItems: 'api/',
  createProperty: 'api/property',
  updateProperty: 'api/property/update',
  uploadImages: 'api/document/upload-property',
  deleteOwner: 'api/property/delete-owner',
};

export function getItemsServices(payload: IQuery) {
  const { name, type, value } = payload;
  return request({
    url: `${END_POINTS.getItems}${name}?type=${type}&search=${value || ''}`,
  });
}
export function createPropertyServices(payload: any) {
  return request({
    method: 'POST',
    url: `${END_POINTS.createProperty}`,
    data: { ...payload },
  });
}
export function updatePropertyServices(payload: any) {
  return request({
    method: 'PUT',
    url: `${END_POINTS.updateProperty}/${payload.id}`,
    data: { ...payload.requestData },
  });
}

export function uploadImagesServices(payload: { images: File[]; propertyId: string }) {
  const formData = new FormData();
  payload.images.forEach((image: File | string) => {
    if (typeof image !== 'string') {
      formData.append(`files`, image, image.name);
    }
  });
  formData.append('propertyId', payload.propertyId);
  return request({
    method: 'POST',
    url: `${END_POINTS.uploadImages}`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function deleteOwnerServices(payload: any) {
  return request({
    method: 'POST',
    url: `${END_POINTS.deleteOwner}/${payload.propertyId}`,
    data: [payload.ownerId],
  });
}
