import React, { useState, useRef, useCallback, useEffect } from 'react';

import { Box, Avatar } from '@mui/material';
import imageCompression from 'browser-image-compression';
import cn from 'classnames';
import ImageDialog from 'components/shared/ImageDialog';
import toast from 'react-hot-toast';
import { Document, Page } from 'react-pdf';

import styles from './ImageUploader.module.scss';
import { B2, B4 } from '../../Typography/Body';
import Loading from '../Loading';
import SvgIcon from '../utils/SvgIcon';

interface ImageUploaderProps {
  maxSize?: number;
  multiple?: boolean;
  classNames?: string;
  imageUrls: any;
  accept?: string;
  isBig?: boolean;
  fileName?: string;
  uploaderText?: string;
  fileTypeText?: string;
  otherErrorMessage?: string;
  disabled?: boolean;
  controlName?: string;
  setImageUrls: (value: any) => void;
  deleteImage?: (value: any, controlName?: string) => void;
  hasErrorBlock?: boolean;
  message?: string | null;
  limit?: number | null;
  hasCompression?: boolean;
}

const ImageUploader = ({
  maxSize = 50000000,
  multiple,
  classNames,
  imageUrls,
  setImageUrls,
  isBig,
  fileName,
  deleteImage,
  controlName,
  accept = 'image/png, image/jpeg',
  uploaderText = 'Upload image here',
  fileTypeText = '(jpg, png)',
  disabled = false,
  hasErrorBlock = false,
  message = null,
  limit = null,
  hasCompression = true,
}: ImageUploaderProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(message);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [openFullScreen, setOpenFullScreen] = useState(false);
  const [screenImage, setScreenImage] = useState('');
  const isPdf = useCallback(
    (file: File | string) => {
      return (
        (typeof file === 'string' && file.toLowerCase().endsWith('.pdf')) ||
        (file instanceof File && !file.type.includes('image'))
      );
    },
    [imageUrls]
  );

  useEffect(() => {
    if (message) {
      setErrorMessage(message);
    }
  }, [message]);

  const handleOpenFullScreen = (el: string) => {
    setScreenImage(el);
    setOpenFullScreen(true);
  };

  const handleCloseFullScreen = () => {
    setOpenFullScreen(false);
  };

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      setErrorMessage(null);
      const selectedFiles: File[] = [];
      const newFiles = Array.from(files);

      if (limit && (imageUrls?.length ? imageUrls?.length + files.length > limit : files.length > limit) && !isBig) {
        toast.error(`You can select maximum ${limit} files`);
      } else {
        for (let file of newFiles) {
          if (file.size > maxSize) {
            if (hasCompression) {
              const compressedFile = await compressFile(file);
              file = compressedFile || file;
            } else {
              toast.error(`File size exceeds the limit (${maxSize / 1000000} MB)`);
            }
          }
          const renamedFile = new File([file], fileName ? fileName : file.name, { type: file.type });
          selectedFiles.push(renamedFile);
        }
      }
      if (multiple && imageUrls) {
        setImageUrls([...imageUrls, ...selectedFiles]);
      } else {
        setImageUrls([...selectedFiles]);
      }
      if (message) {
        setErrorMessage(null);
      }
    }
  };

  const handleDelete = (url: File | string) => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    if (imageUrls?.length) {
      setImageUrls(imageUrls?.filter((el: any) => el !== url));
    }
    if (deleteImage && typeof url === 'string') {
      controlName ? deleteImage(url, controlName) : deleteImage(url);
    }
  };

  const compressFile = useCallback(async (image: File): Promise<File | null> => {
    const compressionOptions = {
      maxSizeMB: 2,
    };
    if (image.type === 'image/png' || image.type === 'image/jpeg') {
      setIsLoading(true);
      const compressedFile = await imageCompression(image, compressionOptions);
      setIsLoading(false);
      return compressedFile;
    } else {
      return null;
    }
  }, []);

  return (
    <div className={cn(styles.imageUploader, classNames)}>
      <button
        className={cn(
          styles.uploadButton,
          { [styles.bigUploadButton]: isBig },
          { [styles.hasError]: errorMessage && hasErrorBlock }
        )}
      >
        {!(errorMessage && hasErrorBlock) ? (
          <>
            <div className={styles.iconWrap}>
              <SvgIcon icon={'upload'} className={styles.uploadIcon} />
            </div>
            <B4 bold className={styles.desc}>
              {uploaderText}
            </B4>
            <B4 bold className={styles.desc}>
              {fileTypeText}
            </B4>
          </>
        ) : (
          <>
            <SvgIcon icon={'exclamation'} className={styles.errorIcon} />
            <B4 className={styles.btnErrorText}>{errorMessage}</B4>
          </>
        )}
        <input
          type="file"
          accept={accept}
          ref={fileInputRef}
          className={cn(styles.fileInput, {
            [styles.disabled]: disabled || (imageUrls?.length && limit) ? imageUrls.length >= Number(limit) : false,
          })}
          multiple={multiple}
          onChange={handleImageChange}
          disabled={disabled || (imageUrls?.length && limit) ? imageUrls.length >= Number(limit) : false}
        />
      </button>
      {!hasErrorBlock && errorMessage && (
        <B2 bold className={styles.error}>
          {errorMessage}
        </B2>
      )}
      {isLoading && <Loading isBig />}
      {!!imageUrls?.length && (
        <div className={cn([styles.documents], { [styles.bigImages]: isBig })}>
          {imageUrls?.map((imageUrl: File | string, index: number) => (
            <div
              key={typeof imageUrl !== 'string' ? imageUrl?.name : imageUrl}
              className={cn(
                {
                  [styles.image]:
                    typeof imageUrl !== 'string'
                      ? imageUrl.type.includes('image/png') || imageUrl.type.includes('image/jpeg')
                      : imageUrl.includes('.png') || imageUrl.includes('.jpeg'),
                },
                {
                  [styles.document]:
                    typeof imageUrl !== 'string'
                      ? imageUrl.type.includes('pdf') || imageUrl.type.includes('image/jpeg')
                      : imageUrl.includes('.pdf'),
                }
              )}
            >
              {isPdf(imageUrl) ? (
                // <SvgIcon icon={'document'} />
                <>
                  <Box
                    className={styles.pdfDocumentContainer}
                    onClick={() =>
                      handleOpenFullScreen(
                        typeof imageUrl === 'string'
                          ? `${process.env.REACT_APP_BASE_URL}/api/document?path=${imageUrl}`
                          : URL.createObjectURL(new Blob([imageUrl]))
                      )
                    }
                  >
                    <div className={styles.file}>
                      <Document
                        file={
                          typeof imageUrl === 'string'
                            ? `${process.env.REACT_APP_BASE_URL}/api/document?path=${imageUrl}`
                            : URL.createObjectURL(new Blob([imageUrl]))
                        }
                      >
                        <Page pageNumber={1} width={150} />
                      </Document>
                    </div>
                    <div className={styles.fileBlock}></div>
                    <div className={styles.fileName}>
                      <B4 className={styles.docInfo}>{typeof imageUrl === 'string' ? imageUrl : imageUrl.name}</B4>
                    </div>
                  </Box>
                </>
              ) : (
                <Avatar
                  className={styles.img}
                  src={
                    typeof imageUrl === 'string'
                      ? `${process.env.REACT_APP_BASE_URL}/api/document?path=${imageUrl}`
                      : URL.createObjectURL(new Blob([imageUrl]))
                  }
                  onClick={() =>
                    handleOpenFullScreen(
                      typeof imageUrl === 'string'
                        ? `${process.env.REACT_APP_BASE_URL}/api/document?path=${imageUrl}`
                        : URL.createObjectURL(new Blob([imageUrl]))
                    )
                  }
                  alt={`Uploaded ${index + 1}`}
                />
              )}
              <SvgIcon icon={'closeBlack'} className={styles.close} onClick={() => handleDelete(imageUrl)} />
            </div>
          ))}
        </div>
      )}
      {openFullScreen &&
        screenImage &&
        (isPdf(screenImage) ? (
          <ImageDialog imageUrl={screenImage} onClose={handleCloseFullScreen} pdf={true} />
        ) : (
          <ImageDialog imageUrl={screenImage} onClose={handleCloseFullScreen} />
        ))}
    </div>
  );
};

export default ImageUploader;
