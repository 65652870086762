import request from 'services/request';

import { type getTenantsListReqModel, type IAddTenant } from '../types';

const END_POINTS = {
  getTenants: 'api/account?role=TENANT',
  addTenant: 'api/account/add-tenant',
};

export function getTenantList(payload: getTenantsListReqModel) {
  return request({
    url: `${END_POINTS.getTenants}&limit=${payload.limit}&page=${payload.page}`,
  });
}

export function addTenantService(payload: IAddTenant) {
  return request({
    method: 'POST',
    url: `${END_POINTS.addTenant}`,
    data: payload,
  });
}
