import React, { useCallback, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { Controller } from 'react-hook-form';

import styles from './TenantPersonalInfo.module.scss';
import { desideMaxDateByYear, getRandomId, unregisterFormById } from '../../common/utils/utils';
import { regexp } from '../../constants/regexp';
import { autocompleteOptions } from '../../modules/Application/Steps/constants';
import { type PhoneDataModel } from '../../modules/types/applicationTypes';
import Button from '../shared/Button';
import { Input } from '../shared/FormElements';
import Autocomplete from '../shared/FormElements/Autocomplete/Autocomplete';
import DatePicker from '../shared/FormElements/DatePicker/DatePicker';
import PhoneInput from '../shared/FormElements/PhoneInput';
import SvgIcon from '../shared/utils/SvgIcon';
import { H5 } from '../Typography/Headlines';

interface TenantPersonalInfo {
  form: any;
  personalInformationPhones: any;
}

const TenantPersonalInfo = ({ form, personalInformationPhones }: TenantPersonalInfo) => {
  const {
    control,
    setValue,
    unregister,
    formState: { errors },
  } = form;
  const [phoneGroupId, setPhoneGroupId] = useState<{ id: string }[]>([]);
  const deletePhonesGroup = useCallback(
    (id: string) => {
      unregisterFormById([`phones.phoneNumber${id}`, `phones.phoneType${id}`], unregister);
      const newAPhonesGroup = phoneGroupId.filter(phonesGroup => phonesGroup.id !== id);
      setPhoneGroupId(newAPhonesGroup);
    },
    [phoneGroupId, unregister]
  );

  const addAddressGroup = useCallback(() => {
    const newPhone = [...phoneGroupId, { id: getRandomId() }];
    setPhoneGroupId(newPhone);
  }, [phoneGroupId]);

  useEffect(() => {
    if (personalInformationPhones) {
      const [mainPhoneGroup, ...aditionalPhoneGroup] = personalInformationPhones || [];
      setValue('phones.phoneNumber#', mainPhoneGroup?.number, { shouldValidate: true });
      setValue('phones.phoneType#', autocompleteOptions?.phoneType?.find(el => el.value === mainPhoneGroup?.type) || undefined, {
        shouldValidate: true,
      });
      const newPhones: { id: string }[] = [];
      if (aditionalPhoneGroup) {
        aditionalPhoneGroup.forEach((phone: PhoneDataModel) => {
          const id = getRandomId();
          setValue(`phones.phoneNumber${id}`, phone.number);
          setValue(
            `phones.phoneType${id}`,
            autocompleteOptions.phoneType.find(el => el.value === phone.type)
          );

          newPhones.push({ id });
        });
      }
      setPhoneGroupId(newPhones);
    }
  }, [personalInformationPhones]);

  return (
    <div className={styles.tenantPersonalInfo}>
      <Box className={styles.inputBox}>
        <Box className={styles.grid}>
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <Input
                label="First Name*"
                errorMessage={errors.firstName?.message}
                hasError={!!errors.firstName}
                placeholder="Enter your Legal First Name"
                {...field}
              />
            )}
          />
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => (
              <Input
                label="Last Name*"
                placeholder="Enter your Last Name"
                errorMessage={errors.lastName?.message}
                hasError={!!errors.lastName}
                {...field}
              />
            )}
          />
        </Box>
        <Controller
          name="middleName"
          control={control}
          render={({ field }) => (
            <Input
              label="Middle Name"
              placeholder="Enter your Middle Name"
              errorMessage={errors.middleName?.message}
              hasError={!!errors.middleName}
              {...field}
            />
          )}
        />
        <Controller
          name={'suffix'}
          control={control}
          render={({ field }) => (
            <Autocomplete label={'Suffix'} placeholder={'Enter your Suffix'} options={autocompleteOptions.suffix} {...field} />
          )}
        />
        <Controller name="email" control={control} render={({ field }) => <Input disabled={true} {...field} />} />
        <Box className={styles.grid}>
          <div>
            <Controller
              name="phones.phoneNumber#"
              control={control}
              render={({ field }) => (
                <PhoneInput
                  label="Phone number*"
                  placeholder="Enter Phone number"
                  errorMessage={errors?.phones ? errors.phones['phoneNumber#']?.message : ''}
                  hasError={errors?.phones ? !!errors?.phones['phoneNumber#'] : ''}
                  {...field}
                />
              )}
            />
          </div>
          <Controller
            name={'phones.phoneType#'}
            control={control}
            render={({ field }) => (
              <Autocomplete
                errorMessage={errors?.phones ? errors.phones['phoneType#']?.message : ''}
                hasError={errors?.phones ? !!errors?.phones['phoneType#'] : ''}
                label={'Phone Type*'}
                placeholder={'Enter your phoneType'}
                options={autocompleteOptions.phoneType}
                {...field}
              />
            )}
          />
        </Box>
        {!!phoneGroupId.length && (
          <div className={styles.phones}>
            {phoneGroupId.map(({ id }, index) => (
              <div className={styles.phonesWrap} key={id}>
                <div className={styles.closeBox}>
                  {!index && <H5 className={styles.titlePhone}>Additional Phone number</H5>}
                  <SvgIcon icon={'close'} className={styles.closeIcon} onClick={() => deletePhonesGroup(id)} />
                </div>
                <Box className={index ? `${styles.grid} ${styles.addPhoneGroup}` : styles.grid}>
                  <Controller
                    name={`phones.phoneNumber${id}`}
                    control={control}
                    render={({ field }) => (
                      <PhoneInput
                        errorMessage={errors?.phones ? errors.phones[`phoneNumber${id}`]?.message : ''}
                        hasError={errors?.phones ? !!errors?.phones[`phoneNumber${id}`] : ''}
                        label={'Phone number*'}
                        className="full-size"
                        type="tel"
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name={`phones.phoneType${id}`}
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        errorMessage={errors?.phones ? errors.phones[`phoneType${id}`]?.message : ''}
                        hasError={errors?.phones ? !!errors?.phones[`phoneType${id}`] : ''}
                        label={'Phone Type'}
                        placeholder={'Enter your phoneType'}
                        options={autocompleteOptions.phoneType}
                        {...field}
                      />
                    )}
                  />
                </Box>
              </div>
            ))}
          </div>
        )}
        <Button
          className={styles.addPhone}
          disabled={phoneGroupId.length === 2}
          type={'button'}
          variant="outlined"
          size="full-lg"
          label="+ Add New phone number"
          onClick={addAddressGroup}
        />
      </Box>
      <Box className={styles.inputBox}>
        <Box className={styles.grid}>
          <Controller
            name="dateOfBirth"
            control={control}
            render={({ field }) => (
              <DatePicker
                label="Date Of Birth*"
                {...field}
                errorMessage={errors?.dateOfBirth?.message}
                hasError={!!errors.dateOfBirth}
                maxDate={dayjs(desideMaxDateByYear())}
              />
            )}
          />
          <Controller
            name="SSN"
            control={control}
            render={({ field }) => (
              <Input
                label="Social Security Number*"
                placeholder="Enter your SSN"
                regexp={regexp.number}
                errorMessage={errors.SSN?.message}
                hasError={!!errors.SSN}
                {...field}
              />
            )}
          />
        </Box>
      </Box>
    </div>
  );
};

export default TenantPersonalInfo;
