import { type FC, type PropsWithChildren, useEffect } from 'react';

import { Avatar, Box, Hidden } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import cn from 'classnames';
import Reminder from 'components/Reminder';
import toast from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

import styles from './Routes.module.scss';
import { headerMenu } from '../../constants/headerMenu';
import { routes } from '../../constants/routes';
import { sideBarLinks } from '../../constants/sideBarLinks';
import { activeUsers } from '../../constants/userStatus';
import { onMessageListener } from '../../firebase';
import { userSelector } from '../../modules/Auth/store/selectors';
import { getNotificationsAction } from '../../modules/Notifications/store/actions';
import notificationsSlice from '../../modules/Notifications/store/notificationsSlice';
import { notificationsCountSelector, notificationsQueryOptionsSelector } from '../../modules/Notifications/store/selectors';
import SideBar from '../../SideBar';
import PrivateAccess from '../PrivateAccess';
import Popover from '../shared/Popover';
import riLogo from '../SideBar/riLogo.png';
import { B2, B3 } from '../Typography/Body';
const ProtectedRoute: FC<PropsWithChildren> = () => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const count = useSelector(notificationsCountSelector);
  const { page, size } = useSelector(notificationsQueryOptionsSelector);
  const token = localStorage.getItem('token') || '';
  const navigate = useNavigate();
  const desctop = useMediaQuery('(max-width: 1600px)');

  if ((user && !activeUsers.includes(user?.status)) || !token) {
    return <Navigate to="/auth/login" replace />;
  }

  const location = useLocation();

  useEffect(() => {
    if (user) {
      dispatch(getNotificationsAction({ userId: user._id, page, size }));
    }
  }, [user, page, size]);

  useEffect(() => {
    onMessageListener()
      .then(payload => {
        if (user) {
          dispatch(getNotificationsAction({ userId: user._id, page, size }));
          dispatch(notificationsSlice.actions.setLoading(false));
        }
        if (payload?.notification) {
          toast(
            <div>
              <p>
                <b>{payload.notification?.title}</b>
              </p>
              {payload.notification.body && payload.notification.body.length > 50 ? (
                <p>{payload.notification.body.slice(0, 50) + '...'}</p>
              ) : (
                <p>{payload.notification.body}</p>
              )}
            </div>
          );
        }
      })
      .catch(err => console.log('onMessageListener-Notification', err));
  }, [count]);

  return (
    <div className={styles.protectedRoute}>
      <Hidden mdDown>
        <SideBar isDefaultCollapsed={desctop}>
          <SideBar.Header>
            {({ isCollapsed }: any) => {
              return (
                <div>
                  <Box className={styles.logoWrap} onClick={() => navigate(routes.home)}>
                    <img src={riLogo} alt="Logo" className={cn(styles.logo, { [styles.collapsedLogo]: isCollapsed })} />
                  </Box>
                  <SideBar.NotificationSection
                    linkTo={routes.notifications}
                    notificationCount={count?.unReadNotifications}
                    isActive={location.pathname.includes(routes.notifications)}
                  />
                </div>
              );
            }}
          </SideBar.Header>
          <SideBar.Content>
            {sideBarLinks.map(({ to, icon, title, roles }) => (
              <PrivateAccess key={to} roles={roles}>
                <SideBar.MenuItem linkTo={to} title={title} icon={icon} isActive={location.pathname.includes(to)}>
                  {title}
                </SideBar.MenuItem>
              </PrivateAccess>
            ))}
          </SideBar.Content>
          <SideBar.Footer>
            {({ isCollapsed }: any) => {
              return (
                <div className={styles.header}>
                  <Popover>
                    <Popover.Target>
                      {user && (
                        <button className={cn(styles.button, styles.fullNameButton, { [styles.collapsedButton]: isCollapsed })}>
                          <Avatar
                            className={styles.firstCharacter}
                            alt={user?.fullName ? user?.fullName[0] : ''}
                            src={`${process.env.REACT_APP_BASE_URL}/api/document?path=${user?.profile?.profilePic}`}
                          />
                          <B3 bold>
                            {user?.fullName || user?.profile?.firstName
                              ? `${user?.profile?.firstName || user?.fullName} ${user?.profile?.lastName || ''}`
                              : ''}
                          </B3>
                        </button>
                      )}
                    </Popover.Target>
                    <Popover.Content>
                      <div className={styles.wrap}>
                        {headerMenu.map(({ to, title, icon }) => (
                          <B2 bold className={styles.content} key={to}>
                            <SideBar.MenuItem linkTo={to} title={title} icon={icon}>
                              {title}
                            </SideBar.MenuItem>
                          </B2>
                        ))}
                      </div>
                    </Popover.Content>
                  </Popover>
                </div>
              );
            }}
          </SideBar.Footer>
        </SideBar>
      </Hidden>
      <div className={styles.container}>
        {user?.status !== 'ACTIVE' && (
          <Reminder
            text="Complete Your Profile for Full Access"
            note="To access all features and functionalities, please complete your personal information. 
            Incomplete profiles may limit your ability to utilize certain services. Thank you for your cooperation."
            buttonText="Complete Your Profile"
            redirectUrl="profile"
          />
        )}
        <Outlet />
      </div>
    </div>
  );
};

export default ProtectedRoute;
