import { Box, TextField } from '@mui/material';
import MiPhoneInput from 'react-phone-input-material-ui';

import '../Input/Input.scss';
const PhoneInput = ({ ...rest }: any) => {
  return (
    <Box>
      <MiPhoneInput
        {...rest}
        inputClass={'input-wrapper'}
        inputProps={{
          error: rest.hasError,
          helperText: rest.errorMessage,
          autoComplete: 'off',
        }}
        countryCodeEditable={false}
        component={TextField}
        country={'us'} // Set default country to United States
        onlyCountries={['us']} // Allow only United States country code
      />
    </Box>
  );
};

export default PhoneInput;
