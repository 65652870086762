export const userInfoDefaultValues = {
  firstName: '',
  middleName: '',
  lastName: '',
  email: '',
  phone: '',
  accountType: 'PERSONAL',
};
export const personalInfoDefaultValues = {
  state: '',
  SSN: '',
  date: null,
  zipCode: '',
  city: '',
  country: null,
  address: null,
  address1: '',
};

export const companyInfoDefaultValues = {
  companyName: '',
  companyAddress: undefined,
  structure: null,
  EIN: '',
  DBAName: '',
  businessName: '',
  useDbn: 'Yes',
  weatherSsn: 'EIN',
  SSNORITIN: '',
};

export const businessInfoDefaultValues = {
  businessPhone: '',
  businessStartDate: null,
  USALocated: true,
  isSameAddress: false,
  businessAddress: null,
  businessAddress2: '',
  businessCountry: null,
  businessCity: '',
  businessState: '',
  businessZip: '',
};

export const businessStructureOptions = [
  { label: 'Corporation', value: 'DBA', code: 'CORPORATION' },
  { label: 'LLC', value: 'DBA', code: 'LLC' },
  { label: 'Partnership', value: 'DBA', code: 'PARTNERSHIP' },
  { label: 'Sole proprietorship', value: 'Sole proprietorship', code: 'SOLE_PROPRIETORSHIP' },
  { label: 'Single- member LLC', value: 'Single- member LLC', code: 'SINGLE_MEMBER_LLC' },
  { label: 'Nonprofit organization', value: 'DBA', code: 'NONPROFIT_ORGANIZATION' },
];

export const accountTypeValues = {
  PERSONAL: 'PERSONAL',
  COMPANY: 'COMPANY',
};
export const accountTypeContent = {
  first: {
    name: 'PERSONAL',
    title: 'Personal Account',
    description: 'Select this option to manage your personal account',
    icon: 'tenants',
  },
  second: {
    name: 'COMPANY',
    title: 'Company Account',
    description: 'Select this option to manage your company account',
    icon: 'properties',
  },
};

export const profileAddressName = {
  address: { name: 'address', label: 'Address' },
  country: { name: 'country', label: 'Country*' },
  address1: { name: 'address2', label: 'Address Name 2' },
  state: { name: 'state', label: 'State/Provance/Region*' },
  zipCode: { name: 'zipCode', label: 'Zip/Postal Cod*' },
  city: { name: 'city', label: ' City*' },
};

export const businessAddressName = {
  address: { name: 'physicalBusinessAddress', label: 'Physical business address*' },
  country: { name: 'businessCountry', label: 'Country*' },
  address1: { name: 'businessAddress2', label: 'Address Name 2' },
  state: { name: 'businessState', label: 'State/Province/Region*' },
  zipCode: { name: 'businessZip', label: 'Zip/Postal Code*' },
  city: { name: 'businessCity', label: ' City*' },
};

export const structureTypes = ['LLC', 'Corporation', 'Partnership', 'Nonprofit organization'];
