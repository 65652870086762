import {
  createContext,
  createRef,
  type Dispatch,
  forwardRef,
  type ForwardRefRenderFunction,
  type HTMLAttributes,
  type ReactNode,
  type RefObject,
  type SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';
import cx from 'classnames';

import styles from './SideBar.module.scss';

export type Props = HTMLAttributes<HTMLElement> & {
  isDefaultCollapsed?: boolean;
  collapseOnInactive?: boolean;
  isSavedSession?: boolean;
  className?: string;
  children?: ReactNode;
};

export interface SidebarStateType {
  isCollapsed: boolean;
  isActive: boolean;
  isHovered: boolean;
  isImmediatelyClose?: boolean;
}

export interface ISidebarContext {
  collapseOnInactive: boolean;
  isCollapsed: boolean;
  isHovered: boolean;
  isActive: boolean;
  setSidebarState: Dispatch<SetStateAction<SidebarStateType>>;
}

export const SidebarContext = createContext<ISidebarContext>({
  collapseOnInactive: false,
  isCollapsed: false,
  isHovered: false,
  isActive: false,
  setSidebarState: () => ({ isCollapsed: false, isActive: false }),
});

const SideBar: ForwardRefRenderFunction<unknown, Props> = (
  { children, className, isDefaultCollapsed, isSavedSession = true, collapseOnInactive = false },
  ref
) => {
  const sidebarRef: RefObject<HTMLElement> = (ref as RefObject<HTMLElement>) || createRef<HTMLElement>();
  const isTablet = useMediaQuery(`(max-width: 900)`);

  const localSidebarState = JSON.parse(localStorage.getItem('sidebarState') as string);

  const [sidebarState, setSidebarState] = useState<SidebarStateType>(
    localSidebarState || {
      isCollapsed: Boolean(isDefaultCollapsed),
      isActive: Boolean(!isDefaultCollapsed),
      isHovered: false,
    }
  );

  const { isCollapsed, isActive, isHovered } = sidebarState;

  const handleSidebarHover = useCallback(() => {
    if (sidebarState.isCollapsed && !sidebarState.isImmediatelyClose) {
      setSidebarState(state => ({ ...state, isCollapsed: false, isHovered: true }));
    } else if (!sidebarState.isHovered) {
      setSidebarState(state => ({ ...state, isHovered: true }));
    }
  }, [sidebarState]);

  const handleSidebarHoverLeave = useCallback(() => {
    if (isTablet) {
      setSidebarState(state => ({ ...state, isCollapsed: true, isHovered: false }));
    } else if (!sidebarState.isCollapsed && !sidebarState.isActive) {
      setSidebarState(state => ({ ...state, isCollapsed: true, isHovered: false }));
    } else if (sidebarState.isHovered) {
      setSidebarState(state => ({ ...state, isHovered: false }));
    }
  }, [sidebarState, isTablet]);

  useEffect(() => {
    if (isSavedSession) {
      localStorage.setItem('sidebarState', JSON.stringify(sidebarState));
    }
  }, [sidebarState, isSavedSession]);
  useEffect(() => {
    if (sidebarState.isImmediatelyClose) {
      setSidebarState(state => ({ ...state, isImmediatelyClose: false }));
    }
  }, [sidebarState.isImmediatelyClose]);

  useEffect(() => {
    if (isTablet) {
      setSidebarState(state => ({ ...state, isActive: false }));
    }
  }, [isTablet]);

  return (
    <SidebarContext.Provider value={{ ...sidebarState, collapseOnInactive, setSidebarState }}>
      {((isHovered && !isActive) || isCollapsed || isTablet) && <div className={styles.backgroundBox} />}
      <aside
        className={cx(styles.asideBar, className, {
          [styles.collapsed as string]: isCollapsed,
          [styles.hovered as string]: isHovered && !isActive,
          [styles.active as string]: isActive,
        })}
        onMouseEnter={isTablet ? undefined : handleSidebarHover}
        onMouseLeave={handleSidebarHoverLeave}
        ref={sidebarRef}
      >
        <div className={styles.layout}>{children}</div>
      </aside>
    </SidebarContext.Provider>
  );
};

export default Object.assign(forwardRef<unknown, Props>(SideBar), { displayName: 'SideBar' });
