import React, { memo, useEffect, useState } from 'react';

import cx from 'classnames';
import { useTable, useSortBy, useRowSelect, usePagination, useFlexLayout } from 'react-table';

import styles from './Table.module.scss';
import { B2 } from '../../Typography/Body';
import Pagination from '../Pagination';

export interface Props {
  className?: string;
  defaultPage?: number;
  defaultPageSize?: number;
  size?: 'sm' | 'md' | 'lg';
  columns: any[];
  data: any[];
  defaultSortBy?: { id: string; desc: boolean }[];
  onSort?: (props: { pageSize: number; sortBy: any }) => void;
  fetchData?: (props: { pageSize: number; pageIndex: number }) => void;
  totalItems?: number;
  onRowClick?: (id: number, e: any, row: unknown) => void;
  hidePagination?: boolean;
  hiddenColumns?: string[];
}

const Table: React.FC<Props> = ({
  className,
  data,
  columns,
  size,
  onSort,
  defaultSortBy,
  defaultPageSize,
  fetchData,
  defaultPage,
  onRowClick = () => ({}),
  totalItems = 0,
  hidePagination = false,
  hiddenColumns = [],
}) => {
  const [pageCount, setPageCount] = useState(0);
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30,
      width: 150,
      maxWidth: 300,
    }),
    []
  );

  const tableProps = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: defaultSortBy ?? [],
        pageIndex: defaultPage ?? 0,
        pageSize: defaultPageSize ?? 10,
        hiddenColumns,
      },
      manualSortBy: true,
      disableSortRemove: true,
      manualPagination: true,
      pageCount,
      defaultColumn,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    useFlexLayout
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    state: { pageIndex, pageSize, sortBy },
  } = tableProps;

  useEffect(() => {
    if (defaultPage === 0) {
      gotoPage(defaultPage);
    }
  }, [defaultPage]);

  useEffect(() => {
    setPageCount(Math.ceil(totalItems / pageSize));
  }, [totalItems, pageSize]);

  useEffect(() => {
    fetchData?.({ pageIndex: pageIndex, pageSize });
  }, [fetchData, pageIndex, pageSize]);

  useEffect(() => {
    onSort?.({ sortBy, pageSize });
  }, [onSort, sortBy, pageSize]);

  const handleRowClick = (event: React.MouseEvent, id: number, row: unknown) => {
    const selection = window?.getSelection()?.toString() ?? '';
    if (selection.length > 0) {
      event.preventDefault();
    } else {
      onRowClick(id, event, row);
    }
  };

  return (
    <div>
      <table {...getTableProps()} className={cx(styles.table, styles[size as string], className)}>
        <>
          <thead>
            {headerGroups.map((headerGroup: any) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps({ title: 'Sort Column' }))}>
                    <B2 bold>{column.render('Header')}</B2>
                    <span>{column.isSorted ? (column.isSortedDesc ? '<' : '>') : ''}</span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row: any) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} onClick={event => handleRowClick(event, row.original.id, row)}>
                  {row.cells.map((cell: any) => {
                    return (
                      <td className={cell.column.className} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </>
      </table>

      {!hidePagination && (
        <div className={styles.paginationWrap}>
          <div>
            <span className={styles.records}>'Total number':</span>
            <span className={styles.totalItems}>{totalItems}</span>
          </div>
          {pageSize < totalItems && (
            <Pagination gotoPage={page => gotoPage(page - 1)} page={pageIndex + 1} pageSize={pageSize} pageCount={40} />
          )}
        </div>
      )}
    </div>
  );
};

export default memo(Table);
