import React, { useEffect } from 'react';

import { B3 } from 'components/Typography/Body';
import { H5 } from 'components/Typography/Headlines';
import { Controller, type UseFormReturn } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import styles from './PrimaryBankAccount.module.scss';
import Autocomplete from '../../../../components/shared/FormElements/Autocomplete';
import { getBankAccountAction } from '../../../Profile/store/actions';
import { profileBankAccountsSelector } from '../../../Profile/store/selectors';
import { propertySelector } from '../../store/selectors';
import { type IForm } from '../types';

interface PrimaryBankAccountProps {
  form: UseFormReturn<IForm>;
}
const PrimaryBankAccount = ({ form }: PrimaryBankAccountProps) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = form;

  const dispatch = useDispatch();
  const bankAccounts = useSelector(profileBankAccountsSelector);
  const property = useSelector(propertySelector);

  useEffect(() => {
    if (property?.payoutBankAccount && bankAccounts?.length) {
      const value = bankAccounts.find((el: any) => el.bank_account_id === property?.payoutBankAccount) || undefined;
      setValue('payoutBankAccount', {
        label: `${value?.account_name} ( ${value?.account_number} )`,
        _id: value?._id || '',
        bank_account_id: value?.bank_account_id || '',
      });
    }
  }, [property?.payoutBankAccount, bankAccounts]);

  useEffect(() => {
    dispatch(getBankAccountAction());
  }, []);

  return (
    <div className={styles.primaryBankAccount}>
      <H5 className={styles.title}>What is this property's primary bank account?</H5>
      <B3 className={styles.desc}>This Information will be used to help prepare owner draw and 1099s</B3>
      <Controller
        name="payoutBankAccount"
        control={control}
        render={({ field }) => (
          <Autocomplete
            errorMessage={errors.payoutBankAccount?.message}
            hasError={!!errors.payoutBankAccount}
            label={'Operating Account*'}
            options={
              bankAccounts ? bankAccounts.map((el: any) => ({ ...el, label: `${el.account_name} ( ${el.account_number} )` })) : []
            }
            {...field}
          />
        )}
      />
    </div>
  );
};

export default PrimaryBankAccount;
