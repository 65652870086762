import toast from 'react-hot-toast';
import { call, type ForkEffect, put, takeLatest, takeEvery } from 'redux-saga/effects';

import * as actions from './actions';
import profileSlice from './createPropertiesSlice';
import createPropertyData, { updatePropertyData } from './dataAdapter';
import { routes } from '../../../../constants/routes';
import {
  createPropertyServices,
  getItemsServices,
  updatePropertyServices,
  uploadImagesServices,
  deleteOwnerServices,
} from '../createPropertyServices';

function* getItemsSaga({ payload }: ReturnType<typeof actions.getItems>) {
  yield put(profileSlice.actions.setLoading(true));
  try {
    const { data } = yield call(getItemsServices, payload);
    yield put(profileSlice.actions.setItems({ data: data.items, query: payload }));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(profileSlice.actions.setLoading(false));
  }
}
function* createPropertySaga({ payload }: ReturnType<typeof actions.createProperty>) {
  yield put(profileSlice.actions.setLoading(true));
  try {
    const images = [
      ...(payload.data?.imageCover ? payload.data.imageCover : []),
      ...(payload?.data?.imageUrls ? payload.data.imageUrls : []),
    ];

    const requestData = createPropertyData(payload.data);

    const { data } = yield call(createPropertyServices, requestData);
    if (images.length) {
      yield call(uploadImagesServices, {
        images,
        propertyId: data._id,
      });
    }
    toast.success('Congratulations! Your property has been successfully created.');
    payload.navigate(routes.properties);
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(profileSlice.actions.setLoading(false));
  }
}

function* updatePropertySaga({ payload }: ReturnType<typeof actions.updateProperty>) {
  yield put(profileSlice.actions.setLoading(true));
  const { imageCover, imageUrls, ...res } = payload.data;
  try {
    const images = [...(imageCover ? imageCover : []), ...(imageUrls ? imageUrls : [])];

    const requestData = updatePropertyData(res);

    const { data } = yield call(updatePropertyServices, { requestData, id: payload.id });
    if (!images.every((element: any) => typeof element === 'string')) {
      yield call(uploadImagesServices, {
        images,
        propertyId: data._id,
      });
    }
    if (payload.navigate) {
      toast.success('Congratulations! Your property has been successfully updated.');
      payload.navigate(routes.properties);
    }
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(profileSlice.actions.setLoading(false));
  }
}

function* deleteOwnerSaga({ payload }: ReturnType<typeof actions.deleteOwner>) {
  yield put(profileSlice.actions.setLoading(true));
  try {
    yield call(deleteOwnerServices, payload);
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(profileSlice.actions.setLoading(false));
  }
}

export function* watchPCreatePropertySaga(): Generator<ForkEffect> {
  yield takeEvery(actions.getItems.type, getItemsSaga);
  yield takeLatest(actions.createProperty.type, createPropertySaga);
  yield takeLatest(actions.updateProperty.type, updatePropertySaga);
  yield takeLatest(actions.deleteOwner.type, deleteOwnerSaga);
}
