import React from 'react';

import { Box } from '@mui/material';
import { Input } from 'components/shared/FormElements';
import Autocomplete from 'components/shared/FormElements/Autocomplete/Autocomplete';
import { regexp } from 'constants/regexp';
import { Controller, type UseFormReturn } from 'react-hook-form';

import styles from './SelectUnit.module.scss';
import { statusOptions, unitTypeOptions } from '../constants';
import { type IForm, type IUnit } from '../types';

export interface UnitBoxProps {
  form: UseFormReturn<IForm>;
  unit: IUnit;
}

const UnitBox = ({ form, unit }: UnitBoxProps) => {
  const { id } = unit;
  const {
    control,
    formState: { errors },
  } = form;

  return (
    <Box className={styles.inputBox}>
      <Box className={styles.grid}>
        <Controller
          name={`units.unitNumber${id}`}
          control={control}
          render={({ field }) => (
            <Input
              label="Unit No*"
              className="full-size"
              errorMessage={errors?.units ? errors?.units[`unitNumber${id}`]?.message : ''}
              hasError={errors?.units ? !!errors?.units[`unitNumber${id}`] : false}
              {...field}
            />
          )}
        />
        <Controller
          name={`units.bedrooms${id}`}
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              regexp={regexp.number}
              label="Bedroom(s)*"
              placeholder="Enter bedrooms"
              errorMessage={errors?.units ? errors?.units[`bedrooms${id}`]?.message : ''}
              hasError={errors?.units ? !!errors?.units[`bedrooms${id}`] : false}
            />
          )}
        />
      </Box>
      <Box className={styles.grid}>
        <Controller
          name={`units.unitSize${id}`}
          control={control}
          render={({ field }) => (
            <Input
              label="Unit Size (sq.ft.)*"
              className="full-size"
              type="text"
              regexp={regexp.numericalDigits}
              errorMessage={errors?.units ? errors?.units[`unitSize${id}`]?.message : ''}
              hasError={errors?.units ? !!errors?.units[`unitSize${id}`] : false}
              {...field}
              // onChange={(e: any) => field.onChange(numberToStringWithCommas(e.target.value))}
            />
          )}
        />
        <Controller
          name={`units.bathrooms${id}`}
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              regexp={regexp.number}
              label="Bathrooms*"
              placeholder="Enter Bathrooms"
              errorMessage={errors?.units ? errors?.units[`bathrooms${id}`]?.message : ''}
              hasError={errors?.units ? !!errors?.units[`bathrooms${id}`] : false}
            />
          )}
        />
      </Box>
      <Box className={styles.grid}>
        <Controller
          name={`units.status${id}`}
          control={control}
          render={({ field }) => (
            <Autocomplete
              label="Status*"
              options={statusOptions}
              {...field}
              errorMessage={errors?.units ? errors?.units[`status${id}`]?.message : ''}
              hasError={errors?.units ? !!errors?.units[`status${id}`] : false}
            />
          )}
        />
        <Controller
          name={`units.unitType${id}`}
          control={control}
          render={({ field }) => (
            <Autocomplete
              label="Unit type"
              options={unitTypeOptions}
              {...field}
              errorMessage={errors?.units ? errors?.units[`unitType${id}`]?.message : ''}
              hasError={errors?.units ? !!errors?.units[`unitType${id}`] : false}
            />
          )}
        />
      </Box>
      <Box className={styles.grid}>
        <Controller
          name={`units.parkingSpot${id}`}
          control={control}
          render={({ field }) => (
            <Input
              label="Parking spot(s)*"
              regexp={regexp.number}
              errorMessage={errors?.units ? errors?.units[`parkingSpot${id}`]?.message : ''}
              hasError={errors?.units ? !!errors?.units[`parkingSpot${id}`] : false}
              {...field}
            />
          )}
        />
        <Box className={styles.grid}>
          <Controller
            name={`units.monthlyRent${id}`}
            control={control}
            render={({ field }) => (
              <Input
                label="Monthly rent*"
                regexp={regexp.number}
                errorMessage={errors?.units ? errors?.units[`monthlyRent${id}`]?.message : ''}
                hasError={errors?.units ? !!errors?.units[`monthlyRent${id}`] : false}
                {...field}
              />
            )}
          />
          <Controller
            name={`units.rentDeposit${id}`}
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                regexp={regexp.number}
                label="Rent Deposit*"
                errorMessage={errors?.units ? errors?.units[`rentDeposit${id}`]?.message : ''}
                hasError={errors?.units ? !!errors?.units[`rentDeposit${id}`] : false}
              />
            )}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default UnitBox;
