import { createSlice } from '@reduxjs/toolkit';

import { type LeaseState } from '../types';

const initialState: LeaseState = {
  isLoading: false,
  count: 0,
  leaseList: null,
  lease: null,
  leaseByUnitId: null,
  queryOptions: {
    page: 1,
    size: '5',
    sortBy: {
      id: '',
      desc: false,
    },
  },
};

const leaseSlice = createSlice({
  name: 'leaseSlice',
  initialState,
  reducers: {
    setLoading: (state: LeaseState, { payload }) => {
      state.isLoading = payload;
    },
    clearLeaseList: (state: LeaseState) => {
      state.leaseList = null;
      state.queryOptions = initialState.queryOptions;
    },
    clearLease: (state: LeaseState) => {
      state.lease = null;
    },
    getLeaseListSuccess: (state: LeaseState, { payload }) => {
      state.leaseList = payload.data.items;
      state.count = payload.data.count;
      state.queryOptions = { ...state.queryOptions, ...payload.queryOptions };
    },
    getLeaseByIdSuccess: (state: LeaseState, { payload }) => {
      state.lease = payload;
    },
    getLeaseByUnitIdSuccess: (state: LeaseState, { payload }) => {
      state.leaseByUnitId = payload;
    },
  },
});

export default leaseSlice;
