import toast from 'react-hot-toast';
import { type ForkEffect, call, takeLatest, put } from 'redux-saga/effects';

import * as actions from './actions';
import { getApplicantList } from './applicationRequestService.ts';
import applicantSlice from './tenantApplicationSlice';
import { type TenantApplicationResDataModel } from './types';

function* getApplicantListSaga({ payload }: ReturnType<typeof actions.getApplicantList>) {
  yield put(applicantSlice.actions.setLoading(true));
  try {
    const { data }: { data: TenantApplicationResDataModel } = yield call(getApplicantList, payload);

    yield put(applicantSlice.actions.setApplicantData({ data, queryOptions: payload }));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(applicantSlice.actions.setLoading(false));
  }
}

function* setPageSize({ payload }: ReturnType<typeof actions.setPageSize>) {
  yield put(applicantSlice.actions.setPageSize(payload[0]));
}

export function* watchTenantApplicationSaga(): Generator<ForkEffect> {
  yield takeLatest(actions.getApplicantList.type, getApplicantListSaga);
  yield takeLatest(actions.setPageSize.type, setPageSize);
}
