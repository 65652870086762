/* eslint-disable jsx-a11y/no-static-element-interactions */
import { forwardRef, useEffect } from 'react';

import { Box } from '@mui/material';
import Button from 'components/shared/Button/Button';
import { Input } from 'components/shared/FormElements';
import { B2, B3 } from 'components/Typography/Body';
import { H4 } from 'components/Typography/Headlines';
import { accountDataSelector, applicationLoadingSelector } from 'modules/Application/store/selectors';
import { type IForm } from 'modules/Application/types';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import styles from './ApplicationLogin.module.scss';

interface ApplicationLoginProps {
  signUp: (value: any) => void;
}

const ApplicationLogin = ({ signUp }: ApplicationLoginProps) => {
  const applicationLoading = useSelector(applicationLoadingSelector);
  const loginData = useSelector(accountDataSelector);

  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<IForm>();

  useEffect(() => {
    if (loginData) {
      setValue('login.email', loginData.email, { shouldValidate: true });
    }
  }, [loginData]);

  return (
    <div>
      <div className={styles.container}>
        <H4 className={styles.title} color="var(--additional-grey-1)">
          Sign in
        </H4>
        <B3 className={styles.desc}>Plase sign in to your account</B3>
        <Box className={styles.inputBox}>
          <Box className={styles.grid}>
            <Controller
              name="login.email"
              control={control}
              render={({ field }) => (
                <Input
                  label="Email*"
                  placeholder="Enter your Email"
                  errorMessage={errors.login?.email?.message}
                  hasError={!!errors.login?.email}
                  {...field}
                />
              )}
            />
            <Controller
              name="login.password"
              control={control}
              render={({ field }) => (
                <Input
                  className="full-size"
                  type="password"
                  label="Password*"
                  placeholder="Enter your password"
                  errorMessage={errors.login?.password?.message}
                  hasError={!!errors.login?.password}
                  {...field}
                />
              )}
            />
          </Box>
          <Button
            className={styles.button}
            variant="contained"
            size="full-lg"
            type="submit"
            label="Continue"
            loading={applicationLoading}
            disabled={applicationLoading}
          />

          <Box className={styles.createAccountBox}>
            <B2 color="var(--additional-grey-3)">
              Don’t have an account ?
              <span className={styles.signUp} onClick={() => signUp(true)}>
                Create an account
              </span>
            </B2>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default forwardRef(ApplicationLogin);
