import { createAction } from '@reduxjs/toolkit';

import { type IQuery } from './types';
import { type IForm } from '../types';

export const getItems = createAction<IQuery>('getItems');
export const createProperty = createAction<{
  data: IForm;
  navigate: (to: string) => void;
}>('createProperty');
export const updateProperty = createAction<{
  data: any;
  id: string;
  navigate: ((to: string) => void) | null;
}>('updateProperty');
export const deleteOwner = createAction<{
  propertyId: string;
  ownerId: string;
}>('deleteOwner');
