import React from 'react';

import { Avatar } from '@mui/material';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import styles from './Header.module.scss';
import { headerMenu } from '../../constants/headerMenu';
import { routes } from '../../constants/routes';
import { activeUsers } from '../../constants/userStatus';
import { userSelector } from '../../modules/Auth/store/selectors';
import { notificationsCountSelector } from '../../modules/Notifications/store/selectors';
import SideBar from '../../SideBar';
import Popover from '../shared/Popover';
import SvgIcon from '../shared/utils/SvgIcon';
import { B2 } from '../Typography/Body';
const Header = () => {
  const count = useSelector(notificationsCountSelector);
  const user = useSelector(userSelector);
  const navigate = useNavigate();

  return (
    <div className={styles.header}>
      {user && activeUsers.includes(user?.status) && (
        <>
          <button className={cn(styles.button, styles.menuButton)} onClick={() => navigate(routes.notifications)}>
            <SvgIcon icon={'notifications'} className={styles.menuIcon} />
            {count.unReadNotifications > 0 && <div className={styles.unReadNotifications}></div>}
          </button>
          <Popover>
            {user?.fullName && (
              <Popover.Target>
                <button className={cn(styles.button, styles.fullNameButton)}>
                  <Avatar
                    className={styles.firstCharacter}
                    alt={user.fullName[0]}
                    src={`${process.env.REACT_APP_BASE_URL}/api/document?path=${user?.profile?.profilePic}`}
                  />
                </button>
              </Popover.Target>
            )}
            <Popover.Content>
              <div className={styles.wrap}>
                {headerMenu.map(({ to, title, icon }) => (
                  <B2 bold className={styles.content} key={to}>
                    <SideBar.MenuItem linkTo={to} title={title} icon={icon}>
                      {title}
                    </SideBar.MenuItem>
                  </B2>
                ))}
              </div>
            </Popover.Content>
          </Popover>
        </>
      )}
    </div>
  );
};
export default Header;
