import { useEffect, useState } from 'react';

import { Box, Avatar } from '@mui/material';
import cn from 'classnames';
// import { formatDate } from 'common/utils/utils';
import BackButton from 'components/shared/BackButton/BackButton';
import Autocomplete from 'components/shared/FormElements/Autocomplete/Autocomplete';
import ImageDialog from 'components/shared/ImageDialog';
import Loading from 'components/shared/Loading';
import SvgIcon from 'components/shared/utils/SvgIcon';
import { B1, B3 } from 'components/Typography/Body';
import { H3 } from 'components/Typography/Headlines';
import { routes } from 'constants/routes';
import { type MaintenanceTaskStatus } from 'constants/userStatus';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import styles from './TaskDetails.module.scss';
import Chat from '../../Chat/Chat';
import PropertyCard from '../PropertyCard/PropertyCard';
import { getTaskDetails, updatetTaskStatus } from '../store/actions';
import { taskStatus } from '../store/constants';
import { loadingSelector, taskDetailsSelector } from '../store/selector';
import taskSlice from '../store/taskSlice';

const TaskDetails = () => {
  const dispatch = useDispatch();
  const form = useForm({
    mode: 'all',
  });

  const { control, getValues, reset } = form;

  const loading = useSelector(loadingSelector);
  const taskDetails = useSelector(taskDetailsSelector);
  const [searchParams] = useSearchParams();
  const taskId = searchParams.get('taskId') as string;
  const [openFullScreen, setOpenFullScreen] = useState(false);
  const [screenImage, setScreenImage] = useState('');

  useEffect(() => {
    dispatch(getTaskDetails({ taskId }));
    return () => {
      if (!location.pathname.includes(routes.taskDetails)) {
        dispatch(taskSlice.actions.clearTask());
      }
    };
  }, []);

  useEffect(() => {
    if (taskDetails) {
      const status = taskStatus.find(el => el.value === taskDetails.status);
      reset({
        ...getValues(),
        status,
      });
    }
  }, [taskDetails]);

  const handleOpenFullScreen = (el: string) => {
    setScreenImage(el);
    setOpenFullScreen(true);
  };

  const handleCloseFullScreen = () => {
    setOpenFullScreen(false);
  };

  return (
    <div className={styles.container}>
      <BackButton to={routes.tasks} />
      <form>
        <div className={styles.titleContent}>
          <H3 className={styles.title}>Task Details</H3>
          <div className={styles.statusButton}>
            <Box>
              <Controller
                name={'status'}
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    label="Status"
                    options={taskStatus}
                    {...field}
                    onAutocompleteChange={(evnt: { label: string; value: string }) => {
                      const currentStatus = taskStatus.find(el => el.label === evnt.label)?.value;
                      dispatch(
                        updatetTaskStatus({
                          taskId,
                          status: currentStatus as MaintenanceTaskStatus,
                        })
                      );
                    }}
                  />
                )}
              />
            </Box>
          </div>
        </div>
        {!!taskDetails?.property && <PropertyCard taskDetails={taskDetails} />}

        <div className={styles.details}>
          <div className={styles.personInfo}>
            <B3 className={styles.title}>
              <SvgIcon icon={'profile'} className={styles.profile} />
              {taskDetails?.landlord?.fullName} / Landlord
            </B3>
            <B3 className={styles.value}>{taskDetails?.landlord?.email}</B3>
          </div>
          {taskDetails?.assignee && (
            <div className={`${styles.personInfo} ${styles.personMailInfo}`} key={taskDetails._id}>
              <B3 className={styles.title}>
                <SvgIcon icon={'profile'} className={styles.profile} />
                {taskDetails?.assignee?.managerId?.fullName} / Manager
              </B3>
              <B3>{taskDetails?.assignee?.email}</B3>
            </div>
          )}
          {/* <div className={styles.personInfo}>
            <B3 className={styles.title}>
              <SvgIcon icon={'accounting'} className={cn(styles.profile, styles.iconLight)} />
              Due date
            </B3>
            <B3 className={styles.value}>
              {taskDetails?.createdAt && formatDate(taskDetails.createdAt)} {`     `}
              {taskDetails?.convenientTime}
            </B3>
          </div> */}
          <div className={styles.personInfo}>
            <B3 className={styles.title}>
              <SvgIcon icon={'accounting'} className={cn(styles.profile, styles.iconLight)} />
              Categoris
            </B3>
            {taskDetails?.category?.length ? (
              <Box className={styles.fileBox}>
                {taskDetails?.category?.length &&
                  taskDetails?.category.map(el => {
                    return (
                      <B3 className={styles.value} key={taskDetails._id}>
                        {el}
                      </B3>
                    );
                  })}
              </Box>
            ) : (
              ''
            )}
          </div>
          <div className={styles.personInfo}>
            <B3 className={styles.title}>
              <SvgIcon icon={'calendar'} className={styles.profile} />
              Request priority
            </B3>
            <B3 className={styles.value}>{taskDetails?.priority}</B3>
          </div>
          <div className={styles.description}>
            <B1 bold>{taskDetails?.title}</B1>
            <B3 className={styles.descriptionTitle}>Description</B3>
            <B3 className={styles.descriptionBody}>{taskDetails?.description}</B3>
          </div>
          <div className={styles.documents}>
            <B3 className={styles.title}>
              <SvgIcon icon={'applications'} className={styles.profile} />
              Photo:
            </B3>
            <Box className={styles.fileBox}>
              {taskDetails?.gallery &&
                Object.values(taskDetails?.gallery)?.map((el: any, index: number) => (
                  <Avatar
                    className={styles.image}
                    onClick={() => handleOpenFullScreen(el)}
                    key={el as string}
                    src={`${process.env.REACT_APP_BASE_URL}/api/document?path=${el}`}
                    alt={`Uploaded ${index + 1}`}
                  />
                ))}
              {openFullScreen && screenImage && (
                <ImageDialog
                  imageUrl={`${process.env.REACT_APP_BASE_URL}/api/document?path=${screenImage}`}
                  onClose={handleCloseFullScreen}
                />
              )}
            </Box>
          </div>
        </div>
      </form>
      {taskDetails?.chatId && <Chat chatId={taskDetails.chatId} />}
      {loading && <Loading isBig />}
    </div>
  );
};
export default TaskDetails;
