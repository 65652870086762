import { useEffect } from 'react';

import { Box } from '@mui/material';
import { Input } from 'components/shared/FormElements';
import { B3 } from 'components/Typography/Body';
import { H4, H5 } from 'components/Typography/Headlines';
import { regexp } from 'constants/regexp';
import { getApplicationById } from 'modules/Application/store/actions';
import { occupantsDataSelector } from 'modules/Application/store/selectors';
import { type IForm } from 'modules/Application/types';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import styles from './Occupantas.module.scss';
import Pets from './Pets/Pets';
import Vehicles from './Vehicles/Vehicles';

const Occupants = () => {
  const dispatch = useDispatch();
  const occupantsData = useSelector(occupantsDataSelector);

  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<IForm>();

  useEffect(() => {
    const userId = localStorage.getItem('id');
    if (userId && !occupantsData) {
      dispatch(getApplicationById(userId));
    }
  }, []);

  useEffect(() => {
    if (occupantsData) {
      setValue('aboveNumber', occupantsData?.aboveNumber);
      setValue('underNumber', occupantsData?.underNumber);
    }
  }, [occupantsData]);

  return (
    <div>
      <div className={styles.container}>
        <H4 className={styles.title} bold>
          Rental Application
        </H4>
        <B3 className={styles.desc}>Evolve Realty & Development</B3>
        <Pets />
      </div>
      <div className={styles.container}>
        <Vehicles />
      </div>
      <div className={styles.container}>
        <H5 className={styles.petsTitle}>Other info</H5>
        <Box className={styles.inputBox}>
          <Box className={styles.grid}>
            <B3>please indicate the number of above the age of 18, besides yourself, will be residing here</B3>
            <B3>please indicate the number of individuals under the age of 18 who will be living here.</B3>
          </Box>
          <Box className={styles.grid}>
            <Controller
              name="aboveNumber"
              control={control}
              render={({ field }) => (
                <Input
                  label="Number*"
                  errorMessage={errors.aboveNumber?.message}
                  hasError={!!errors.aboveNumber}
                  regexp={regexp.number}
                  placeholder="Choose the number of above the age of 18"
                  {...field}
                />
              )}
            />
            <Controller
              name="underNumber"
              control={control}
              render={({ field }) => (
                <Input
                  label="Number*"
                  placeholder="Choose the number of under the age of 18"
                  errorMessage={errors.underNumber?.message}
                  hasError={!!errors.underNumber}
                  regexp={regexp.number}
                  {...field}
                />
              )}
            />
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default Occupants;
