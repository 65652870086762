import { type FC, memo, type MouseEvent, type ReactNode, useCallback, useContext } from 'react';

import { Box } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import cx from 'classnames';

import styles from './SideBarHeader.module.scss';
import SvgIcon from '../../components/shared/utils/SvgIcon';
import { SidebarContext } from '../SideBar';

export interface Props {
  className?: string;
  onToggle?: (value: boolean) => void;
  children: ReactNode | any;
}

const SideBarHeader: FC<Props> = ({ className, children, onToggle }) => {
  const { isCollapsed, isActive, setSidebarState, collapseOnInactive, isHovered } = useContext(SidebarContext);

  const isTablet = useMediaQuery(`(max-width: 900)`);

  const handleToggle = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      setSidebarState(state => {
        onToggle?.(!state.isCollapsed);

        if (isTablet) {
          return {
            isActive: false,
            isCollapsed: !state.isCollapsed,
            isHovered: !state.isCollapsed,
            isImmediatelyClose: false,
          };
        }

        if (isActive) {
          return { isActive: false, isCollapsed: true, isHovered: false, isImmediatelyClose: true };
        }

        return { ...state, isActive: !state.isActive };
      });
    },
    [onToggle, isTablet, setSidebarState, isActive]
  );

  return (
    <div className={cx(styles.sidebarHeader, className, { [styles.withRtl as string]: false })}>
      {typeof children === 'function' ? children({ isCollapsed, isActive, isHovered }) : children}
      <div className={cx(styles.toggle)}>
        <div className={styles.line} />
        {(isHovered || isTablet) && (
          <Box
            className={cx(styles.toggleIcon, {
              [styles.collapsed as string]: !collapseOnInactive && isCollapsed && !isTablet,
            })}
            onClick={handleToggle}
          >
            <SvgIcon
              className={cx(styles.arrowIcon, {
                [styles.active as string]: isActive || (isTablet ? !isCollapsed : false),
              })}
              icon="arrow"
            />
          </Box>
        )}
      </div>
    </div>
  );
};

export default Object.assign(memo(SideBarHeader), { displayName: 'SideBarHeader' });
