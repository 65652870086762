import toast from 'react-hot-toast';
import { call, type ForkEffect, put, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import profileSlice from './propertiesSlice';
import {
  addPropertyUnit,
  deletePropertyImage,
  deletePropertyUnit,
  getPropertyServices,
  getPropertyWithActiveLeasedUnitsServices,
  updatePropertyUnit,
} from '../propertyServices';

function* getPropertiesSaga({ payload }: ReturnType<typeof actions.getPropertiesAction>) {
  yield put(profileSlice.actions.setLoading(true));
  try {
    const { data } = yield call(getPropertyServices, payload);
    yield put(profileSlice.actions.setPropertyList({ data, query: payload }));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(profileSlice.actions.setLoading(false));
  }
}

function* getPropertiesWithActiveLeasedUnitsSaga({ payload }: ReturnType<typeof actions.getPropertiesAction>) {
  yield put(profileSlice.actions.setLoading(true));
  try {
    const { data } = yield call(getPropertyWithActiveLeasedUnitsServices, payload);
    yield put(profileSlice.actions.setPropertyList({ data, query: payload }));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(profileSlice.actions.setLoading(false));
  }
}

function* updatePropertySaga({ payload }: ReturnType<typeof actions.updatePropertyUnitAction>) {
  try {
    const { data } = yield call(updatePropertyUnit, payload);
    toast.success('Your property unit has been successfully updated.');
    yield put(profileSlice.actions.updateUnit(data));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  }
}
function* addPropertySaga({ payload }: ReturnType<typeof actions.addPropertyUnitAction>) {
  yield put(profileSlice.actions.setLoading(true));
  try {
    const { data } = yield call(addPropertyUnit, payload);
    toast.success('Your property unit has been successfully Created.');
    yield put(profileSlice.actions.addUnit(data));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(profileSlice.actions.setLoading(false));
  }
}
function* deleteImagePropertySaga({ payload }: ReturnType<typeof actions.deleteImagePropertyAction>) {
  try {
    yield call(deletePropertyImage, payload);
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  }
}
function* deleteUnitSaga({ payload }: ReturnType<typeof actions.deleteUnitAction>) {
  try {
    yield put(profileSlice.actions.setLoading(true));
    yield call(deletePropertyUnit, payload);
    toast.success('Your property unit has been successfully deleted.');
    yield put(profileSlice.actions.deleteUnit({ id: payload.unitsId }));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(profileSlice.actions.setLoading(false));
  }
}
export function* watchPropertySaga(): Generator<ForkEffect> {
  yield takeLatest(actions.getPropertiesAction.type, getPropertiesSaga);
  yield takeLatest(actions.getPropertiesWithActiveLeasedUnitsAction.type, getPropertiesWithActiveLeasedUnitsSaga);
  yield takeLatest(actions.updatePropertyUnitAction.type, updatePropertySaga);
  yield takeLatest(actions.deleteUnitAction.type, deleteUnitSaga);
  yield takeLatest(actions.deleteImagePropertyAction.type, deleteImagePropertySaga);
  yield takeLatest(actions.addPropertyUnitAction.type, addPropertySaga);
}
