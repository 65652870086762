import request from '../../../services/request';
import { type IQueryOptions } from '../types';

const END_POINTS = {
  getLease: 'api/lease',
  createLease: 'api/lease/create',
  uploadDocument: 'api/document/upload-lease',
  sentToSignLease: 'api/lease/send-to-sign',
  updateLease: 'api/lease/update/',
};

export function getLeaseService(payload: IQueryOptions) {
  return request({
    url: `${END_POINTS.getLease}?page=${payload.page}&limit=${payload.size}`,
  });
}
export function getLeaseByIdService(payload: string) {
  return request({
    url: `${END_POINTS.getLease}/${payload}`,
  });
}
export function createLeaseService(payload: any): any {
  return request({
    url: `${END_POINTS.createLease}`,
    method: 'POST',
    data: payload,
  });
}
export function sentToSignLeaseService(payload: any): any {
  return request({
    url: `${END_POINTS.sentToSignLease}`,
    method: 'POST',
    data: payload,
  });
}
export function updateLeaseService(payload: any): any {
  return request({
    url: `${END_POINTS.updateLease}${payload.id}`,
    method: 'PUT',
    data: payload.data,
  });
}

export function uploadDocumentServices(payload: { images: File[]; id: string }) {
  const formData = new FormData();
  payload.images.forEach((image: File | string) => {
    if (typeof image !== 'string') {
      formData.append(`files`, image, image.name);
    }
  });
  formData.append('leaseId', payload.id);
  return request({
    method: 'POST',
    url: `${END_POINTS.uploadDocument}`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function getLeaseByUnitIdService(payload: string) {
  return request({
    url: `${END_POINTS.getLease}?status=NEW&unitId=${payload}`,
  });
}
