import { useCallback, useRef } from 'react';

import { DateFormat } from 'constants/dateFormat';
import toast from 'react-hot-toast';

import { type userRole } from '../../constants/userStatus';

export const hasPermissions = (roles: userRole[], userPermissions: any): boolean => {
  if (!userPermissions) {
    return false;
  }

  return roles.includes(userPermissions);
};
function padZero(number: any) {
  return number < 10 ? '0' + number : number;
}
export const formatTime = (milliseconds: number): string => {
  const minutes = Math.floor((milliseconds % 3600000) / 60000);
  const seconds = Math.floor((milliseconds % 60000) / 1000);
  return `${padZero(minutes)}:${padZero(seconds)}`;
};

export const getUrlParams = (url = location.search) => {
  const params: { [key: string]: string | undefined } = {};
  url
    .slice(1)
    .split('&')
    .forEach(el => {
      const [key, value] = el.split('=');
      if (key) {
        params[key] = value;
      }
    });
  return params;
};

export const einChangeFormat = (value: string, onChange: any) => {
  if (value.length > 2) {
    const newValue = value.slice(0, 2) + '-' + value.slice(2);
    onChange(newValue);
  } else {
    onChange(value);
  }
};
export const ssnChangeFormat = (value: string, onChange: any) => {
  const newValue = value.replace(/[^\d]/g, ''); // Remove non-digit characters
  let formattedValue = '';
  if (newValue.length > 0) {
    formattedValue += newValue.slice(0, 3);
    if (newValue.length > 3) {
      formattedValue += '-' + newValue.slice(3, 5);
      if (newValue.length > 5) {
        formattedValue += '-' + newValue.slice(5, 9);
      }
    }
  }
  onChange(formattedValue);
};

export const numberToStringWithCommas = (x: any): any => {
  const num = Number(x);
  if (num || num === 0) {
    const isWithCommas = num.toString().indexOf(',') === -1;
    if (isWithCommas) {
      const n = Number(num);
      return n.toLocaleString('en-US', { minimumFractionDigits: 0 });
    }
    return num;
  }
  return '';
};

export const unregisterFormById = (ids: string[], unregister: any) => {
  ids.forEach(id => {
    unregister(id);
  });
};

export const formatHoursAndMinutes = (date: Date | string) => {
  if (!date) {
    return null;
  }
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  const hoursAndMinutes = date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
  });

  return hoursAndMinutes;
};

export const formatDate = (date: Date | string, format: DateFormat = DateFormat.MMDDYYYY) => {
  if (!date) {
    return null;
  }
  if (!(date instanceof Date)) {
    date = new Date(date);
  }

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };

  const datesFormat = {
    [DateFormat.DDMMYYYY]: date.toLocaleDateString('en-GB', options).split(',')[0].replace(/\//g, '.'),
    [DateFormat.MMDDYYYY]: date.toLocaleDateString('en-US', options).split(',')[0].replace(/\//g, '.'),
    [DateFormat.MMYYYY]: date.toLocaleDateString('en-US', { month: '2-digit', year: 'numeric' }),
    [DateFormat.YYYYMMDD]: date.toISOString().split('T')[0],
  };

  return datesFormat[format];
};

export const getMonth = (index: number) => {
  const currentDate = new Date();
  const targetDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - index, 1);

  return formatDate(targetDate, DateFormat.MMYYYY);
};

export const getYear = (index: number) => {
  const currentDate = new Date();
  const targetDate = new Date(currentDate.getFullYear() - index, currentDate.getMonth(), currentDate.getDate())
    .getFullYear()
    .toString();

  return targetDate;
};

export const getRandomId = () => `#${Math.ceil(Math.random() * 100000)}`;

export function findKeyByValue(object: { [key: string]: string }, valueToFind: string) {
  for (const key in object) {
    if (Object.prototype.hasOwnProperty.call(object, key) && object[key] === valueToFind) {
      return { [key]: object[key] };
    }
  }
  return null;
}

export const desideMaxDateByYear = (year = 18) => {
  const currentDate = new Date();
  return new Date(currentDate.getFullYear() - year, currentDate.getMonth(), currentDate.getDate());
};

export const scrollToFirstErrorInput = (errors: any) => {
  if (Object.keys(errors).length > 0) {
    const firstErrorKey = Object.keys(errors)[0];
    if (firstErrorKey) {
      toast.error('Incomplete Information. Please Fill All Required Fields.');
    }
    const firstErrorInput = document.querySelector(`[name="${firstErrorKey}"]`);
    if (firstErrorInput) {
      firstErrorInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
};

type CallbackFunction = (...args: any[]) => void;
const useDebouncedCallback = (callback: CallbackFunction, delay = 400, dependencies: any[] = []) => {
  const timeout = useRef<any>();

  return useCallback(
    (...args: any[]) => {
      if (timeout.current != null) {
        clearTimeout(timeout.current);
      }

      timeout.current = setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay, ...dependencies]
  );
};

export default useDebouncedCallback;
