/* eslint-disable max-len */
import { useEffect, useMemo } from 'react';

import { Box } from '@mui/material';
import Loading from 'components/shared/Loading';
import MobileCard from 'components/shared/MobileCard';
import StatusTag from 'components/shared/StatusTag';
import TableCard from 'components/shared/TableCard';
import { B1, B2 } from 'components/Typography/Body';
import { H3 } from 'components/Typography/Headlines';
import { regexp } from 'constants/regexp';
import { colors, userRole } from 'constants/userStatus';
import { useDispatch, useSelector } from 'react-redux';

import AddTenant from './AddTenant';
import { getTenantsList } from './store/actions';
import { tenantLoadingSelector, tenantQueryOptionsSelector, tenantsSelector } from './store/selector';
import styles from './Tenants.module.scss';
import PrivateAccess from '../../components/PrivateAccess';

const Tenants = () => {
  const dispatch = useDispatch();
  const loading = useSelector(tenantLoadingSelector);
  const tenants = useSelector(tenantsSelector);
  const { page, size } = useSelector(tenantQueryOptionsSelector);

  const memoColumns = useMemo(() => {
    return [
      {
        Header: 'Name',
        id: 'name',
        title: 'Name',
        Cell: ({ row }: any) => {
          return (
            <div className={styles.item} style={colors[row.original?.status]}>
              <B1 bold className={styles.firstName}>
                {`${row.original?.fullName}`}
              </B1>
            </div>
          );
        },
      },
      {
        Header: 'Property name',
        id: 'propertyName',
        title: 'Property name',
        Cell: ({ row }: any) => {
          return (
            <div>
              {row.original?.tenantInfo?.propertyInfo.length
                ? row.original?.tenantInfo.propertyInfo.map(({ property, name }: any) => {
                    return (
                      <B2 bold>
                        {`Unit ${row.original?.tenantInfo?.unit?.number}, ${name} - 
                        ${property?.googleApi?.address?.value}`}
                      </B2>
                    );
                  })
                : ''}
            </div>
          );
        },
      },
      {
        Header: 'Phone',
        id: 'phone',
        title: 'Phone',
        Cell: ({ row }: any) => {
          return (
            <div>
              <B2>{row.original?.phone ? regexp.formatPhoneNumber(row.original?.phone) : '-'}</B2>
            </div>
          );
        },
      },
      {
        Header: 'Email',
        id: 'email',
        title: 'Email',
        Cell: ({ row }: any) => {
          return (
            <div>
              <B2 className={styles.email}>{row.original?.email}</B2>
            </div>
          );
        },
      },
      {
        Header: 'Status',
        id: 'status',
        title: 'Status',
        width: 50,
        Cell: ({ row }: any) => {
          return (
            <div>
              <StatusTag type={row.original?.status} label={row.original?.status} size={'medium'} styles={{ minWidth: '50px' }} />
            </div>
          );
        },
      },
    ];
  }, []);

  const mobileCardData = tenants?.items.map((tenant: any) => {
    const properties = tenant?.tenantInfo?.propertyInfo
      .map((item: any) => {
        return item.name;
      })
      .join(',\n');
    return [
      {
        index: 1,
        label: 'Name',
        value: tenant?.fullName,
        status: tenant?.status,
      },
      {
        index: 2,
        label: 'Property name',
        value: properties,
      },
      {
        index: 3,
        label: 'Phone',
        value: tenant?.phone,
      },
      {
        index: 4,
        label: 'Email',
        value: tenant?.email,
      },
      {
        index: 5,
        label: 'Status',
        value: tenant?.status,
      },
    ];
  });

  useEffect(() => {
    dispatch(getTenantsList({ limit: size, page: 1 }));
  }, []);

  return (
    <PrivateAccess roles={[userRole.LANDLOARD, userRole.MANAGER]} isPage>
      <div className={styles.tenants}>
        <Box>
          <div className={styles.addTenant}>
            <H3 className={styles.title}>Tenants</H3>
            <PrivateAccess roles={[userRole.LANDLOARD]}>
              <AddTenant />
            </PrivateAccess>
          </div>
        </Box>
        {tenants?.items?.length ? (
          <div>
            {mobileCardData?.map((cardData, index) => (
              <MobileCard key={index} items={cardData} />
            ))}
            <TableCard
              columns={memoColumns}
              data={tenants?.items || []}
              defaultPageSize={size}
              totalItems={+tenants.count}
              defaultPage={page}
              setPageSize={size => dispatch(getTenantsList({ limit: size, page: 1 }))}
              fetchData={({ pageSize, pageIndex }) => dispatch(getTenantsList({ page: pageIndex, limit: `${pageSize}` }))}
              className={styles.tenantTable}
            />
          </div>
        ) : (
          !loading && (
            <div className={styles.emptyContainer}>
              <B1>No Tenants found</B1>
              <B2>You don't have any Tenants to publish.</B2>
            </div>
          )
        )}
        {loading && <Loading isBig />}
      </div>
    </PrivateAccess>
  );
};

export default Tenants;
