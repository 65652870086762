import React from 'react';

import { Box } from '@mui/material';
import { Input } from 'components/shared/FormElements';
import DatePicker from 'components/shared/FormElements/DatePicker/DatePicker';
import { B1 } from 'components/Typography/Body';
import dayjs from 'dayjs';
import { Controller, type UseFormReturn, useWatch } from 'react-hook-form';

import styles from './GroupInfo.module.scss';
import { type IForm } from '../types';

interface GroupInfoProps {
  form: UseFormReturn<IForm>;
  leaseEndDate: string;
}
const GroupInfo = ({ form, leaseEndDate }: GroupInfoProps) => {
  const {
    control,
    formState: { errors },
  } = form;
  const minDate = dayjs(new Date());
  const startFrom = leaseEndDate ? dayjs(leaseEndDate).add(1, 'day') : useWatch({ control, name: 'startFrom' });

  return (
    <div className={styles.groupInfo}>
      <Box className={styles.inputBox}>
        <Box className={styles.grid}>
          <Controller
            name="title"
            control={control}
            render={({ field }) => (
              <Input
                placeholder="Enter Listing title"
                label="Title*"
                className="full-size"
                errorMessage={errors?.title?.message}
                hasError={!!errors?.title}
                {...field}
              />
            )}
          />
          <Box className={styles.grid}>
            <Controller
              name="startFrom"
              control={control}
              render={({ field }) => (
                <DatePicker
                  className={styles.calendar}
                  label="Start from*"
                  {...field}
                  minDate={minDate}
                  errorMessage={errors?.startFrom?.message}
                  hasError={!!errors.startFrom}
                />
              )}
            />
            <Controller
              name="availableFrom"
              control={control}
              render={({ field }) => (
                <DatePicker
                  className={styles.calendar}
                  label="Available From*"
                  minDate={startFrom}
                  {...field}
                  errorMessage={errors?.availableFrom?.message}
                  hasError={!!errors.availableFrom}
                />
              )}
            />
          </Box>
        </Box>
        <Box>
          <B1 className={styles.description}>Listing Description*</B1>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <Input
                placeholder="Enter your Listing Description"
                multiline
                rows={5}
                className="full-size"
                errorMessage={errors?.description?.message}
                hasError={!!errors?.description}
                {...field}
              />
            )}
          />
        </Box>
      </Box>
    </div>
  );
};
export default GroupInfo;
