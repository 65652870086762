import toast from 'react-hot-toast';
import { call, type ForkEffect, put, takeEvery } from 'redux-saga/effects';

import * as actions from './actions';
import tenantPaymentSlice from './tenantPaymentSlice';
import modalNames from '../../../constants/modalNames';
import { routes } from '../../../constants/routes';
import { modalsSlice } from '../../../modals';
import {
  confirmPaymentService,
  deletePaymentMethodsService,
  getPaymentByIdService,
  getPaymentMethodsService,
  getTenantPaymentService,
  setPaymentMethodsService,
  getDepositByTenantService,
  markAsPaidService,
  returnDepositService,
  getDashboardPaymentsService,
  notifyAboutPaymentService,
} from '../paymentServices';

function* getTenantPaymentSaga({ payload }: ReturnType<typeof actions.getTenantPaymentListAction>) {
  yield put(tenantPaymentSlice.actions.setLoading(true));
  try {
    const { data } = yield call(getTenantPaymentService as any, payload);
    yield put(tenantPaymentSlice.actions.getTenantPaymentStateSuccess({ data, queryOptions: payload, status: payload?.status }));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(tenantPaymentSlice.actions.setLoading(false));
  }
}
function* getPaymentByIdSaga({ payload }: ReturnType<typeof actions.getPaymentByIdAction>) {
  yield put(tenantPaymentSlice.actions.setLoading(true));
  try {
    const { data } = yield call(getPaymentByIdService as any, payload);
    yield put(tenantPaymentSlice.actions.getPaymentByIdSuccess({ data }));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(tenantPaymentSlice.actions.setLoading(false));
  }
}
function* getPaymentMethodsSaga({ payload }: ReturnType<typeof actions.getPaymentMethodsAction>) {
  try {
    const { data } = yield call(getPaymentMethodsService as any, payload);
    yield put(tenantPaymentSlice.actions.getPaymentMethodsSuccess({ data }));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  }
}
function* deletePaymentMethodsSaga({ payload }: ReturnType<typeof actions.deletePaymentMethodsAction>) {
  yield put(tenantPaymentSlice.actions.setLoading(true));
  try {
    const { data } = yield call(deletePaymentMethodsService as any, payload);
    yield put(tenantPaymentSlice.actions.getPaymentMethodsSuccess({ data }));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(tenantPaymentSlice.actions.setLoading(false));
  }
}
function* setPaymentMethodsSaga({ payload }: ReturnType<typeof actions.setPaymentMethodsAction>) {
  yield put(tenantPaymentSlice.actions.setLoading(true));
  try {
    yield call(setPaymentMethodsService as any, payload);
    // yield put(tenantPaymentSlice.actions.getPaymentMethodsSuccess({ data }));
    yield put(modalsSlice.actions.hideModal(modalNames.PAYMENT_METHODS_MODAL));
    toast.success('Your Payment Method activated');
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(tenantPaymentSlice.actions.setLoading(false));
  }
}
function* confirmPaymentSaga({ payload }: ReturnType<typeof actions.confirmPaymentAction>) {
  try {
    yield call(confirmPaymentService as any, payload);
  } catch (error: any) {
    console.log(error);
  }
}

function* markAsPaidSaga({ payload }: ReturnType<typeof actions.markAsPaidAction>) {
  try {
    yield call(markAsPaidService as any, payload);
    payload.navigate(routes.landloardPayment);
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  }
}

function* returnDepositSaga({ payload }: ReturnType<typeof actions.returnDepositAction>) {
  try {
    yield call(returnDepositService as any, payload);
    payload.navigate(routes.landloardPayment);
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  }
}

function* getDepositByTenantSaga({ payload }: ReturnType<typeof actions.getDepositByTenantAction>) {
  yield put(tenantPaymentSlice.actions.setLoading(true));
  try {
    const { data } = yield call(getDepositByTenantService as any, payload);
    yield put(tenantPaymentSlice.actions.getDepositByTenantSuccess({ data }));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(tenantPaymentSlice.actions.setLoading(false));
  }
}

function* getDashboardPaymentsSaga() {
  try {
    const { data } = yield call(getDashboardPaymentsService);
    yield put(tenantPaymentSlice.actions.getDashboardPaymentsSuccess(data));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  }
}

function* notifyAboutPaymentSaga({ payload }: ReturnType<typeof actions.notifyAboutPaymentAction>) {
  try {
    yield call(notifyAboutPaymentService as any, payload);
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  }
}

export function* watchTenantPaymentSaga(): Generator<ForkEffect> {
  yield takeEvery(actions.getTenantPaymentListAction.type, getTenantPaymentSaga);
  yield takeEvery(actions.getPaymentByIdAction.type, getPaymentByIdSaga);
  yield takeEvery(actions.confirmPaymentAction.type, confirmPaymentSaga);
  yield takeEvery(actions.getPaymentMethodsAction.type, getPaymentMethodsSaga);
  yield takeEvery(actions.deletePaymentMethodsAction.type, deletePaymentMethodsSaga);
  yield takeEvery(actions.setPaymentMethodsAction.type, setPaymentMethodsSaga);
  yield takeEvery(actions.markAsPaidAction.type, markAsPaidSaga);
  yield takeEvery(actions.returnDepositAction.type, returnDepositSaga);
  yield takeEvery(actions.getDepositByTenantAction.type, getDepositByTenantSaga);
  yield takeEvery(actions.getDashboardPaymentsAction.type, getDashboardPaymentsSaga);
  yield takeEvery(actions.notifyAboutPaymentAction.type, notifyAboutPaymentSaga);
}
