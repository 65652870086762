const images = (gallery: any) => {
  const newImages: string[] = [];
  if (gallery) {
    const links = Object.keys(gallery).filter(el => el !== 'cover');
    links.forEach(element => {
      newImages.push(gallery[element]);
    });
  }
  return newImages;
};

export const getPropertyDetails = (details: any) => {
  const { propertyName, propertyType, googleApi, selectUnitType, other, managers, gallery, amenities, features, owners } =
    details;
  const { hasPets, isRentersInsurance, laundry, petDescription, petWeight } = other;

  return {
    propertyName,
    propertyType,
    ...googleApi,
    address: { ...googleApi.address, label: googleApi.address.value },
    address1: googleApi.address2,
    selectUnitType,
    petDescription,
    hasPets: hasPets ? 'Yes' : 'No',
    petWeight,
    laundry,
    isRentersInsurance: isRentersInsurance ? 'Yes' : 'No',
    owners,
    managerEmail: managers.map((el: { email: string }) => ({ ...el, value: el.email, label: el.email })),
    amenityItems: amenities
      ?.map((el: { name: string }) => ({ ...el, label: el.name }))
      ?.filter((element: { type: string }) => element.type !== 'REGULAR'),
    amenity: amenities?.map((el: { _id: string }) => el._id),
    feature: features.map((el: { _id: string }) => el._id),
    featureItems: features
      .map((el: { name: string }) => ({ ...el, label: el.name }))
      .filter((element: { type: string }) => element.type !== 'REGULAR'),
    ...(gallery?.cover && { imageCover: [gallery?.cover] }),
    ...(images(gallery).length && { imageUrls: [...images(gallery)] }),
  };
};

export const getUtilitiesIconByName = (name: string | undefined) => {
  const names: { [key: string]: string } = {
    Water: 'tap',
    Internet: 'internet',
    'Electricity/Hydro': 'lamp',
    Heat: 'balon',
  };
  if (!names[name || '']) {
    return 'emptyHome';
  }
  return names[name || ''];
};
