import { useEffect } from 'react';

import { B3 } from 'components/Typography/Body';
import { H4 } from 'components/Typography/Headlines';
import { userSelector } from 'modules/Auth/store/selectors';
import { useDispatch, useSelector } from 'react-redux';

import styles from './Home.module.scss';
import ExpiringLeases from './HomeCharts/BarChart/ExpiringLeases';
import Maintenance from './HomeCharts/PieChart/Maintenance/index';
import RentalApplication from './HomeCharts/PieChart/RentalApplication/index';
import RentalListings from './HomeCharts/PieChart/RentalListings/index';
import TenantsIncome from './HomeCharts/PieChart/TenantsIncome';
import PaymentCard from './PaymentCard';
import Tasks from './Tasks';
import PrivateAccess from '../../components/PrivateAccess';
import { userRole } from '../../constants/userStatus';
import { getTenantPaymentListAction, getDashboardPaymentsAction } from '../../modules/PaymentPage/store/actions';
import { newPaymentListSelector, dashboardPayments } from '../../modules/PaymentPage/store/selectors';

const Home = () => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const newPaymentList = useSelector(newPaymentListSelector);
  const landlordPayment = useSelector(dashboardPayments);

  useEffect(() => {
    if (user?.role === 'TENANT') {
      dispatch(getTenantPaymentListAction({ status: 'NEW,OVERDUE' }));
    } else if (user?.role === 'LANDLORD') {
      dispatch(getDashboardPaymentsAction());
    }
  }, [user]);

  return (
    <div>
      <div className={styles.userInfo}>
        <H4>{`Welcome ${user?.fullName}!`}</H4>
        <B3>Let's start managing your rental properties</B3>
      </div>
      <PrivateAccess roles={[userRole.LANDLOARD]}>
        {landlordPayment && landlordPayment.length
          ? landlordPayment.map((payment: any) => <PaymentCard key={payment._id} payment={payment} role={user?.role} />)
          : ''}
      </PrivateAccess>
      <PrivateAccess roles={[userRole.TENANT]}>
        {newPaymentList.items.length
          ? newPaymentList.items.map((payment: any) => <PaymentCard key={payment._id} payment={payment} role={user?.role} />)
          : ''}
      </PrivateAccess>
      <div className={styles.chartContent}>
        <PrivateAccess roles={[userRole.LANDLOARD, userRole.MANAGER]}>
          <RentalListings title="Rental Listings" />
        </PrivateAccess>
        <PrivateAccess roles={[userRole.TENANT]}>
          <Maintenance title="Maintenance" />
        </PrivateAccess>
        <ExpiringLeases title="Expiring Leases" />
      </div>
      <PrivateAccess roles={[userRole.LANDLOARD, userRole.MANAGER]}>
        <Tasks />
        <div className={styles.chartContent}>
          <RentalApplication title="Rental Application" />
          <Maintenance title="Maintenance" />
        </div>
        <div>
          <TenantsIncome title="Income From Tenants" />
        </div>
      </PrivateAccess>
    </div>
  );
};

export default Home;
