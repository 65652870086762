import React, { type ReactNode } from 'react';

import cn from 'classnames';

import styles from './Content.module.scss';

interface ContentProps {
  className?: string;
  children: ReactNode;
}
const Content = ({ children, className }: ContentProps) => {
  return <div className={cn(styles.content, className)}>{children}</div>;
};
export default Content;
