import request from '../../../services/request';

const END_POINTS = {
  getTenantPayments: 'api/payment',
  getById: 'api/payment',
  getMethods: 'api/payment/payment-method',
  deleteMethods: 'api/payment/detach-payment-method',
  setMethods: 'api/payment/default-payment-method',
  confirm: 'api/payment/confirm/',
  markAsPaid: 'api/payment/mark-paid/',
  returnDeposit: 'api/payment/return-deposit/',
  getDepositByTenant: 'api/payment/deposit/',
  dashboardDeposits: 'api/payment/return-deposit',
  notifyAboutPayment: 'api/payment/notify',
};

export function getTenantPaymentService(payload: any) {
  return request({
    url:
      `${END_POINTS.getTenantPayments}?status=${payload?.status || ''}` +
      (payload.tenant ? `&tenant=${payload.tenant}` : '') +
      `&page=${payload?.page || ''}&limit=${payload?.size || ''}`,
  });
}
export function getPaymentByIdService(payload: any) {
  return request({
    url: `${END_POINTS.getById}/${payload}`,
  });
}
export function getPaymentMethodsService() {
  return request({
    url: `${END_POINTS.getMethods}`,
  });
}
export function deletePaymentMethodsService(payload: any) {
  return request({
    method: 'PATCH',
    url: `${END_POINTS.deleteMethods}`,
    data: {
      paymentMethod: payload,
    },
  });
}
export function setPaymentMethodsService(payload: any) {
  return request({
    method: 'PATCH',
    url: `${END_POINTS.setMethods}`,
    data: {
      paymentMethod: payload.id,
      switcher: true,
    },
  });
}
export function confirmPaymentService(payload: any) {
  return request({
    url: `${END_POINTS.confirm}${payload}`,
    method: 'PATCH',
  });
}

export function markAsPaidService(payload: any) {
  return request({
    url: `${END_POINTS.markAsPaid}${payload.paymentId}`,
    method: 'PUT',
  });
}

export function returnDepositService(payload: any) {
  return request({
    url: `${END_POINTS.returnDeposit}${payload.paymentId}`,
    method: 'PATCH',
  });
}

export function getDepositByTenantService(payload: any) {
  return request({
    url: `${END_POINTS.getDepositByTenant}${payload}`,
  });
}

export async function getDashboardPaymentsService() {
  return request({
    method: 'GET',
    url: `${END_POINTS.dashboardDeposits}`,
  });
}

export async function notifyAboutPaymentService(payload: any) {
  return request({
    method: 'POST',
    url: `${END_POINTS.notifyAboutPayment}`,
    data: payload,
  });
}
