import * as Yup from 'yup';

export const propertySchema = Yup.object().shape({
  propertyName: Yup.string().required('Required field').max(128, 'Property name is too long. Maximum length is 128 characters.'),
  propertyType: Yup.object().required('Required field'),
  address: Yup.object().required('Required field'),
  imageUrls: Yup.array(),
  city: Yup.string().required('Required field'),
  state: Yup.string().required('Required field'),
  zipCode: Yup.string().required('Required field'),
  country: Yup.object().required('Required field'),
  payoutBankAccount: Yup.object().required('Required field'),
  petWeight: Yup.string().when(['hasPets'], {
    is: (hasPets: string) => hasPets === 'Yes',
    then: schema => schema.required('Required field'),
  }),
  petDescription: Yup.string().when(['hasPets'], {
    is: (hasPets: string) => hasPets === 'Yes',
    then: schema => schema.max(50, 'No more than 50 characters'),
  }),
  managerEmail: Yup.array(),
  units: Yup.object().when([], (value, schema, form: any) => {
    const newSchema: any = {};
    if (form.key === 'units') {
      const originalValue = Object.keys(form.originalValue);
      originalValue.forEach((value: string) => {
        if (value.includes('status') || value.includes('unitType')) {
          newSchema[value] = Yup.object().required('Required field');
        } else {
          newSchema[value] = Yup.string().required('Required field');
        }
      });
    }
    return schema.shape({
      ...newSchema,
    });
  }),
});
