import { useMemo, useState } from 'react';

import { Chip } from '@mui/material';
import cn from 'classnames';
import useDebouncedCallback from 'common/utils/utils';
import Autocomplete from 'components/shared/FormElements/Autocomplete/Autocomplete';
import { B3 } from 'components/Typography/Body';
import { H5 } from 'components/Typography/Headlines';
import { getItems } from 'modules/Maintenance/store/actions';
import createPropertiesSlice from 'modules/Properties/CreateProperty/store/createPropertiesSlice';
import { createPropertyLoadingSelector } from 'modules/Properties/CreateProperty/store/selectors';
import { type Item } from 'modules/Properties/CreateProperty/store/types';
import { Controller, type UseFormReturn, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import styles from './Categories.module.scss';

interface ItemSelectorProps {
  form: UseFormReturn<any>;
  title: string;
  description: string;
  label: string;
  name: 'category';
  items: Item[];
  options: Item[];
}
const Categories = ({ form, title, description, items, name, label, options }: ItemSelectorProps) => {
  const loading = useSelector(createPropertyLoadingSelector);
  const [inputValue, setInputValue] = useState('');
  const { setValue, control } = form;
  const formItems = useWatch({ control, name });
  const optionItems = useWatch({ control, name: `${name}` });
  const dispatch = useDispatch();
  const handleItemClick = (item: Item) => {
    const hasItem = formItems.find((name: any) => name === item.name);
    if (hasItem) {
      setValue(name, [...formItems.filter((el: any) => el !== hasItem)], { shouldDirty: true });
    } else {
      setValue(name, [...formItems, item.name], { shouldDirty: true });
    }
  };

  const handleSearch = useDebouncedCallback(
    (val: string) => {
      if (val.length) {
        dispatch(getItems({ type: 'EXTRA', name: name, value: val }));
      } else {
        dispatch(createPropertiesSlice.actions.clearItems(name));
      }
    },
    400,
    []
  );

  const handleValueChange = useDebouncedCallback(
    (val: string) => {
      setInputValue(val);
    },
    500,
    []
  );

  const filteredOptions = useMemo(() => {
    const newOptions = options.filter(item => {
      return !optionItems.map((item: any) => item._id).includes(item._id);
    });
    return newOptions.map(el => ({ ...el, label: el.name }));
  }, [options, optionItems]);

  return (
    <div className={styles.itemSelector}>
      <H5 className={styles.title}>{title}</H5>
      <B3 className={styles.desc}>{description}</B3>
      <div className={styles.items}>
        {items.map(item => (
          <Chip
            className={cn(styles.item, { [styles.active]: formItems?.includes(item._id) })}
            label={item.name}
            key={item._id}
            onClick={() => handleItemClick(item)}
          />
        ))}
      </div>
      <Controller
        name={`${name}`}
        control={control}
        render={({ field }) => (
          <Autocomplete
            isMultiple
            loading={loading}
            label={label}
            multiline
            options={filteredOptions}
            {...field}
            inputValue={inputValue}
            setInputValue={setInputValue}
            onAutocompleteChange={(e: any, deletedValues: any) => {
              if (deletedValues) {
                setValue(
                  name,
                  formItems.filter((el: any) => el !== deletedValues._id)
                );
              }
              setInputValue('');
              dispatch(createPropertiesSlice.actions.clearItems(name));
            }}
            onInputChange={(e: unknown, newInputValue: string) => {
              handleSearch(newInputValue);
              handleValueChange(newInputValue);
            }}
            value={field.value || []}
          />
        )}
      />
    </div>
  );
};
export default Categories;
