import cn from 'classnames';
import { formatDate } from 'common/utils/utils';
import SvgIcon from 'components/shared/utils/SvgIcon';
import { B3 } from 'components/Typography/Body';
import { regexp } from 'constants/regexp';
import { personalInformationDataSelector } from 'modules/Application/store/selectors';
import { type PersonalInformationDataModel } from 'modules/types/applicationTypes';
import { useSelector } from 'react-redux';

import styles from './PersonalInformationDetails.module.scss';

const PersonalInformationDetails = () => {
  const personalInformationData: PersonalInformationDataModel | null | undefined = useSelector(personalInformationDataSelector);

  return (
    <div className={styles.container}>
      <div className={styles.personInfo}>
        <B3 className={styles.title}>
          <SvgIcon icon={'profile'} className={styles.profile} />
          Full name
        </B3>
        <B3 className={styles.value}>{`${personalInformationData?.firstName ? personalInformationData?.firstName : ''} ${
          personalInformationData?.middleName ? personalInformationData?.middleName : ''
        } ${personalInformationData?.lastName ? personalInformationData.lastName : ''}`}</B3>
      </div>
      <div className={`${styles.personInfo} ${styles.personMailInfo}`}>
        <B3 className={styles.title}>
          <SvgIcon icon={'email'} className={`${styles.profile} ${styles.iconLight}`} />
          Email Address
        </B3>
        <B3 className={styles.value}>{personalInformationData?.email ? personalInformationData.email : ''}</B3>
      </div>
      <div className={styles.personInfo}>
        <B3 className={styles.title}>
          <SvgIcon icon={'phone'} className={cn(styles.profile, styles.iconLight)} />
          Phone Number
        </B3>
        <B3 className={styles.value}>
          {personalInformationData?.phones &&
            personalInformationData?.phones[0]?.type &&
            `${(personalInformationData?.phones[0]?.type as string).toLocaleLowerCase()}: ${regexp.formatPhoneNumber(
              personalInformationData?.phones[0].number
            )}`}
        </B3>
      </div>
      {personalInformationData?.phones && personalInformationData?.phones?.length > 1 && (
        <div className={styles.personInfo}>
          <B3 className={styles.title}>
            <SvgIcon icon={'phone'} className={cn(styles.profile, styles.iconLight)} />
            Phone Number 2
          </B3>
          <B3 className={styles.value}>
            {personalInformationData?.phones?.length &&
              personalInformationData?.phones[1]?.type &&
              `${(personalInformationData?.phones[1]?.type as string).toLocaleLowerCase()}: ${regexp.formatPhoneNumber(
                personalInformationData?.phones[1].number
              )}`}
          </B3>
        </div>
      )}
      <div className={styles.personInfo}>
        <B3 className={styles.title}>
          <SvgIcon icon={'calendar'} className={styles.profile} />
          Date of birth
        </B3>
        <B3 className={styles.value}>
          {personalInformationData?.dateOfBirth && formatDate(personalInformationData?.dateOfBirth)}
        </B3>
      </div>
      <div className={styles.personInfo}>
        <B3 className={styles.title}>
          <SvgIcon icon={'list'} className={cn(styles.profile, styles.iconLight)} />
          Social security number
        </B3>
        <B3 className={styles.value}>{personalInformationData?.SSN}</B3>
      </div>
    </div>
  );
};

export default PersonalInformationDetails;
