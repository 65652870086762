import Accordion from 'components/shared/Accordion';
import { B3 } from 'components/Typography/Body';

import styles from './CriminalDetails.module.scss';

const CriminalDetails = () => {
  const numbers = ['9097RF6773IKL', '998UI6745FG098', '092332444ER5VF787'];
  return (
    <div>
      {numbers.map(number => (
        <div key={number} className={styles.accordion}>
          <Accordion title="JACKFIRST DEWMID BECLAST">
            <div className={styles.container}>
              <div className={styles.info}>
                <B3 className={styles.title}>Name</B3>
                <B3 className={styles.value}>JACKFIRST DEWMID BECLAST</B3>
              </div>
              <div className={styles.info}>
                <B3 className={styles.title}>Address</B3>
                <B3 className={styles.value}>123 Adress 1</B3>
              </div>
              <div className={styles.info}>
                <B3 className={styles.title}>Age</B3>
                <B3 className={styles.value}>50</B3>
              </div>
              <div className={styles.info}>
                <B3 className={styles.title}>Date Of Birth</B3>
                <B3 className={styles.value}>1970 XXXXX</B3>
              </div>
              <div className={styles.info}>
                <B3 className={styles.title}>Sex</B3>
                <B3 className={styles.value}>M</B3>
              </div>
              <div className={styles.info}>
                <B3 className={styles.title}>Height</B3>
                <B3 className={styles.value}>604</B3>
              </div>
              <div className={styles.info}>
                <B3 className={styles.title}>Width</B3>
                <B3 className={styles.value}>240</B3>
              </div>
              <div className={styles.info}>
                <B3 className={styles.title}>Race</B3>
                <B3 className={styles.value}>CAU</B3>
              </div>
              <div className={styles.info}>
                <B3 className={styles.title}>Eye color</B3>
                <B3 className={styles.value}>BLU</B3>
              </div>
              <div className={styles.info}>
                <B3 className={styles.title}>Hair color</B3>
                <B3 className={styles.value}>BRO</B3>
              </div>
              <div className={styles.info}>
                <B3 bold className={styles.title}>
                  COURT ACTION
                </B3>
              </div>
              <div className={styles.info}>
                <B3 className={styles.title}>Activity type</B3>
                <B3 className={styles.value}>cTYPE</B3>
              </div>
              <div className={styles.info}>
                <B3 className={styles.title}>Court Record ID</B3>
                <B3 className={styles.value}>9089878777</B3>
              </div>
              <div className={styles.info}>
                <B3 className={styles.title}>Filling Agency Name</B3>
                <B3 className={styles.value}>cFillingAgency</B3>
              </div>
              <div className={styles.info}>
                <B3 className={styles.title}>Filling Date</B3>
                <B3 className={styles.value}>01/30/2013</B3>
              </div>
              <div className={styles.info}>
                <B3 className={styles.title}>Jurisdiction Description</B3>
                <B3 className={styles.value}>cJurisdiction</B3>
              </div>
              <div className={styles.info}>
                <B3 bold className={styles.title}>
                  COURT CHARGE
                </B3>
              </div>
              <div className={styles.info}>
                <B3 className={styles.title}>Effective Date</B3>
                <B3 className={styles.value}>01/30/2013</B3>
              </div>
              <div className={styles.info}>
                <B3 className={styles.title}>Charge Sequence ID</B3>
                <B3 className={styles.value}>1</B3>
              </div>
              <div className={styles.info}>
                <B3 className={styles.title}>Number Of Counts</B3>
                <B3 className={styles.value}>2</B3>
              </div>
              <div className={styles.info}>
                <B3 className={styles.title}>Charge Description</B3>
                <B3 className={styles.value}>cDescription</B3>
              </div>
              <div className={styles.info}>
                <B3 bold className={styles.title}>
                  CHARGE CLASIFICATION
                </B3>
              </div>
              <div className={styles.info}>
                <B3 className={styles.title}>Charge Degree</B3>
                <B3 className={styles.value}>oDegree</B3>
              </div>
              <div className={styles.info}>
                <B3 bold className={styles.title}>
                  CHARGE DISPOSITION
                </B3>
              </div>
              <div className={styles.info}>
                <B3 className={styles.title}>Charge Disposition Additional Information</B3>
                <B3 className={styles.value}>oDISPOSITION_STATUS</B3>
              </div>
              <div className={styles.info}>
                <B3 className={styles.title}>Charge Disposition Date</B3>
                <B3 className={styles.value}>01/30/2013</B3>
              </div>
              <div className={styles.info}>
                <B3 className={styles.title}>Disposition</B3>
                <B3 className={styles.value}>oDisposition Description</B3>
              </div>
              <div className={styles.info}>
                <B3 bold className={styles.title}>
                  CHARGE STATUS
                </B3>
              </div>
              <div className={styles.info}>
                <B3 className={styles.title}>Charge Date</B3>
                <B3 className={styles.value}>oDisposition Description</B3>
              </div>
              <div className={styles.info}>
                <B3 className={styles.title}>Expiration Date</B3>
                <B3 className={styles.value}>oDisposition Description</B3>
              </div>
              <div className={styles.info}>
                <B3 bold className={styles.title}>
                  CHARGE STATUTE
                </B3>
              </div>
              <div className={styles.info}>
                <B3 className={styles.title}>Statute Code ID</B3>
                <B3 className={styles.value}>989897868667</B3>
              </div>
              <div className={styles.info}>
                <B3 className={styles.title}>Level</B3>
                <B3 className={styles.value}>oLevel</B3>
              </div>
              <div className={styles.info}>
                <B3 bold className={styles.title}>
                  OFFENCE
                </B3>
              </div>
              <div className={styles.info}>
                <B3 className={styles.title}>Offence Type Description</B3>
                <B3 className={styles.value}>oDegree</B3>
              </div>
              <div className={styles.info}>
                <B3 bold className={styles.title}>
                  RECORD INFORMATION
                </B3>
              </div>
            </div>
          </Accordion>
        </div>
      ))}
    </div>
  );
};

export default CriminalDetails;
