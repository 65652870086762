import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Controller } from 'react-hook-form';

import styles from './Inshurance.module.scss';
import { B1, B2 } from '../../../../components/Typography/Body';
import { H5 } from '../../../../components/Typography/Headlines';

interface InshuranceProps {
  form: any;
  isValidStatus?: boolean;
}

const Inshurance = ({ form, isValidStatus = true }: InshuranceProps) => {
  const { control } = form;
  return (
    <div className={styles.insurance}>
      <H5>Insurance</H5>
      <B1 bold className={styles.desc}>
        Require tenant(a) to purchase renters insurance
      </B1>
      <FormControl component="fieldset" className={styles.detectInformation} disabled={!isValidStatus}>
        <Controller
          control={control}
          name="insurance"
          render={({ field }) => (
            <RadioGroup {...field} className={styles.group}>
              <FormControlLabel className={styles.radio} value="YES" control={<Radio color={'warning'} />} label="Yes" />
              <FormControlLabel className={styles.radio} value="NO" control={<Radio color={'warning'} />} label="No" />
            </RadioGroup>
          )}
        />
      </FormControl>
      <B2 className={styles.description}>
        Interested party as an interested party, you will be informed of tenant(s) coverages and in the event a claim is filed.
        Please provide the best contact information
      </B2>
    </div>
  );
};

export default Inshurance;
