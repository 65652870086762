/* eslint-disable max-len */
import { useEffect, useMemo } from 'react';

import { Box } from '@mui/material';
import { formatDate } from 'common/utils/utils';
import Autocomplete from 'components/shared/FormElements/Autocomplete';
import Loading from 'components/shared/Loading';
import StatusTag from 'components/shared/StatusTag';
import TableCard from 'components/shared/TableCard';
import { B1, B2 } from 'components/Typography/Body';
import { H3 } from 'components/Typography/Headlines';
import { colors, userRole } from 'constants/userStatus';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ApplicantCard from './ApplicantCard';
import styles from './Applicants.module.scss';
import { applicantStatus, statusValue } from './constants';
import { getApplicantList } from './store/actions';
import { applicantDataSelector, applicantLoadingSelector, applicantQueryOptionsSelector } from './store/selector';
import PrivateAccess from '../../components/PrivateAccess';

const Applicants = () => {
  const dispatch = useDispatch();
  const loading = useSelector(applicantLoadingSelector);
  const applicantData = useSelector(applicantDataSelector);
  const { page, size } = useSelector(applicantQueryOptionsSelector);
  const navigate = useNavigate();
  const { control, getValues } = useForm();

  const memoColumns = useMemo(() => {
    return [
      {
        Header: 'Applicant',
        id: 'applicant',
        title: 'Applicant',
        Cell: ({ row }: any) => {
          const firstName = row.original?.personalInformation?.firstName;
          const lastName = row.original?.personalInformation?.lastName;
          const middleName = row.original?.personalInformation?.middleName;
          return (
            <div className={styles.applicant} style={colors[row.original?.status]}>
              <B1 bold className={styles.firstName}>
                {`${firstName ? firstName : ''} ${middleName ? middleName : ''} ${lastName ? lastName : ''}`}
              </B1>
            </div>
          );
        },
      },
      {
        Header: 'Date',
        id: 'data',
        title: 'Date',
        Cell: ({ row }: any) => {
          return (
            <div>
              <B2 bold className={styles.availableFrom}>
                {formatDate(row.original?.coApplicants?.moveIn)}
              </B2>
            </div>
          );
        },
      },
      {
        Header: 'Listing',
        id: 'listing',
        title: 'Listing',
        Cell: ({ row }: any) => {
          return (
            <div>
              <B2>{row.original?.listing?.title}</B2>
            </div>
          );
        },
      },
      {
        Header: 'Status',
        id: 'status',
        title: 'Status',
        Cell: ({ row }: any) => {
          return (
            <div>
              <StatusTag type={row.original?.status} label={row.original?.status} size={'medium'} styles={{ minWidth: '50px' }} />
            </div>
          );
        },
      },
    ];
  }, [applicantData]);

  useEffect(() => {
    dispatch(getApplicantList({ limit: size, page: page }));
  }, []);

  return (
    <PrivateAccess roles={[userRole.LANDLOARD, userRole.MANAGER]} isPage>
      <div className={styles.applicants}>
        <div className={styles.titleContent}>
          <H3 className={styles.title}>Applicants</H3>
          <div className={styles.incompleteButton}>
            <Box>
              <Controller
                name={'status'}
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    label="Status"
                    autoComplete={'off'}
                    options={applicantStatus}
                    {...field}
                    onInputChange={(e: unknown, newInputValue: string) => {
                      dispatch(getApplicantList({ limit: size, page: page, status: statusValue[newInputValue] }));
                    }}
                  />
                )}
              />
            </Box>
          </div>
        </div>
        {applicantData?.items?.length ? (
          <div>
            {applicantData.items.map(applicant => (
              <ApplicantCard key={applicant._id} applicant={applicant} />
            ))}
            <TableCard
              className={styles.applicantTabel}
              columns={memoColumns}
              data={applicantData?.items || []}
              defaultPageSize={size}
              totalItems={+applicantData.count}
              defaultPage={Number(page)}
              setPageSize={size => {
                // eslint-disable-next-line no-unsafe-optional-chaining
                const value = getValues()?.status?.value;
                return dispatch(getApplicantList({ limit: size, page: 1, ...(value && { status: value }) }));
              }}
              onRowClick={(row: any) => {
                return navigate(`${row?.original?.listing?._id}?id=${row.original?._id}`);
              }}
              fetchData={({ pageSize, pageIndex }) => {
                const value = getValues()?.status?.value;
                // eslint-disable-next-line no-unsafe-optional-chaining
                return dispatch(getApplicantList({ page: pageIndex, limit: `${pageSize}`, ...(value && { status: value }) }));
              }}
            />
          </div>
        ) : (
          !loading && (
            <div className={styles.emptyContainer}>
              <B1>No Applicants Found</B1>
              <B2>You're yet to receive any applications</B2>
            </div>
          )
        )}
        {loading && <Loading isBig />}
      </div>
    </PrivateAccess>
  );
};

export default Applicants;
