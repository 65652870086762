import { createAction } from '@reduxjs/toolkit';

import { type IForm, type IQueryOptions } from '../types';

export const getLeaseAction = createAction<IQueryOptions>('getLeaseAction');
export const getLeaseByIdAction = createAction<string>('getLeaseByIdAction');
export const createLeaseAction = createAction<{ data: IForm; navigate: (to: string) => void }>('createLeaseAction');
export const sentToSignLeaseAction = createAction<{ data: IForm; navigate: (to: string) => void }>('sentToSignLeaseAction');
export const updateLeaseAction = createAction<{ data: IForm; navigate: (to: string) => void; id: string; dirtyFields: any }>(
  'updateLeaseAction'
);
export const getLeaseByUnitIdAction = createAction<string>('getLeaseByUnitIdAction');
