import { type ReactNode, type FC } from 'react';

import { Typography, type TypographyProps } from '@mui/material';

interface IHeadlineParameters extends TypographyProps {
  children: ReactNode;
  bold?: boolean;
}

export const H1: FC<IHeadlineParameters> = ({ children, bold = false, ...rest }) => (
  <Typography variant="h1" fontFamily={bold ? 'montserrat-bold' : 'montserrat-black'} fontSize={56} lineHeight={'64px'} {...rest}>
    {children}
  </Typography>
);

export const H2: FC<IHeadlineParameters> = ({ children, bold = false, ...rest }) => (
  <Typography variant="h2" fontFamily={bold ? 'montserrat-bold' : 'montserrat-black'} fontSize={48} lineHeight={'56px'} {...rest}>
    {children}
  </Typography>
);

export const H3: FC<IHeadlineParameters> = ({ children, bold = false, ...rest }) => (
  <Typography variant="h3" fontFamily={bold ? 'montserrat-bold' : 'montserrat-black'} fontSize={36} lineHeight={'44px'} {...rest}>
    {children}
  </Typography>
);

export const H4: FC<IHeadlineParameters> = ({ children, bold = false, ...rest }) => (
  <Typography variant="h4" fontFamily={bold ? 'montserrat-bold' : 'montserrat-black'} fontSize={28} lineHeight={'36px'} {...rest}>
    {children}
  </Typography>
);

export const H5: FC<IHeadlineParameters> = ({ children, bold = false, ...rest }) => (
  <Typography variant="h5" fontFamily={bold ? 'montserrat-bold' : 'montserrat-black'} fontSize={20} lineHeight={'28px'} {...rest}>
    {children}
  </Typography>
);
