import { useCallback, useEffect } from 'react';

import { Box, Modal } from '@mui/material';
import dayjs from 'dayjs';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { paymentMethods } from './constants';
import styles from './MarkAsPaidModal.module.scss';
import Button from '../../components/shared/Button';
import { Input } from '../../components/shared/FormElements';
import Autocomplete from '../../components/shared/FormElements/Autocomplete/Autocomplete';
import DatePicker from '../../components/shared/FormElements/DatePicker/DatePicker';
import SvgIcon from '../../components/shared/utils/SvgIcon';
import { H5 } from '../../components/Typography/Headlines';
import modalNames from '../../constants/modalNames';
import { regexp } from '../../constants/regexp';
import { markAsPaidAction, returnDepositAction } from '../../modules/PaymentPage/store/actions';
import { type RootState } from '../../store/setupStore';
import { modalsSlice } from '../index';
import { checkModalVisibility } from '../store/selectors';

const MarkAsPaidModal = ({ amount, paymentId, type }: any) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      paidDate: dayjs(new Date()),
      amountPaid: '',
      method: 'CASH',
    },
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isModalVisible = useSelector((store: RootState) => checkModalVisibility(store, modalNames.MARK_AS_PAID));

  useEffect(() => {
    if (amount) {
      setValue('amountPaid', amount);
    }
  });
  const handleModalClose = useCallback(() => {
    reset();
    dispatch(modalsSlice.actions.hideModal(modalNames.MARK_AS_PAID));
  }, [dispatch]);

  const onSubmit = () => {
    if (type === 'DEPOSIT') {
      dispatch(returnDepositAction({ paymentId, navigate }));
    } else {
      dispatch(markAsPaidAction({ paymentId, navigate }));
    }
    handleModalClose();
  };

  return (
    <Modal open={isModalVisible} onClose={handleModalClose} className={styles.modal}>
      <div className={styles.markAsPaidModalModal}>
        <header className={styles.header}>
          <H5 bold>{type === 'DEPOSIT' ? 'Return Deposit' : 'Mark as Paid'}</H5>
          <SvgIcon icon="close" className={styles.close} onClick={handleModalClose} />
        </header>
        <form className={styles.content}>
          <Controller
            name="paidDate"
            control={control}
            render={({ field }) => (
              <DatePicker
                className={styles.calendar}
                disabled={true}
                label="Date Of Paid*"
                {...field}
                errorMessage={errors?.paidDate?.message}
                hasError={!!errors.paidDate}
              />
            )}
          />
          <Controller
            name="amountPaid"
            control={control}
            render={({ field }) => (
              <Input
                label="Amount Paid*"
                errorMessage={errors.amountPaid?.message}
                hasError={!!errors.amountPaid}
                regexp={regexp.number}
                placeholder="Enter amount paid"
                disabled={true}
                {...field}
              />
            )}
          />
          <Controller
            name="method"
            control={control}
            render={({ field }) => (
              <Autocomplete
                autoComplete={'off'}
                errorMessage={errors.method?.message}
                hasError={!!errors.method}
                label={'Method*'}
                placeholder={'Enter payment method'}
                disabled={true}
                options={paymentMethods}
                {...field}
              />
            )}
          />
          <Box className={styles.buttons}>
            <Button variant="outlined" size="ds" type="button" label="Cancel" onClick={handleModalClose} />
            <Button variant="contained" size="ds" type="button" label="Confirm" onClick={handleSubmit(onSubmit)} />
          </Box>
        </form>
      </div>
    </Modal>
  );
};

export default MarkAsPaidModal;
