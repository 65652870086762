import toast from 'react-hot-toast';
import { call, type ForkEffect, put, takeLatest, select } from 'redux-saga/effects';

import * as actions from './actions';
import notificationsSlice from './notificationsSlice';
import { notificationsQueryOptionsSelector } from './selectors';
import { type RootState } from '../../../store/setupStore';
import * as notificationsService from '../notificationsService';

function* getNotificationsSaga({ payload }: ReturnType<typeof actions.getNotificationsAction>) {
  yield put(notificationsSlice.actions.setLoading(true));
  try {
    const { data } = yield call(notificationsService.getNotifications, payload);

    yield put(notificationsSlice.actions.setQueryOptions({ page: payload.page, size: payload.size }));
    yield put(notificationsSlice.actions.getNotificationsSuccessAction(data));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(notificationsSlice.actions.setLoading(false));
  }
}

function* updateNotificationStatusSaga({ payload }: ReturnType<typeof actions.updateNotificationStatusAction>) {
  yield put(notificationsSlice.actions.setLoading(true));
  const notificationsQueryOptions: RootState = yield select(notificationsQueryOptionsSelector);
  try {
    const { data } = yield call(notificationsService.updateNotificationStatus, { ...payload, ...notificationsQueryOptions });

    yield put(notificationsSlice.actions.getNotificationsSuccessAction(data));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(notificationsSlice.actions.setLoading(false));
  }
}

function* archiveNotificationSaga({ payload }: ReturnType<typeof actions.archiveNotificationAction>) {
  yield put(notificationsSlice.actions.setLoading(false));
  const notificationsQueryOptions: RootState = yield select(notificationsQueryOptionsSelector);
  try {
    const { data } = yield call(notificationsService.archiveNotification, { ...payload, ...notificationsQueryOptions });

    yield put(notificationsSlice.actions.getNotificationsSuccessAction(data));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(notificationsSlice.actions.setLoading(false));
  }
}

function* isFlaggedNotificationSaga({ payload }: ReturnType<typeof actions.isFlaggedNotificationAction>) {
  yield put(notificationsSlice.actions.setLoading(false));
  const notificationsQueryOptions: RootState = yield select(notificationsQueryOptionsSelector);
  try {
    const { data } = yield call(notificationsService.isFlaggedNotification, { ...payload, ...notificationsQueryOptions });

    yield put(notificationsSlice.actions.getNotificationsSuccessAction(data));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(notificationsSlice.actions.setLoading(false));
  }
}

export function* watchNotificationsSaga(): Generator<ForkEffect> {
  yield takeLatest(actions.getNotificationsAction.type, getNotificationsSaga);
  yield takeLatest(actions.updateNotificationStatusAction.type, updateNotificationStatusSaga);
  yield takeLatest(actions.archiveNotificationAction.type, archiveNotificationSaga);
  yield takeLatest(actions.isFlaggedNotificationAction.type, isFlaggedNotificationSaga);
}
