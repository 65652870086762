export const propertyDetailsDefaultValues = {
  propertyName: '',
  propertyType: null,
  state: '',
  zipCode: '',
  city: '',
  country: null,
  address1: '',
};
export const unitDefaultValues = {
  unitNumber: '',
  bedrooms: '',
  unitSize: '',
  bathrooms: '',
  parkingSpot: '',
  monthlyRent: '',
  rentDeposit: '',
  unitType: null,
  status: null,
  units: {},
};
export const otherInformationDefaultValues = {
  hasPets: 'Yes',
  isRentersInsurance: 'Yes',
  petWeight: '',
  petDescription: '',
  laundry: 'NONE',
  managerEmail: [],
  selectUnitType: 'SELECT_UNIT',
  featureItems: [],
  feature: [],
  amenityItems: [],
  amenity: [],
  utilityItems: [],
  utility: [],
  owners: [],
  payoutBankAccount: null,
};
export const propertyTypeOptions = [
  { label: ' Condo/Townhome', value: 'CONDO/TOWNHOME' },
  { label: 'Multi-Family', value: 'MULTI_FAMILY' },
  { label: 'Single-Family', value: 'SINGLE_FAMILY' },
  { label: 'Industrial', value: 'INDUSTRIAL' },
  { label: 'Office', value: 'OFFICE' },
  { label: 'Retail', value: 'RETAIL' },
  { label: 'Shopping center', value: 'SHOPPING_CENTER' },
  { label: 'Storage', value: 'STORAGE' },
  { label: 'Parking space', value: 'PARKING_SPACE' },
];

export const propertyAddressName = {
  address: { name: 'address', label: 'Address*' },
  country: { name: 'country', label: 'Country*' },
  address1: { name: 'address1', label: 'Address Name 2' },
  state: { name: 'state', label: 'State/Provance/Region*' },
  zipCode: { name: 'zipCode', label: 'Zip/Postal Cod*' },
  city: { name: 'city', label: ' City*' },
};

export const statusOptions = [
  { label: 'Occupied', value: 'Occupied' },
  { label: 'Vacant', value: 'Vacant' },
];
export const unitTypeOptions = [
  { label: 'Studio', value: 'Studio' },
  { label: 'A single-room unit', value: 'A single-room unit' },
  { label: 'Two-Bedroom', value: 'Two-Bedroom' },
  { label: 'Three-Bedroom', value: 'Three-Bedroom' },
  { label: 'Penthouse', value: 'Penthouse' },
  { label: 'Loft', value: 'Loft' },
  { label: 'Duplex', value: 'Duplex' },
  { label: 'Apartment', value: 'Apartment' },
  { label: 'Micro-Apartment', value: 'Micro-Apartment' },
  { label: 'Basement Unit', value: 'Basement Unit' },
  { label: 'Garden Unit', value: 'Garden Unit' },
  { label: 'Alcove', value: 'Alcove' },
  { label: 'Junior Suite', value: 'Junior Suite' },
  { label: 'Senior Living Unit', value: 'Senior Living Unit' },
  { label: 'Corporate Housing', value: 'Corporate Housing' },
];

export const unitTypeContent = {
  first: {
    name: 'SELECT_UNIT',
    title: 'Single unit',
    description: 'Add information about your property',
    icon: 'properties',
  },
  second: {
    name: 'MULTIPLE_UNIT',
    title: 'Multiple Unit',
    description: 'Choose this to add details of multiple units',
    icon: 'tenants',
  },
};

export const utilitiesList = [
  { isLendloard: false, laundry: { label: 'Electricity/Hydro' } },
  { isLendloard: false, laundry: { label: 'Heat' } },
  { isLendloard: false, laundry: { label: 'Water' } },
];
