import { type FC, memo, type ReactNode } from 'react';

import cx from 'classnames';

import styles from './SideBarContent.module.scss';

export interface Props {
  className?: string;
  children: ReactNode;
}

const SideBarContent: FC<Props> = ({ className, children }) => (
  <div className={cx(styles.sidebarContent, className)}>{children}</div>
);

export default Object.assign(memo(SideBarContent), { displayName: 'SideBarContent' });
