/* eslint-disable jsx-a11y/no-static-element-interactions */
import { forwardRef, useEffect } from 'react';

import { Box } from '@mui/material';
import cn from 'classnames';
import Button from 'components/shared/Button/Button';
import { Input } from 'components/shared/FormElements';
import { B2, B3 } from 'components/Typography/Body';
import { H4 } from 'components/Typography/Headlines';
import { applicationLoadingSelector } from 'modules/Application/store/selectors';
import { type IForm } from 'modules/Application/types';
import { userSelector } from 'modules/Auth/store/selectors';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

import styles from './ApplicationSignUp.module.scss';
import ConfirmEmail from './ConfirmEmail/ConfirmEmail';
import Checkbox from '../../../../../components/shared/FormElements/Checkbox';

interface ApplicationSignUpProps {
  signIn: (value: any) => void;
}

const ApplicationSignUp = ({ signIn }: ApplicationSignUpProps) => {
  const [searchParams] = useSearchParams();
  const applicationLoading = useSelector(applicationLoadingSelector);
  const user = useSelector(userSelector);
  const inviteToken = searchParams.get('token');
  const passwordToken = localStorage.getItem('passwordToken');
  const registeredUser = localStorage.getItem('registeredUser') && JSON.parse(localStorage.getItem('registeredUser') as string);

  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<IForm>();

  useEffect(() => {
    localStorage.removeItem('registeredUser');
    setValue('signUp.email', (user && user.email) || localStorage.getItem('email') || '', { shouldValidate: true });
  }, [user]);

  return (
    <div className={styles.container}>
      <H4 className={styles.title} color="var(--additional-grey-1)">
        Sign Up
      </H4>
      {!passwordToken && (
        <B3 className={styles.desc}>
          Already a member?
          <button className={cn(styles.signIn, { [styles.disabled]: passwordToken })} onClick={() => signIn(false)}>
            Sign In
          </button>
        </B3>
      )}
      <Box className={styles.inputBox}>
        <Box className={styles.grid}>
          <Controller
            name="signUp.email"
            control={control}
            render={({ field }) => (
              <Input
                label="Email*"
                placeholder="Enter your Email"
                errorMessage={errors.signUp?.email?.message}
                hasError={!!errors.signUp?.email}
                disabled={!!passwordToken || registeredUser?.isDisabled}
                {...field}
              />
            )}
          />
        </Box>
        <Box className={styles.grid}>
          <Controller
            name="signUp.password"
            control={control}
            render={({ field }) => (
              <Input
                className="full-size"
                type="password"
                label="Password*"
                placeholder="Enter your password"
                errorMessage={errors.signUp?.password?.message}
                hasError={!!errors.signUp?.password}
                disabled={registeredUser?.isDisabled}
                {...field}
              />
            )}
          />
          <Controller
            name="signUp.confirmPassword"
            control={control}
            render={({ field }) => (
              <Input
                className="full-size"
                type="password"
                label="Repeat Password*"
                placeholder="Enter your repeat password"
                errorMessage={errors.signUp?.confirmPassword?.message}
                hasError={!!errors.signUp?.confirmPassword}
                disabled={registeredUser?.isDisabled}
                {...field}
              />
            )}
          />
        </Box>
        <Box className={styles.termsAndConditionsBox}>
          <Controller
            name="signUp.check"
            control={control}
            render={({ field }) => <Checkbox {...field} disabled={!inviteToken && searchParams.get('auth') === 'confirm'} />}
          />
          <B2 bold color="var(--additional-grey-2)" className={cn({ [styles.redText]: errors.signUp?.check })}>
            I agree to the{' '}
            <Link
              to={`${process.env.REACT_APP_LANDING_PAGE_URL}/termsOfUse/tenant`}
              className={cn({ [styles.redText]: errors.signUp?.check })}
            >
              Term & Conditions
            </Link>
          </B2>
        </Box>
        {!inviteToken && searchParams.get('auth') === 'confirm' && <ConfirmEmail />}
        <Button
          className={styles.button}
          variant="contained"
          size="full-lg"
          label="Continue"
          loading={applicationLoading}
          type="submit"
          disabled={!inviteToken && searchParams.get('auth') === 'confirm'}
        />
      </Box>
    </div>
  );
};

export default forwardRef(ApplicationSignUp);
