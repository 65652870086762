import { useState } from 'react';

import { Avatar } from '@mui/material';
import inactive from 'assets/images/inactive.png';
import Card from 'components/shared/Card';
import ImageDialog from 'components/shared/ImageDialog';
import SvgIcon from 'components/shared/utils/SvgIcon';
import { B2, B3 } from 'components/Typography/Body';
import { H5 } from 'components/Typography/Headlines';
import modalNames from 'constants/modalNames';
import { modalsSlice } from 'modals';
import { useDispatch } from 'react-redux';

import styles from './PropertyCard.module.scss';
import { type taskDetails } from '../store/types';

interface PropertyCardProps {
  taskDetails: taskDetails;
}
const PropertyCard = ({ taskDetails }: PropertyCardProps) => {
  const dispatch = useDispatch();

  const [openFullScreen, setOpenFullScreen] = useState(false);

  const handleOpenFullScreen = () => {
    setOpenFullScreen(true);
  };

  const handleCloseFullScreen = () => {
    setOpenFullScreen(false);
  };

  return (
    <Card className={styles.listingsCard}>
      <Card.Content className={styles.content}>
        <div className={styles.imageWrap}>
          <Avatar
            className={styles.image}
            onClick={handleOpenFullScreen}
            src={
              taskDetails?.property?.gallery?.cover
                ? `${process.env.REACT_APP_BASE_URL}/api/document?path=${taskDetails?.property?.gallery?.cover}`
                : inactive
            }
          />
          {openFullScreen && taskDetails?.property?.gallery?.cover && (
            <ImageDialog
              imageUrl={`${process.env.REACT_APP_BASE_URL}/api/document?path=${taskDetails?.property?.gallery?.cover}`}
              onClose={handleCloseFullScreen}
            />
          )}
        </div>
        <div className={styles.info}>
          <div>
            <H5 bold>{`${taskDetails?.property?.propertyType?.label || ''} ${
              taskDetails?.property?.propertyType?.label ? '|' : ''
            } ${taskDetails?.unit?.unitNumber || ''} ${taskDetails?.property?.propertyType?.label ? '|' : ''} ${
              taskDetails?.property?.googleApi?.address?.value || ''
            }`}</H5>
            <B2
              className={styles.address}
              onClick={() =>
                dispatch(modalsSlice.actions.showModal({ modalName: modalNames.LOCATION_MODAL, data: taskDetails.property }))
              }
            >
              <SvgIcon icon={'location'} className={styles.locationIcon} />
              {taskDetails?.property?.googleApi?.address?.value}
            </B2>
            <div className={styles.policy}>
              <B3 bold>Pet Policy:</B3>
              <B3>{'Pet are not allowed'}</B3>
              {/* property?.unit_info?.other?.petWeight ? 'allowed' :  */}
            </div>
            {/* <div>
              <B2 bold>{`Square feet: ${property?.unit_info?.unitSize || property?.unit_info?.unitSize} | ${
                property?.unit_info?.bedrooms || property?.unit_info?.bedrooms
              }
              Bedroom | ${property?.unit_info?.bathrooms || property?.unit_info?.bathrooms} Bathroom | Parking spot ${
                property?.unit_info?.parkingSpot || property?.unit_info?.parkingSpot
              }`}</B2>
            </div> */}
          </div>
        </div>
      </Card.Content>
    </Card>
  );
};

export default PropertyCard;
