import { type CSSProperties, forwardRef, memo, useState, type ChangeEvent } from 'react';

import { MenuItem, TextField, type TextFieldProps } from '@mui/material';
import cn from 'classnames';

import SvgIcon from '../../utils/SvgIcon';
import './Input.scss';

export interface ISelectOptions<T> {
  label: string;
  value: T;
}

export type InputProps<T> = TextFieldProps & {
  style?: CSSProperties;
  options?: ISelectOptions<T>[];
  selectedOption?: ISelectOptions<T>;
  className?: string;
  errorMessage?: string | any;
  hasError?: boolean;
  multiline?: boolean;
  autoComplete?: string;
  type?: 'text' | 'password' | 'email' | 'tel' | 'number';
  regexp?: RegExp;
};

const Input = ({
  className = '',
  options,
  selectedOption,
  type = 'text',
  errorMessage,
  multiline,
  hasError,
  regexp,
  autoComplete = 'off',
  ...rest
}: InputProps<any>) => {
  const [show, setShow] = useState(false);

  return (
    <TextField
      type={type === 'password' && !show ? type : 'text'}
      className={cn('password-autocomplete-disabler', 'input-wrapper', className, { ['textarea-wrapper']: multiline })}
      multiline={multiline}
      helperText={errorMessage}
      error={hasError || !!errorMessage}
      sx={
        rest.value
          ? {
              '& .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
                borderColor: 'var(--additional-light-grey-4)',
              },
            }
          : {}
      }
      {...rest}
      inputProps={{
        ...(rest?.inputProps && { ...rest.inputProps }),
        autoComplete: autoComplete,
      }}
      InputProps={{
        endAdornment: options ? (
          <MenuItem value={''} className={'select-input'}>
            <TextField select value={selectedOption}>
              {options.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </MenuItem>
        ) : (
          type === 'password' && (
            <MenuItem value="" onClick={() => setShow(!show)}>
              <SvgIcon icon={show ? 'hide-password' : 'show-password'} />
            </MenuItem>
          )
        ),
        ...(rest?.InputProps && { ...rest.InputProps }),
      }}
      value={rest.value ?? null}
      {...(regexp
        ? {
            onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              if (rest?.onChange) {
                const value: string = e?.target?.value || '';
                rest.onChange({
                  ...e,
                  target: {
                    ...e.target,
                    value: value.replace(regexp, ''),
                  },
                });
              }
            },
          }
        : {})}
    />
  );
};

export default Object.assign(memo(forwardRef(Input)), { displayName: 'Input' });
