import { Box } from '@mui/system';
import Card from 'components/shared/Card';
import { B3 } from 'components/Typography/Body';

import styles from './AccountingCard.module.scss';

const AccountingCard = ({ account }: any) => {
  return (
    <Card className={styles.AccountingCard}>
      <Card.Content>
        <Box>
          <div className={styles.cardItem}>
            <B3 bold className={styles.cardItemtitle}>
              Property Name
            </B3>
            <p className={styles.cardItemInfo}>{account?.propertyName}</p>
          </div>
          <div className={styles.cardItem}>
            <B3 bold className={styles.cardItemtitle}>
              Rent Income
            </B3>
            <p className={styles.cardItemInfo}>{account?.rentIncome}</p>
          </div>
          <div className={styles.cardItem}>
            <B3 bold className={styles.cardItemtitle}>
              Late Fee Income
            </B3>
            <p className={styles.cardItemInfo}>{account?.lateFeeIncome}</p>
          </div>
          <div className={styles.cardItem}>
            <B3 bold className={styles.cardItemtitle}>
              Deposit Income
            </B3>
            <p className={styles.cardItemInfo}>{account?.depositIncome}</p>
          </div>
          <div className={styles.cardItem}>
            <B3 bold className={styles.cardItemtitle}>
              Total Income
            </B3>
            <p className={styles.cardItemInfo}>{account?.totalIncome}</p>
          </div>
        </Box>
      </Card.Content>
    </Card>
  );
};

export default AccountingCard;
