import { useState } from 'react';

import { Box } from '@mui/material';
import { PaymentElement } from '@stripe/react-stripe-js';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import Button from 'components/shared/Button';

import styles from './CheckoutForm.module.scss';

interface CheckoutFormProps {
  returnUrl: string;
  shouldNotify?: boolean;
  notifyCallback?: () => void;
}

export default function CheckoutForm({ returnUrl, notifyCallback, shouldNotify = false }: CheckoutFormProps) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<any>(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    const { error }: any = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}${returnUrl}`,
      },
    });

    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
    } else if (error) {
      setMessage('An unexpected error occured.');
    }

    if (shouldNotify && notifyCallback) {
      notifyCallback();
    }

    setIsProcessing(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <Box>
        <Button
          color={'primary-blue'}
          className={styles.privacyPolicyBtn}
          variant="contained"
          size="full-lg"
          type="submit"
          label={isProcessing ? 'Processing ... ' : 'Pay now'}
          disabled={isProcessing || !stripe || !elements}
        />
      </Box>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
