import cn from 'classnames';
import SvgIcon from 'components/shared/utils/SvgIcon';
import { B3 } from 'components/Typography/Body';
import { regexp } from 'constants/regexp';

import styles from './YourIncomeDetails.module.scss';

interface Props {
  applicationData: any;
}

const YourIncomeDetails = ({ applicationData }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.incomeInfo}>
        <B3>
          <SvgIcon icon={'profile'} className={styles.profile} />
          Employer Name
        </B3>
        <B3>{applicationData.yourIncome?.employerName}</B3>
      </div>
      <div className={styles.incomeInfo}>
        <B3>
          <SvgIcon icon={'phone'} className={cn(styles.profile, styles.iconLight)} />
          Employer Phone Number
        </B3>
        <B3>{regexp.formatPhoneNumber(applicationData.yourIncome?.employerPhone)}</B3>
      </div>
      <div className={styles.incomeInfo}>
        <B3>
          <SvgIcon icon={'file-text'} className={`${styles.profile} ${styles.iconLight}`} />
          Income
        </B3>
        {applicationData.yourIncome?.monthlyIncome && <B3>${applicationData.yourIncome?.monthlyIncome}</B3>}
      </div>
      <div className={styles.incomeInfo}>
        <B3>
          <SvgIcon icon={'applications'} className={`${styles.profile} ${styles.iconLight}`} />
          Monthly Expense
        </B3>
        {applicationData.yourIncome?.monthlyExpense && <B3>${applicationData.yourIncome?.monthlyExpense}</B3>}
      </div>
      <div className={styles.incomeInfo}>
        <B3>
          <SvgIcon icon={'accounting'} className={`${styles.profile} ${styles.iconLight}`} />
          Position Held
        </B3>
        <B3>{applicationData.yourIncome?.positionHeld}</B3>
      </div>
      <div className={styles.incomeInfo}>
        <B3>
          <SvgIcon icon={'calendar'} className={styles.profile} />
          Years Worked
        </B3>
        {applicationData.yourIncome?.yearsWorked && <B3>{applicationData.yourIncome?.yearsWorked} years</B3>}
      </div>
      {!!applicationData.yourIncome?.additionalIncome.length && (
        <div className={styles.additionalIncome}>
          <B3 bold>Additional Incomes</B3>
          {applicationData.yourIncome?.additionalIncome &&
            applicationData.yourIncome.additionalIncome?.map((income: any) => {
              return (
                <div key={income.sourceName}>
                  <div className={styles.additionalIncomeRow}>
                    <B3>{income.sourceName}</B3>
                    {income.monthlyIncome && <B3>{`Monthly Income:  $${income.monthlyIncome}`}</B3>}
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default YourIncomeDetails;
