import request from '../../../services/request';

const END_POINTS = {
  createProfile: 'api/profile/create',
  updateProfile: 'api/profile/update/',
  createTenantProfile: 'api/profile/tenant/create',
  updateTenantProfile: 'api/profile/tenant/update/',
  addBankAccount: 'api/account/bank-accounts',
  getBankAccount: 'api/account/me',
  deleteBankAccount: 'api/account/delete-bank-account/',
};

export function createProfile(payload: any) {
  const id = payload.user?.profile?._id;
  const formData = new FormData();
  payload?.imageCover?.forEach((image: File | string) => {
    if (typeof image !== 'string') {
      formData.append(`files`, image, 'profilePic.png');
    }
  });

  payload?.organizationArticle?.forEach((image: File | string) => {
    if (typeof image !== 'string') {
      formData.append(`files`, image, 'organizationArticle.png');
    }
  });

  payload?.identity?.forEach((image: File | string) => {
    if (typeof image !== 'string') {
      formData.append(`files`, image, 'identity.png');
    }
  });

  formData.append(`formFields`, JSON.stringify(payload.requestData));
  return request({
    method: id ? 'PUT' : 'POST',
    url: id ? `${END_POINTS.updateProfile}${id}` : `${END_POINTS.createProfile}`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function createTenantProfile(payload: any) {
  const id = payload.user?.profile?._id;
  const formData = new FormData();
  payload?.imageCover?.forEach((image: File | string) => {
    if (typeof image !== 'string') {
      formData.append(`files`, image, 'profilePic.png');
    }
  });

  payload?.organizationArticle?.forEach((image: File | string) => {
    if (typeof image !== 'string') {
      formData.append(`files`, image, 'organizationArticle.png');
    }
  });

  payload?.identity?.forEach((image: File | string) => {
    if (typeof image !== 'string') {
      formData.append(`files`, image, 'identity.png');
    }
  });

  formData.append(`formFields`, JSON.stringify(payload.requestData));
  return request({
    method: id ? 'PUT' : 'POST',
    url: id ? `${END_POINTS.updateTenantProfile}${id}` : `${END_POINTS.createTenantProfile}`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function addBankAccountService(payload: any) {
  return request({
    method: 'POST',
    url: END_POINTS.addBankAccount,
    data: payload,
  });
}

export function getBankAccountService() {
  return request({
    url: END_POINTS.getBankAccount,
  });
}

export function deleteBankAccountService(payload: any) {
  return request({
    method: 'POST',
    url: `${END_POINTS.deleteBankAccount}${payload.id}`,
  });
}
