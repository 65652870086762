import dayjs from 'dayjs';

export const getFormValuesByListing = (listing: any) => {
  const {
    title,
    description,
    deposit,
    rentDuration,
    leaseDuration,
    rent,
    listingPropertyType,
    leaseDetail,
    startFrom,
    availableFrom,
    gallery,
  } = listing;

  const images = () => {
    const newImages: string[] = [];
    if (listing?.gallery) {
      const links = Object.keys(listing?.gallery).filter(el => el !== 'cover');
      links.forEach(element => {
        newImages.push(listing?.gallery[element]);
      });
    }
    return newImages;
  };

  return {
    title,
    description,
    deposit,
    rentDuration,
    leaseDuration,
    rent,
    listingPropertyType,
    startFrom: dayjs(startFrom),
    availableFrom: dayjs(availableFrom),
    ...(leaseDetail && { ...leaseDetail }),
    ...(gallery?.cover && { imageCover: [gallery?.cover] }),
    ...(images().length && { imageUrls: [...images()] }),
  };
};
