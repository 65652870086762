import { useState, useEffect } from 'react';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { formatDate } from 'common/utils/utils';
import Chart from 'components/shared/Chart';
import { chartData } from 'constants/chart';
import { DateFormat } from 'constants/dateFormat';
import { getExpiringLeasesAction } from 'modules/Home/store/actions';
import { expiringLeasesSelector } from 'modules/Home/store/selectors';
import { useDispatch, useSelector } from 'react-redux';

import styles from './ExpiringLeases.module.scss';

interface ExpiringLeasesProps {
  title: string;
}

const ExpiringLeases: React.FC<ExpiringLeasesProps> = ({ title }) => {
  const dispatch = useDispatch();
  const expiringLeases = useSelector(expiringLeasesSelector);
  const [value, setValue] = useState(3);

  useEffect(() => {
    dispatch(getExpiringLeasesAction({ date: '' }));
  }, []);

  function transformExpiringLeases(obj: any) {
    const result = [];
    for (const key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        const chartDataKey = key as keyof typeof chartData;
        result.push([chartData[chartDataKey], obj[key]]);
      }
    }

    return result;
  }

  const initialData: any = {
    content: [
      {
        name: 'Expiring Leases',
        colors: ['#E3296C', '#D18E19', '#091433', '#D91CFF'],
        colorByPoint: true,
        groupPadding: 0,
        data: transformExpiringLeases(expiringLeases),
      },
    ],
    categories: ['Active', 'Not sent', 'End', 'Pending', 'Stopped', 'Rejected', 'Invalid'],
  };

  const handleTabChange = (event: any, newValue: number) => {
    setValue(newValue);
    const today = new Date();
    switch (newValue) {
      case 0: // 0-30 Days
        dispatch(getExpiringLeasesAction({ date: formatDate(today, DateFormat.YYYYMMDD) }));
        break;
      case 1: // 30-61 Days
        {
          const nextMonth = new Date(today);
          nextMonth.setMonth(nextMonth.getMonth() + 1);
          dispatch(getExpiringLeasesAction({ date: formatDate(nextMonth, DateFormat.YYYYMMDD) }));
        }
        break;
      case 2: // 61-90 Days
        {
          const afterNextMonth = new Date(today);
          afterNextMonth.setMonth(afterNextMonth.getMonth() + 2);
          dispatch(getExpiringLeasesAction({ date: formatDate(afterNextMonth, DateFormat.YYYYMMDD) }));
        }
        break;
      case 3: // All
        dispatch(getExpiringLeasesAction({ date: '' }));
        break;
      default:
        dispatch(getExpiringLeasesAction({ date: formatDate(new Date(), DateFormat.YYYYMMDD) }));
    }
  };

  return (
    <div className={styles.chart}>
      <Tabs
        className={styles.tabs}
        value={value}
        variant="scrollable"
        scrollButtons="auto"
        onChange={handleTabChange}
        classes={{
          indicator: styles.indicator,
        }}
        textColor="inherit"
      >
        <Tab label="0-30 Days" />
        <Tab label="30-61 Days" />
        <Tab label="61-90 Days" />
        <Tab label="All" />
      </Tabs>
      <Chart type="bar" data={initialData.content} text={title} categories={initialData.categories} legend={false} />
    </div>
  );
};

export default ExpiringLeases;
