import React from 'react';

import { Box } from '@mui/material';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { Controller } from 'react-hook-form';

import styles from './GoogleAddressBox.module.scss';
import { countryes } from '../../../constants/countryes';
import { Input } from '../FormElements';
import Autocomplete from '../FormElements/Autocomplete/Autocomplete';

type name = {
  name: string;
  label: string;
};

export interface GoogleAddressBoxPropsProps {
  form: any;
  names: {
    country: name;
    city: name;
    address: name;
    address1: name;
    state: name;
    zipCode: name;
  };
}

const GoogleAddressBox = ({ form, names }: GoogleAddressBoxPropsProps) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = form;
  const key = process.env.REACT_APP_ADDRESS_API_KEY;
  const { placesService, placePredictions, getPlacePredictions } = usePlacesService({
    apiKey: key,
  });

  const getDetails = (e: any) => {
    const value = e.target.value;
    placesService?.getDetails(
      {
        placeId: placePredictions.find((el: any) => el?.description === value)?.place_id || '',
      },
      (placeDetails: any) => {
        const formattedAddress = placeDetails.formatted_address;
        const postalCodeRegex = /(\d{5}([-]\d{4})?)/;
        const match = formattedAddress.match(postalCodeRegex);
        const cityComponent = placeDetails.address_components.find((component: { types: string }) =>
          component.types.includes('locality')
        );
        const stateComponent = placeDetails.address_components.find((component: { types: string }) =>
          component.types.includes('administrative_area_level_1')
        );
        const countryComponent = placeDetails.address_components.find((component: { types: string }) =>
          component.types.includes('country')
        );
        setValue(names.address.name, {
          lat: placeDetails.geometry.location.lat(),
          lng: placeDetails.geometry.location.lng(),
          label: value,
          place_id: placeDetails.place_id,
        });
        setValue(
          names.country.name,
          countryComponent.long_name ? { label: countryComponent.long_name, code: countryComponent.short_name } : undefined,
          { shouldValidate: true }
        );
        setValue(names.city.name, cityComponent?.long_name ? cityComponent?.long_name : '', { shouldValidate: true });
        setValue(names.state.name, stateComponent?.long_name ? stateComponent.long_name : '', { shouldValidate: true });
        if (match) {
          setValue(names.zipCode.name, match[0], { shouldValidate: true });
        } else {
          setValue(names.zipCode.name, '', { shouldValidate: true });
        }
      }
    );
  };

  return (
    <div className={styles.googleAddressBox}>
      <Box className={styles.inputBox}>
        <Controller
          name={names.address.name}
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              onInputChange={(e: unknown, newInputValue: string) => {
                getPlacePredictions({ input: newInputValue });
              }}
              onSelect={getDetails}
              label={names.address.label}
              placeholder={'Enter your business address'}
              errorMessage={errors[names.address.name]?.message}
              hasError={!!errors[names.address.name]}
              options={placePredictions.map((el: { description: string }) => ({ ...el, label: el.description }))}
            />
          )}
        />
        <Controller
          name={names.address1.name}
          control={control}
          render={({ field }) => (
            <Input
              label={names.address1.label}
              placeholder={'Enter your Address'}
              errorMessage={errors[names.address1.name]?.message}
              hasError={!!errors[names.address1.name]}
              autoComplete={'new-password'}
              {...field}
            />
          )}
        />
        <Box className={styles.grid}>
          <Controller
            name={names.country.name}
            control={control}
            render={({ field }) => (
              <Autocomplete
                errorMessage={errors[names.country.name]?.message}
                hasError={!!errors[names.country.name]}
                label={names.country.label}
                options={countryes}
                {...field}
              />
            )}
          />
          <Controller
            name={names.city.name}
            control={control}
            render={({ field }) => (
              <Input
                className="full-size"
                type="text"
                label={names.city.label}
                placeholder="Enter your city"
                errorMessage={errors[names.city.name]?.message}
                hasError={!!errors[names.city.name]}
                autoComplete={'new-password'}
                {...field}
              />
            )}
          />
        </Box>
        <Box className={styles.grid}>
          <Controller
            name={names.state.name}
            control={control}
            render={({ field }) => (
              <Input
                className="full-size"
                type="text"
                label={names.state.label}
                placeholder="Enter your State/Province/Region"
                errorMessage={errors[names.state.name]?.message}
                hasError={!!errors[names.state.name]}
                autoComplete={'new-password'}
                {...field}
              />
            )}
          />
          <Controller
            name={names.zipCode.name}
            control={control}
            render={({ field }) => (
              <Input
                className="full-size"
                type="text"
                label={names.zipCode.label}
                placeholder="Enter your Zip/Postal code"
                errorMessage={errors[names.zipCode.name]?.message}
                hasError={!!errors[names.zipCode.name]}
                {...field}
              />
            )}
          />
        </Box>
      </Box>
    </div>
  );
};
export default GoogleAddressBox;
