import axios, { type AxiosRequestConfig } from 'axios';

import { REACT_APP_BASE_URL } from '../constants/envVariables';

async function request({
  method,
  baseURL = REACT_APP_BASE_URL,
  url,
  data,
  params,
  headers,
  signal,
  responseType,
}: Partial<AxiosRequestConfig>) {
  const token = localStorage.getItem('token');

  const config = {
    method,
    url: `${baseURL}/${url ?? ''}`,
    data,
    headers: {
      ...headers,
      'x-access-token': token,
    },
    params,
    signal,
    responseType,
  };
  try {
    return await axios(config);
  } catch (err: any) {
    if (err?.response?.status === 403) {
      window.location.replace('/auth/login');
      localStorage.setItem('token', '');
    }
    throw err;
  }
}

export default request;
