import * as Yup from 'yup';

import { listingType } from '../constants';

const isEntirePlace = (listingPropertyType: string) => listingPropertyType !== listingType.ENTIRE_PLACE;

export const createListingSchema = Yup.object().shape({
  imageUrls: Yup.array().nullable(),
  imageCover: Yup.array().nullable(),
  title: Yup.string().required('Required field'),
  description: Yup.string()
    .required('Required field')
    .min(10, 'Listing Description must be at least 10 characters.')
    .max(2000, 'Listing Description must be under 2000 characters.'),
  deposit: Yup.string().required('Required field'),
  rent: Yup.string().required('Required field'),
  startFrom: Yup.string().required('Required field'),
  availableFrom: Yup.string().required('Required field'),
  leaseDuration: Yup.object().required('Required field'),
  rentDuration: Yup.object().required('Required field'),
  parkingSpot: Yup.string().when(['listingPropertyType'], {
    is: isEntirePlace,
    then: schema => schema.required('Required field'),
    otherwise: schema => schema.nullable(),
  }),
  bedrooms: Yup.string().when(['listingPropertyType'], {
    is: isEntirePlace,
    then: schema => schema.required('Required field'),
    otherwise: schema => schema.nullable(),
  }),
  unitSize: Yup.string().when(['listingPropertyType'], {
    is: isEntirePlace,
    then: schema => schema.required('Required field'),
    otherwise: schema => schema.nullable(),
  }),
  bathrooms: Yup.string().when(['listingPropertyType'], {
    is: isEntirePlace,
    then: schema => schema.required('Required field'),
    otherwise: schema => schema.nullable(),
  }),
});
