import { userRole } from 'constants/userStatus';
import { type ApplicationsDataModel } from 'modules/types/applicationTypes';

import { addCurrentStepData } from './helpers';
import { type createApplicationData } from './types';
import { AplicationSteps } from '../constants';
import { type IForm } from '../types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const createApplicationRequestData = (applicationData: createApplicationData, appData: ApplicationsDataModel) => {
  const userId = localStorage.getItem('id');
  const stepKey = applicationData.data.step.toString();

  const adaptedData: ApplicationsDataModel = {
    ...appData,
    documents: {
      ...appData.documents,
      IDPhoto: appData.documents?.IDPhoto ? appData.documents.IDPhoto : [],
      proofOfIncome: appData.documents?.proofOfIncome ? appData.documents.proofOfIncome : [],
      others: appData.documents?.others ? appData.documents.others : [],
    },
    moveIn: applicationData.data.moveIn && new Date(applicationData.data.moveIn),
    applyingAs: userRole.TENANT,
    step:
      applicationData.nextStep === AplicationSteps.CONGRATULATIONS ? AplicationSteps.CONGRATULATIONS : applicationData.data.step,
    ...((addCurrentStepData as Record<string, (data: IForm) => any>)[stepKey]?.(applicationData.data) || {}),
  };
  if (userId) {
    adaptedData.id = userId;
  }
  if (applicationData.id) {
    adaptedData.listing = applicationData.id;
  }
  return adaptedData;
};

export const customizeRegisteredDataByInvite = (data: IForm) => {
  const passwordToken = localStorage.getItem('passwordToken');
  const adaptedData = {
    password: data.signUp?.password,
    email: data.signUp?.email,
    passwordToken: passwordToken,
  };
  return adaptedData;
};

export const customizeEmailVerifyData = (data: IForm) => {
  const adaptedData = {
    password: data.signUp?.password,
    email: data.signUp?.email,
  };
  return adaptedData;
};

export const payByCardReqData = (data: any) => {
  return {
    amount: 3500,
    token: data.paymentMethod,
  };
};

export const payAttachMethodAdapter = (data: any) => {
  return {
    paymentMethod: data.paymentMethod,
    id: data.id,
  };
};

export const paymentCreateDataAdapter = (payload: any) => {
  return {
    paymentId: payload?.paymentId,
    id: payload.id,
  };
};
