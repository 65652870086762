const modalNames = {
  ERROR_MODAL: 'ERROR_MODAL',
  SUCCESS_REGISTERED_MODAL: 'SUCCESS_REGISTERED_MODAL',
  PAYMENT_METHODS_MODAL: 'PAYMENT_METHODS_MODAL',
  ADD_PROPERTY_OWNER: 'ADD_PROPERTY_OWNER',
  ADD_USER_MODAL: 'ADD_USER_MODAL',
  INVITE_TO_APPLY: 'INVITE_TO_APPLY',
  LOCATION_MODAL: 'LOCATION_MODAL',
  ADD_BANK_ACCOUNT: 'ADD_BANK_ACCOUNT',
  MARK_AS_PAID: 'MARK_AS_PAID',
  DELETE_SMT: 'DELETE_SMT',
};

export default modalNames;
