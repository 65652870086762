import { CssBaseline, ThemeProvider } from '@mui/material';
import { Toaster } from 'react-hot-toast';
import { pdfjs } from 'react-pdf';
import { Provider } from 'react-redux';

import MainRoutes from './components/Routes/MainRoutes';
import AddUserModal from './modals/AddUserModal';
import LocationModal from './modals/LocationModal';
import { setupStore } from './store/setupStore';
import './App.css';
import { themes } from './themes';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const store = setupStore();

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={themes.light}>
        <CssBaseline />
        <MainRoutes />
        <Toaster
          position="bottom-right"
          reverseOrder={false}
          toastOptions={{
            duration: 5000,
            style: {
              padding: '20px',
              borderRadius: '8px',
            },
          }}
        />
        <LocationModal />
        <AddUserModal />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
