import { createAction } from '@reduxjs/toolkit';
import { type MaintenanceTaskStatus } from 'constants/userStatus';

import { type createMaintenenceActionModel, type getCategoriModel } from './types';

export const getItems = createAction<getCategoriModel>('getItems');
export const getProperty = createAction<{ id: string }>('getProperty');
export const createMaintenence = createAction<createMaintenenceActionModel>('createMaintenence');
export const getMaintenenceList = createAction<any>('getMaintenenceList');
export const getMaintenanceDetails = createAction<{ maintenanceId: string }>('getMaintenanceDetails');
export const updatetMaintenanceStatus = createAction<{ maintenanceId: string; status: MaintenanceTaskStatus }>(
  'updatetMaintenanceStatus'
);
export const managerAssign = createAction<{ _id: string; email: string; fullName: string; maintenanceId: string; navigate: any }>(
  'managerAssign'
);
export const getAssigned = createAction<{ propertyId: string }>('getAssigned');
