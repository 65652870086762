import * as React from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import MuiSelect, { type SelectChangeEvent } from '@mui/material/Select';

import styles from './Select.module.scss';

const ITEM_HEIGHT = 32;
const ITEM_PADDING_TOP = 4;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: '50px',
    },
  },
};

export interface SelectProps {
  isMultiple?: boolean;
  label?: string;
  options?: { code: string; value: string }[];
  errorMessage?: string;
  hasError?: boolean;
  value?: string;
  onChange?: (value: any) => void;
  width?: string | number;
  height?: string | number;
}

export default function Select({
  isMultiple = false,
  options,
  errorMessage,
  hasError,
  label,
  value = '',
  onChange,
  width = '100%',
  height = 32,
  ...rest
}: SelectProps) {
  const [innerValue, setInnerValue] = React.useState<string[]>([value]);

  const handleChange = (event: SelectChangeEvent<typeof innerValue>) => {
    const {
      target: { value },
    } = event;
    const newValue = typeof value === 'string' ? value.split(',') : value;
    setInnerValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <div className={styles.select}>
      <FormControl error={hasError || !!errorMessage}>
        <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
        <MuiSelect
          sx={{
            width: width,
            height: height,
            border: 'none',
            background: 'white',
            '& div.Mui-focused': {
              display: 'none !important',
            },
          }}
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple={isMultiple}
          error={hasError}
          value={innerValue}
          onChange={handleChange}
          renderValue={selected => selected.join(', ')}
          MenuProps={MenuProps}
          {...rest}
        >
          {options &&
            options.map(({ code, value }) => (
              <MenuItem key={code} value={value}>
                {isMultiple && <Checkbox checked={innerValue.indexOf(value) > -1} />}
                <ListItemText primary={value} />
              </MenuItem>
            ))}
        </MuiSelect>
        {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
    </div>
  );
}
