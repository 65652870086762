import React from 'react';

import { Avatar } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import styles from './PropertyViewCard.module.scss';
import inactive from '../../../assets/images/inactive.png';
import modalNames from '../../../constants/modalNames';
import { modalsSlice } from '../../../modals';
import { B2 } from '../../Typography/Body';
import { H5 } from '../../Typography/Headlines';
import Button from '../Button';
import SvgIcon from '../utils/SvgIcon';

interface PropertyViewCardProps {
  property: any;
  monthlyRent: string;
  to: string;
}
const PropertyViewCard = ({ property, monthlyRent, to }: PropertyViewCardProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className={styles.property}>
      <Avatar
        className={styles.galleryImage}
        src={
          property.gallery?.cover ? `${process.env.REACT_APP_BASE_URL}/api/document?path=${property?.gallery?.cover}` : inactive
        }
      />
      <div className={styles.info}>
        <H5 bold className={styles.name}>{`${property.propertyName} | Unit ${property?.units?.length}`}</H5>
        <B2
          className={styles.address}
          onClick={() => dispatch(modalsSlice.actions.showModal({ modalName: modalNames.LOCATION_MODAL, data: property }))}
        >
          <SvgIcon icon={'location'} className={styles.locationIcon} />
          {property?.googleApi?.address?.value}
        </B2>

        <H5 bold className={styles.monthlyRent}>
          {`$${monthlyRent}`}{' '}
          <Button
            className={styles.seeDetails}
            variant="contained"
            size="full-sm"
            type="button"
            label="See Details"
            onClick={() => navigate(to)}
          />
        </H5>
      </div>
    </div>
  );
};

export default PropertyViewCard;
