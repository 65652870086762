import { type AxiosResponse } from 'axios';
import request from 'services/request';

const END_POINTS = {
  getCategory: 'api/category',
  getPropertyByUnitId: 'api/maintenance/getLeaseByunitId',
  uploadDocuments: 'api/document/upload-maintenance',
  createMaintenence: 'api/maintenance/create',
  getMaintenence: 'api/maintenance',
  updateMaintenanceStatus: 'api/maintenance/update',
  assignManager: 'api/maintenance/assignee',
  getAssigned: 'api/property/property-manager',
};

export function getCategory(): Promise<AxiosResponse<any, any>> {
  return request({
    method: 'GET',
    url: END_POINTS.getCategory,
  });
}

export function getPropertyByUnitId(payload: { id: string }): Promise<AxiosResponse<any, any>> {
  return request({
    method: 'GET',
    url: `${END_POINTS.getPropertyByUnitId}/${payload.id}`,
  });
}

export function uploadDocuments(document: File[], maintenanceId: string) {
  const formData = new FormData();
  !!document.length &&
    document.forEach((file: File) => {
      formData.append(`files`, file, file.name);
    });
  formData.append('maintenanceId', maintenanceId);
  return request({
    method: 'POST',
    url: END_POINTS.uploadDocuments,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function createMaintenence(payload: any): Promise<AxiosResponse<any, any>> {
  return request({
    method: 'POST',
    url: END_POINTS.createMaintenence,
    data: { ...payload },
  });
}

export function getMaintenenceList(payload: any): Promise<AxiosResponse<any, any>> {
  return request({
    method: 'GET',
    url: `${END_POINTS.getMaintenence}?limit=${payload.size}&page=${payload.page}`,
  });
}

export function getMaintenanceDetails(payload: any): Promise<AxiosResponse<any, any>> {
  return request({
    method: 'GET',
    url: `${END_POINTS.getMaintenence}/${payload.maintenanceId}`,
  });
}

export function updateMaintenanceStatus(payload: { maintenanceId: string; status: string }): Promise<AxiosResponse<any, any>> {
  return request({
    method: 'PUT',
    url: `${END_POINTS.updateMaintenanceStatus}/${payload.maintenanceId}`,
    data: { status: payload.status },
  });
}

export function assignManager(payload: {
  managerId: string;
  email: string;
  maintenanceId: string;
}): Promise<AxiosResponse<any, any>> {
  return request({
    method: 'POST',
    url: `${END_POINTS.assignManager}/${payload.maintenanceId}`,
    data: { assignee: { managerId: payload.managerId, email: payload.email } },
  });
}

export function getAssigned(payload: { propertyId: string }): Promise<AxiosResponse<any, any>> {
  return request({
    method: 'GET',
    url: `${END_POINTS.getAssigned}/${payload.propertyId}`,
  });
}
