import React from 'react';

import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { Controller, type UseFormReturn } from 'react-hook-form';

import styles from './PersonalInfo.module.scss';
import { desideMaxDateByYear } from '../../../common/utils/utils';
import { Input } from '../../../components/shared/FormElements';
import DatePicker from '../../../components/shared/FormElements/DatePicker';
import GoogleAddressBox from '../../../components/shared/GoogleAddressBox';
import { H5 } from '../../../components/Typography/Headlines';
import { profileAddressName } from '../constants';
import { type IForm } from '../types';

export interface PersonalInfoProps {
  form: UseFormReturn<IForm>;
}
const PersonalInfo = ({ form }: PersonalInfoProps) => {
  const {
    control,
    formState: { errors },
  } = form;

  return (
    <div className={styles.personalInfo}>
      <H5 className={styles.title}>Personal information</H5>
      <Box className={styles.inputBox}>
        <GoogleAddressBox key={2} form={form} names={profileAddressName} />
        <Box className={styles.grid}>
          <Controller
            name="dateOfBirth"
            control={control}
            render={({ field }) => (
              <DatePicker
                label="Date of birth"
                {...field}
                maxDate={dayjs(desideMaxDateByYear())}
                errorMessage={errors.dateOfBirth?.message}
                hasError={!!errors.dateOfBirth}
              />
            )}
          />
          <Controller
            name="SSN"
            control={control}
            render={({ field }) => (
              <Input
                className="full-size"
                regexp={/[^0-9]/g}
                label="SSN"
                placeholder="Enter your SSN"
                errorMessage={errors.SSN?.message}
                hasError={!!errors.SSN}
                {...field}
              />
            )}
          />
        </Box>
      </Box>
    </div>
  );
};
export default PersonalInfo;
