import Loading from 'components/shared/Loading';
import NotificationCard from 'components/shared/NotificationCard';
import Pagination from 'components/shared/Pagination/Pagination';
import { B2, B3 } from 'components/Typography/Body';
import { H3 } from 'components/Typography/Headlines';
import { DateFormat } from 'constants/dateFormat';
import { useDispatch, useSelector } from 'react-redux';

import styles from './Notifications.module.scss';
import notificationsSlice from './store/notificationsSlice';
import {
  notificationsQueryOptionsSelector,
  notificationsSelector,
  notificationsLoadingSelector,
  notificationsCountSelector,
} from './store/selectors';
import { formatDate } from '../../common/utils/utils';

const Notifications = () => {
  const dispatch = useDispatch();
  const notifications: any = useSelector(notificationsSelector);
  const count = useSelector(notificationsCountSelector);
  const { page, size } = useSelector(notificationsQueryOptionsSelector);
  const loading = useSelector(notificationsLoadingSelector);

  const groupedNotifications: any = {};

  // Group notifications based on createdAt
  notifications?.items.map((notification: any) => {
    const createdAt = notification.createdAt.split('T')[0];

    if (!groupedNotifications[createdAt]) {
      groupedNotifications[createdAt] = [];
    }
    groupedNotifications[createdAt].push(notification);
  });

  return (
    <div className={styles.notifications}>
      <H3 className={styles.title}>Notifications</H3>
      {notifications?.items && notifications?.items.length > 0 && !loading ? (
        <div>
          {Object.keys(groupedNotifications).map(createdAt => (
            <div key={createdAt}>
              <B3 className={styles.dateText}>
                {formatDate(new Date(createdAt)) == formatDate(new Date()) ? 'Today' : formatDate(createdAt, DateFormat.YYYYMMDD)}
              </B3>
              {groupedNotifications[createdAt].map((notification: any, index: any) => {
                return <NotificationCard notification={notification} key={index} />;
              })}
            </div>
          ))}
          <Pagination
            page={page}
            pageCount={count.totalNotifications}
            gotoPage={page => dispatch(notificationsSlice.actions.setQueryOptions({ page, size }))}
            setPageSize={size => dispatch(notificationsSlice.actions.setQueryOptions({ page: 1, size }))}
            pageSize={size}
            className={styles.paggination}
          />
        </div>
      ) : (
        <div className={styles.emptyContainer}>
          <B2>You don't have any Notification.</B2>
        </div>
      )}
      {loading && <Loading isBig />}
    </div>
  );
};

export default Notifications;
