import React, { useEffect, useMemo, useState } from 'react';

import Checkbox from 'components/shared/FormElements/Checkbox';
import Table from 'components/shared/Table';
import { B2, B3 } from 'components/Typography/Body';
import { H5 } from 'components/Typography/Headlines';
import { Controller, type UseFormReturn } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import styles from './Utilities.module.scss';
import useDebouncedCallback, { getRandomId, getUrlParams } from '../../../../common/utils/utils';
import Autocomplete from '../../../../components/shared/FormElements/Autocomplete/Autocomplete';
import SvgIcon from '../../../../components/shared/utils/SvgIcon';
import { propertySelector } from '../../store/selectors';
import { getItems } from '../store/actions';
import createPropertiesSlice from '../store/createPropertiesSlice';
import { createPropertyLoadingSelector, utilityItemsSelector, utilityOptionSelector } from '../store/selectors';
import { type Item } from '../store/types';
import { type IForm } from '../types';

interface UtilitiesProps {
  form: UseFormReturn<IForm>;
}
const Utilities = ({ form }: UtilitiesProps) => {
  const utilityItems = useSelector(utilityItemsSelector);
  const [columnsData, setColumnsData] = useState<Item[]>([]);
  const { control, getValues, reset, setValue } = form;
  const [inputValue, setInputValue] = useState('');
  const dispatch = useDispatch();
  const loading = useSelector(createPropertyLoadingSelector);
  const utilityOption = useSelector(utilityOptionSelector);
  const property = useSelector(propertySelector);
  const urlParams = getUrlParams();

  useEffect(() => {
    const newData = [
      ...(!urlParams?.unit ? utilityItems : []),
      ...(property?.utilities
        ? property.utilities.map(el => ({ ...el, name: el?.utility?.name || '', isLendloard: el?.payer !== 'TENANT' }))
        : []),
    ];
    setValue('utilityColumnsData', newData, { shouldDirty: true });
    setColumnsData(newData);
  }, [utilityItems, property?.utilities, urlParams?.unit]);
  const handleBoxClick = (id: string) => {
    const newData = columnsData.map(el => {
      return el._id === id || el.utility?._id === id ? { ...el, isLendloard: !el.isLendloard } : el;
    });

    setValue('utilityColumnsData', newData, { shouldDirty: true });
    setColumnsData(newData);
  };

  const handleDelete = (id: string) => {
    const newData = columnsData.filter(el => el._id !== id);
    setValue('utilityColumnsData', newData, { shouldDirty: true });
    setColumnsData(newData);
  };

  const memoColumns = useMemo(() => {
    return [
      {
        Header: 'Name',
        id: 'name',
        title: 'Name',
        Cell: ({ row }: any) => {
          return <B2 className={styles.label}>{row.original.name}</B2>;
        },
      },
      {
        Header: 'Landlord',
        id: 'landloard',
        title: 'Landloard',
        Cell: ({ row }: any) => {
          return (
            <B2>
              <Checkbox type={'radio'} checked={row.original.isLendloard} onClick={() => handleBoxClick(row.original._id)} />
            </B2>
          );
        },
      },
      {
        Header: 'Tenant',
        id: 'tenant',
        title: 'Tenant',
        Cell: ({ row }: any) => {
          return (
            <B2 className={styles.tenant}>
              <Checkbox type={'radio'} checked={!row.original.isLendloard} onClick={() => handleBoxClick(row.original._id)} />
              <SvgIcon icon={'trash'} className={styles.trashIcon} onClick={() => handleDelete(row.original._id)} />
            </B2>
          );
        },
      },
    ];
  }, [columnsData]);

  const handleSearch = useDebouncedCallback(
    (val: string) => {
      if (val.length) {
        dispatch(getItems({ type: 'EXTRA', name: 'utility', value: val }));
      } else {
        dispatch(createPropertiesSlice.actions.clearItems('utility'));
      }
    },
    400,
    []
  );

  const handleValueChange = useDebouncedCallback(
    (val: string) => {
      setInputValue(val);
    },
    500,
    []
  );
  const filteredOptions = useMemo(() => {
    const newOptions = utilityOption.filter(item => !columnsData.map((item: any) => item._id).includes(item._id));
    return newOptions.map(el => ({ ...el, label: el.name }));
  }, [utilityOption, columnsData]);

  return (
    <div className={styles.utilities}>
      <H5 className={styles.title}>Utilities</H5>
      <B3 className={styles.desc}>Select Who pays each utilitiy bill</B3>
      <Table className={styles.table} columns={memoColumns} data={columnsData} hidePagination />
      <Controller
        name={`utilityItems`}
        control={control}
        render={({ field }) => (
          <Autocomplete
            isMultiple
            loading={loading}
            label="Add Utility"
            options={filteredOptions}
            {...field}
            inputValue={inputValue}
            setInputValue={setInputValue}
            onAutocompleteChange={(value: any) => {
              setInputValue('');
              dispatch(createPropertiesSlice.actions.clearItems('utility'));
              const item = value[value.length - 1];
              if (item) {
                reset({ ...getValues(), utilityItems: [] });
                setValue(
                  'utilityColumnsData',
                  [...columnsData, { ...item, isLendloard: false, name: item.label, _id: item._id || getRandomId() }],
                  {
                    shouldDirty: true,
                  }
                );
                setColumnsData([...columnsData, { ...item, name: item.label, _id: item._id || getRandomId() }]);
              }
            }}
            onInputChange={(e: unknown, newInputValue: string) => {
              handleSearch(newInputValue);
              handleValueChange(newInputValue);
            }}
            value={field.value || []}
          />
        )}
      />
    </div>
  );
};

export default Utilities;
