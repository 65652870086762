import React from 'react';

import { Avatar, Box } from '@mui/material';
import Card from 'components/shared/Card';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import styles from './ListingsCard.module.scss';
import inactive from '../../../assets/images/inactive.png';
import { formatDate } from '../../../common/utils/utils';
import Button from '../../../components/shared/Button';
import SvgIcon from '../../../components/shared/utils/SvgIcon';
import { B2, B3 } from '../../../components/Typography/Body';
import { H5 } from '../../../components/Typography/Headlines';
import modalNames from '../../../constants/modalNames';
import { routes } from '../../../constants/routes';
import { modalsSlice } from '../../../modals';
import { statusUpdateAction } from '../store/actions';
import { uploadActiveIdSelector } from '../store/selectors';
import { type item } from '../store/types';

interface ListingsCardProps {
  listing: item;
  activeTab?: string;
}
const ListingsCard = ({ listing, activeTab }: ListingsCardProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isPublish = listing.listingStatus === 'PUBLISHED';
  const uploadActiveId = useSelector(uploadActiveIdSelector);

  return (
    <Card className={styles.listingsCard}>
      <Card.Content className={styles.content}>
        <div className={styles.imageWrap}>
          <Avatar
            className={styles.image}
            src={
              listing?.gallery?.cover
                ? `${process.env.REACT_APP_BASE_URL}/api/document?path=${listing?.gallery?.cover}`
                : inactive
            }
          />
        </div>
        <div className={styles.info}>
          <div>
            <div className={styles.price}>
              <H5 className={styles.rent}>{`$${listing.rent}`}</H5>
              <B2 className={styles.availableFrom}>Available {formatDate(listing.availableFrom)}</B2>
            </div>

            <H5 className={styles.title}>{listing.title}</H5>
            <B2
              className={styles.address}
              onClick={() =>
                dispatch(modalsSlice.actions.showModal({ modalName: modalNames.LOCATION_MODAL, data: listing.property_info }))
              }
            >
              <SvgIcon icon={'location'} className={styles.locationIcon} />
              {listing?.property_info?.googleApi?.address?.value}
            </B2>
            <div className={styles.policy}>
              <B3 bold>Pet Policy:</B3>
              <B3>{listing?.property_info?.other?.petWeight ? 'allowed' : 'Pet are not allowed'}</B3>
            </div>
            <div>
              <B2 bold>{`Square feet: ${listing?.leaseDetail?.unitSize || listing?.unit_info?.unitSize} | ${
                listing?.leaseDetail?.bedrooms || listing?.unit_info?.bedrooms
              } 
              Bedroom | ${listing?.leaseDetail?.bathrooms || listing?.unit_info?.bathrooms} Bathroom | Parking spot ${
                listing?.leaseDetail?.parkingSpot || listing?.unit_info?.parkingSpot
              }`}</B2>
            </div>
          </div>
          <Box className={styles.buttons}>
            <Button
              variant={isPublish ? 'outlined' : 'contained'}
              size="full-sm"
              type="button"
              loading={uploadActiveId === listing._id}
              disabled={uploadActiveId === listing._id}
              label={isPublish ? 'Unpublish' : 'Publish'}
              onClick={() =>
                dispatch(statusUpdateAction({ _id: listing._id, status: isPublish ? 'UNPUBLISHED' : 'PUBLISHED', activeTab }))
              }
            />
            <Button
              variant="outlined"
              size="full-sm"
              type="button"
              label="View Details"
              onClick={() => navigate(`${routes.listings}/${listing._id}`)}
            />
          </Box>
        </div>
      </Card.Content>
    </Card>
  );
};

export default ListingsCard;
