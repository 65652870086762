export const leaseDefaultValues = {
  insurance: 'Yes',
  currency: '',
  period: '',
  deposit: '',
  rent: '',
  leaseDuration: null,
  startDate: null,
  endDate: null,
  nextDueDate: null,
  gracePeriod: '',
  amount: '',
};

export const activeStatuses = ['UNSIGNED'];
