import styles from './NotFound.module.scss';
import notFound from '../../assets/images/notFound.png';
import { B1, B2 } from '../Typography/Body';
const NotFound = () => {
  return (
    <div className={styles.notFound}>
      <img src={notFound} alt={'Access Denied'} />
      <B1 bold>There's NOTHING here</B1>
      <B2>the page you're looking for is not found</B2>
    </div>
  );
};

export default NotFound;
