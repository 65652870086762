import React, { useEffect, useMemo } from 'react';

import { Box } from '@mui/material';
import BackButton from 'components/shared/BackButton/BackButton';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import styles from './ViewListing.module.scss';
import Button from '../../../components/shared/Button';
import Loading from '../../../components/shared/Loading';
import PropertyViewCard from '../../../components/shared/PropertyViewCard';
import Slider from '../../../components/shared/Slider';
import { B1, B2 } from '../../../components/Typography/Body';
import { H3, H5 } from '../../../components/Typography/Headlines';
import modalNames from '../../../constants/modalNames';
import { routes } from '../../../constants/routes';
import { modalsSlice } from '../../../modals';
import InviteToApplyModal from '../../../modals/InviteToApplyModal';
import { getApplicationCountByIdAction, getListingByIdAction, statusUpdateAction } from '../store/actions';
import listingsSlice from '../store/listingsSlice';
import { countSelector, listingSelector, listingsLoadingSelector, uploadActiveIdSelector } from '../store/selectors';

const ViewListing = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const dispatch = useDispatch();
  const { id } = useParams();
  const listing = useSelector(listingSelector);
  const appCount = useSelector(countSelector);
  const uploadActiveId = useSelector(uploadActiveIdSelector);
  const loading = useSelector(listingsLoadingSelector);
  const navigate = useNavigate();
  const isPublish = listing?.listingStatus === 'PUBLISHED';
  useEffect(() => {
    if (id) {
      dispatch(getListingByIdAction(id));
      dispatch(getApplicationCountByIdAction(id));
    }
  }, [id]);

  useEffect(() => {
    return () => {
      dispatch(listingsSlice.actions.clearListing());
    };
  }, []);

  const images = useMemo(() => {
    const newImages: {
      original: string;
      thumbnail?: string;
      originalHeight?: number;
      thumbnailHeight?: number;
    }[] = [];
    if (listing?.gallery) {
      const links = Object.keys(listing?.gallery).filter(el => el !== 'cover');
      if (listing?.gallery?.cover) {
        newImages.push({
          original: `${baseUrl}/api/document?path=${listing?.gallery?.cover}`,
          thumbnail: `${baseUrl}/api/document?path=${listing?.gallery?.cover}`,
          originalHeight: 500,
          thumbnailHeight: 80,
        });
      }

      links.forEach(element => {
        newImages.push({
          original: `${baseUrl}/api/document?path=${listing?.gallery[element]}`,
          thumbnail: `${baseUrl}/api/document?path=${listing?.gallery[element]}`,
          originalHeight: 500,
          thumbnailHeight: 80,
        });
      });
    }
    return newImages;
  }, [listing?.gallery]);

  return (
    <div className={styles.viewListing}>
      <BackButton to={routes.listings} />
      {listing && (
        <div className={styles.actions}>
          <H3 className={styles.title}>Listing</H3>
          <Box className={styles.buttons}>
            <Button
              variant="contained"
              size="full-sm"
              type="button"
              label="Invite to apply"
              onClick={() => dispatch(modalsSlice.actions.showModal({ modalName: modalNames.INVITE_TO_APPLY }))}
            />
            <Button
              variant={isPublish ? 'outlined' : 'contained'}
              size="full-sm"
              loading={!!uploadActiveId}
              disabled={!!uploadActiveId}
              type="button"
              label={isPublish ? 'Unpublish' : 'Publish'}
              onClick={() =>
                dispatch(
                  statusUpdateAction({ _id: listing._id, status: isPublish ? 'UNPUBLISHED' : 'PUBLISHED', isSingle: true })
                )
              }
            />
            <Button
              variant="outlined"
              size="full-sm"
              type="button"
              label="Edit"
              onClick={() =>
                navigate(`${routes.listingsCreate}/${listing?.propertyId?._id}/${listing?.unitId?._id}?listingId=${listing?._id}`)
              }
            />
          </Box>
        </div>
      )}
      {listing && (
        <div className={styles.content}>
          <Slider images={images} className={styles.slide} />
          <H5 bold className={styles.aplicationCount}>{`Application count : ${appCount}`}</H5>
          <PropertyViewCard
            property={listing?.propertyId}
            monthlyRent={listing?.unitId?.monthlyRent || ''}
            to={`${routes.properties}/${listing?.propertyId._id}`}
          />
          <div className={styles.wrap}>
            <B2 className={styles.item}>Lease duration</B2>
            <B2 bold>{`${listing.leaseDuration.label}`}</B2>
          </div>
          <div className={styles.wrap}>
            <B2 className={styles.item}>Rent duration</B2>
            <B2 bold>{`${listing.rentDuration.label}`}</B2>
          </div>
          <div className={styles.wrap}>
            <B2 className={styles.item}>Deposit</B2>
            <B2 bold>{`${listing.deposit}$`}</B2>
          </div>
          <div className={styles.wrap}>
            <B2 className={styles.item}>Rent</B2>
            <B2 bold>{`${listing.rent}$`}</B2>
          </div>
          {listing?.leaseDetail && (
            <>
              <div className={styles.wrap}>
                <B2 className={styles.item}>Parking spot(s)*</B2>
                <B2 bold>{`${listing.leaseDetail.parkingSpot}`}</B2>
              </div>
              <div className={styles.wrap}>
                <B2 className={styles.item}>Unit Size (sq.ft.)*</B2>
                <B2 bold>{`${listing.leaseDetail.unitSize}`}</B2>
              </div>
              <div className={styles.wrap}>
                <B2 className={styles.item}>Bedroom(s)*</B2>
                <B2 bold>{`${listing.leaseDetail.bedrooms}`}</B2>
              </div>
              <div className={styles.wrap}>
                <B2 className={styles.item}>Bathrooms*</B2>
                <B2 bold>{`${listing.leaseDetail.bathrooms}`}</B2>
              </div>
            </>
          )}
          <B1 bold className={styles.label}>
            Description
          </B1>
          <B2 className={styles.desc}>{listing.description}</B2>
        </div>
      )}
      {loading && <Loading isBig />}
      <InviteToApplyModal id={listing?._id || ''} />
    </div>
  );
};
export default ViewListing;
