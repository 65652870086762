import React, { useEffect, useRef } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Avatar } from '@mui/material';
import cn from 'classnames';
import { Input } from 'components/shared/FormElements';
import SvgIcon from 'components/shared/utils/SvgIcon';
import { B1, B2, B3 } from 'components/Typography/Body';
import { H5 } from 'components/Typography/Headlines';
import { userSelector } from 'modules/Auth/store/selectors';
import * as process from 'process';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import io from 'socket.io-client';

import styles from './Chat.module.scss';
import { sendMessage, getChat } from './store/actions';
import { chatSelector } from './store/selectors';
import { type IChat } from './store/types';
import { chatSchema } from './validations/chatSchema';
import { formatHoursAndMinutes } from '../../common/utils/utils';
import Button from '../../components/shared/Button';

const socket = io(`${process.env.REACT_APP_BASE_URL}`);

const Chat = ({ chatId }: any) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ defaultValues: { message: '' }, resolver: yupResolver<any>(chatSchema) });
  const dispatch = useDispatch();
  const chatMessages: any = useSelector(chatSelector);
  const user = useSelector(userSelector);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const onSubmit = (data: any) => {
    const messageData = {
      message: data.message,
      chatId: chatId,
    };
    dispatch(sendMessage(messageData));
    reset();
  };

  useEffect(() => {
    socket.on('chat message', messageData => {
      dispatch(getChat(messageData.chatId));
    });
  }, [socket]);

  useEffect(() => {
    if (chatId) {
      dispatch(getChat(chatId));
    }
  }, [chatId]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatMessages]);
  return (
    <div className={styles.chatDetails}>
      <div className={styles.chatHeader}>
        <H5 bold className={styles.title}>
          Message
        </H5>
        <B3 className={styles.description}>Comments are public and can be visible by tenant, manager and landlord</B3>
      </div>
      {chatMessages.length ? (
        <div className={styles.chatContent}>
          {chatMessages.map((chatMessage: IChat) => (
            <div
              key={chatMessage._id}
              className={cn(styles.messagesing, { [styles.myMessagesing]: user?._id === chatMessage.account._id })}
            >
              <div className={styles.userInfo}>
                {user?._id !== chatMessage.account._id ? (
                  <Avatar
                    className={styles.userImg}
                    alt={chatMessage.account.fullName[0]}
                    src={`${process.env.REACT_APP_BASE_URL}/api/document?path=${chatMessage.account?.profile?.profilePic}`}
                  />
                ) : (
                  ''
                )}
                <div className={styles.message}>
                  {user?._id !== chatMessage.account._id ? (
                    <B1 className={styles.userName} bold>
                      {chatMessage.account.fullName}
                    </B1>
                  ) : (
                    ''
                  )}
                  <span className={styles.createdAt}>{formatHoursAndMinutes(chatMessage.createdAt)}</span>
                  <B2 className={styles.messageText}>{chatMessage.message}</B2>
                </div>
              </div>
              <div ref={messagesEndRef} />
            </div>
          ))}
        </div>
      ) : (
        ''
      )}

      <div>
        <B2 className={styles.messageLabel}>Message</B2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.messageContent}>
            <Controller
              name="message"
              control={control}
              render={({ field }) => (
                <Input
                  placeholder="Enter your text"
                  rows={1}
                  className="full-size"
                  errorMessage={errors.message?.message}
                  hasError={!!errors.message}
                  {...field}
                />
              )}
            />
            <Button
              className={styles.sendButton}
              variant="outlined"
              size="full-sm"
              type="submit"
              label={<SvgIcon icon={'send'} className={styles.sendMessage} />}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Chat;
