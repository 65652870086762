import * as Yup from 'yup';

export const editLeaseSchema = Yup.object().shape({
  insurance: Yup.string(),
  leaseDuration: Yup.object().required('Required field'),
  startDate: Yup.object().required('Required field'),
  endDate: Yup.object().required('Required field'),
  deposit: Yup.string().required('Required field'),
  rent: Yup.string().required('Required field'),
  amount: Yup.string().required('Required field'),
  gracePeriod: Yup.string().required('Required field'),
});
