import React, { useState, type ReactNode } from 'react';

import MuiPopover from '@mui/material/Popover';

import Content from './Content';
import styles from './Popover.module.scss';
import Target from './Target';

export interface PopoverProps {
  children: ReactNode;
}

const Popover = ({ children }: PopoverProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  let content: ReactNode | null = null;
  let target: ReactNode | null = null;

  React.Children.forEach(children, child => {
    if (React.isValidElement(child)) {
      if (child?.type === Content) {
        content = child;
      }
      if (child?.type === Target) {
        target = child;
      }
    }
  });
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className={styles.popover}>
      <button className={styles.button} onClick={handleClick}>
        {target}
      </button>
      <MuiPopover
        id={id}
        open={open}
        onClick={handleClose}
        className={styles.content}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {content}
      </MuiPopover>
    </div>
  );
};
Popover.Content = Content;
Popover.Target = Target;

export default Popover;
