import { useMemo } from 'react';

import { Box } from '@mui/material';
import { FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { Input } from 'components/shared/FormElements';
import Autocomplete from 'components/shared/FormElements/Autocomplete/Autocomplete';
import { B3, B1 } from 'components/Typography/Body';
import { H5 } from 'components/Typography/Headlines';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { Controller, useWatch, type UseFormReturn } from 'react-hook-form';

import styles from './CompanyInfo.module.scss';
import { einChangeFormat, ssnChangeFormat } from '../../../common/utils/utils';
import { accountTypeValues, businessStructureOptions, structureTypes } from '../constants';
import { type IForm, type ITarget } from '../types';
export interface CompanyInfoProps {
  form: UseFormReturn<IForm>;
}
const CompanyInfo = ({ form }: CompanyInfoProps) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = form;
  const key = process.env.REACT_APP_ADDRESS_API_KEY;
  const { placePredictions, getPlacePredictions } = usePlacesService({
    apiKey: key,
  });

  const accountType = useWatch({ control, name: 'accountType' });
  const businessStructure = useWatch({ control, name: 'structure' });
  const useDbn = useWatch({ control, name: 'useDbn' });
  const weatherSsn = useWatch({ control, name: 'weatherSsn' });
  const handleWeatherSsnChange = (value: string) => {
    if (value === 'EIN') {
      setValue('SSNORITIN', '', { shouldValidate: true });
    } else {
      setValue('EIN', '', { shouldValidate: true });
    }
  };

  const isPersonal = useMemo(() => {
    return accountType === accountTypeValues.PERSONAL;
  }, [accountType]);

  const isDba = useMemo(() => {
    return businessStructure?.value === 'DBA';
  }, [businessStructure]);

  const onStructureChange = (e: { target: { value: string } }) => {
    const value = e.target.value;
    if (value) {
      if (structureTypes.includes(value)) {
        setValue('EIN', '');
        setValue('DBAName', '');
        setValue('SSNORITIN', '');
        setValue('businessName', '');
      } else {
        setValue('EIN', '');
        setValue('DBAName', '');
      }
    }
  };

  if (isPersonal) {
    return null;
  }
  return (
    <div className={styles.companyInfo}>
      <H5>Business details</H5>
      <B3 className={styles.desc}>Please fill in your company information</B3>
      <Box className={styles.inputBox}>
        <Box className={styles.grid}>
          <Controller
            name="companyName"
            control={control}
            render={({ field }) => (
              <Input
                className="full-size"
                type="text"
                label="Company name*"
                placeholder="Enter your Company Name"
                errorMessage={errors.companyName?.message}
                hasError={!!errors.companyName}
                {...field}
              />
            )}
          />
          <Controller
            name="companyAddress"
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                onInputChange={(e: unknown, newInputValue: string) => {
                  getPlacePredictions({ input: newInputValue });
                }}
                placeholder="Enter Company addres"
                label="Company Address*"
                options={placePredictions.map((el: { description: string }) => ({ ...el, label: el.description }))}
                errorMessage={errors.companyAddress?.message}
                hasError={!!errors.companyAddress}
              />
            )}
          />
        </Box>
        <Controller
          name="structure"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              onSelect={onStructureChange}
              placeholder="Enter your Business structure"
              label="Business structure"
              options={businessStructureOptions}
              errorMessage={errors.structure?.message}
              hasError={!!errors.structure}
            />
          )}
        />
        {isDba && (
          <>
            <Controller
              name="EIN"
              control={control}
              render={({ field }) => (
                <Input
                  regexp={/[^0-9]/g}
                  className="full-size"
                  type="text"
                  label="EIN"
                  placeholder="EIN"
                  errorMessage={errors.EIN?.message}
                  hasError={!!errors.EIN}
                  {...field}
                  onChange={event => {
                    einChangeFormat(event.target.value, field.onChange);
                  }}
                />
              )}
            />
            <Controller
              name="DBAName"
              control={control}
              render={({ field }) => (
                <Input
                  className="full-size"
                  type="text"
                  label="DBA name"
                  placeholder="Enter DBA name"
                  errorMessage={errors.DBAName?.message}
                  hasError={!!errors.DBAName}
                  {...field}
                />
              )}
            />
            <Controller
              name="businessName"
              control={control}
              render={({ field }) => (
                <Input
                  className="full-size"
                  type="text"
                  label="Business name*"
                  placeholder="Enter Business name"
                  errorMessage={errors.businessName?.message}
                  hasError={!!errors.businessName}
                  {...field}
                />
              )}
            />
          </>
        )}
        {!isDba && !!businessStructure?.value && (
          <div className={styles.wrap}>
            <B1 bold>Which type of Taxpayer Identification Number does your business use?</B1>
            <FormControl component="fieldset" className={styles.detectInformation}>
              <Controller
                control={control}
                name="weatherSsn"
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    onChange={(e: ITarget) => {
                      const value = e.target.value;
                      handleWeatherSsnChange(value);
                      field.onChange(value);
                    }}
                    className={styles.group}
                  >
                    <FormControlLabel
                      className={styles.radio}
                      value="SSN/ITIN"
                      control={<Radio color={'warning'} />}
                      label="SSN/ITIN"
                    />
                    <FormControlLabel className={styles.radio} value="EIN" control={<Radio color={'warning'} />} label="EIN" />
                  </RadioGroup>
                )}
              />
            </FormControl>
            {weatherSsn === 'SSN/ITIN' ? (
              <Controller
                name="SSNORITIN"
                control={control}
                render={({ field }) => (
                  <Input
                    regexp={/[^0-9]/g}
                    className="full-size"
                    type="text"
                    label="SSN/ITIN"
                    placeholder="SSN_ITIN"
                    errorMessage={errors.SSNORITIN?.message}
                    hasError={!!errors.SSNORITIN}
                    {...field}
                    onChange={event => {
                      ssnChangeFormat(event.target.value, field.onChange);
                    }}
                  />
                )}
              />
            ) : (
              <Controller
                name="EIN"
                control={control}
                render={({ field }) => (
                  <Input
                    regexp={/[^0-9]/g}
                    className="full-size"
                    type="text"
                    label="EIN"
                    placeholder="EIN"
                    errorMessage={errors.EIN?.message}
                    hasError={!!errors.EIN}
                    {...field}
                    onChange={event => {
                      einChangeFormat(event.target.value, field.onChange);
                    }}
                  />
                )}
              />
            )}
            <B1 bold className={styles.dbnTitle}>
              Does your business use a DBA name?
            </B1>
            <FormControl component="fieldset" className={styles.detectInformation}>
              <Controller
                control={control}
                name="useDbn"
                render={({ field }) => (
                  <RadioGroup {...field} className={styles.group}>
                    <FormControlLabel className={styles.radio} value="Yes" control={<Radio color={'warning'} />} label="Yes" />
                    <FormControlLabel className={styles.radio} value="No" control={<Radio color={'warning'} />} label="No" />
                  </RadioGroup>
                )}
              />
            </FormControl>
            {useDbn === 'Yes' && (
              <Controller
                name="DBAName"
                control={control}
                render={({ field }) => (
                  <Input
                    className="full-size"
                    type="text"
                    label="DBA name"
                    placeholder="Enter DBA name"
                    errorMessage={errors.DBAName?.message}
                    hasError={!!errors.DBAName}
                    {...field}
                  />
                )}
              />
            )}
          </div>
        )}
      </Box>
    </div>
  );
};

export default CompanyInfo;
