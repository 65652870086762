import { createSlice } from '@reduxjs/toolkit';

import { type ManagersDataModel, type ManagerDataModel, type getManagerListReqModel } from './types';

const initialState: ManagersDataModel = {
  isLoading: false,
  data: null,
  queryOptions: {
    page: 1,
    size: '5',
  },
};

const managersSlice = createSlice({
  name: 'manager',
  initialState,
  reducers: {
    setLoading: (state: ManagersDataModel, { payload }) => {
      state.isLoading = payload;
    },
    setManagerData: (
      state: ManagersDataModel,
      { payload }: { payload: { data: { items: ManagerDataModel[]; count: string }; queryOptions: getManagerListReqModel } }
    ) => {
      state.data = payload.data;
      state.queryOptions = {
        ...state.queryOptions,
        size: payload.queryOptions.limit,
        page: payload.queryOptions.page,
      };
    },
    addManager: (state: ManagersDataModel, { payload }: { payload: { items: ManagerDataModel[]; count: string } }) => {
      state.data = payload;
    },
  },
});

export default managersSlice;
