import dayjs from 'dayjs';
export const createListingsDefaultValues = {
  imageCover: [],
  imageUrls: [],
  title: '',
  startFrom: dayjs(new Date()),
  availableFrom: null,
  description: '',
  listingPropertyType: 'ENTIRE_PLACE',
  leaseDuration: null,
  rentDuration: null,
  deposit: '',
  rent: '',
  parkingSpot: '',
  bedrooms: '',
  unitSize: '',
  bathrooms: '',
};

export const listingType = {
  ENTIRE_PLACE: 'ENTIRE_PLACE',
  PRIVATE_ROOM: 'PRIVATE_ROOM',
  SHARED_ROOM: 'SHARED_ROOM',
};
export const listingTypeValue: { [key: string]: string } = {
  ENTIRE_PLACE: 'Entire Place',
  PRIVATE_ROOM: 'Private Room',
  SHARED_ROOM: 'Shared Room',
};

export const rentDurationOption = [
  { label: 'Monthly', value: 'MONTHLY' },
  // { label: 'Quarterly', value: 'QUARTERLY' },
  // { label: 'Half Yearly', value: 'HALF_YEARLY' },
];
