import { type AxiosResponse } from 'axios';
import request from 'services/request';

import { type getApplicantListReqModel } from '../types';

const END_POINTS = {
  application: 'api/application',
};

export function getApplicantList(payload: getApplicantListReqModel): Promise<AxiosResponse<any, any>> {
  return request({
    method: 'GET',
    url:
      `${END_POINTS.application}?limit=${payload.limit}&page=${payload.page}` +
      (payload.status ? `&status=${payload.status}` : ''),
  });
}
