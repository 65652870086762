import React, { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import BackButton from 'components/shared/BackButton/BackButton';
import Button from 'components/shared/Button';
import Loading from 'components/shared/Loading';
import { H3 } from 'components/Typography/Headlines';
import { routes } from 'constants/routes';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { otherInformationDefaultValues, propertyDetailsDefaultValues, unitDefaultValues, unitTypeContent } from './constants';
import styles from './CreateProperty.module.scss';
import ItemSelector from './ItemSelector';
import OtherInformation from './OtherInformation';
import PrimaryBankAccount from './PrimaryBankAccount';
import PrimaryManager from './PrimaryManager/PrimaryManager';
import PropertyDetails from './PropertyDetails';
import RentalOwner from './RentalOwner';
import SelectUnit from './SelectUnit';
import { createProperty, getItems, updateProperty } from './store/actions';
import {
  amenitiesItemsSelector,
  amenitiesOptionSelector,
  createPropertyLoadingSelector,
  featuresItemsSelector,
  featuresOptionSelector,
} from './store/selectors';
import { type IForm } from './types';
import Utilities from './Utilities';
import { propertySchema } from './validations/propertySchema';
import { getUrlParams, scrollToFirstErrorInput } from '../../../common/utils/utils';
import PrivateAccess from '../../../components/PrivateAccess';
import DetectInformation from '../../../components/shared/DetectInformation';
import { userRole } from '../../../constants/userStatus';
import { getPropertiesAction } from '../store/actions';
import propertiesSlice from '../store/propertiesSlice';
import { propertyLoadingSelector, propertySelector } from '../store/selectors';
import UnitCard from '../UnitCard';
import { getPropertyDetails } from '../utils';
import { getDataForUpdate } from '../utils/helpers';

const CreateProperty = () => {
  const form = useForm({
    defaultValues: { ...propertyDetailsDefaultValues, ...unitDefaultValues, ...otherInformationDefaultValues },
    resolver: yupResolver<any>(propertySchema),
    mode: 'all',
  });
  const {
    reset,
    getValues,
    control,
    formState: { errors, dirtyFields },
  } = form;
  const property = useSelector(propertySelector);
  const navigate = useNavigate();
  const selectUnitType = useWatch({ control, name: 'selectUnitType' });
  const [addUnit, setAddUnit] = useState(false);
  const dispatch = useDispatch();

  const featuresItems = useSelector(featuresItemsSelector);
  const amenitiesItems = useSelector(amenitiesItemsSelector);
  const featuresOption = useSelector(featuresOptionSelector);
  const amenitiesOption = useSelector(amenitiesOptionSelector);
  const loading = useSelector(createPropertyLoadingSelector);
  const propertyLoading = useSelector(propertyLoadingSelector);
  const urlParams = getUrlParams();
  useEffect(() => {
    const preventFormSubmit = (event: any) => {
      if (event.keyCode === 13 && event.target.tagName === 'INPUT') {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', preventFormSubmit);

    return () => {
      document.removeEventListener('keydown', preventFormSubmit);
    };
  }, []);
  useEffect(() => {
    dispatch(getItems({ type: 'REGULAR', name: 'amenity' }));
    dispatch(getItems({ type: 'REGULAR', name: 'feature' }));

    if (urlParams?.unit) {
      dispatch(getPropertiesAction({ unit: urlParams.unit }));
    } else {
      dispatch(getItems({ type: 'REGULAR', name: 'utility' }));
    }
  }, []);

  useEffect(() => {
    scrollToFirstErrorInput(errors);
  }, [errors]);

  useEffect(() => {
    if (property && urlParams?.unit) {
      reset({ ...getValues(), ...getPropertyDetails({ ...property }) });
    }
  }, [property, urlParams?.unit]);
  const onSubmit = (data: IForm) => {
    if (urlParams?.unit) {
      dispatch(updateProperty({ data: getDataForUpdate(data, dirtyFields), navigate, id: urlParams?.unit }));
    } else {
      dispatch(createProperty({ data, navigate }));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(propertiesSlice.actions.clearProperty());
    };
  }, []);

  useEffect(() => {
    if (urlParams?.unit && dirtyFields?.selectUnitType) {
      dispatch(updateProperty({ data: getDataForUpdate({ selectUnitType }, dirtyFields), navigate: null, id: urlParams?.unit }));
    }
  }, [selectUnitType]);

  return (
    <PrivateAccess roles={[userRole.LANDLOARD]} isPage>
      <div className={styles.createProperty}>
        {propertyLoading && <Loading isBig />}
        <BackButton to={routes.properties} />
        <H3 className={styles.title}>{urlParams?.unit ? 'Edit Properties' : 'Create Properties'}</H3>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <PropertyDetails form={form} />
          {!urlParams.unit && <SelectUnit form={form} />}

          {urlParams.unit && (
            <DetectInformation
              form={form}
              name="selectUnitType"
              content={unitTypeContent}
              skipErrors
              disabled={!!property?.units?.length && property?.units?.length > 1}
            />
          )}
          {urlParams.unit && selectUnitType === unitTypeContent.second.name && (
            <div className={styles.addUnits}>
              <H3 bold>Units</H3>
              {!addUnit && (
                <Button variant="contained" size="sm" type="button" label="Add Unit" onClick={() => setAddUnit(true)} />
              )}
            </div>
          )}
          {addUnit && (
            <UnitCard
              closeUnit={() => setAddUnit(false)}
              isAdd
              key={4}
              unit={{
                _id: '1',
                unitNumber: '',
                unitType: { value: '', label: '' },
                unitSize: '',
                bedrooms: '',
                bathrooms: '',
                parkingSpot: '',
                monthlyRent: '',
                status: { value: '', label: '' },
                rentDeposit: '',
              }}
              propertyId={property?._id || ''}
              isLast={false}
            />
          )}
          {urlParams.unit && property?.units && (
            <Box className={styles.units}>
              {property.units.map(unit => (
                <UnitCard key={unit._id} unit={unit} propertyId={property._id} isLast={property?.units?.length === 1} />
              ))}
            </Box>
          )}

          <ItemSelector
            form={form}
            label="Add Amenities"
            title="Amenities"
            description="Select any number of amenities as may apply to your property"
            items={amenitiesItems}
            options={amenitiesOption}
            name="amenity"
          />
          <ItemSelector
            form={form}
            title="Features"
            label="Add Features"
            description="Select any number of features as may apply to your property"
            items={featuresItems}
            options={featuresOption}
            name="feature"
          />
          <Utilities form={form} />
          <OtherInformation form={form} />
          <RentalOwner setValue={form.setValue} control={form.control} propertyId={property?._id} />
          <PrimaryBankAccount form={form} />
          <PrimaryManager form={form} />
          <Button
            className={styles.create}
            variant="contained"
            label={urlParams?.unit ? 'Save' : 'Create'}
            size="full-lg"
            type="submit"
            loading={loading}
            disabled={loading}
          />
        </form>
      </div>
    </PrivateAccess>
  );
};

export default CreateProperty;
