import React from 'react';

import { Box } from '@mui/material';
import cn from 'classnames';
import Button from 'components/shared/Button';
import { B3 } from 'components/Typography/Body';
import { H5 } from 'components/Typography/Headlines';
import modalNames from 'constants/modalNames';
import AddRentalOwnerModal from 'modals/AddRentalOwnerModal';
import modalsSlice from 'modals/store/modalsSlice';
import { type UseFormSetValue, useWatch, type Control } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';

import styles from './RentalOwner.module.scss';
import SvgIcon from '../../../../components/shared/utils/SvgIcon';
import { deleteOwner } from '../store/actions';
import { type IForm, type IOwner } from '../types';

interface RentalOwnerProps {
  setValue: UseFormSetValue<IForm>;
  control: Control<IForm>;
  propertyId?: string;
}

const RentalOwner = ({ setValue, control, propertyId }: RentalOwnerProps) => {
  const dispatch = useDispatch();
  const owners = useWatch({ control, name: 'owners' });
  const addOwner = () => {
    dispatch(modalsSlice.actions.showModal({ modalName: modalNames.ADD_PROPERTY_OWNER }));
  };

  function emailExists(owners: any, newEmail: any) {
    return owners.some((obj: IOwner) => obj?.email === newEmail);
  }

  const handleAddOwner = (data: IOwner) => {
    if (!emailExists(owners, data?.email)) {
      setValue('owners', [...owners, data], { shouldDirty: true });
    } else {
      toast.error(`The owner already exists`);
    }
  };
  const handleDeleteOwner = (data: IOwner) => {
    if (data?._id && propertyId) {
      dispatch(
        deleteOwner({
          propertyId: propertyId,
          ownerId: data._id,
        })
      );
    }
    setValue('owners', [...owners.filter(el => el?.email !== data?.email)], { shouldDirty: true });
  };

  return (
    <div className={styles.rentalOwner}>
      <H5 className={styles.title}>Who is the property owner?</H5>
      <B3 className={styles.desc}>This Information will be used to help prepare owner draw and 1099s</B3>
      {!!owners?.length &&
        owners.map((owner, index) => (
          <Box key={owner?.email} className={styles.items}>
            <SvgIcon icon={'close'} className={styles.closeIcon} onClick={() => handleDeleteOwner(owner)} />
            <div className={cn(styles.label, styles.lineButtom, styles.name)}>
              <div className={styles.iconsWrap}>
                <SvgIcon icon={'profile'} className={styles.icon} />
                <B3>Name</B3>
              </div>
              <B3 className={styles.value}>{owner?.fullName}</B3>
            </div>
            <div className={cn(styles.label, styles.lineButtom)}>
              <div className={styles.iconsWrap}>
                <SvgIcon icon={'phone'} className={cn(styles.icon, styles.phoneIcon)} />
                <B3>Phone</B3>
              </div>
              <B3 className={styles.value}>{owner?.phone}</B3>
            </div>
            <div className={cn(styles.label, styles.email, { [styles.phone]: owners?.length !== index + 1 })}>
              <div className={styles.iconsWrap}>
                <SvgIcon icon={'email'} className={cn(styles.icon, styles.phoneIcon)} />
                <B3>Email</B3>
              </div>
              <B3 className={styles.value}>{owner?.email}</B3>
            </div>
          </Box>
        ))}
      <Button variant="outlined" size="full-lg" type={'button'} label="+ Add Rental owner" onClick={addOwner} />
      <AddRentalOwnerModal handleAddOwner={handleAddOwner} />
    </div>
  );
};

export default RentalOwner;
