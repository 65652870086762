import request from '../../../services/request';
import { type IForm } from '../store/types';
const END_POINTS = {
  sendMessage: 'api/message',
};

export function sendMessage({ message, chatId }: IForm) {
  return request({
    method: 'POST',
    url: `${END_POINTS.sendMessage}`,
    data: {
      message,
      chatId,
    },
  });
}

export function getChat(chatId: string) {
  return request({
    url: `${END_POINTS.sendMessage}` + `?chatId=${chatId}`,
  });
}
