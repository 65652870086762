function hexToRGB(hex: string, alpha: number) {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
}

export const statusColors = {
  PAID: {
    background: 'rgba(var(--active),0.1)',
    color: 'rgba(var(--active))',
  },
  ACTIVE: {
    background: 'rgba(var(--active),0.1)',
    color: 'rgba(var(--active))',
  },
  SIGNED: {
    background: 'rgba(var(--active),0.1)',
    color: 'rgba(var(--active))',
  },
  REJECTED: {
    background: 'rgba(var(--rejected),0.1)',
    color: 'rgba(var(--rejected))',
  },
  PENDING: {
    background: 'rgba(var(--pending),0.1)',
    color: 'rgba(var(--pending))',
  },
  STOPPED: {
    background: 'rgba(var(--stopped),0.1)',
    color: 'rgba(var(--stopped))',
  },
  END: {
    background: hexToRGB('#9499A4', 0.1),
    color: '#4D5567',
  },
  INCOMPLETE: {
    background: 'rgba(var(--incomplete),0.1)',
    color: 'rgba(var(--incomplete))',
  },
  NEW: {
    background: 'rgba(var(--new),0.1)',
    color: 'rgba(var(--new))',
  },
  CONFIRMED: {
    background: 'rgba(var(--active),0.1)',
    color: 'rgba(var(--active))',
  },
  NOT_SENT: {
    background: hexToRGB('#9499A4', 0.1),
    color: '#4D5567',
  },
  UNSIGNED: {
    background: 'rgba(var(--unsigned),0.1)',
    color: 'rgba(var(--unsigned))',
  },
  REGISTERED: {
    background: 'rgba(var(--active),0.1)',
    color: 'rgba(var(--active))',
  },
  LOW: {
    background: 'rgba(var(--active),0.1)',
    color: 'rgba(var(--active))',
  },
  DONE: {
    background: 'rgba(var(--active),0.1)',
    color: 'rgba(var(--active))',
  },
  INPROGRESS: {
    background: 'rgba(var(--pending),0.1)',
    color: 'rgba(var(--pending))',
  },
  CRITICAL: {
    background: 'rgba(var(--rejected),0.1)',
    color: 'rgba(var(--rejected))',
  },
  NORMAL: { background: 'rgba(var(--pending),0.1)', color: 'rgba(var(--pending))' },
  HIGH: { background: 'rgba(var(--rejected),0.1)', color: 'rgba(var(--rejected))' },
  CANCELLED: {
    background: 'rgba(var(--rejected),0.1)',
    color: 'rgba(var(--rejected))',
  },
  RETURNED: {
    background: 'rgba(var(--returned),0.1)',
    color: 'rgba(var(--returned))',
  },
  OVERDUE: {
    background: 'rgba(var(--overdue),0.1)',
    color: 'rgba(var(--overdue))',
  },
};
export const lineColors = {
  PAID: {
    background: 'rgba(var(--active))',
  },
  ACTIVE: {
    background: 'rgba(var(--active))',
  },
  SIGNED: {
    background: 'rgba(var(--active))',
  },
  REJECTED: {
    background: 'rgba(var(--rejected))',
  },
  PENDING: {
    background: 'rgba(var(--pending))',
  },
  STOPPED: {
    background: 'rgba(var(--stopped))',
  },
  END: {
    background: '#4D5567',
  },
  INCOMPLETE: {
    background: 'rgba(var(--incomplete))',
  },
  NEW: {
    background: 'rgba(var(--new))',
  },
  CONFIRMED: {
    background: 'rgba(var(--active))',
  },
  NOT_SENT: {
    background: '#4D5567',
  },
  UNSIGNED: {
    background: 'rgba(var(--unsigned))',
  },
  REGISTERED: {
    background: 'rgba(var(--active))',
  },
  RETURNED: {
    background: 'rgba(var(--returned))',
  },
  OVERDUE: {
    background: 'rgba(var(--overdue))',
  },
  DONE: {
    background: 'rgba(var(--active))',
  },
  CANCELLED: {
    background: 'rgba(var(--rejected))',
  },
  INPROGRESS: {
    background: 'rgba(var(--pending))',
  },
};
