import request from 'services/request';

import { type IAddManager, type getManagersListReqModel } from '../types';

const END_POINTS = {
  getManagers: 'api/account',
  addManager: 'api/account/add-manager',
};

export function getManagerList(payload: getManagersListReqModel) {
  return request({
    method: 'GET',
    url: `${END_POINTS.getManagers}?limit=${payload.limit}&page=${payload.page}&role=MANAGER`,
  });
}

export function addManager(payload: IAddManager) {
  return request({
    method: 'POST',
    url: `${END_POINTS.addManager}`,
    data: payload,
  });
}
