export const userStatus: { [key: string]: string } = {
  ACTIVE: 'Active',
  PAID: 'Paid',
  PENDING: 'Pending',
  REJECTED: 'Rejected',
  STOPPED: 'Stopped',
  END: 'End',
  INCOMPLETE: 'Incomplete',
  NEW: 'New',
  CONFIRMED: 'Confirm',
  NOT_SENT: 'Not sent',
  UNSIGNED: 'Unsigned',
  SIGNED: 'Signed',
  REGISTERED: 'Registered',
  LOW: 'Low',
  CRITICAL: 'Critical',
  NORMAL: 'Normal',
  HIGH: 'High',
  INPROGRESS: 'Inprogress',
  DONE: 'Done',
  CANCELLED: 'Cancelled',
  RETURNED: 'Returned',
  OVERDUE: 'Overdue',
};
export enum userRole {
  TENANT = 'TENANT',
  LANDLOARD = 'LANDLORD',
  MANAGER = 'MANAGER',
}

export enum UStatus {
  REGISTERED = 'REGISTERED',
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
}

export enum UsersStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  STOPPED = 'STOPPED',
  END = 'END',
  INCOMPLETE = 'INCOMPLETE',
  NEW = 'NEW',
  CONFIRMED = 'CONFIRMED',
  NOT_SENT = 'NOT_SENT',
  UNSIGNED = 'UNSIGNED',
  SIGNED = 'SIGNED',
  REGISTERED = 'REGISTERED',
}

export enum MaintenanceTaskStatus {
  NEW = 'NEW',
  INPROGRESS = 'INPROGRESS',
  CANCELLED = 'CANCELLED',
  DONE = 'DONE',
}

export enum applicantsStatus {
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  INCOMPLETE = 'INCOMPLETE',
  NEW = 'NEW',
  CONFIRMED = 'CONFIRMED',
}

export const activeUsers = ['ACTIVE', 'REGISTERED'];

export const colors: any = {
  ACTIVE: {
    borderLeft: '3px solid rgba(var(--active))',
  },
  REJECTED: {
    borderLeft: '3px solid rgba(var(--rejected))',
  },
  PENDING: {
    borderLeft: '3px solid rgba(var(--pending))',
  },
  STOPPED: {
    borderLeft: '3px solid rgba(var(--stopped))',
  },
  END: {
    borderLeft: '3px solid #4D5567',
  },
  INCOMPLETE: {
    borderLeft: '3px solid rgba(var(--incomplete)',
  },
  NEW: {
    borderLeft: '3px solid rgba(var(--new))',
  },
  CONFIRMED: {
    borderLeft: '3px solid rgba(var(--active))',
  },
  REGISTERED: {
    borderLeft: '3px solid rgba(var(--active))',
  },
};
