import { type RootState } from 'store/setupStore';

import {
  type vehiclesDataModel,
  type ApplicationsDataModel,
  type petsDataModel,
  type occupantsDataModel,
  type ResidentialDataModel,
  type PersonalInformationDataModel,
  type yourIncomeDataModel,
  type AccountDataModel,
} from '../../types/applicationTypes';

export const applicationLoadingSelector = (state: RootState): boolean | undefined => state.applications.isLoading;
export const moveInSelector = (state: RootState): string | null | undefined | Date => state.applications.coApplicants?.moveIn;
export const personalInformationDataSelector = (state: RootState): PersonalInformationDataModel | null | undefined =>
  state.applications.personalInformation;
export const residentialDataSelector = (state: RootState): ResidentialDataModel | null | undefined =>
  state.applications.residential;
export const occupantsDataSelector = (state: RootState): occupantsDataModel | null | undefined => state.applications.coApplicants;
export const petsDataSelector = (state: RootState): petsDataModel[] | undefined => state.applications.coApplicants?.pets;
export const hasPetsDataSelector = (state: RootState): boolean | undefined => state.applications.coApplicants?.hasPet;
export const occupantsAboveNumberSelector = (state: RootState): number | undefined =>
  state.applications.coApplicants?.aboveNumber;
export const occupantsUnderNumberSelector = (state: RootState): number | undefined =>
  state.applications.coApplicants?.underNumber;
export const vehiclesDataSelector = (state: RootState): vehiclesDataModel[] | undefined =>
  state.applications.coApplicants?.vehicles;
export const hasVehiclesDataSelector = (state: RootState): boolean | undefined => state.applications.coApplicants?.hasVehicle;
export const yourIncomeDataSelector = (state: RootState): yourIncomeDataModel | null | undefined => state.applications.yourIncome;
export const documentsDataSelector = (state: RootState): any | null | undefined => state.applications.documents;
export const applicationDataSelector = (state: RootState): ApplicationsDataModel => state.applications;
export const accountDataSelector = (state: RootState): AccountDataModel | null | undefined => state.applications.account;
export const plaidLinkSelector = (state: RootState): string | null | undefined => state.applications.linkToken;
export const stepSelector = (state: RootState): string | null | undefined => state.applications.step;
export const paymentSuccessSelector = (state: RootState): any | null | undefined => state.applications.paymentSuccess;
export const hasPlaidVerifyedSelector = (state: RootState): boolean | undefined => state.applications.plaidVerified;
