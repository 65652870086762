import {
  type CSSProperties,
  type FC,
  type HTMLProps,
  memo,
  type PropsWithChildren,
  type ReactElement,
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import cx from 'classnames';
import { type NavLink, type NavLinkProps, useNavigate } from 'react-router-dom';

import styles from './MenuItem.module.scss';
import SvgIcon from '../../components/shared/utils/SvgIcon';
import { B3 } from '../../components/Typography/Body';
import MenuLink from '../MenuLink';
import { SidebarContext } from '../SideBar';
import { isNavLinkComponent } from '../utils';

type ChildrenFunction = (api: { isCollapsed: boolean; isActive?: boolean; isHovered?: boolean }) => ReactNode;

interface LinkProps {
  className: string;
  children: ReactNode;
  title: string;
}

export interface Props {
  className?: string;
  children: ChildrenFunction | ReactNode;
  linkTo?: string;
  linkComponent?: typeof NavLink | FC<PropsWithChildren<LinkProps>>;
  style?: CSSProperties;
  title?: string;
  iconSize?: number | string;
  icon?: string | ChildrenFunction | ReactElement;
  isActive?: boolean;
  onClick?: (isActive: boolean) => void;
}

const MenuItem: FC<Props & Omit<Partial<NavLinkProps>, 'children'> & Omit<HTMLProps<HTMLLinkElement>, 'children'>> = ({
  className,
  style,
  children,
  linkTo,
  linkComponent,
  title,
  icon,
  isActive: isActiveWithProps,
  onClick,
  ...restProps
}) => {
  const navigate = useNavigate();
  const { isCollapsed } = useContext(SidebarContext);
  const [isActive, setIsActive] = useState(isActiveWithProps ?? false);
  const [isHovered, setIsHovered] = useState(false);
  const onActiveChange = useCallback(
    (active: boolean) => {
      if (onClick) {
        onClick(active);
      } else {
        setTimeout(() => setIsActive(active));
      }
    },
    [onClick]
  );

  useEffect(() => {
    setIsActive(!!isActiveWithProps);
  }, [isActiveWithProps]);

  return (
    <div
      className={cx(
        styles.menuItem,
        {
          [styles.collapsed as string]: isCollapsed,
          [styles.isActiveMenu as string]: isActive,
        },
        className
      )}
      onMouseLeave={() => setIsHovered(false)}
      onMouseEnter={() => setIsHovered(true)}
      style={style}
    >
      {typeof children === 'function' ? (
        children({ isCollapsed, isActive, isHovered })
      ) : (
        <MenuLink
          title={isCollapsed ? title : ''}
          linkComponent={linkComponent}
          isCollapsed={isCollapsed}
          isActive={isActive}
          {...(isNavLinkComponent(linkComponent)
            ? { onActiveChange }
            : {
                onClick: () => {
                  if (onClick) {
                    onClick(isActive);
                  }
                },
              })}
          onClick={() => navigate(linkTo || '')}
          linkTo={linkTo}
          {...restProps}
        >
          <div className={styles.elem}>
            <span className={styles.icon}>
              {typeof icon === 'string' ? (
                <SvgIcon className={cx(styles.iconElement, { [styles.selected as string]: isActive })} icon={icon} />
              ) : typeof icon === 'function' ? (
                icon({ isCollapsed, isActive, isHovered })
              ) : (
                icon
              )}
            </span>
            <B3
              bold
              className={cx(styles.label, {
                [styles.collapsed as string]: isCollapsed,
                [styles.isActiveLabel as string]: isActive,
              })}
            >
              {children}
            </B3>
          </div>
        </MenuLink>
      )}
    </div>
  );
};

export default Object.assign(memo(MenuItem), { displayName: 'MenuItem' });
