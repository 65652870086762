import { type Item } from './types';
import { type IForm, type IOption, type IOwner } from '../types';

const getUnits = (units: { [key: string]: string }) => {
  const map = new Map();
  const unitsKey = Object.keys(units);
  unitsKey.forEach(el => {
    const [name, index] = el.split('#');
    map.set(index || '1', { ...map.get(index || '1'), [name]: units[el] });
  });
  return [...map.values()];
};

const getItems = (amenity: string[], amenityItems: IOption[]) => {
  const existing = [...amenity];
  const added: string[] = [];
  amenityItems.forEach(el => {
    if (el._id) {
      existing.push(el._id);
    } else {
      added.push(el.value);
    }
  });
  return { existing: [...new Set(existing)], added };
};
const getManagers = (managerEmail: { account?: string; value: string; email: string }[]) => {
  const existing: any[] = [];
  const added: string[] = [];
  managerEmail.forEach(el => {
    if (el?.account) {
      existing.push({ id: el.account, email: el.email });
    } else {
      added.push(el.value);
    }
  });
  return { existing, added };
};
const getOwners = (managerEmail: IOwner[]) => {
  const owners: any[] = [];
  managerEmail.forEach((el: IOwner) => {
    owners.push({
      phone: el?.phone,
      fullName: el?.fullName,
      email: el?.email,
    });
  });
  return owners;
};

const updateUtilities = (utilityColumnsData: Item[]) => {
  const existing: any[] = [];
  const added: any[] = [];
  utilityColumnsData.forEach(el => {
    if (!el?._id.includes('#')) {
      existing.push({ utility: el?.utility?._id || el?._id, payer: el?.isLendloard ? 'LANDLORD' : 'TENANT' });
    } else {
      added.push({ utility: el.value, payer: el?.isLendloard ? 'LANDLORD' : 'TENANT' });
    }
  });
  return {
    existing,
    added,
  };
};

const getUtilities = (utilityColumnsData: Item[]) => {
  const existing: { utility: string; payer: string }[] = [];
  const added: { utility: string; payer: string }[] = [];
  utilityColumnsData.forEach(el => {
    if (el._id && el?.type) {
      existing.push({ utility: el._id, payer: el?.isLendloard ? 'LANDLORD' : 'TENANT' });
    } else {
      added.push({ utility: el.name, payer: el?.isLendloard ? 'LANDLORD' : 'TENANT' });
    }
  });
  return {
    existing,
    added,
  };
};
const createPropertyData = (data: IForm) => {
  const {
    propertyName,
    propertyType,
    address,
    address1,
    city,
    state,
    zipCode,
    country,
    selectUnitType,
    laundry,
    petWeight,
    petDescription,
    hasPets,
    isRentersInsurance,
    utilityColumnsData,
    owners,
    managerEmail,
    payoutBankAccount,
  } = data;
  const havePets = hasPets === 'Yes';
  const rentersInsurance = isRentersInsurance === 'Yes';

  return {
    amenities: getItems(data.amenity, data.amenityItems),
    features: getItems(data.feature, data.featureItems),
    utilities: getUtilities(utilityColumnsData),
    payoutBankAccount: payoutBankAccount.bank_account_id,
    propertyName,
    propertyType: propertyType,
    googleApi: {
      address: {
        id: address?.place_id,
        value: address?.label,
        lat: address?.lat,
        lng: address?.lng,
      },
      address2: address1,
      city,
      state,
      zipCode,
      country: { ...country, value: country?.label },
    },
    units: getUnits(data.units),
    selectUnitType,
    other: {
      isRentersInsurance: rentersInsurance,
      hasPets: havePets,
      laundry,
      ...(havePets && { petDescription }),
      ...(havePets && { petWeight }),
    },
    ...(owners?.length && { owners: getOwners(owners) }),
    managers: getManagers(managerEmail),
  };
};

export default createPropertyData;

export const updatePropertyData = (data: any) => {
  const {
    address,
    city,
    state,
    zipCode,
    country,
    address1,
    managerEmail,
    owners,
    amenityItems,
    amenity,
    feature,
    featureItems,
    utilityColumnsData,
    laundry,
    hasPets,
    isRentersInsurance,
    petDescription,
    petWeight,
    payoutBankAccount,
    ...res
  } = data;
  console.log(laundry, hasPets, isRentersInsurance, petDescription, petWeight);
  return {
    ...res,
    ...(payoutBankAccount && { payoutBankAccount: payoutBankAccount.bank_account_id }),
    ...(utilityColumnsData && { utilities: updateUtilities(utilityColumnsData) }),
    ...(owners && { owners: getOwners(owners) }),
    ...(amenity && { amenities: getItems(amenity, amenityItems) }),
    ...(feature && { features: getItems(feature, featureItems) }),
    ...(managerEmail ? { managers: getManagers(managerEmail) } : {}),
    ...(data.address
      ? {
          googleApi: {
            address: {
              id: address?.place_id,
              value: address?.label,
              lat: address?.lat,
              lng: address?.lng,
            },
            address2: address1,
            city: city,
            state: state,
            zipCode: zipCode,
            country: { code: country.code, value: country?.label, label: country?.label },
          },
        }
      : {}),
  };
};
