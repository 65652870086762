import { useEffect } from 'react';

import cn from 'classnames';
import Button from 'components/shared/Button/Button';
import { B1, B3 } from 'components/Typography/Body';
import { H4, H5 } from 'components/Typography/Headlines';
import { getApplicationById, plaidRetrieveBalance, plaidVerification } from 'modules/Application/store/actions';
import { hasPlaidVerifyedSelector, plaidLinkSelector } from 'modules/Application/store/selectors';
import { usePlaidLink } from 'react-plaid-link';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import styles from './IncomeVerefication.module.scss';

const IncomeVerefication = () => {
  const dispatch = useDispatch();
  const plaidLinkToken = useSelector(plaidLinkSelector);
  const hasPlaidVerifyed = useSelector(hasPlaidVerifyedSelector);
  const applicationId = localStorage.getItem('id');

  useEffect(() => {
    if (applicationId) {
      dispatch(getApplicationById(applicationId));
      dispatch(plaidVerification(applicationId));
    }
  }, []);

  const { open, ready, error } = usePlaidLink({
    token: plaidLinkToken as string,
    onSuccess: (publicToken: string) => {
      if (applicationId) {
        dispatch(plaidRetrieveBalance({ publicToken, applicationId }));
      }
    },
  });

  const openPlaid = (): void => {
    if (ready && !error) {
      open();
    }
  };

  return (
    <div className={styles.container}>
      <H4 className={styles.title} bold>
        Rental Application
      </H4>
      <B3 className={styles.desc}>Evolve Realty & Development</B3>
      <div className={styles.infoBox}>
        <H5 className={styles.incomeTitle}>Incom Verification</H5>
        <B1 bold className={styles.rightInfo}>
          Proof of Income
        </B1>
      </div>
      <B3 className={styles.text}>
        Get approved faster! Authenticate with your bank to instantly prove your income. Powered by Experian. All you will need is
        your login credentials for the accounts you want considered. It's a simple and secure process similar to online banking
        and is more secure than emailing financial documents.How it works
        <ol>
          <li>Select your financial institution.</li>
          <li>Securely authenticate with your financial institution.</li>
          <li>Select the accounts you want considered & add multiple banks if necessary.</li>
          <li>Click submit. That's it! </li>
        </ol>
        Your data is accessed as a read-only report and all data is encrypted
      </B3>
      <B3 className={styles.subText}>Have more questions? You can find more answers on our</B3>
      <div className={styles.faq}>
        <div className={styles.circleIcon}>i</div>
        <B1 bold className={styles.faqText}>
          FAQs for Income Verification
        </B1>
      </div>
      <div className={styles.authenticateBankBtn}>
        <Button
          variant="contained"
          size="full-lg"
          type="button"
          label="Authenticate Bank"
          onClick={openPlaid}
          disabled={hasPlaidVerifyed}
        />
      </div>
      <B3 className={styles.endText}>
        If you do not bank online or want to upload your own proof of income, you can do so on the Attach Document page
      </B3>
      <div className={styles.continueeBankBtn}>
        <Button
          className={cn({ [styles.skip]: !hasPlaidVerifyed })}
          type={'submit'}
          variant={hasPlaidVerifyed ? 'contained' : 'outlined'}
          size={hasPlaidVerifyed ? 'full-lg' : 'full-md'}
          label={hasPlaidVerifyed ? 'Continue' : 'Skip'}
        />
      </div>
    </div>
  );
};

export default IncomeVerefication;
