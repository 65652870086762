import { useCallback, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { getRandomId, unregisterFormById } from 'common/utils/utils';
import Button from 'components/shared/Button/Button';
import { Input } from 'components/shared/FormElements';
import Checkbox from 'components/shared/FormElements/Checkbox/Checkbox';
import DatePicker from 'components/shared/FormElements/DatePicker';
import SvgIcon from 'components/shared/utils/SvgIcon';
import { B1 } from 'components/Typography/Body';
import { H5 } from 'components/Typography/Headlines';
import { regexp } from 'constants/regexp';
import dayjs from 'dayjs';
import { hasPetsDataSelector, moveInSelector, petsDataSelector } from 'modules/Application/store/selectors';
import { type IForm } from 'modules/Application/types';
import { type petsDataModel } from 'modules/types/applicationTypes';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

import styles from './Pets.module.scss';

const Pets = () => {
  const {
    control,
    unregister,
    setValue,
    formState: { errors },
  } = useFormContext<IForm>();
  const isPets = useWatch({ control, name: 'hasPet' });
  const [petsGroupId, setPetsGroupId] = useState<{ id: string }[]>([]);
  const petsData = useSelector(petsDataSelector);
  const hasPets = useSelector(hasPetsDataSelector);
  const moveIn: any = useSelector(moveInSelector);

  useEffect(() => {
    setValue('hasPet', !!hasPets);
    if (petsData) {
      const [mainPetsGroup, ...aditionalPetsGroup] = petsData || [];
      setValue('pets.name#', mainPetsGroup?.name);
      setValue('pets.breed#', mainPetsGroup?.breed);
      setValue('pets.weight#', mainPetsGroup?.weight);
      setValue('pets.age#', mainPetsGroup?.age);
      setValue('moveIn', dayjs(moveIn) as any, { shouldValidate: true });
      const newPets: { id: string }[] = [];
      if (aditionalPetsGroup) {
        aditionalPetsGroup.forEach((pets: petsDataModel) => {
          const id = getRandomId();
          setValue(`pets.name${id}`, pets.name);
          setValue(`pets.breed${id}`, pets.breed);
          setValue(`pets.weight${id}`, pets.weight);
          setValue(`pets.age${id}`, pets.age);
          newPets.push({ id });
        });
      }
      setPetsGroupId(newPets);
    }
  }, [petsData]);

  const deletePets = useCallback(
    (id: string) => {
      unregisterFormById([`pets.name${id}`, `pets.breed${id}`, `pets.weight${id}`, `pets.age${id}`], unregister);
      const newPetsGroup = petsGroupId.filter(petsGroup => petsGroup.id !== id);
      setPetsGroupId(newPetsGroup);
    },
    [petsGroupId, unregister]
  );

  const addPets = useCallback(() => {
    const newpetsGroup = [...petsGroupId, { id: getRandomId() }];
    setPetsGroupId(newpetsGroup);
  }, [petsGroupId]);
  return (
    <div className={styles.container}>
      <div className={styles.applyToRent}>
        <B1 bold className={styles.applyText}>
          You can select your ideal date
        </B1>
      </div>
      <Box className={styles.inputBox}>
        <Box className={styles.grid1}>
          <Controller
            name="moveIn"
            control={control}
            render={({ field }) => (
              <DatePicker
                className={styles.calendar}
                label="Desired move-in"
                {...field}
                errorMessage={errors?.moveIn?.message}
                hasError={!!errors.moveIn}
              />
            )}
          />
        </Box>
      </Box>
      <H5 className={styles.petsTitle}>Pets</H5>
      {/* <B3 className={styles.text}>
        Please check the listing for this property's pet policy, as pets may not be allowed in certain units.
      </B3> */}
      <Box className={styles.inputBox}>
        <Box className={styles.checkboxGrid}>
          <Controller name="hasPet" control={control} render={({ field }) => <Checkbox {...field} checked={!!isPets} />} />
          <B1 bold className={!isPets ? `${styles.petsText} ${styles.petsTextUnchacked}` : styles.petsText}>
            I have pets
          </B1>
        </Box>
        {!!isPets && (
          <div className={styles.petsGroup}>
            <Box className={styles.inputBox}>
              <Box className={styles.grid}>
                <Controller
                  name={'pets.name#'}
                  control={control}
                  render={({ field }) => (
                    <Input
                      label="Pet`s Name"
                      placeholder="Enter your Pet`s Name"
                      errorMessage={errors?.pets ? errors.pets['name#']?.message : ''}
                      hasError={errors?.pets && !!errors.pets['name#']}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name={'pets.breed#'}
                  control={control}
                  render={({ field }) => (
                    <Input
                      label="Type/Breed*"
                      errorMessage={errors?.pets ? errors.pets['breed#']?.message : ''}
                      hasError={errors?.pets && !!errors.pets['breed#']}
                      placeholder="Enter your Pet`s Type or Breed"
                      {...field}
                    />
                  )}
                />
              </Box>
              <Box className={styles.grid}>
                <Controller
                  name={'pets.weight#'}
                  control={control}
                  render={({ field }) => (
                    <Input
                      label="Weight(lb)"
                      placeholder="Enter your Pet`s Weight(lbs)"
                      errorMessage={errors?.pets ? errors.pets['weight#']?.message : ''}
                      hasError={errors?.pets && !!errors.pets['weight#']}
                      {...field}
                      regexp={regexp.number}
                    />
                  )}
                />
                <Controller
                  name={'pets.age#'}
                  control={control}
                  render={({ field }) => (
                    <Input
                      label="Age"
                      placeholder="Choose Your Pet`s Age"
                      errorMessage={errors?.pets ? errors.pets['age#']?.message : ''}
                      hasError={errors?.pets && !!errors.pets['age#']}
                      regexp={regexp.number}
                      {...field}
                    />
                  )}
                />
              </Box>
              {!!petsGroupId.length && (
                <div className={styles.pets}>
                  {petsGroupId.map(({ id }) => (
                    <div className={styles.petsWrap} key={id}>
                      <div className={styles.closeBox}>
                        <SvgIcon icon={'close'} className={styles.closeIcon} onClick={() => deletePets(id)} />
                      </div>
                      <Box className={styles.inputBox}>
                        <Box className={styles.grid}>
                          <Controller
                            name={`pets.name${id}`}
                            control={control}
                            render={({ field }) => (
                              <Input
                                label="Pet`s Name"
                                errorMessage={errors?.pets ? errors.pets[`name${id}`]?.message : ''}
                                hasError={errors?.pets && !!errors.pets[`name${id}`]}
                                placeholder="Enter your Pet`s Name"
                                {...field}
                              />
                            )}
                          />
                          <Controller
                            name={`pets.breed${id}`}
                            control={control}
                            render={({ field }) => (
                              <Input
                                label="Type/Breed*"
                                errorMessage={errors?.pets ? errors.pets[`breed${id}`]?.message : ''}
                                hasError={errors?.pets && !!errors.pets[`breed${id}`]}
                                placeholder="Enter your Pet`s Type or Breed"
                                {...field}
                              />
                            )}
                          />
                        </Box>
                        <Box className={styles.grid}>
                          <Controller
                            name={`pets.weight${id}`}
                            control={control}
                            render={({ field }) => (
                              <Input
                                label="Weight(lb)"
                                errorMessage={errors?.pets ? errors.pets[`weight${id}`]?.message : ''}
                                hasError={errors?.pets && !!errors.pets[`weight${id}`]}
                                placeholder="Enter your Pet`s Weight(lbs)"
                                {...field}
                              />
                            )}
                          />
                          <Controller
                            name={`pets.age${id}`}
                            control={control}
                            render={({ field }) => (
                              <Input
                                label="Age"
                                errorMessage={errors?.pets ? errors.pets[`age${id}`]?.message : ''}
                                hasError={errors?.pets && !!errors.pets[`age${id}`]}
                                placeholder="Choose Your Pet`s Age"
                                {...field}
                              />
                            )}
                          />
                        </Box>
                      </Box>
                    </div>
                  ))}
                </div>
              )}
              <Button
                className={styles.addPets}
                disabled={petsGroupId.length === 5}
                type={'button'}
                variant="outlined"
                size="full-lg"
                label="+ Add new pet"
                onClick={addPets}
              />
            </Box>
          </div>
        )}
      </Box>
    </div>
  );
};

export default Pets;
