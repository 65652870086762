import * as Yup from 'yup';

export const accountingSchema = Yup.object().shape({
  property: Yup.object()
    .shape({
      label: Yup.string().required('Required field').nullable(),
      value: Yup.string().required('Required field').nullable(),
    })
    .required('Required field')
    .nullable(),
  startDate: Yup.string().required('Required field'),
  endDate: Yup.string().required('Required field'),
});
