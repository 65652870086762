import * as Yup from 'yup';

export const createLeaseSchema = Yup.object().shape({
  isSigned: Yup.boolean(),
  insurance: Yup.string(),
  tenant: Yup.object().required('Required field'),
  leaseDuration: Yup.object().required('Required field'),
  startDate: Yup.object().required('Required field'),
  deposit: Yup.string().required('Required field'),
  rent: Yup.string().required('Required field'),
  amount: Yup.string().required('Required field'),
  gracePeriod: Yup.string().required('Required field'),
  documents: Yup.array().when(['isSigned'], {
    is: (isSigned: boolean) => isSigned,
    then: schema => schema.required('Required field'),
    otherwise: schema => schema.nullable(),
  }),
  nextDueDate: Yup.object().when(['isSigned'], {
    is: (isSigned: boolean) => isSigned,
    then: schema => schema.required('Required field'),
    otherwise: schema => schema.nullable(),
  }),
});
