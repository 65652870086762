import { useEffect } from 'react';

import Chart from 'components/shared/Chart';
import { useDispatch, useSelector } from 'react-redux';

import styles from './TenantsIncome.module.scss';
import { getTenantsIncomeAction } from '../../../store/actions';
import { tenantsIncomeSelector } from '../../../store/selectors';

interface TenantsIncomeProps {
  title: string;
}

const TenantsIncome: React.FC<TenantsIncomeProps> = ({ title }) => {
  const dispatch = useDispatch();
  const tenantsIncome = useSelector(tenantsIncomeSelector);

  useEffect(() => {
    dispatch(getTenantsIncomeAction());
  }, []);

  function transformObjectToArray(obj: any) {
    const result = [];
    for (const key in obj) {
      const formattedName = key.replace(/\b\w/g, char => char.toUpperCase());
      const finalName = formattedName.replace('Coount', '');
      const spacedName = finalName.replace(/([a-z])([A-Z])/g, '$1 $2');
      result.push({ name: spacedName, y: obj[key] });
    }

    return result;
  }

  const data: any = {
    content: [
      {
        minPointSize: 10,
        innerSize: '85%',
        zMin: 0,
        name: 'Tenants',
        borderRadius: 10,
        data: transformObjectToArray(tenantsIncome),
      },
    ],
    legendTitle: '',
    colors: ['#E3296C', '#FDBF03', '#142B6F', '#2C5F9C'],
    layout: 'vertical',
    itemWidth: 0,
    title: 'Incom From Tenants',
    showDataLabels: true,
  };

  return (
    <div className={styles.chart}>
      <Chart
        type="pie"
        data={data.content}
        text={title}
        colors={data.colors}
        legendTitle={data.legendTitle}
        layout={data.layout}
        showDataLabels={data.showDataLabels}
        itemWidth={data.itemWidth}
      />
    </div>
  );
};

export default TenantsIncome;
