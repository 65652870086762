import { Box } from '@mui/system';
import Card from 'components/shared/Card';
import StatusLine from 'components/shared/StatusLine';
import { B3 } from 'components/Typography/Body';
import { useNavigate } from 'react-router-dom';

import styles from './ApplicantCard.module.scss';
import StatusTag from '../../../../src/components/shared/StatusTag';
import { formatDate } from '../../../common/utils/utils';

const ApplicantCard = ({ applicant }: any) => {
  const navigate = useNavigate();

  const onCardClick = (id: string) => {
    navigate(`${applicant?.listing?._id}?id=${id}`);
  };

  return (
    <Card className={styles.applicantCard}>
      <Card.Content className={styles.applicantCardContent}>
        <Box onClick={() => onCardClick(applicant?._id)}>
          <div className={styles.cardItem}>
            <StatusLine type={applicant?.status}>
              <B3 bold className={styles.cardItemName}>
                {applicant?.personalInformation?.firstName} {''}
                {applicant?.personalInformation?.lastName} {''}
                {applicant?.personalInformation?.middleName}
              </B3>
            </StatusLine>
          </div>
          <div className={styles.cardItem}>
            <B3 bold className={styles.cardItemtitle}>
              Data
            </B3>
            <p className={styles.cardItemInfo}>{formatDate(applicant?.coApplicants?.moveIn)}</p>
          </div>
          <div className={styles.cardItem}>
            <B3 bold className={styles.cardItemtitle}>
              Unit
            </B3>
            <p className={styles.cardItemInfo}>{applicant?.listing?.title}</p>
          </div>
          <div className={styles.cardItem}>
            <B3 bold className={styles.cardItemtitle}>
              Status
            </B3>
            <StatusTag label={applicant?.status} type={applicant?.status} />
          </div>
        </Box>
      </Card.Content>
    </Card>
  );
};

export default ApplicantCard;
