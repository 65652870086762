import { configureStore, type PreloadedState } from '@reduxjs/toolkit';
import { enableMapSet } from 'immer';
import saga from 'redux-saga';

enableMapSet();
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

// setupStore function is needed to create a separate Redux store instance for every test,
// rather than reusing the same store instance and resetting its state.
// That ensures no values accidentally leak between tests.
export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  const sagaMiddleware = saga();

  const reduxStore = configureStore({
    reducer: rootReducer,
    middleware: [sagaMiddleware],
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState,
  });

  sagaMiddleware.run(rootSaga);

  return reduxStore;
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
