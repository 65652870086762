import React from 'react';

import { FormControl, FormControlLabel, Radio, RadioGroup, Box } from '@mui/material';
import { Input } from 'components/shared/FormElements';
import { B1, B3 } from 'components/Typography/Body';
import { H5 } from 'components/Typography/Headlines';
import { regexp } from 'constants/regexp';
import { Controller, type UseFormReturn, useWatch } from 'react-hook-form';

import styles from './OtherInformation.module.scss';
import { type IForm } from '../types';
interface OtherInformationProps {
  form: UseFormReturn<IForm>;
}
const OtherInformation = ({ form }: OtherInformationProps) => {
  const {
    control,
    formState: { errors },
  } = form;
  const hasPets = useWatch({ control, name: 'hasPets' });

  return (
    <div className={styles.otherInformation}>
      <H5 className={styles.title}>Other information</H5>
      <B3 className={styles.desc}>Select Who pays each utilitiy bill</B3>
      <Box className={styles.inputBox}>
        <FormControl component="fieldset" className={styles.detectInformation}>
          <B1 bold>Renters insurance required?</B1>
          <Controller
            control={control}
            name="isRentersInsurance"
            render={({ field }) => (
              <RadioGroup {...field} className={styles.group}>
                <FormControlLabel className={styles.radio} value={'Yes'} control={<Radio color={'warning'} />} label="Yes" />
                <FormControlLabel className={styles.radio} value={'No'} control={<Radio color={'warning'} />} label="No" />
              </RadioGroup>
            )}
          />
        </FormControl>
        <FormControl component="fieldset" className={styles.detectInformation}>
          <B1 bold>Dose the property allow pets?</B1>
          <Controller
            control={control}
            name="hasPets"
            render={({ field }) => (
              <RadioGroup {...field} className={styles.group}>
                <FormControlLabel className={styles.radio} value={'Yes'} control={<Radio color={'warning'} />} label="Yes" />
                <FormControlLabel className={styles.radio} value={'No'} control={<Radio color={'warning'} />} label="No" />
              </RadioGroup>
            )}
          />
          {hasPets === 'Yes' && (
            <>
              <Box className={styles.grid}>
                <Controller
                  name="petWeight"
                  control={control}
                  render={({ field }) => (
                    <Input
                      className={styles.petWeightInp}
                      label={'Pet weight (lb)*'}
                      regexp={regexp.number}
                      errorMessage={errors.petWeight?.message}
                      hasError={!!errors.petWeight}
                      {...field}
                    />
                  )}
                />
              </Box>
              <Box>
                <H5 className={styles.title}>Add Pet Description</H5>
                <B3 className={styles.description}>Provide details about pets allowed in the property</B3>
              </Box>
              <Controller
                name="petDescription"
                control={control}
                render={({ field }) => (
                  <Input
                    placeholder="Enter description"
                    errorMessage={errors.petDescription?.message}
                    hasError={!!errors.petDescription}
                    {...field}
                  />
                )}
              />
            </>
          )}
        </FormControl>
        <FormControl component="fieldset">
          <B1 bold>Laundry</B1>
          <Controller
            control={control}
            name="laundry"
            render={({ field }) => (
              <RadioGroup {...field} className={styles.group}>
                <FormControlLabel className={styles.radio} value={'NONE'} control={<Radio color={'warning'} />} label="None" />
                <FormControlLabel
                  className={styles.radio}
                  value={'IN_SUITE'}
                  control={<Radio color={'warning'} />}
                  label="In-suite Laundry"
                />
                <FormControlLabel
                  className={styles.radio}
                  value={'SHARED'}
                  control={<Radio color={'warning'} />}
                  label="Shared Laundry"
                />
              </RadioGroup>
            )}
          />
        </FormControl>
      </Box>
    </div>
  );
};

export default OtherInformation;
