import React, { useCallback, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { Controller, type UseFormReturn, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

import styles from './UserInfo.module.scss';
import Button from '../../../components/shared/Button';
import { Input } from '../../../components/shared/FormElements';
import PhoneInput from '../../../components/shared/FormElements/PhoneInput';
import ImageUploader from '../../../components/shared/ImageUploader';
import SvgIcon from '../../../components/shared/utils/SvgIcon';
import { B3 } from '../../../components/Typography/Body';
import { H5 } from '../../../components/Typography/Headlines';
import { userSelector } from '../../Auth/store/selectors';
import { type IForm } from '../types';

export interface UserInfoProps {
  form: UseFormReturn<IForm>;
}

const UserInfo = ({ form }: UserInfoProps) => {
  const [emails, setEmails] = useState<string[]>([]);
  const [phones, setPhones] = useState<string[]>([]);
  const user = useSelector(userSelector);

  const {
    control,
    unregister,
    setValue,
    formState: { errors },
  } = form;
  const imageCover = useWatch({ control, name: 'imageCover' });
  const addEmail = useCallback(() => {
    const newEmails = [...emails, `emails.${Math.ceil(Math.random() * 100000)}`];
    setEmails(newEmails);
  }, [emails]);

  const addPhone = useCallback(() => {
    const newPhones = [...phones, `userPhones.${Math.ceil(Math.random() * 100000)}`];
    setPhones(newPhones);
  }, [phones]);

  const deleteEmail = useCallback(
    (value: string) => {
      unregister(value);
      const newEmails = emails.filter(email => email !== value);
      setEmails(newEmails);
    },
    [emails, unregister]
  );

  const deletePhone = useCallback(
    (value: string) => {
      unregister(value);
      const newEmails = phones.filter(phone => phone !== value);
      setPhones(newEmails);
    },
    [phones, unregister]
  );

  useEffect(() => {
    if (user?.profile) {
      const { emails, phones } = user.profile;
      const newEmail: string[] = [];
      const newPhones: string[] = [];
      emails?.forEach(email => {
        const el = `emails.${Math.ceil(Math.random() * 100000)}`;
        newEmail.push(el);
        setValue(el, email);
      });
      phones?.forEach(phone => {
        const el = `userPhones.${Math.ceil(Math.random() * 100000)}`;
        newPhones.push(el);
        setValue(el, phone.number);
      });
      setEmails(newEmail);
      setPhones(newPhones);
    }
  }, [setValue, user?.profile]);

  return (
    <div className={styles.userInfo}>
      <H5>Profile Photo</H5>
      <B3 className={styles.desc}>You can upload jpg. or png image files</B3>
      <ImageUploader isBig imageUrls={imageCover} setImageUrls={value => setValue('imageCover', value)} />
      <Box className={styles.inputBox}>
        <Box className={styles.grid}>
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <Input
                label={'First Name*'}
                className="full-size"
                type="text"
                errorMessage={errors.firstName?.message}
                hasError={!!errors.firstName}
                {...field}
              />
            )}
          />
          <Controller
            name="middleName"
            control={control}
            render={({ field }) => <Input label={'Middle name'} className="full-size" type="text" {...field} />}
          />
        </Box>
        <Controller
          name="lastName"
          control={control}
          render={({ field }) => (
            <Input
              label={'Last Name*'}
              className="full-size"
              type="text"
              {...field}
              errorMessage={errors.lastName?.message}
              hasError={!!errors.lastName}
            />
          )}
        />
        <Box className={styles.grid}>
          <div>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Input
                  label={'Email*'}
                  disabled
                  className="full-size"
                  {...field}
                  errorMessage={errors.email?.message}
                  hasError={!!errors.email}
                />
              )}
            />
            {!!emails.length && (
              <div className={styles.emails}>
                {emails.map(email => (
                  <div className={styles.emailWrap} key={email}>
                    <Controller
                      name={`${email}`}
                      control={control}
                      render={({ field }) => <Input label={'Email*'} type={'email'} className="full-size" {...field} />}
                    />
                    <SvgIcon icon={'close'} className={styles.closeIcon} onClick={() => deleteEmail(email)} />
                  </div>
                ))}
              </div>
            )}
          </div>

          <Button
            disabled={emails.length === 5}
            variant="outlined"
            size="full-lg"
            type={'button'}
            label="+ Add New email address"
            onClick={addEmail}
          />
        </Box>
        <Box className={styles.grid}>
          <div>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <PhoneInput
                  disabled
                  label={'Phone number*'}
                  className="full-size"
                  type="tel"
                  errorMessage={errors.phone?.message}
                  hasError={!!errors.phone}
                  {...field}
                />
              )}
            />
            {!!phones.length && (
              <div className={styles.emails}>
                {phones.map(phone => (
                  <div className={styles.emailWrap} key={phone}>
                    <Controller
                      name={`${phone}`}
                      control={control}
                      render={({ field }) => (
                        <PhoneInput
                          label={'Phone number*'}
                          className="full-size"
                          type="tel"
                          errorMessage={errors.phone?.message}
                          hasError={!!errors.phone}
                          {...field}
                        />
                      )}
                    />
                    <SvgIcon icon={'close'} className={styles.closeIcon} onClick={() => deletePhone(phone)} />
                  </div>
                ))}
              </div>
            )}
          </div>

          <Button
            disabled={phones.length === 5}
            type={'button'}
            variant="outlined"
            size="full-lg"
            label="+ Add New phone number"
            onClick={addPhone}
          />
        </Box>
      </Box>
    </div>
  );
};

export default UserInfo;
