import cn from 'classnames';
import ImageGallery from 'react-image-gallery';

import 'react-image-gallery/styles/css/image-gallery.css';
import styles from './Slider.module.scss';

import './Slider.module.scss';

interface imagesProps {
  images: { original: string; thumbnail?: string; originalHeight?: number; thumbnailHeight?: number; thumbnailClass?: string }[];
  className?: string;
}
const Slider = ({ images, className }: imagesProps) => {
  if (!images.length) {
    return null;
  }
  return (
    <div className={cn(styles.slider, className)}>
      <ImageGallery swipeThreshold={5} items={images} lazyLoad showFullscreenButton={false} showPlayButton={false} />
    </div>
  );
};

export default Slider;
