import React, { type ReactNode, useState } from 'react';

import cn from 'classnames';

import styles from './Card.module.scss';
import Content from './Content';
import ExtendableContent from './ExtendableContent';
import Button from '../Button';
import SvgIcon from '../utils/SvgIcon';

interface CardProps {
  className?: string;
  open?: boolean;
  children: ReactNode;
}

const Card = ({ children, className, open = false, ...rest }: CardProps) => {
  const [isOpen, setIsOpen] = useState(open);
  let content: ReactNode | null = null;
  let extendableContent: ReactNode | null = null;
  React.Children.forEach(children, (child: any) => {
    if (child?.type === Content) {
      content = child;
    }
    if (child?.type === ExtendableContent) {
      extendableContent = child;
    }
  });
  return (
    <div className={cn(styles.card, className)} {...rest}>
      <div className={styles.wrap}>
        {content}
        {extendableContent && (
          <Button
            className={styles.button}
            variant="outlined"
            size="full-sm"
            type="button"
            label={<SvgIcon icon={'arrow'} className={cn(styles.arrowIcon, { [styles.active]: isOpen })} />}
            onClick={() => setIsOpen(!isOpen)}
          />
        )}
      </div>
      <div className={cn(styles.extendableContent, { [styles.isOpen]: isOpen })}>{extendableContent}</div>
    </div>
  );
};

Card.Content = Content;
Card.ExtendableContent = ExtendableContent;

export default Card;
