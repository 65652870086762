import * as Yup from 'yup';

import { regexp } from '../../../constants/regexp';

export const registrationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Please enter your password')
    .matches(
      /^.*(?=.{8,})((?=.*[!?@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      'Min 8 characters  one uppercase, lowercase letters, a number and a symbol'
    ),
  confirmPassword: Yup.string()
    .required('Repeat your password')
    .oneOf([Yup.ref('password'), ''], 'Password Does Not Match'),
  firstname: Yup.string().min(2, 'First name is required').max(25, 'Please enter max 25 characters').required('Required field'),
  middleName: Yup.string(),
  lastName: Yup.string().min(2, 'Last name is required').max(25, 'Please enter max 25 characters').required(),
  email: Yup.string()
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Email address is required')
    .required(),
  phone: Yup.string()
    .matches(/^[0-9+]([0-9]+)?$/, 'Phone number is required')
    .min(2, 'Phone number is required')
    .max(25, 'Please enter max 25 characters')
    .required('Required field'),
  check: Yup.boolean().oneOf([true], 'Required field'),
});
export const loginSchema = Yup.object().shape({
  password: Yup.string().required('Please enter your password'),
  email: Yup.string().matches(regexp.email, 'Please enter a valid email address').required('Please enter a valid email address'),
});
export const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .matches(regexp.email, 'Please enter a valid email address')
    .required('Please enter a valid email address')
    .nullable(),
});
export const createPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required('Please enter your password')
    .matches(
      /^.*(?=.{8,})((?=.*[!?@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      'Be sure to use at least one uppercase, lowercase letters, a number and a symbol.'
    ),
  confirmNewPassword: Yup.string()
    .required('Please confirm your password')
    .oneOf([Yup.ref('newPassword'), ''], 'Password Does Not Match'),
});
