import React, { useState } from 'react';

import { Box } from '@mui/system';
import GoogleMapReact from 'google-map-react';
import * as process from 'process';

import styles from './GoogleMap.module.scss';
import { B1, B2 } from '../../Typography/Body';
import Button from '../Button';
import SvgIcon from '../utils/SvgIcon';

interface MapProps {
  zoom?: number;
  handleClick?: (id: string) => void;
  activeItem: { id: string; lat: number; lng: number; value: string };
  points: { id: string; lat: number; lng: number; address: { value: string } }[];
}
interface MyMarkerProps {
  isActive?: boolean;
  lat: number;
  lng: number;
  address: { value: string };
  handleClick?: (id: string) => void;
}

const MyMarker = ({ isActive, address, handleClick }: MyMarkerProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Box className={styles.marker} onClick={() => setIsOpen(!isOpen)}>
      {isActive ? (
        <SvgIcon icon={'markerActive'} className={styles.markerActive} />
      ) : (
        <SvgIcon icon={'marker'} className={styles.markerActive} />
      )}
      {isOpen && (
        <Box className={styles.info}>
          <B1 bold className={styles.header}>
            Location details
          </B1>
          <div className={styles.content}>
            <B2 className={styles.address}>
              <SvgIcon icon={'location'} className={styles.locationIcon} />
              {address?.value}
            </B2>
            <Button
              onClick={() => {
                if (handleClick) {
                  handleClick('id');
                }
              }}
              variant="contained"
              size="full-sm"
              type="submit"
              label="See More"
            />
          </div>
        </Box>
      )}
    </Box>
  );
};

const Map = ({ zoom = 15, activeItem, points, handleClick }: MapProps) => {
  return (
    <div className={styles.googleMap}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_ADDRESS_API_KEY }}
        defaultCenter={activeItem}
        defaultZoom={zoom}
      >
        {!!points?.length &&
          points.map(({ lat, lng, id, address }) => {
            return <MyMarker key={id} lat={lat} lng={lng} isActive={activeItem.id === id} address={{ value: address?.value }} />;
          })}

        <MyMarker
          lat={activeItem.lat}
          lng={activeItem.lng}
          isActive
          address={{ value: activeItem.value }}
          handleClick={handleClick}
        />
      </GoogleMapReact>
    </div>
  );
};

export default Map;
