import React, { useCallback } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Modal } from '@mui/material';
import Button from 'components/shared/Button';
import { Input } from 'components/shared/FormElements';
import PhoneInput from 'components/shared/FormElements/PhoneInput';
import SvgIcon from 'components/shared/utils/SvgIcon';
import { H5 } from 'components/Typography/Headlines';
import modalNames from 'constants/modalNames';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { defaultValues } from './constants';
import styles from './InviteToApplyModal.module.scss';
import { type IForm } from './types';
import { inviteToApplyModalSchema } from './validations/InviteToApplyModalSchema';
import { inviteToApplyAction } from '../../modules/Listings/store/actions';
import { type RootState } from '../../store/setupStore';
import { modalsSlice } from '../index';
import { checkModalVisibility } from '../store/selectors';
const InviteToApplyModal = ({ id }: { id: string }) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IForm>({ defaultValues, resolver: yupResolver(inviteToApplyModalSchema) });

  const dispatch = useDispatch();
  const isModalVisible = useSelector((store: RootState) => checkModalVisibility(store, modalNames.INVITE_TO_APPLY));

  const handleModalClose = useCallback(() => {
    reset();
    dispatch(modalsSlice.actions.hideModal(modalNames.INVITE_TO_APPLY));
  }, [dispatch]);

  const onSubmit = (data: IForm) => {
    dispatch(inviteToApplyAction({ fullName: data.fullName, email: data.email, phone: data.phone, listing: id }));
    handleModalClose();
  };

  return (
    <Modal open={isModalVisible} onClose={handleModalClose} className={styles.modal}>
      <div className={styles.inviteToApplyModal}>
        <header className={styles.header}>
          <H5 bold>Invite to apply</H5>
          <SvgIcon icon="close" className={styles.close} onClick={handleModalClose} />
        </header>
        <form className={styles.content}>
          <Controller
            name="fullName"
            control={control}
            render={({ field }) => (
              <Input
                label="Name*"
                errorMessage={errors.fullName?.message}
                hasError={!!errors.fullName}
                placeholder="Enter applicant Name"
                {...field}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                label="Applicant’s email*"
                placeholder="Enter applicant Email address"
                errorMessage={errors.email?.message}
                hasError={!!errors.email}
                {...field}
              />
            )}
          />
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <PhoneInput
                label="Phone number*"
                placeholder="Enter Phone number"
                errorMessage={errors.phone?.message}
                hasError={!!errors.phone}
                {...field}
              />
            )}
          />
          <Box className={styles.buttons}>
            <Button variant="outlined" size="ds" type="button" label="Cancel" onClick={handleModalClose} />
            <Button variant="contained" size="ds" type="button" label="Send" onClick={handleSubmit(onSubmit)} />
          </Box>
        </form>
      </div>
    </Modal>
  );
};

export default InviteToApplyModal;
