import { Box } from '@mui/system';
import Card from 'components/shared/Card';
import StatusLine from 'components/shared/StatusLine';
import { B3 } from 'components/Typography/Body';
import { regexp } from 'constants/regexp';

import styles from './MobileCard.module.scss';
import StatusTag from '../StatusTag';

const MobileCard = (mobileData: any) => {
  return (
    <Card className={styles.mobileCard}>
      <Card.Content className={styles.mobileCardContent}>
        <Box>
          {mobileData?.items?.length &&
            mobileData.items.map((item: any) => (
              <div key={item.index}>
                {item.index === 1 ? (
                  <div className={styles.cardItem}>
                    <StatusLine type={item?.status}>
                      <B3 bold className={styles.cardItemName}>
                        {item?.value}
                      </B3>
                    </StatusLine>
                  </div>
                ) : (
                  <div className={styles.cardItem}>
                    <B3 bold className={styles.cardItemtitle}>
                      {item.label}
                    </B3>
                    {item.label === 'Status' ? (
                      <StatusTag label={item?.value} type={item?.value} />
                    ) : item.label === 'Phone' ? (
                      <p className={styles.cardItemInfo}>{item?.value ? regexp.formatPhoneNumber(item?.value) : '-'}</p>
                    ) : (
                      <p className={styles.cardItemInfo}>{item?.value}</p>
                    )}
                  </div>
                )}
              </div>
            ))}
        </Box>
      </Card.Content>
    </Card>
  );
};

export default MobileCard;
