import React, { useEffect, useMemo, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useWatch } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import BankAccount from './BankAccount';
import BusinessInfo from './BusinessInfo';
import CompanyInfo from './CompanyInfo/CompanyInfo';
import {
  accountTypeContent,
  accountTypeValues,
  businessInfoDefaultValues,
  companyInfoDefaultValues,
  personalInfoDefaultValues,
  userInfoDefaultValues,
} from './constants';
import PersonalInfo from './PersonalInfo';
import styles from './Profile.module.scss';
import { createProfileAction, createTenantProfileAction } from './store/actions';
import { profileLoadingSelector } from './store/selectors';
import { profileBankAccountsSelector } from './store/selectors';
import { type IForm } from './types';
import UserInfo from './UserInfo';
import { getCompanyDetails, getPersonalDetails } from './utils';
import { profileSchema } from './validations/profileSchema';
import { scrollToFirstErrorInput } from '../../common/utils/utils';
import PrivateAccess from '../../components/PrivateAccess';
import AlertCard from '../../components/shared/AlertCard';
import Button from '../../components/shared/Button';
import DetectInformation from '../../components/shared/DetectInformation';
import ImageUploader from '../../components/shared/ImageUploader';
import TenantPersonalInfo from '../../components/TenantPersonalInfo';
import { B3 } from '../../components/Typography/Body';
import { H3, H5 } from '../../components/Typography/Headlines';
import { userRole } from '../../constants/userStatus';
import { userSelector } from '../Auth/store/selectors';

const Profile = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [bankAccountError, setBankAccountError] = useState(false);
  const user = useSelector(userSelector);
  const profileLoading = useSelector(profileLoadingSelector);
  const navigate = useNavigate();
  const bankAccounts = useSelector(profileBankAccountsSelector);

  const dispatch = useDispatch();
  const form = useForm({
    defaultValues: {
      ...userInfoDefaultValues,
      ...personalInfoDefaultValues,
      ...companyInfoDefaultValues,
      ...businessInfoDefaultValues,
    },
    resolver: yupResolver<any>(profileSchema),
    mode: 'all',
  });
  const isTenant = user?.role === userRole.TENANT;
  const isManager = user?.role === userRole.MANAGER;

  const {
    reset,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = form;

  const imageCover = useWatch({ control, name: 'imageCover' });

  const onSubmit = (data: IForm) => {
    if (isTenant) {
      dispatch(createTenantProfileAction({ data, navigate, dirtyFields: form.formState.dirtyFields }));
    } else {
      if (bankAccounts?.length || isManager) {
        data.address = data.address || { label: '' };
        dispatch(createProfileAction({ data, navigate, dirtyFields: form.formState.dirtyFields }));
      } else {
        setBankAccountError(true);
        toast.error('Please add bank account');
      }
    }
  };

  useEffect(() => {
    scrollToFirstErrorInput(errors);
  }, [errors]);

  useEffect(() => {
    if (user) {
      if (user?.profile) {
        const { accountType } = user.profile;
        let businessDetails = {};
        if (user.profile?.businessDetails) {
          businessDetails = getCompanyDetails(user.profile.businessDetails);
        }
        reset({
          ...getValues(),
          ...getPersonalDetails(user),
          ...(accountType === accountTypeValues.COMPANY && { ...businessDetails }),
        });
      } else {
        const [firstName, lastName = '', middleName] = user?.fullName?.split(' ') || '';
        reset({
          ...userInfoDefaultValues,
          ...personalInfoDefaultValues,
          ...companyInfoDefaultValues,
          ...businessInfoDefaultValues,
        });
        setValue('firstName', firstName);
        setValue('lastName', lastName);
        setValue('middleName', middleName);
        setValue('phone', user.phone);
        setValue('email', user.email);
      }
    }
    setValue('isTenant', isTenant);
  }, [user]);

  const personalInformationPhones = useMemo(() => {
    return user?.profile?.phones?.length ? user?.profile?.phones : null;
  }, [user?.profile?.phones]);

  return (
    <div className={styles.profile}>
      <H3 className={styles.title}>Profile information</H3>
      {!user?.profile && user && (
        <AlertCard
          type="info"
          title={
            'It is important to complete your profile with your information and your business’ information ' +
            'тo be able to create Properties and listings. ' +
            'Make sure you provide valid and correct information to be used in your listings, e-leases etc.'
          }
          setIsOpen={setIsOpen}
          isOpen={isOpen}
        />
      )}
      <form onSubmit={form.handleSubmit(onSubmit)} className={styles.form}>
        <PrivateAccess roles={[userRole.LANDLOARD]}>
          <DetectInformation form={form} name="accountType" content={accountTypeContent} />
        </PrivateAccess>
        <PrivateAccess roles={[userRole.LANDLOARD, userRole.MANAGER]}>
          <>
            <UserInfo form={form} />
            <PrivateAccess roles={[userRole.LANDLOARD]}>
              <BankAccount bankAccountError={bankAccountError} setBankAccountError={setBankAccountError} />
            </PrivateAccess>
            <PersonalInfo form={form} />
          </>
        </PrivateAccess>

        <PrivateAccess roles={[userRole.TENANT]}>
          <H5>Profile Photo</H5>
          <B3 className={styles.desc}>You can upload jpg. or png image files</B3>
          <ImageUploader isBig imageUrls={imageCover} setImageUrls={value => setValue('imageCover', value)} />
          <TenantPersonalInfo form={form} personalInformationPhones={personalInformationPhones} />
        </PrivateAccess>
        <CompanyInfo form={form} />
        <BusinessInfo form={form} />
        <Button
          className={styles.create}
          variant="contained"
          label="Save"
          size="full-lg"
          type="submit"
          loading={profileLoading}
          disabled={profileLoading}
        />
      </form>
    </div>
  );
};

export default Profile;
