import { Box } from '@mui/material';
import Payments from 'components/Payments/Payments';
import { B1, B2, B3 } from 'components/Typography/Body';
import { H4, H5 } from 'components/Typography/Headlines';
import { useSearchParams } from 'react-router-dom';

import styles from './Payment.module.scss';

const Payment = () => {
  const [searchParams] = useSearchParams();

  return (
    <div className={styles.container}>
      <H4 className={styles.title} bold>
        Rental Application
      </H4>
      <B3 className={styles.desc}>Evolve Realty & Development</B3>
      <H5 className={styles.payTitle}>Pay fees</H5>
      <B3 className={styles.info}>The following fees are necessary for us to verify your rental application</B3>
      <B1 bold className={styles.price}>
        Application Fee: $35.00
      </B1>
      <div className={styles.payInfo}>
        <B2 className={styles.text}>Your payment information is secure.</B2>
        <Box className={styles.inputBox}>
          <Payments returnUrl={`/application/9?id=${searchParams.get('id')}`} id={localStorage.getItem('id') as string} />
        </Box>
      </div>
    </div>
  );
};

export default Payment;
