import { type FC, memo, type ReactNode, useContext } from 'react';

import cx from 'classnames';

import styles from './SideBarFooter.module.scss';
import { SidebarContext } from '../SideBar';

export interface Props {
  className?: string;
  children: ReactNode | any;
}

const SideBarFooter: FC<Props> = ({ className, children }) => {
  const { isCollapsed, isActive, isHovered } = useContext(SidebarContext);

  return (
    <div className={cx(styles.sidebarFooter, className)}>
      <div className={styles.line} />
      {typeof children === 'function' ? children({ isCollapsed, isActive, isHovered }) : children}
    </div>
  );
};

export default Object.assign(memo(SideBarFooter), { displayName: 'SideBarFooter' });
