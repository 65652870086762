import { Avatar } from '@mui/material';
import cn from 'classnames';

import styles from './UserCard.module.scss';
import { B1, B2 } from '../../Typography/Body';
import { H2 } from '../../Typography/Headlines';

interface UserCard {
  email: string;
  name?: string;
  type: string;
  className?: string;
  src?: string;
}

const UserCard = ({ email, name, type, className, src = '' }: UserCard) => {
  return (
    <div className={cn(styles.userCard, className)}>
      <Avatar className={styles.avatar} alt={'LA'} src={src}>
        <H2 bold>{name ? name[0].toUpperCase() : email[0].toUpperCase()}</H2>
      </Avatar>
      <div className={styles.info}>
        <B1 bold>{name}</B1>
        <B2 className={styles.type}>{type}</B2>
        <B2 className={styles.email}>{email}</B2>
      </div>
    </div>
  );
};

export default UserCard;
