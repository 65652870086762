import cn from 'classnames';
import { B2 } from 'components/Typography/Body';
import { Controller, type UseFormReturn, useWatch } from 'react-hook-form';

import styles from './Filter.module.scss';
import Checkbox from '../../../components/shared/FormElements/Checkbox/Checkbox';
import { type IForm } from '../types';

interface FilterProps {
  form: UseFormReturn<IForm>;
}
const Filter = ({ form }: FilterProps) => {
  const { control, setValue } = form;
  const activeTab = useWatch({ control, name: 'propertyType' });

  return (
    <div className={styles.filter}>
      <div className={styles.options}>
        <div className={styles.tabs}>
          <B2
            className={cn(styles.tab, { [styles.activeTab]: activeTab === 'All' })}
            onClick={() => setValue('propertyType', 'All')}
          >
            All
          </B2>
          <B2
            className={cn(styles.tab, styles.house, { [styles.activeTab]: activeTab === 'house' })}
            onClick={() => setValue('propertyType', 'house')}
          >
            House
          </B2>
          <B2
            className={cn(styles.tab, { [styles.activeTab]: activeTab === 'office' })}
            onClick={() => setValue('propertyType', 'office')}
          >
            Office
          </B2>
        </div>
        <div className={styles.peats}>
          <Controller name="hasPets" control={control} render={({ field }) => <Checkbox {...field} />} />
          <B2>Pets allowed</B2>
        </div>
      </div>
    </div>
  );
};

export default Filter;
