import React, { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import Button from 'components/shared/Button';
import Card from 'components/shared/Card';
import { Input } from 'components/shared/FormElements';
import Autocomplete from 'components/shared/FormElements/Autocomplete/Autocomplete';
import { B1, B2, B3 } from 'components/Typography/Body';
import { H5 } from 'components/Typography/Headlines';
import { regexp } from 'constants/regexp';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import styles from './UnitCard.module.scss';
import SvgIcon from '../../../components/shared/utils/SvgIcon';
import { statusOptions, unitTypeOptions } from '../CreateProperty/constants';
import { addPropertyUnitAction, deleteUnitAction, updatePropertyUnitAction } from '../store/actions';
import { type IUnit } from '../types';
import { propertyViewSchema } from '../ViewProperty/validations/propertyViewSchema';

interface UnitCardProps {
  unit: IUnit;
  propertyId: string;
  isLast: boolean;
  isAdd?: boolean;
  closeUnit?: any;
}
const UnitCard = ({ unit, propertyId, isLast, isAdd = false, closeUnit }: UnitCardProps) => {
  const { unitNumber, unitType, unitSize, bedrooms, bathrooms, parkingSpot, monthlyRent, status, rentDeposit } = unit;
  const [isActive, setIsActive] = useState(isAdd);
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<any>({ resolver: yupResolver(propertyViewSchema) });

  useEffect(() => {
    reset({ unitNumber, unitType, unitSize, bedrooms, bathrooms, parkingSpot, monthlyRent, status, rentDeposit });
  }, []);
  const onSubmit = (data: any) => {
    if (isAdd) {
      dispatch(addPropertyUnitAction({ data, id: propertyId }));
      closeUnit();
    } else {
      dispatch(updatePropertyUnitAction({ data, id: unit._id }));
    }

    setIsActive(false);
  };

  const handleUnitDelete = () => {
    dispatch(deleteUnitAction({ unitsId: unit._id, propertyId }));
  };

  return (
    <Card className={styles.unitCard} open={isActive}>
      <Card.Content>
        <div className={styles.status}>
          {!isAdd && <B1 bold>{unitNumber} |</B1>}
          <B1 className={styles.orange} bold>
            {status.label}
          </B1>
        </div>
        {!isActive && (
          <>
            <B2 className={styles.info}>
              {`${unitType.label} | Square feet: ${unitSize} | ${bedrooms} Bedroom | ${bathrooms}
           Bathroom | Parking spot: ${parkingSpot}`}
            </B2>
            <div className={styles.action}>
              <H5 className={styles.orange}>{`$${monthlyRent}`}</H5>
              <Button
                variant="contained"
                className={styles.editButton}
                size="full-sm"
                type="button"
                label="Edit Unit"
                onClick={() => setIsActive(true)}
              />
            </div>
          </>
        )}
        {isActive && (
          <form>
            <Box className={styles.inputBox}>
              <Box className={styles.grid}>
                <Controller
                  name={`unitNumber`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      label="Unit No*"
                      className="full-size"
                      errorMessage={errors?.unitNumber?.message}
                      hasError={!!errors?.unitNumber}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name={`bedrooms`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      regexp={regexp.number}
                      label="Bedroom(s)*"
                      placeholder="Enter bedrooms"
                      errorMessage={errors?.bedrooms?.message}
                      hasError={!!errors?.bedrooms}
                    />
                  )}
                />
              </Box>
              <Box className={styles.grid}>
                <Controller
                  name={`unitSize`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      label="Unit Size (sq.ft.)*"
                      className="full-size"
                      type="text"
                      regexp={regexp.numericalDigits}
                      errorMessage={errors?.unitSize?.message}
                      hasError={!!errors?.unitSize}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name={`bathrooms`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      regexp={regexp.number}
                      label="Bathrooms*"
                      placeholder="Enter bedrooms"
                      errorMessage={errors?.bathrooms?.message}
                      hasError={!!errors?.bathrooms}
                    />
                  )}
                />
              </Box>
              <Box className={styles.grid}>
                <Controller
                  name={`status`}
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      label="Status*"
                      autoComplete="off"
                      options={statusOptions}
                      {...field}
                      errorMessage={errors?.status?.message}
                      hasError={!!errors?.status}
                    />
                  )}
                />
                <Controller
                  name={`unitType`}
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      label="Unit type"
                      autoComplete="off"
                      options={unitTypeOptions}
                      {...field}
                      errorMessage={errors?.unitType?.message}
                      hasError={!!errors?.unitType}
                    />
                  )}
                />
              </Box>
              <Box className={styles.grid}>
                <Controller
                  name={`parkingSpot`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      label="Parking spot(s)*"
                      regexp={regexp.number}
                      errorMessage={errors?.parkingSpot?.message}
                      hasError={!!errors?.parkingSpot}
                      {...field}
                    />
                  )}
                />
                <Box className={styles.grid}>
                  <Controller
                    name={`monthlyRent`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        label="Monthly rent*"
                        regexp={regexp.number}
                        errorMessage={errors?.monthlyRent?.message}
                        hasError={!!errors?.monthlyRent}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name={`rentDeposit`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        regexp={regexp.number}
                        label="Rent Deposit*"
                        errorMessage={errors?.rentDeposit?.message}
                        hasError={!!errors?.rentDeposit}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Box>
            <div className={styles.buttonsWrap}>
              <Box className={styles.delete} onClick={handleUnitDelete}>
                {!isLast && !isAdd && (
                  <>
                    <SvgIcon icon={'trash'} className={styles.trash} />
                    <B3 bold className={styles.label}>
                      Delet unit
                    </B3>
                  </>
                )}
              </Box>
              <Box className={styles.buttons}>
                <Button
                  variant="outlined"
                  className={styles.editButton}
                  size="full-sm"
                  type="button"
                  label="Cancel"
                  onClick={() => {
                    reset({ unitNumber, unitType, unitSize, bedrooms, bathrooms, parkingSpot, monthlyRent, status, rentDeposit });
                    setIsActive(false);
                    if (closeUnit) {
                      closeUnit();
                    }
                  }}
                />
                <Button
                  variant="contained"
                  className={styles.editButton}
                  size="full-sm"
                  type="button"
                  label="Save"
                  onClick={handleSubmit(onSubmit)}
                />
              </Box>
            </div>
          </form>
        )}
      </Card.Content>
    </Card>
  );
};

export default UnitCard;
