import { createSlice } from '@reduxjs/toolkit';

import { type IModalsState } from './types';

const initialState: IModalsState = {
  modals: new Map(),
};

const modalsSlice = createSlice({
  name: 'modalsStore',
  initialState,
  reducers: {
    showModal: (state: IModalsState, { payload }) => {
      const modals = new Map(state.modals);
      const { modalName, data } = payload;
      modals.set(modalName, data || {});
      state.modals = modals;
    },
    hideModal: (state: IModalsState, { payload }) => {
      const modals = new Map(state.modals);
      modals.set(payload, null);
      state.modals = modals;
    },
  },
});

export default modalsSlice;
