import { Box, Grid } from '@mui/material';
import { Link } from 'react-router-dom';

import logoText from '../../../../assets/images/logoText.png';
import logoX from '../../../../assets/images/logoX.png';
import { B2 } from '../../../../components/Typography/Body';
import { routes } from '../../../../constants/routes';
import styles from '../../Auth.module.scss';

const PageLeftSide = () => {
  return (
    <Grid item xs={6} className={styles.signInLeft}>
      <Box className={styles.content}>
        <Link to={routes.home}>
          <img width={97} height={31} src={logoText} alt="RentX logo" />
          <img width={29} height={31} src={logoX} alt="RentX logo" />
        </Link>
        <Box className={styles.headlineBox}>
          <B2 className={styles.title} fontFamily="Lexend, sans-serif">
            The Potential of Your Property
          </B2>
        </Box>
      </Box>
    </Grid>
  );
};

export default PageLeftSide;
