import { watchApplicantSaga } from 'modules/Applicants/store/sagas';
import { watchApplicationSaga } from 'modules/Application/store/sagas';
import { watchMaintenenceSaga } from 'modules/Maintenance/store/sagas';
import { watchManagerSaga } from 'modules/Managers/store/sagas';
import { watchTaskSaga } from 'modules/Tasks/store/sagas';
import { watchTenantApplicationSaga } from 'modules/TenantApplication/store/sagas';
import { watchTenantSaga } from 'modules/Tenants/store/sagas';
import { all, call } from 'redux-saga/effects';

import { watchAccountingSaga } from '../modules/Accounting/store/sagas';
import { watchAuthSaga } from '../modules/Auth/store/sagas';
import { watchChatSaga } from '../modules/Chat/store/sagas';
import { watchHelpSaga } from '../modules/Help/store/sagas';
import { watchHomeSaga } from '../modules/Home/store/sagas';
import { watchLeaseSaga } from '../modules/Lease/store/sagas';
import { watchListingsSaga } from '../modules/Listings/store/sagas';
import { watchNotificationsSaga } from '../modules/Notifications/store/sagas';
import { watchTenantPaymentSaga } from '../modules/PaymentPage/store/sagas';
import { watchProfileSaga } from '../modules/Profile/store/sagas';
import { watchPCreatePropertySaga } from '../modules/Properties/CreateProperty/store/sagas';
import { watchPropertySaga } from '../modules/Properties/store/sagas';

export default function* rootSaga() {
  yield all([
    call(watchTenantPaymentSaga),
    call(watchAuthSaga),
    call(watchProfileSaga),
    call(watchPCreatePropertySaga),
    call(watchPropertySaga),
    call(watchListingsSaga),
    call(watchApplicationSaga),
    call(watchApplicantSaga),
    call(watchLeaseSaga),
    call(watchTenantSaga),
    call(watchManagerSaga),
    call(watchMaintenenceSaga),
    call(watchNotificationsSaga),
    call(watchTaskSaga),
    call(watchTenantApplicationSaga),
    call(watchAccountingSaga),
    call(watchChatSaga),
    call(watchHelpSaga),
    call(watchHomeSaga),
  ]);
}
