import { useEffect, useMemo } from 'react';

import { Box } from '@mui/material';
import cn from 'classnames';
import Loading from 'components/shared/Loading';
import MobileCard from 'components/shared/MobileCard';
import StatusTag from 'components/shared/StatusTag';
import TableCard from 'components/shared/TableCard';
import { B1, B2, B3 } from 'components/Typography/Body';
import { H3 } from 'components/Typography/Headlines';
import { regexp } from 'constants/regexp';
import { colors, userRole } from 'constants/userStatus';
import { useDispatch, useSelector } from 'react-redux';

import AddManager from './AddManager';
import styles from './Managers.module.scss';
import { getManagersList } from './store/actions';
import { managerSelector, managerListDataSelector, managerLoadingSelector, managerQueryOptionsSelector } from './store/selector';
import PrivateAccess from '../../components/PrivateAccess';

const Managers = () => {
  const dispatch = useDispatch();
  const loading = useSelector(managerLoadingSelector);
  const managers = useSelector(managerSelector);
  const managersList = useSelector(managerListDataSelector);
  const { page, size } = useSelector(managerQueryOptionsSelector);

  const memoColumns = useMemo(() => {
    return [
      {
        Header: 'Name',
        id: 'name',
        title: 'Name',
        Cell: ({ row }: any) => {
          return (
            <div
              className={cn(styles.applicant, { [styles.emptyField]: !row.original?.fullName })}
              style={colors[row.original?.status]}
            >
              {row.original?.fullName ? (
                <B2 bold className={styles.firstName}>
                  {`${row.original?.fullName}`}
                </B2>
              ) : (
                '-'
              )}
            </div>
          );
        },
      },
      {
        Header: 'Phone',
        id: 'phone',
        title: 'Phone',
        Cell: ({ row }: any) => {
          return (
            <div className={cn({ [styles.emptyField]: !row.original?.phone })}>
              <B3>{row.original?.phone ? regexp.formatPhoneNumber(row.original?.phone) : '-'}</B3>
            </div>
          );
        },
      },
      {
        Header: 'Email',
        id: 'email',
        title: 'Email',
        Cell: ({ row }: any) => {
          return (
            <div className={styles.emailWrap}>
              <B3 className={styles.email}>{row.original?.email}</B3>
            </div>
          );
        },
      },
      {
        Header: 'Status',
        id: 'status',
        title: 'Status',
        width: 50,
        Cell: ({ row }: any) => {
          return (
            <div>
              <StatusTag type={row.original?.status} label={row.original?.status} size={'medium'} styles={{ minWidth: '50px' }} />
            </div>
          );
        },
      },
    ];
  }, []);

  const mobileCardData = managersList?.items.map(manager => {
    return [
      {
        index: 1,
        label: 'Name',
        value: manager?.fullName,
        status: manager?.status,
      },
      {
        index: 2,
        label: 'Phone',
        value: manager?.phone,
      },
      {
        index: 3,
        label: 'Email',
        value: manager?.email,
      },
      {
        index: 4,
        label: 'Status',
        value: manager?.status,
      },
    ];
  });

  useEffect(() => {
    dispatch(getManagersList({ limit: size, page: page }));
  }, []);

  return (
    <PrivateAccess roles={[userRole.LANDLOARD, userRole.MANAGER]} isPage>
      <div className={styles.managers}>
        <Box>
          <div className={styles.addManager}>
            <H3 className={styles.title}>Managers</H3>
            <AddManager />
          </div>
        </Box>
        {managersList?.items?.length ? (
          <div>
            {mobileCardData?.map((cardData, index) => (
              <MobileCard key={index} items={cardData} />
            ))}
            <TableCard
              columns={memoColumns}
              data={managers?.data?.items || []}
              defaultPageSize={size}
              totalItems={+managersList.count}
              defaultPage={page}
              setPageSize={size => dispatch(getManagersList({ limit: size, page: 1 }))}
              fetchData={({ pageSize, pageIndex }) => dispatch(getManagersList({ page: pageIndex, limit: `${pageSize}` }))}
              className={styles.managerTable}
            />
          </div>
        ) : (
          !loading && (
            <div className={styles.emptyContainer}>
              <B1 bold>No Managers found</B1>
              <B2>You don't have any Managers to publish.</B2>
            </div>
          )
        )}
        {loading && <Loading isBig />}
      </div>
    </PrivateAccess>
  );
};

export default Managers;
