import { createTheme } from '@mui/material';

import { fontFamilies } from './fonts';

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    'primary-black': true;
    'primary-blue': true;
    'default-btn': true;
    'primary-orange': true;
    'secondary-background': true;
    'secondary-hover-bl': true;
    'secondary-hover-or': true;
    'additional-white': true;
    'additional-grey-1': true;
    'additional-grey-2': true;
    'additional-grey-3': true;
    'additional-light-grey-1': true;
    'additional-light-grey-2': true;
    'additional-light-grey-3': true;
    'additional-light-grey-4': true;
    'additional-red': true;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    'primary-black': Palette['primary'];
    'primary-blue': Palette['primary'];
    'primary-orange': Palette['primary'];
    'default-btn': Palette['primary'];
    'secondary-background': Palette['secondary'];
    'secondary-hover-bl': Palette['secondary'];
    'secondary-hover-or': Palette['secondary'];
    'additional-white': Palette['info'];
    'additional-grey-1': Palette['info'];
    'additional-grey-2': Palette['info'];
    'additional-grey-3': Palette['info'];
    'additional-light-grey-1': Palette['info'];
    'additional-light-grey-2': Palette['info'];
    'additional-light-grey-3': Palette['info'];
    'additional-light-grey-4': Palette['info'];
    'additional-red': Palette['error'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    'primary-black': PaletteOptions['primary'];
    'primary-blue': PaletteOptions['primary'];
    'primary-orange': PaletteOptions['primary'];
    'default-btn': PaletteOptions['primary'];
    'secondary-background': PaletteOptions['secondary'];
    'secondary-hover-bl': PaletteOptions['secondary'];
    'secondary-hover-or': PaletteOptions['secondary'];
    'additional-white': PaletteOptions['info'];
    'additional-grey-1': PaletteOptions['info'];
    'additional-grey-2': PaletteOptions['info'];
    'additional-grey-3': PaletteOptions['info'];
    'additional-light-grey-1': PaletteOptions['info'];
    'additional-light-grey-2': PaletteOptions['info'];
    'additional-light-grey-3': PaletteOptions['info'];
    'additional-light-grey-4': PaletteOptions['info'];
    'additional-red': PaletteOptions['error'];
  }
}

export const lightTheme = createTheme({
  typography: {
    fontFamily: fontFamilies.primary,
  },
  palette: {
    mode: 'light',
    'primary-black': {
      main: '#222222',
      contrastText: '#FFFFFF',
    },
    'primary-blue': {
      main: '#142B6F',
      contrastText: '#FFFFFF',
    },
    'primary-orange': {
      main: '#0D5D73',
      contrastText: '#FFFFFF',
    },
    'default-btn': {
      main: '#142B6F',
      contrastText: '#FFFFFF',
    },
    'secondary-background': {
      main: '#f7f7f8',
    },
    'secondary-hover-bl': {
      contrastText: '#FFFFFF',
      main: '#1389A8',
    },
    'secondary-hover-or': {
      main: '#FFAC30',
    },
    'additional-white': {
      main: '#FFFFFF',
    },
    'additional-grey-1': {
      main: '#4D5567',
    },
    'additional-grey-2': {
      main: '#717785',
    },
    'additional-grey-3': {
      main: '#9499A4',
    },
    'additional-light-grey-1': {
      main: '#FCFCFC',
    },
    'additional-light-grey-2': {
      main: '#EDEEF0',
    },
    'additional-light-grey-3': {
      main: '#A6AAB3',
    },
    'additional-light-grey-4': {
      main: '#CACCD1',
      contrastText: '#FFFFFF',
    },
    'additional-red': {
      main: '#F32424',
    },
  },
});
