import { forwardRef, memo } from 'react';

import { DatePicker as MuiDatePicker, type DatePickerProps, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import './DatePicker.scss';

export interface IDatePickerProps extends DatePickerProps<any> {
  className?: string;
  errorMessage?: string | undefined | null;
  hasError?: boolean;
  label?: string;
}

const DatePicker = (
  { className, errorMessage = '', hasError, label = 'Basic date picker', ...rest }: IDatePickerProps,
  ref: any
) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-US">
      <MuiDatePicker
        className={`${className ? className : ''} datepicker-wrapper`}
        label={label}
        slotProps={
          errorMessage
            ? {
                textField: {
                  error: hasError,
                  helperText: errorMessage,
                },
              }
            : {}
        }
        ref={ref}
        {...rest}
      />
    </LocalizationProvider>
  );
};

export default Object.assign(memo(forwardRef(DatePicker)), { displayName: 'DatePicker' });
