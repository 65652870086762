import { useEffect } from 'react';

import Chart from 'components/shared/Chart';
import { useDispatch, useSelector } from 'react-redux';

import styles from './RentalApplication.module.scss';
import { getRentalAplicationAction } from '../../../store/actions';
import { rentalAplicationSelector } from '../../../store/selectors';

interface RentalApplicationProps {
  title: string;
}

const RentalListings: React.FC<RentalApplicationProps> = ({ title }) => {
  const dispatch = useDispatch();
  const rentalAplication = useSelector(rentalAplicationSelector);

  useEffect(() => {
    dispatch(getRentalAplicationAction());
  }, []);

  function transformObjectToArray(obj: any) {
    const result = [];
    for (const key in obj) {
      const name = key.charAt(0).toUpperCase() + key.slice(1);
      result.push({ name: name, y: obj[key] });
    }

    return result;
  }

  const data: any = {
    content: [
      {
        minPointSize: 10,
        innerSize: '85%',
        zMin: 0,
        name: 'Rental Application',
        borderRadius: 10,
        data: transformObjectToArray(rentalAplication),
      },
    ],
    colors: ['#E3296C', '#FDBF03', '#142B6F', '#2C5F9C'],
    legendTitle: '',
    layout: 'vertical',
    title: 'Rental Application',
    itemWidth: 0,
    showDataLabels: true,
  };

  return (
    <div className={styles.chart}>
      <Chart
        type="pie"
        data={data.content}
        text={title}
        colors={data.colors}
        legendTitle={data.legendTitle}
        layout={data.layout}
        showDataLabels={data.showDataLabels}
        itemWidth={data.itemWidth}
      />
    </div>
  );
};

export default RentalListings;
