import React from 'react';

import { Box } from '@mui/system';
import cn from 'classnames';
import Card from 'components/shared/Card';
import StatusLine from 'components/shared/StatusLine';
import { B2, B3 } from 'components/Typography/Body';
import { routes } from 'constants/routes';
import { useNavigate } from 'react-router-dom';

import styles from './MaintenenceCard.module.scss';
import StatusTag from '../../../../src/components/shared/StatusTag';
import SvgIcon from '../../../../src/components/shared/utils/SvgIcon';

const MaintenenceCard = ({ maintenance }: any) => {
  const navigate = useNavigate();

  const onCardClick = () => {
    navigate(`${routes.maintenanceDetails}?unitId=${maintenance?.unit._id}&maintenanceId=${maintenance._id}`);
  };

  return (
    <Card className={styles.maintenanceCard}>
      <Card.Content className={styles.maintenanceCardContent}>
        <Box onClick={() => onCardClick()}>
          <div className={styles.cardItem}>
            <StatusLine type={maintenance?.status}>
              <B2 className={cn(styles.cardItemInfo, styles.address)}>
                <SvgIcon icon={'location'} className={styles.locationIcon} />
                {maintenance?.property?.propertyName} | {maintenance?.unit?.unitNumber} |
                {maintenance?.property?.googleApi?.address?.value}
              </B2>
            </StatusLine>
          </div>
          <div className={styles.cardItem}>
            <B3 bold className={styles.cardItemtitle}>
              Title
            </B3>
            <p className={styles.cardItemInfo}>{maintenance?.title}</p>
          </div>
          <div className={styles.cardItem}>
            <B3 bold className={styles.cardItemtitle}>
              Tenant name
            </B3>
            <p className={styles.cardItemInfo}>{maintenance?.tenant?.fullName}</p>
          </div>
          <div className={styles.cardItem}>
            <B3 bold className={styles.cardItemtitle}>
              Assigned manager
            </B3>
            <p className={styles.cardItemInfo}>{maintenance?.assignee?.email}</p>
          </div>
          <div className={styles.cardItem}>
            <B3 bold className={styles.cardItemtitle}>
              Request priority
            </B3>
            <StatusTag label={maintenance?.priority} type={maintenance?.priority} />
          </div>
          <div className={cn(styles.cardItem, styles.lastItem)}>
            <B3 bold className={styles.cardItemtitle}>
              Status
            </B3>
            <StatusTag label={maintenance?.status} type={maintenance?.status} />
          </div>
        </Box>
      </Card.Content>
    </Card>
  );
};

export default MaintenenceCard;
