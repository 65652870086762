/* eslint-disable jsx-a11y/no-static-element-interactions */
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Grid } from '@mui/material';
import cn from 'classnames';
import CodeInput from 'components/shared/FormElements/CodeInput/CodeInput';
import { B2 } from 'components/Typography/Body';
import { H4 } from 'components/Typography/Headlines';
import { confirmEmailOtp } from 'modules/Application/store/actions';
import { resendOtpAction } from 'modules/Auth/store/actions';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import styles from './ConfirmEmail.module.scss';

function padZero(number: any) {
  return number < 10 ? '0' + number : number;
}

export const formatTime = (milliseconds: number): string => {
  const minutes = Math.floor((milliseconds % 3600000) / 60000);
  const seconds = Math.floor((milliseconds % 60000) / 1000);
  return `${padZero(minutes)}:${padZero(seconds)}`;
};

const ConfirmEmail = () => {
  const [code, setCode] = useState('');
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const registeredUser = localStorage.getItem('registeredUser') && JSON.parse(localStorage.getItem('registeredUser') as string);
  const hendleSetCode = useCallback(
    (e: string) => {
      setCode(e);
      const uuid = registeredUser?.uuid;
      if (e.length === 6 && uuid) {
        dispatch(confirmEmailOtp({ data: { code: e, uuid }, navigate, id: searchParams.get('id') }));
      }
    },
    [code]
  );

  const handleResend = useCallback(() => {
    if (registeredUser && countdown <= 0) {
      dispatch(resendOtpAction({ uuid: registeredUser.uuid }));
      const timerInterval = setInterval(() => {
        if (countdown > 0) {
          setCountdown(prevCountdown => prevCountdown - 1000);
        } else {
          clearInterval(timerInterval);
        }
      }, 1000);
    }
  }, []);

  const targetTime = useMemo(() => {
    const newDate = new Date();
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  }, []);

  const currentTime = useMemo(() => {
    const newDate = new Date();
    newDate.setHours(0, 0, 120 - Math.floor((Date.now() - registeredUser?.timer) / 1000), 0);
    return newDate;
  }, [registeredUser?.timer]);

  const initialCountdown = useMemo(() => {
    return currentTime.getTime() - targetTime.getTime();
  }, [currentTime, targetTime]);

  const [countdown, setCountdown] = useState(initialCountdown);

  useEffect(() => {
    setCountdown(initialCountdown);
  }, [initialCountdown, registeredUser?.timer]);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (countdown > 0) {
        setCountdown(prevCountdown => prevCountdown - 1000);
      } else {
        clearInterval(timerInterval);
      }
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [countdown]);

  return (
    <Grid item xs={6} className={styles.confirmAccount}>
      <div className={styles.boxGroup}>
        <Box className={styles.headlineBox}>
          <H4 className={styles.confirmCode} color="var(--additional-grey-1)">
            Confirm code*
          </H4>
          <B2 color="var(--additional-grey-2)" bold>
            {`Enter the 6- digit confirmation code weve sent ${registeredUser?.email} your email`}
          </B2>
        </Box>
        <Box className={styles.codeInputBox}>
          <CodeInput name="confirmCode" isValid={true} value={code} onChange={hendleSetCode} disabled={false} />
        </Box>
        <Box className={cn(styles.dontGetCodeBox, { [styles.countdownBox]: countdown > 0 })}>
          {countdown <= 0 ? (
            <B2 color="var(--additional-grey-3)" bold>
              Don't get any code?
            </B2>
          ) : (
            ''
          )}
          <B2 className={cn({ [styles.resend]: countdown <= 0 })} color="var(--primary-orange)" bold onClick={handleResend}>
            {countdown <= 0 ? <>Request again</> : <div>{formatTime(countdown)}</div>}
          </B2>
        </Box>
      </div>
    </Grid>
  );
};

export default forwardRef(ConfirmEmail);
