import toast from 'react-hot-toast';
import { call, type ForkEffect, put, takeLatest } from 'redux-saga/effects';

import * as homeService from '../HomeService';
import * as actions from '../store/actions';
import homeSlice from '../store/HomeSlice';

function* getRentalListingsSaga() {
  try {
    const { data } = yield call(homeService.getRentalListings);
    yield put(homeSlice.actions.getRentalListings(data));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  }
}

function* getRentalAplicationSaga() {
  try {
    const { data } = yield call(homeService.getRentalAplication);
    yield put(homeSlice.actions.getRentalAplication(data));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  }
}

function* getTenantsIncomeSaga() {
  try {
    const { data } = yield call(homeService.getTenantsIncome);
    yield put(homeSlice.actions.getTenantsIncome(data));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  }
}

function* getDashboardMaintenanceSaga() {
  try {
    const { data } = yield call(homeService.getDashboardMaintenance);
    yield put(homeSlice.actions.getdashboardMaintenance(data));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  }
}

function* getExpiringLeasesSaga({ payload }: ReturnType<typeof actions.getExpiringLeasesAction>) {
  try {
    const { data } = yield call(homeService.getExpiringLeases, payload);
    yield put(homeSlice.actions.getExpiringLeases(data));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  }
}

function* getDashboardTasksSaga({ payload }: ReturnType<typeof actions.getDashboardTasksAction>) {
  try {
    const { data } = yield call(homeService.getDashboardTasks, payload);
    yield put(homeSlice.actions.getDashboardTasks(data.items));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
  }
}

export function* watchHomeSaga(): Generator<ForkEffect> {
  yield takeLatest(actions.getRentalListingsAction.type, getRentalListingsSaga);
  yield takeLatest(actions.getRentalAplicationAction.type, getRentalAplicationSaga);
  yield takeLatest(actions.getTenantsIncomeAction.type, getTenantsIncomeSaga);
  yield takeLatest(actions.getDashboardMaintenanceAction.type, getDashboardMaintenanceSaga);
  yield takeLatest(actions.getExpiringLeasesAction.type, getExpiringLeasesSaga);
  yield takeLatest(actions.getDashboardTasksAction.type, getDashboardTasksSaga);
}
