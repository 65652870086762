import { type RootState } from 'store/setupStore';

import { type taskDetails } from './types';

export const loadingSelector = (state: RootState) => state.task.isLoading;
export const taskItemsSelector = (state: RootState) => state.task.categoriItems;
export const taskOptionsSelector = (state: RootState) => state.task.categoriOption;
export const propertyDataSelector = (state: RootState) => state.task.properties;
export const taskDataSelector = (state: RootState) => state.task;
export const taskListSelector = (state: RootState) => state.task.tasks;
export const taskCountSelector = (state: RootState) => state.task.count;
export const taskDetailsSelector = (state: RootState): taskDetails => state.task.task;
export const managersSelector = (state: RootState): any[] => state.task.managers;
export const managersAndLandlordsSelector = (state: RootState): any => state.task.managersAndLandlords;
export const taskQueryOptionsSelector = (state: RootState): any => state.task.queryOptions;
