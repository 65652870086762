import { createAction } from '@reduxjs/toolkit';
import { type MaintenanceTaskStatus } from 'constants/userStatus';

import { type createMaintenenceActionModel, type getCategoriModel } from './types';

export const getItems = createAction<getCategoriModel>('getItems');
export const getProperty = createAction<{ id: string }>('getProperty');
export const createTask = createAction<createMaintenenceActionModel>('createTask');
export const getTasksList = createAction<any>('getTasksList');
export const getTaskDetails = createAction<{ taskId: string }>('getTaskDetails');
export const updatetTaskStatus = createAction<{ taskId: string; status: MaintenanceTaskStatus }>('updatetTaskStatus');
export const getManager = createAction('getManager');
export const getManagersAndLandlords = createAction('getManagersAndLandlords');
export const getProperties = createAction('getProperties');
