import { Suspense, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import ProtectedRoute from './ProutectedRoute';
import { authRoutes, RouteRenderer, appRoutes, applicationRoutes } from './routes';
import styles from './Routes.module.scss';
import { routes } from '../../constants/routes';
import AddBankAccount from '../../modals/AddBankAccount';
import SuccessRegisteredModal from '../../modals/SuccessRegisteredModal';
import Application from '../../modules/Application/Application';
import Activate from '../../modules/Auth/Activate';
import { Layout } from '../../modules/Auth/components/views';
import ListingsLayout from '../../modules/Auth/components/views/ListingsLayout';
import { getUserAction } from '../../modules/Auth/store/actions';
import { authLoadingSelector } from '../../modules/Auth/store/selectors';
import PublicListings from '../../modules/PublicListings';
import ViewPublicListing from '../../modules/PublicListings/ViewPublicListing';
import NotFound from '../NotFound';
import PageTopSide from '../PageTopSide';
import Loading from '../shared/Loading';
// TODO delete example app after creating few modules,it is here for an example
const MainRoutes = () => {
  const dispatch = useDispatch();
  const loading = useSelector(authLoadingSelector);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      dispatch(getUserAction());
    }
  }, [dispatch]);

  return (
    <Suspense>
      <BrowserRouter>
        <PageTopSide />
        {loading && <Loading isBig />}
        <div className={styles.wrapper}>
          <Routes>
            <Route path={routes.activate} element={<Activate />} />
            <Route path={routes.publicListings} element={<ListingsLayout />}>
              <Route index element={<PublicListings />} />
              <Route path={`${routes.publicListings}:id`} element={<ViewPublicListing />} />
            </Route>

            <Route path={routes.application} element={<Application />}>
              <Route key={applicationRoutes.name} path={applicationRoutes.path} element={<applicationRoutes.component />} />;
              <Route index element={<Navigate to="1" replace />} />
            </Route>
            <Route path="/auth" element={<Layout />}>
              {authRoutes.map(RouteRenderer)}
              <Route index element={<Navigate to="login" replace />} />
            </Route>
            <Route element={<ProtectedRoute />}>
              {appRoutes.map(RouteRenderer)}
              <Route element={<NotFound />} path="*" />
            </Route>
          </Routes>
        </div>
      </BrowserRouter>
      <SuccessRegisteredModal />
      <AddBankAccount />
    </Suspense>
  );
};

export default MainRoutes;
