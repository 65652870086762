import PrivateAccess from '../../components/PrivateAccess';
import { userRole } from '../../constants/userStatus';

const Documents = () => {
  return (
    <PrivateAccess roles={[userRole.LANDLOARD, userRole.MANAGER]} isPage>
      Documents
    </PrivateAccess>
  );
};

export default Documents;
