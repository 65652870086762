import React, { useEffect, useState } from 'react';

import { Box, Hidden } from '@mui/material';
import DetectInformation from 'components/shared/DetectInformation';
import { B3 } from 'components/Typography/Body';
import { H5 } from 'components/Typography/Headlines';
import { type UseFormReturn, useWatch } from 'react-hook-form';

import styles from './SelectUnit.module.scss';
import UnitBox from './UnitBox';
import { getRandomId, unregisterFormById } from '../../../../common/utils/utils';
import Button from '../../../../components/shared/Button';
import SvgIcon from '../../../../components/shared/utils/SvgIcon';
import { unitTypeContent } from '../constants';
import { type IForm, type IUnit } from '../types';

export interface SelectUnitProps {
  form: UseFormReturn<IForm>;
}
const SelectUnit = ({ form }: SelectUnitProps) => {
  const { control, unregister } = form;
  const selectUnitType = useWatch({ control, name: 'selectUnitType' });
  const [unitsList, setUnitsList] = useState<IUnit[]>([]);
  const addUnits = () => {
    setUnitsList([...unitsList, { id: getRandomId() }]);
  };
  const deleteUnits = (id: string) => {
    unregisterFormById(
      [
        `units.unitNumber${id}`,
        `units.unitSize${id}`,
        `units.bathrooms${id}`,
        `units.bedrooms${id}`,
        `units.status${id}`,
        `units.unitType${id}`,
        `units.parkingSpot${id}`,
        `units.monthlyRent${id}`,
        `units.rentDeposit${id}`,
      ],
      unregister
    );
    setUnitsList(unitsList.filter(el => el.id !== id));
  };

  useEffect(() => {
    if (selectUnitType === unitTypeContent.first.name && unitsList.length) {
      unitsList.forEach(({ id }) => {
        unregisterFormById(
          [
            `units.unitNumber${id}`,
            `units.unitSize${id}`,
            `units.bathrooms${id}`,
            `units.bedrooms${id}`,
            `units.status${id}`,
            `units.unitType${id}`,
            `units.parkingSpot${id}`,
            `units.monthlyRent${id}`,
            `units.rentDeposit${id}`,
          ],
          unregister
        );
      });
      setUnitsList([]);
    }
  }, [selectUnitType]);

  return (
    <div className={styles.selectUnit}>
      <Hidden smDown>
        <H5>Select unit</H5>
        <B3 className={styles.desc}>Add information about your property</B3>
      </Hidden>
      <DetectInformation form={form} name="selectUnitType" content={unitTypeContent} skipErrors />
      <UnitBox form={form} unit={{ id: '#' }} />
      {unitsList.map(unit => (
        <Box key={unit.id} className={styles.boxWrap}>
          <SvgIcon className={styles.deleteIcon} icon={'close'} onClick={() => deleteUnits(unit.id)} />
          <UnitBox form={form} unit={unit} />
        </Box>
      ))}
      {selectUnitType === unitTypeContent.second.name && (
        <Button variant="outlined" size="full-lg" type={'button'} label="+ Add Units" onClick={addUnits} />
      )}
    </div>
  );
};

export default SelectUnit;
