import { useState } from 'react';

import { Avatar } from '@mui/material';
import inactive from 'assets/images/inactive.png';
import { formatDate } from 'common/utils/utils';
import Button from 'components/shared/Button';
import ImageDialog from 'components/shared/ImageDialog';
import SvgIcon from 'components/shared/utils/SvgIcon';
import { B2 } from 'components/Typography/Body';
import { H4, H5 } from 'components/Typography/Headlines';
import modalNames from 'constants/modalNames';
import MarkAsPaidModal from 'modals/MarkAsPaid';
import modalsSlice from 'modals/store/modalsSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import styles from './PaymentCard.module.scss';
import PrivateAccess from '../../../components/PrivateAccess';
import { routes } from '../../../constants/routes';
import { userRole } from '../../../constants/userStatus';

const PaymentCard = ({ payment, role }: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const markAsPaid = () => {
    dispatch(modalsSlice.actions.showModal({ modalName: modalNames.MARK_AS_PAID }));
  };

  const [openFullScreen, setOpenFullScreen] = useState(false);

  const handleOpenFullScreen = () => {
    setOpenFullScreen(true);
  };

  const handleCloseFullScreen = () => {
    setOpenFullScreen(false);
  };

  return (
    <div className={styles.card}>
      <div className={styles.wrap}>
        <div className={styles.imageWrap}>
          <Avatar
            className={styles.image}
            onClick={handleOpenFullScreen}
            src={
              payment?.property?.gallery?.cover
                ? `${process.env.REACT_APP_BASE_URL}/api/document?path=${payment?.property?.gallery?.cover}`
                : inactive
            }
          />
          {openFullScreen && payment?.property?.gallery?.cover && (
            <ImageDialog
              imageUrl={`${process.env.REACT_APP_BASE_URL}/api/document?path=${payment?.property?.gallery?.cover}`}
              onClose={handleCloseFullScreen}
            />
          )}
        </div>
        <div className={styles.info}>
          <H5 className={styles.rent}>
            <SvgIcon icon={'list'} className={styles.listIcon} />
            Payment {payment?.type === 'DEPOSIT' ? 'Deposit' : 'Rent'}
          </H5>
          <H5 bold className={styles.propertyName}>
            {`${role === 'LANDLORD' ? payment?.property[0]?.propertyName : payment?.property?.propertyName} | ${
              payment?.unit?.unitNumber
            }`}
          </H5>
          <B2 className={styles.paymentDate}>Until {formatDate(payment?.dueDate)}</B2>
        </div>
      </div>
      <div className={styles.actions}>
        <H4 bold> $ {payment?.amount}</H4>
        <B2>Current balance</B2>
        <PrivateAccess roles={[userRole.TENANT]}>
          <Button
            variant="contained"
            size="full-sm"
            type="button"
            label="Pay now"
            onClick={() => navigate(`${routes.paymentPage}/${payment._id}`)}
          />
        </PrivateAccess>
        <PrivateAccess roles={[userRole.LANDLOARD, userRole.MANAGER]}>
          <Button variant="contained" size="full-sm" type="button" label="Return Deposit" onClick={markAsPaid} />
        </PrivateAccess>
      </div>
      <MarkAsPaidModal paymentId={payment?._id} amount={payment?.amount} type="DEPOSIT" />
    </div>
  );
};
export default PaymentCard;
