import * as Yup from 'yup';

export const helpSchema = Yup.object().when((_value, schema) => {
  return schema.shape({
    title: Yup.string().required('Required field').min(1, 'Please enter 1 characters').max(25, 'Please enter 25 characters'),
    description: Yup.string()
      .required('Required field')
      .min(1, 'Please enter 1 characters')
      .max(2000, 'Please enter 2000 characters'),
  });
});
