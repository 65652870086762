import { type RootState } from 'store/setupStore';

import { type maintenanceDetails } from './types';

export const loadingSelector = (state: RootState) => state.maintenance.isLoading;
export const maintenecesItemsSelector = (state: RootState) => state.maintenance.categoriItems;
export const maintenecesOptionsSelector = (state: RootState) => state.maintenance.categoriOption;
export const propertyDataSelector = (state: RootState) => state.maintenance.property;
export const maintenenceDataSelector = (state: RootState) => state.maintenance;
export const maintenenceQueryOptionsSelector = (state: RootState) => state.maintenance.queryOptions;
export const maintenenceListSelector = (state: RootState) => state.maintenance.maintenances;
export const maintenenceCountSelector = (state: RootState) => state.maintenance.count;
export const maintenanceDetailsSelector = (state: RootState): maintenanceDetails => state.maintenance.maintenance;
export const maintenanceAssignedSelector = (state: RootState) => state.maintenance.assigned;
