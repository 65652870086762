import { type MaintenenceState } from './types';
import { type IForm } from '../AssignMaintenance/types';

export const createMaintenenceRequestData = (payload: IForm, maintenenceCreateData: MaintenenceState) => {
  const [selectedTime] = [
    { key: 'hasFirstTime', value: 'Any Time' },
    { key: 'hasSecondTime', value: '8AM -12PM' },
    { key: 'hasThirdTime', value: '12PM -4PM' },
    { key: 'hasFourthTime', value: '4PM -8PM' },
  ].filter(({ key }: { key: string }) => payload[key]);
  return {
    dueDate: payload?.availableDate,
    category: categoriAdapter(payload.category as any[]),
    title: payload.title,
    description: payload.description,
    priority: payload.requestPriority,
    property: maintenenceCreateData.property?.propertyId || payload?.propertyId,
    unit: maintenenceCreateData.property?.unitId || payload?.unitId,
    convenientTime: selectedTime?.value,
  };
};

const categoriAdapter = (categories: any[]) => {
  const result = categories.reduce((acc, currentItem) => {
    if (typeof currentItem === 'object') {
      acc.push(currentItem?.label);
    } else {
      acc.push(currentItem);
    }
    return acc;
  }, []);
  return result;
};

export const assigneManager = (manager: { _id: string; email: string; fullName: string; maintenanceId: string }) => {
  return {
    managerId: manager._id,
    email: manager.email,
    maintenanceId: manager.maintenanceId,
  };
};
