import { type AxiosResponse } from 'axios';

import request from '../../../services/request';

const END_POINTS = {
  getRentalListings: 'api/dashboard',
};

export async function getRentalListings() {
  return request({
    method: 'GET',
    url: `${END_POINTS.getRentalListings}/desktop-listings`,
  });
}

export async function getRentalAplication() {
  return request({
    method: 'GET',
    url: `${END_POINTS.getRentalListings}/desktop-application`,
  });
}

export async function getTenantsIncome() {
  return request({
    method: 'GET',
    url: `${END_POINTS.getRentalListings}/income`,
  });
}

export async function getDashboardMaintenance() {
  return request({
    method: 'GET',
    url: `${END_POINTS.getRentalListings}/desktop-maintenance`,
  });
}

export async function getExpiringLeases(payload: any): Promise<AxiosResponse<any, any>> {
  return request({
    method: 'GET',
    url: `${END_POINTS.getRentalListings}/desktop-lease` + (payload.date ? `?date=${payload.date}` : ''),
  });
}

export async function getDashboardTasks(payload: any): Promise<AxiosResponse<any, any>> {
  return request({
    method: 'GET',
    url:
      `${END_POINTS.getRentalListings}/desktop-task?limit=${payload.size}&page=${payload.page}` +
      (payload._id ? `&assignedToMe=${payload._id}` : ''),
  });
}
