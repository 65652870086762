import React from 'react';

import { Box } from '@mui/system';
import cn from 'classnames';
import Card from 'components/shared/Card';
import StatusLine from 'components/shared/StatusLine';
import { B2, B3 } from 'components/Typography/Body';
import { routes } from 'constants/routes';
import { useNavigate } from 'react-router-dom';

import styles from './LeaseCard.module.scss';
import StatusTag from '../../../../src/components/shared/StatusTag';
import SvgIcon from '../../../../src/components/shared/utils/SvgIcon';
import { formatDate } from '../../../common/utils/utils';

const LeaseCard = ({ lease }: any) => {
  const navigate = useNavigate();

  const onCardClick = (id: string) => {
    navigate(`${routes.lease}/${id}`);
  };

  return (
    <Card className={styles.leaseCard}>
      <Card.Content className={styles.leaseCardContent}>
        <Box onClick={() => onCardClick(lease?._id)}>
          <div className={styles.cardItem}>
            <StatusLine type={lease?.status}>
              <B2 className={cn(styles.cardItemInfo, styles.address)}>
                <SvgIcon icon={'location'} className={styles.locationIcon} />
                {lease?.address}
                {`( multi- building complex ) - ${lease?.unitNumber}`}
              </B2>
            </StatusLine>
          </div>
          <div className={styles.cardItem}>
            <B3 bold className={styles.cardItemtitle}>
              Tenant name
            </B3>
            <p className={styles.cardItemInfo}>
              {lease?.fullName} {lease?.firstName} {lease?.lastName}
            </p>
          </div>
          <div className={styles.cardItem}>
            <B3 bold className={styles.cardItemtitle}>
              Lease Status
            </B3>
            <StatusTag label={lease?.eSignatureStatus} type={lease?.eSignatureStatus} />
          </div>
          <div className={styles.cardItem}>
            <B3 bold className={styles.cardItemtitle}>
              eSignature Status
            </B3>
            <StatusTag label={lease?.status} type={lease?.status} />
          </div>
          <div className={styles.cardItem}>
            <B3 bold className={styles.cardItemtitle}>
              Start Date
            </B3>
            <p className={styles.cardItemInfo}>{formatDate(lease?.startDate)}</p>
          </div>
          <div className={cn(styles.cardItem, styles.lastItem)}>
            <B3 bold className={styles.cardItemtitle}>
              End Date
            </B3>
            <p className={styles.cardItemInfo}>{formatDate(lease?.endDate)}</p>
          </div>
        </Box>
      </Card.Content>
    </Card>
  );
};

export default LeaseCard;
