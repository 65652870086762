import { createAction } from '@reduxjs/toolkit';

import {
  type ILogIn,
  type IRegistration,
  type IVerifyOtp,
  type IResendOtp,
  type IResendEmail,
  type IResetPassword,
  type IActivate,
  type IForgotPassword,
  type IVerifyForgotPassword,
} from './types';

export const logInAction = createAction<ILogIn>('logInAction');
export const getUserAction = createAction('getUserAction');

export const registrationAction = createAction<IRegistration>('registrationAction');
export const inviteSignUpAction = createAction<IRegistration>('inviteSignUp');
export const confirmEmailOtpAction = createAction<IVerifyOtp>('confirmEmailOtpAction');
export const verifyOtpAction = createAction<IVerifyOtp>('verifyOtpAction');
export const resendOtpAction = createAction<IResendOtp>('resendOtpAction');
export const resendEmailAction = createAction<IResendEmail>('resendEmailAction');
export const forgotPasswordAction = createAction<IForgotPassword>('forgotPasswordAction');
export const verifyForgotPasswordAction = createAction<IVerifyForgotPassword>('verifyForgotPasswordAction');
export const resetPasswordAction = createAction<IResetPassword>('resetPasswordAction');
export const activateAction = createAction<IActivate>('activateAction');
export const activationTokenAction = createAction<{ id: string }>('activationTokenAction');
