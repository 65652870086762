export const filterLinksTitle: { [key: string]: string } = {
  ALL: 'All listings',
  UNPUBLISHED: 'Unpublished',
  PUBLISHED: 'Published',
  END: 'End',
  RENTED: 'Rented',
};
export const countTitle: { [key: string]: string } = {
  ALL: 'totalListings',
  UNPUBLISHED: 'unpublishedListings',
  PUBLISHED: 'publishedListings',
  END: 'endListings',
  RENTED: 'rentedListings',
};
