import { type ReactNode } from 'react';

import cn from 'classnames';

import styles from './Content.module.scss';

interface ExtendableContentProps {
  className?: string;
  children: ReactNode;
}

const ExtendableContent = ({ children, className }: ExtendableContentProps) => {
  return <div className={cn(styles.content, className)}>{children}</div>;
};
export default ExtendableContent;
