import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage, getToken } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyDNzzRmz_Z4lB_ifSYV1EB0y3a5PXEOxIc',
  authDomain: 'rentx-fbaa6.firebaseapp.com',
  projectId: 'rentx-fbaa6',
  storageBucket: 'rentx-fbaa6.appspot.com',
  messagingSenderId: '945917591107',
  appId: '1:945917591107:web:4ba5bfe726b3aa0acbdef6',
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey: 'BKS43km7rDBfV7HS6SoQEJSNxsh48J_uwNo7X_VX0Kqk6IgR3xmwYXGKtBkPp4X7ABPIESWZiAg0GyOsHkP4usE',
  })
    .then(currentToken => {
      if (currentToken) {
        return currentToken;
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    })
    .catch(err => {
      console.log('An error occurred while retrieving token. ', err);
    });
};

export const onMessageListener = () => {
  return new Promise(resolve => {
    onMessage(messaging, payload => {
      resolve(payload);
    });
  });
};
