import { useEffect } from 'react';

import Reminder from 'components/Reminder';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import styles from './Activate.module.scss';
import { getUrlParams } from '../../../common/utils/utils';
import { routes } from '../../../constants/routes';
import { activateAction } from '../store/actions';
import { userSelector, tokenErrorSelector } from '../store/selectors';

const Activate = () => {
  const params = getUrlParams();
  const user = useSelector(userSelector);
  const error = useSelector(tokenErrorSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  if (user) {
    navigate(routes.profile);
  }

  useEffect(() => {
    localStorage.setItem('token', '');
    if (params.token && !user) {
      dispatch(activateAction({ token: params.token }));
    }
  }, []);

  return error ? (
    <div className={styles.tokenErrorReminder}>
      <Reminder
        text="Token Not Found."
        note="It seems there was an issue retrieving your authentication token. Please try sign in again."
        buttonText="Return to homepage"
        redirectUrl="auth"
      />
    </div>
  ) : null;
};

export default Activate;
