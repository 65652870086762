import { type AxiosResponse } from 'axios';
import request from 'services/request';

const END_POINTS = {
  getCategory: 'api/helpCategory',
  uploadDocuments: 'api/document/upload-help',
  createHelp: 'api/help/create',
};

export function getCategory(): Promise<AxiosResponse<any, any>> {
  return request({
    method: 'GET',
    url: END_POINTS.getCategory,
  });
}

export function uploadDocuments(document: File[], helpId: string) {
  const formData = new FormData();
  !!document.length &&
    document.forEach((file: File) => {
      formData.append(`files`, file, file.name);
    });
  formData.append('helpId', helpId);
  return request({
    method: 'POST',
    url: END_POINTS.uploadDocuments,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function createHelp(payload: any): Promise<AxiosResponse<any, any>> {
  const data: any = {
    title: payload.title,
    description: payload.description,
  };
  if (payload.category) {
    data.category = payload.category;
  }
  return request({
    method: 'POST',
    url: END_POINTS.createHelp,
    data,
  });
}
