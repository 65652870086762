import { useEffect, useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import useMediaQuery from '@mui/material/useMediaQuery';
import { type ActionCreatorWithPayload } from '@reduxjs/toolkit';
import rxLogo from 'assets/images/rxLogo.png';
import cn from 'classnames';
import ApplicationCard from 'components/shared/ApplicationCard';
import Button from 'components/shared/Button';
import { ProgressBarMode } from 'components/shared/ProgressBar/constants';
import ProgressBar from 'components/shared/ProgressBar/ProgressBar';
import { H3 } from 'components/Typography/Headlines';
import { routes } from 'constants/routes';
import { userRole } from 'constants/userStatus';
import { userSelector } from 'modules/Auth/store/selectors';
import { getListingByIdAction } from 'modules/Listings/store/actions';
import { listingSelector } from 'modules/Listings/store/selectors';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useParams, useNavigate, useSearchParams, type SetURLSearchParams } from 'react-router-dom';

import styles from './Application.module.scss';
import { ProgressBarStepsList, beforeDefaultProps, AplicationSteps, pageList } from './constants';
import Congratulations from './Steps/Congratulations';
import Payment from './Steps/Payment/Payment';
import { applicationLogin, createApplication, emailVerify, registerByInvite } from './store/actions';
import applicationSlice from './store/aplicationSlice';
import { accountDataSelector, applicationLoadingSelector, stepSelector } from './store/selectors';
import { type createApplicationData } from './store/types';
import { type IForm } from './types';
import { applicationSchema } from './validations/applicationSchema';

const Application = () => {
  const path = useParams();
  const form = useForm({ mode: 'all', defaultValues: { ...beforeDefaultProps }, resolver: yupResolver<any>(applicationSchema) });
  const { formState } = form;
  const { isDirty } = formState;
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const listing = useSelector(listingSelector);
  const filledStep = useSelector(stepSelector);
  const loginData = useSelector(accountDataSelector);
  const isMobileScreen = useMediaQuery('(max-width: 900px)');
  const token = localStorage.getItem('token');
  const passwordToken = localStorage.getItem('passwordToken');
  const user = useSelector(userSelector);
  const applicationLoading = useSelector(applicationLoadingSelector);

  const getQueryParam = (query: string) => {
    return searchParams.get(query);
  };
  const activationToken = getQueryParam('token');

  useEffect(() => {
    if (filledStep === AplicationSteps.CONGRATULATIONS) {
      navigate(`${routes.tenantApplication}`);
      return;
    }
    const id = getQueryParam('id');
    dispatch(applicationSlice.actions.clearApplication());
    if (activationToken) {
      localStorage.removeItem('token');
      navigate(`${routes.application}/${AplicationSteps.BEFOREBEGIN}?auth=signup&id=${id}&token=${activationToken}`);
      return;
    } else if (token && !passwordToken) {
      navigate(
        `/application/${
          Number(path.step) === AplicationSteps.BEFOREBEGIN ? AplicationSteps.PERSONALINFORMATION : path.step
        }?id=${id}`
      );
    } else if (
      (Number(path.step) === AplicationSteps.BEFOREBEGIN || !token) &&
      getQueryParam('auth') !== 'signup' &&
      getQueryParam('auth') !== 'confirm'
    ) {
      searchParams.set('auth', 'login');
      setSearchParams(searchParams);
      navigate(`${routes.application}/${AplicationSteps.BEFOREBEGIN}?auth=login&id=${id}`);
    }
    id && dispatch(getListingByIdAction(id));
    localStorage.removeItem('sidebarState');
    return () => {
      dispatch(applicationSlice.actions.clearApplication());
    };
  }, []);

  useEffect(() => {
    loginData?.tenantInfo?.listing && dispatch(getListingByIdAction(loginData?.tenantInfo?.listing));
  }, [loginData]);

  useEffect(() => {
    const id = getQueryParam('id');
    if (token && user?.role === userRole.TENANT && !activationToken && !passwordToken) {
      navigate(
        `/application/${
          Number(path.step) === AplicationSteps.BEFOREBEGIN ? AplicationSteps.PERSONALINFORMATION : path.step
        }?id=${id}`
      );
    }
  }, [path.step]);

  const disabledStep = useMemo(() => {
    return !pageList.includes(searchParams.get('auth') as string);
  }, [searchParams]);

  const onSubmit = (data: IForm) => {
    const id = getQueryParam('id');
    const nextStep =
      Number(path.step) === AplicationSteps.REVIEWANDCONFIRM ? AplicationSteps.CONGRATULATIONS : String(Number(path.step) + 1);
    if (Number(path.step) === AplicationSteps.BEFOREBEGIN) {
      const auth = getQueryParam('auth');
      if (auth) {
        const action = chooseActionByQuery(auth);
        dispatch(action({ data, setSearchParams, navigate, nextStep, id }));
      }
    } else {
      const isAttachDocumentsStep = Number(path.step) === AplicationSteps.ATTACHDOCUMENTS;
      const documents = isAttachDocumentsStep
        ? {
            IDPhoto: data.IDPhoto?.length ? data.IDPhoto : [],
            proofOfIncome: data.proofOfIncome?.length ? data.proofOfIncome : [],
            others: data.others?.length ? data.others : [],
          }
        : {};
      const params: createApplicationData = {
        data,
        navigate,
        nextStep,
        id,
        isDirty,
        ...(isAttachDocumentsStep && { documents }),
      };
      dispatch(createApplication(params));
    }
  };

  const chooseActionByQuery = (query: string): any => {
    const actionsByQuery = {
      signup: localStorage.getItem('passwordToken') ? registerByInvite : emailVerify,
      confirm: emailVerify,
      login: applicationLogin,
    } as unknown as {
      [key: string]: ActionCreatorWithPayload<
        {
          data: IForm;
          setSearchParams?: SetURLSearchParams;
          navigate?: (to: string) => void;
          nextStep?: string;
          id?: string;
        },
        string
      >;
    };
    return actionsByQuery[query];
  };

  return (
    <div className={cn(styles.container, { [styles.done]: path.step === AplicationSteps.CONGRATULATIONS })}>
      {path.step === AplicationSteps.CONGRATULATIONS ? (
        <Congratulations />
      ) : (
        <>
          <div className={styles.progressBarBox}>
            <div className={styles.progressBarBoxIn}>
              <div className={styles.logoWrap}>
                <img src={rxLogo} alt="Logo" className={styles.logo} />
              </div>
              <H3 className={styles.title}>Get started</H3>
              <ProgressBar
                setValue={form.setValue}
                steps={ProgressBarStepsList}
                mode={isMobileScreen ? ProgressBarMode.HORIZONTAL : ProgressBarMode.VERTICAL}
                disabled={disabledStep}
              />
            </div>
          </div>
          {Number(path.step) === AplicationSteps.PAYFEES ? (
            <div>
              <div className={styles.card}>
                <ApplicationCard listing={listing} />
              </div>
              <div>
                <Payment />
              </div>
            </div>
          ) : (
            <FormProvider {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)}>
                <div className={styles.card}>
                  <ApplicationCard listing={listing} />
                </div>
                <div className={styles.outlet}>
                  <Outlet />
                  <div
                    className={cn({
                      [styles.btn]:
                        Number(path.step) !== AplicationSteps.INCOMEVEREFICATIONS &&
                        Number(path.step) !== AplicationSteps.REVIEWANDCONFIRM,
                    })}
                  >
                    {Number(path.step) !== AplicationSteps.INCOMEVEREFICATIONS &&
                      Number(path.step) !== AplicationSteps.REVIEWANDCONFIRM &&
                      Number(path.step) !== AplicationSteps.BEFOREBEGIN && (
                        <Button
                          variant="contained"
                          size="full-lg"
                          type="submit"
                          label="Continue"
                          loading={applicationLoading}
                          disabled={applicationLoading || (!getQueryParam('token') && getQueryParam('auth') === 'confirm')}
                        />
                      )}
                  </div>
                </div>
              </form>
            </FormProvider>
          )}
        </>
      )}
    </div>
  );
};

export default Application;
