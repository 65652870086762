import { forwardRef, useEffect } from 'react';

import { B3 } from 'components/Typography/Body';
import { H4 } from 'components/Typography/Headlines';
import { routes } from 'constants/routes';
import { userRole } from 'constants/userStatus';
import dayjs from 'dayjs';
import { getApplicationById } from 'modules/Application/store/actions';
import { personalInformationDataSelector } from 'modules/Application/store/selectors';
import { userSelector } from 'modules/Auth/store/selectors';
import { AplicationSteps } from 'modules/constants/application';
import { useFormContext } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import styles from './PersonalInformation.module.scss';
import TenantPersonalInfo from '../../../../components/TenantPersonalInfo';
import { type PersonalInformationDataModel } from '../../../types/applicationTypes';
import { type IForm } from '../../types';

const PersonlInformation = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const personalInformationData: PersonalInformationDataModel | null | undefined = useSelector(personalInformationDataSelector);
  console.log(personalInformationData);
  const user = useSelector(userSelector);
  const navigate = useNavigate();

  const form = useFormContext<IForm>();
  const { reset, getValues } = form;

  useEffect(() => {
    const userId = localStorage.getItem('id');
    if (userId) {
      dispatch(getApplicationById(userId));
    }
  }, []);

  useEffect(() => {
    if (user && user.role === userRole.LANDLOARD) {
      localStorage.clear();
      const id = searchParams.get('id');
      navigate(`${routes.application}/${AplicationSteps.BEFOREBEGIN}?auth=login&id=${id}`);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      reset({
        ...getValues(),
        firstName: user.profile?.firstName,
        lastName: user.profile?.lastName,
        middleName: user.profile?.middleName,
        suffix: user.profile?.suffix,
        SSN: user.profile?.SSN,
        email: user?.email,
        ...(user.profile?.dateOfBirth && { dateOfBirth: dayjs(user.profile?.dateOfBirth) }),
      });
    }
  }, [user]);

  return (
    <div className={styles.personlInformation}>
      <H4 className={styles.title} bold>
        Rental Application
      </H4>
      <B3 className={styles.desc}>Evolve Realty & Development</B3>
      <div className={styles.personInfoForm}>
        <TenantPersonalInfo form={form} personalInformationPhones={user && user.profile?.phones} />
      </div>
    </div>
  );
};

export default forwardRef(PersonlInformation);
