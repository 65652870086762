import { createSlice } from '@reduxjs/toolkit';

import { type DocumentsDataModel, type ApplicationsDataModel } from '../../types/applicationTypes';
import { AplicationSteps } from '../constants';

const initialState: ApplicationsDataModel = {
  isLoading: false,
  unit: null,
  step: `${AplicationSteps.BEFOREBEGIN}`,
  moveIn: '',
  applyingAs: 'TENANT',
  personalInformation: null,
  residential: null,
  coApplicants: null,
  yourIncome: null,
};

const applicationSlice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    setLoading: (state: ApplicationsDataModel, { payload }) => {
      state.isLoading = payload;
    },
    getUnitById: (state: ApplicationsDataModel, { payload }) => {
      state.unit = payload.unit;
    },
    setApplicationData: (state: ApplicationsDataModel, { payload }: { payload: ApplicationsDataModel }) => {
      if (payload.documents) {
        if (!payload.documents?.IDPhoto?.length) {
          payload.documents.IDPhoto = null;
        }
        if (!payload.documents?.proofOfIncome?.length) {
          payload.documents.proofOfIncome = null;
        }
      }
      state.step = payload.step;
      state.moveIn = payload.createdAt;
      state.personalInformation = payload.personalInformation;
      state.residential = payload.residential;
      state.coApplicants = payload.coApplicants;
      state.yourIncome = payload.yourIncome;
      state.documents = payload.documents;
      state.account = payload.account;
      state.status = payload.status;
      state.plaidVerified = payload.plaidVerified;
      state.paid = payload.paid;
    },
    setApplicationStatus: (state: ApplicationsDataModel, { payload }) => {
      state.status = payload;
    },
    setPlaidLinkToken: (state: ApplicationsDataModel, { payload }) => {
      state.linkToken = payload;
    },
    setPlaidSuccess: (state: ApplicationsDataModel, { payload }: { payload: boolean }) => {
      state.plaidVerified = payload;
    },
    setPaymentSuccess: (state: ApplicationsDataModel, { payload }) => {
      state.paymentSuccess = payload;
    },
    setAccountToken: (state: ApplicationsDataModel, { payload }) => {
      state.account = payload;
    },
    setStep: (state: ApplicationsDataModel, { payload }: { payload: `${AplicationSteps}` }) => {
      state.step = payload;
    },
    setDocumentsData: (state: ApplicationsDataModel, { payload }: { payload: DocumentsDataModel }) => {
      state.documents = payload;
    },
    clearApplication: () => {
      return initialState;
    },
  },
});

export default applicationSlice;
