import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { hasPermissions } from '../common/utils/utils';
import { type userRole } from '../constants/userStatus';
import { userSelector } from '../modules/Auth/store/selectors';

export const useIsAuthorized = (roles: userRole[]): any => {
  const user = useSelector(userSelector);
  return useMemo(() => user && hasPermissions(roles, user.role), [roles, user]);
};
