import React, { useCallback, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import Button from 'components/shared/Button/Button';
import { Input } from 'components/shared/FormElements';
import DatePicker from 'components/shared/FormElements/DatePicker/DatePicker';
import PhoneInput from 'components/shared/FormElements/PhoneInput/PhoneInput';
import GoogleAddressBox from 'components/shared/GoogleAddressBox/GoogleAddressBox';
import SvgIcon from 'components/shared/utils/SvgIcon';
import { B3 } from 'components/Typography/Body';
import { H4, H5 } from 'components/Typography/Headlines';
import dayjs from 'dayjs';
import { AplicationSteps } from 'modules/Application/constants';
import { getApplicationById } from 'modules/Application/store/actions';
import { residentialDataSelector } from 'modules/Application/store/selectors';
import { type IForm } from 'modules/Application/types';
import { type ResidentialHistoryDataModel, type ResidentialDataModel } from 'modules/types/applicationTypes';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import styles from './ResidanceAddress.module.scss';
import { getRandomId, unregisterFormById } from '../../../../common/utils/utils';
import { ResidanceAddressName } from '../constants';

const ResidanceAddress = () => {
  const [addresses, setAddresses] = useState<{ id: string }[]>([]);
  const [maxDates, setMaxDates] = useState<Record<string, any>>({});

  const dispatch = useDispatch();
  const residentialData: ResidentialDataModel | null | undefined = useSelector(residentialDataSelector);
  const defaultDate = dayjs(new Date());

  const { step } = useParams();

  const {
    control,
    unregister,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<IForm>();

  const startFrom = useWatch({ control, name: 'residedFrom#' });

  useEffect(() => {
    const userId = localStorage.getItem('id');
    if (userId && Number(step) === AplicationSteps.RESIDENCEADDRESS) {
      dispatch(getApplicationById(userId));
    }
  }, []);
  useEffect(() => {
    if (residentialData && residentialData.residentialHistory.length) {
      const [mainAddressGroup, ...aditionalAddressGroup] = residentialData.residentialHistory || [];
      setValue('address#', mainAddressGroup?.address, { shouldValidate: true });
      setValue('address1#', mainAddressGroup?.address1, { shouldValidate: true });
      setValue('country#', mainAddressGroup?.country, { shouldValidate: true });
      setValue('state#', mainAddressGroup?.state);
      setValue('zipCode#', mainAddressGroup?.zipCode, { shouldValidate: true });
      setValue('city#', mainAddressGroup?.city, { shouldValidate: true });
      setValue('residedFrom#', dayjs(mainAddressGroup?.residedFrom), { shouldValidate: true });

      const newPhones: { id: string }[] = [];
      if (aditionalAddressGroup.length) {
        aditionalAddressGroup.forEach((aditionalAddress: ResidentialHistoryDataModel) => {
          const id = getRandomId();
          setValue(`address${id}`, aditionalAddress?.address, { shouldValidate: true });
          setValue(`address1${id}`, aditionalAddress?.address1, { shouldValidate: true });
          setValue(`country${id}`, aditionalAddress?.country, { shouldValidate: true });
          setValue(`state${id}`, aditionalAddress?.state, { shouldValidate: true });
          setValue(`zipCode${id}`, aditionalAddress?.zipCode, { shouldValidate: true });
          setValue(`city${id}`, aditionalAddress?.city, { shouldValidate: true });
          setValue(`residedFrom${id}`, dayjs(mainAddressGroup?.residedFrom), { shouldValidate: true });

          newPhones.push({ id });
        });
      }
      setAddresses(newPhones);

      setValue('personRefFirstName', residentialData.personRefFirstName, { shouldValidate: true });
      setValue('personRefAddress', residentialData.personRefAddress, { shouldValidate: true });
      setValue('personRefRelationship', residentialData.personRefRelationship, { shouldValidate: true });
      setValue('personRefPhoneNumber', residentialData.personRefPhoneNumber, { shouldValidate: true });
    }
  }, [residentialData]);

  const deleteAddressGroup = useCallback(
    (id: string) => {
      unregisterFormById(
        [`address${id}`, `address1${id}`, `country${id}`, `state${id}`, `zipCode${id}`, `city${id}`, `residedFrom${id}`],
        unregister
      );
      const newAddressGroup = addresses.filter(addressGroup => addressGroup.id !== id);
      maxDateCompas(newAddressGroup, id);
      setAddresses(newAddressGroup);
    },
    [addresses, unregister]
  );
  const addAddressGroup = useCallback(() => {
    const newId = getRandomId();
    const newAddresses = [...addresses, { id: newId }];
    setAddresses(newAddresses);
    maxDateCompas(newAddresses, newId);
  }, [addresses, maxDates, defaultDate]);

  const maxDateCompas = useCallback(
    (newAddresses: any, newId: string) => {
      const form = getValues();

      const lastAddressId = newAddresses[newAddresses.length - 2]?.id;
      const lastResidedFrom = Object.keys(form).find(el => el === `residedFrom${lastAddressId}`);
      const lastMaxDate = form[lastResidedFrom as string] || startFrom;

      setMaxDates(prevMaxDates => ({
        ...prevMaxDates,
        [newId]: lastMaxDate,
      }));
    },
    [addresses, maxDates, defaultDate, unregister]
  );

  return (
    <div className={styles.container}>
      <H4 className={styles.title} bold>
        Rental Application
      </H4>
      <B3 className={styles.desc}>Evolve Realty & Development</B3>
      <H5 className={styles.addressTitle}>Where you've lived</H5>
      <B3 className={styles.info}>Please provide 2 years of residential history</B3>
      <Box className={styles.inputBox}>
        <GoogleAddressBox key={2} form={useFormContext<IForm>()} names={ResidanceAddressName('#')} />
        <Controller
          name="residedFrom#"
          control={control}
          render={({ field }) => (
            <DatePicker
              className={styles.calendar}
              label="Resided From*"
              maxDate={defaultDate}
              {...field}
              errorMessage={errors['residedFrom#'] ? errors['residedFrom#'].message : null}
              hasError={!!errors['residedFrom#']}
            />
          )}
        />
        <div className={styles.previousAddress}>
          <H5 className={styles.previousAddressTitle}>Previous Addresses</H5>
          <B3 className={styles.subText}>Please provide 2 years of residential history</B3>
        </div>
        {!!addresses.length && (
          <div className={styles.addresses}>
            {addresses.map(({ id }, index) => (
              <div className={styles.additionalAddressGroup} key={id}>
                <div className={!index ? `${styles.closeBox} ${styles.closeFirst}` : styles.closeBox}>
                  {!!index && <hr className={styles.line} />}
                  <SvgIcon icon={'close'} className={styles.closeIcon} onClick={() => deleteAddressGroup(id)} />
                </div>
                <Box className={styles.inputBox}>
                  <GoogleAddressBox key={2} form={useFormContext<IForm>()} names={ResidanceAddressName(id)} />
                  <Controller
                    name={`residedFrom${id}`}
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        className={styles.calendar}
                        label="Resided From*"
                        maxDate={maxDates[id] || defaultDate}
                        {...field}
                        errorMessage={errors[`residedFrom${id}`] ? errors[`residedFrom${id}`]?.message : null}
                        hasError={!!errors[`residedFrom${id}`]}
                      />
                    )}
                  />
                </Box>
              </div>
            ))}
          </div>
        )}
        <Button
          className={styles.addAddress}
          disabled={addresses.length === 5}
          type={'button'}
          variant="outlined"
          size="full-lg"
          label="+ Add Previous Addresses"
          onClick={addAddressGroup}
        />
        <hr className={styles.line}></hr>
        <H5>Personal references</H5>
        <Box className={styles.grid}>
          <Controller
            name="personRefFirstName"
            control={control}
            render={({ field }) => (
              <Input
                label="First Name*"
                errorMessage={errors.personRefFirstName?.message}
                hasError={!!errors.personRefFirstName}
                placeholder="Enter your Legal First Name"
                {...field}
              />
            )}
          />
          <Controller
            name="personRefAddress"
            control={control}
            render={({ field }) => (
              <Input
                label="Address*"
                errorMessage={errors.personRefAddress?.message}
                hasError={!!errors.personRefAddress}
                placeholder="Enter your Address"
                {...field}
              />
            )}
          />
        </Box>
        <Box className={styles.grid}>
          <Controller
            name="personRefRelationship"
            control={control}
            render={({ field }) => (
              <Input
                label="Relationship*"
                errorMessage={errors.personRefRelationship?.message}
                hasError={!!errors.personRefRelationship}
                placeholder="Enter your Relationship"
                {...field}
              />
            )}
          />
          <Controller
            name="personRefPhoneNumber"
            control={control}
            render={({ field }) => (
              <PhoneInput
                label={'Phone Number*'}
                errorMessage={errors.personRefPhoneNumber?.message}
                hasError={!!errors.personRefPhoneNumber}
                className="full-size"
                type="tel"
                {...field}
              />
            )}
          />
        </Box>
      </Box>
    </div>
  );
};

export default ResidanceAddress;
