import { createAction } from '@reduxjs/toolkit';

export const getTenantPaymentListAction = createAction<any>('getTenantPaymentListAction');
export const getPaymentByIdAction = createAction<string>('getPaymentByIdAction');
export const confirmPaymentAction = createAction<string>('confirmPaymentAction');
export const getPaymentMethodsAction = createAction<string>('getPaymentMethodsAction');
export const deletePaymentMethodsAction = createAction<string>('deletePaymentMethodsAction');
export const setPaymentMethodsAction = createAction<any>('setPaymentMethodsAction');
export const markAsPaidAction = createAction<any>('markAsPaidAction');
export const returnDepositAction = createAction<any>('returnDepositAction');
export const getDepositByTenantAction = createAction<any>('getDepositByTenantAction');
export const getDashboardPaymentsAction = createAction('getDashboardPaymentsAction');
export const notifyAboutPaymentAction = createAction<any>('notifyAboutPaymentAction');
