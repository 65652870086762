import { createSlice } from '@reduxjs/toolkit';

import { type tenantPaymentState } from '../types';

const initialState: tenantPaymentState = {
  isLoading: false,
  paymentMethods: null,
  paymentStoryList: {
    items: [],
    count: 0,
  },
  singlePayment: null,
  newPaymentList: {
    items: [],
    count: 0,
  },
  queryOptions: {
    page: 1,
    size: '5',
    sortBy: {
      id: '',
      desc: false,
    },
  },
  deposit: [],
  dashboardPayments: [],
};

const tenantPaymentSlice = createSlice({
  name: 'tenantPaymentSlice',
  initialState,
  reducers: {
    setLoading: (state: tenantPaymentState, { payload }) => {
      state.isLoading = payload;
    },
    getTenantPaymentStateSuccess: (state: tenantPaymentState, { payload }) => {
      if (payload?.status === 'NEW,OVERDUE') {
        state.newPaymentList = payload.data;
      } else {
        state.paymentStoryList = payload.data;
      }

      state.queryOptions = { ...state.queryOptions, ...payload.queryOptions };
    },
    getPaymentByIdSuccess: (state: tenantPaymentState, { payload }) => {
      state.singlePayment = payload.data;
    },
    getPaymentMethodsSuccess: (state: tenantPaymentState, { payload }) => {
      state.paymentMethods = payload.data;
    },
    getDepositByTenantSuccess: (state: tenantPaymentState, { payload }) => {
      state.deposit = payload.data;
    },
    getDashboardPaymentsSuccess: (state: tenantPaymentState, { payload }) => {
      state.dashboardPayments = payload;
    },
  },
});

export default tenantPaymentSlice;
