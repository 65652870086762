import { useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import BackButton from 'components/shared/BackButton/BackButton';
import Autocomplete from 'components/shared/FormElements/Autocomplete';
import TableCard from 'components/shared/TableCard';
import { B1, B2 } from 'components/Typography/Body';
import modalNames from 'constants/modalNames';
import { routes } from 'constants/routes';
import MarkAsPaidModal from 'modals/MarkAsPaid';
import modalsSlice from 'modals/store/modalsSlice';
import { getDepositByTenantAction } from 'modules/PaymentPage/store/actions';
import { depositSelector } from 'modules/PaymentPage/store/selectors';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import styles from './ReturnDeposit.module.scss';
import Button from '../../../../components/shared/Button';
import { Input } from '../../../../components/shared/FormElements';
import { H4 } from '../../../../components/Typography/Headlines';
import { getTenantsList } from '../../../Tenants/store/actions';
import { tenantsSelector } from '../../../Tenants/store/selector';

const ReturnDeposit = () => {
  const { control, setValue, reset } = useForm({
    defaultValues: {
      payer: '',
      category: '',
    },
  });

  const dispatch = useDispatch();
  const [selectedTenant, setSelectedTenant] = useState('');
  const tenants = useSelector(tenantsSelector);
  const deposit = useSelector(depositSelector);

  useEffect(() => {
    dispatch(getTenantsList({ limit: '10000', page: 1 }));
  }, []);

  useEffect(() => {
    if (deposit.length && selectedTenant) {
      setValue('category', `Deposit (balance $${deposit[0].deposit})`);
    } else {
      setValue('category', '');
    }
  }, [deposit]);

  const onCancelClick = () => {
    setSelectedTenant('');
    reset();
  };

  const markAsPaid = () => {
    dispatch(modalsSlice.actions.showModal({ modalName: modalNames.MARK_AS_PAID }));
  };

  const options = useMemo(() => {
    if (tenants?.items) {
      return tenants?.items.map(el => ({ ...el, label: el.fullName }));
    }
    return [];
  }, [tenants]);

  const memoColumns = useMemo(() => {
    return [
      {
        Header: 'Payer',
        id: 'payer',
        title: 'Payer',
        Cell: ({ row }: any) => {
          return (
            <div className={styles.applicant}>
              <B1 bold className={styles.payerName}>
                {row.original.tenantFullName}
              </B1>
            </div>
          );
        },
      },
      {
        Header: 'Method',
        id: 'method',
        title: 'Method',
        Cell: () => {
          return (
            <div>
              <B2 bold className={styles.method}>
                Cash
              </B2>
            </div>
          );
        },
      },
      {
        Header: 'Balance',
        id: 'balance',
        title: 'Balance',
        Cell: ({ row }: any) => {
          return (
            <div>
              <B2>{row.original.deposit}</B2>
            </div>
          );
        },
      },
      {
        Header: 'Refund Amount',
        id: 'amount',
        title: 'Refund Amount',
        Cell: ({ row }: any) => {
          return (
            <div>
              <B2>{row.original.deposit}</B2>
            </div>
          );
        },
      },
    ];
  }, []);
  return (
    <div>
      <div className={styles.header}>
        <BackButton to={routes.landloardPayment} />
        <H4 bold>Deposit Return</H4>
      </div>
      <div className={styles.filters}>
        <Box className={styles.inputBox}>
          <Box className={styles.grid}>
            <Controller
              name={'payer'}
              control={control}
              render={({ field }) => (
                <Autocomplete
                  label="Payer"
                  autoComplete={'off'}
                  options={options}
                  onInputChange={(e: unknown, newInputValue: string) => {
                    const tenant = tenants?.items.filter(item => item.email === newInputValue);
                    if (tenant?.length) {
                      setSelectedTenant(tenant[0]._id);
                      dispatch(getDepositByTenantAction(tenant[0]._id));
                    }
                    field.onChange(newInputValue);
                  }}
                  {...field}
                />
              )}
            />
            <Controller
              name={'category'}
              control={control}
              render={({ field }) => <Input label="Deposit category" disabled={true} {...field} />}
            />
          </Box>
        </Box>
      </div>
      {deposit.length && selectedTenant ? (
        <div>
          <TableCard className={styles.applicantTabel} columns={memoColumns} data={deposit} />
          <div className={styles.tableTotal}>
            <B1>Total</B1>
            <B1 bold>${deposit[0].deposit}</B1>
          </div>
          <div className={styles.actions}>
            <Button variant="outlined" size="ds" type="button" label="Cancel" onClick={onCancelClick} />
            <Button variant="contained" size="ds" type="button" label="Return Deposit" onClick={markAsPaid} />
          </div>
          <MarkAsPaidModal paymentId={deposit[0]._id} amount={deposit[0].deposit} type="DEPOSIT" />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default ReturnDeposit;
