export const regexp = {
  alphanumeric: /[^A-Za-z0-9]/g,
  number: /[^0-9]/g,
  numericalDigits: /[^0-9\s,.]|(?<=\..*)\./g,
  email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  maxNumber: (digit: number) => {
    return new RegExp(`^(?:[1-9]\\d{0,1}|1[0-4]\\d{0,2}|${digit}|${digit}\\.\\d*)$$`);
  },
  formatPhoneNumber: (phoneNumberString: any) => {
    if (phoneNumberString) {
      const cleaned = phoneNumberString.replace(/\D/g, '');
      const match = cleaned.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '1 ($2) $3-$4');
      if (match) {
        return `+${match}`;
      }
    }

    return phoneNumberString;
  },
};
