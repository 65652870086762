import React, { useCallback } from 'react';

import { Modal } from '@mui/material';
import GoogleMap from 'components/shared/GoogleMap';
import SvgIcon from 'components/shared/utils/SvgIcon';
import { H5 } from 'components/Typography/Headlines';
import { useDispatch, useSelector } from 'react-redux';

import styles from './LocationModal.module.scss';
import modalNames from '../../constants/modalNames';
import { type RootState } from '../../store/setupStore';
import { modalsSlice } from '../index';
import { checkModalVisibility, getModalByName } from '../store/selectors';
const LocationModal = () => {
  const dispatch = useDispatch();
  const isModalVisible = useSelector((store: RootState) => checkModalVisibility(store, modalNames.LOCATION_MODAL));
  const data: any = useSelector((store: RootState) => getModalByName(store, modalNames.LOCATION_MODAL));

  const handleModalClose = useCallback(() => {
    dispatch(modalsSlice.actions.hideModal(modalNames.LOCATION_MODAL));
  }, [dispatch]);

  if (!data) {
    return null;
  }

  return (
    <Modal open={isModalVisible} onClose={handleModalClose} className={styles.modal}>
      <div className={styles.locationModal}>
        <header className={styles.header}>
          <H5 bold>Location</H5>
          <SvgIcon icon="close" className={styles.close} onClick={handleModalClose} />
        </header>
        <GoogleMap activeItem={data.googleApi.address} points={data?.points} handleClick={data?.handleClick} />
      </div>
    </Modal>
  );
};

export default LocationModal;
