import { B1, B2, B3 } from 'components/Typography/Body';

import styles from './CreditDetails.module.scss';

const CreditDetails = () => {
  return (
    <div>
      <div className={styles.details}>
        <B1 bold>Recommendation</B1>
        <div className={styles.creditDetails}>
          <B2 bold>Credit Details</B2>
        </div>
        <B2 bold>Score Factors</B2>
        <div className={styles.info}>
          <B3 className={styles.title}>1. Too Many serious derogatory items</B3>
        </div>
        <div className={styles.info}>
          <B3 className={styles.title}>2. Insufficient payment activity over the last year</B3>
        </div>
        <div className={styles.info}>
          <B3 className={styles.title}>3. Not enough balance decreases on active non-mortgage accounts</B3>
        </div>
        <div className={styles.info}>
          <B3 className={styles.title}>4. Not enough available credit</B3>
        </div>
      </div>
    </div>
  );
};

export default CreditDetails;
