import { createAction } from '@reduxjs/toolkit';

import { type IGetListingsQuery, type IInviteToApply, type IStatus } from './types';
import { type IDeleteImage, type IForm } from '../CreateListings/types';

export const createListingsAction = createAction<IForm>('createListingsAction');
export const getListingsAction = createAction<IGetListingsQuery>('getListingsAction');
export const deleteImageAction = createAction<IDeleteImage>('deleteImageAction');
export const getListingByIdAction = createAction<string>('getListingByIdAction');
export const statusUpdateAction = createAction<IStatus>('statusUpdateAction');
export const inviteToApplyAction = createAction<IInviteToApply>('inviteToApplyAction');
export const getApplicationCountByIdAction = createAction<string>('getApplicationCountByIdAction');
