import { createSlice } from '@reduxjs/toolkit';

import { type PropertiesState } from '../types';

const initialState: PropertiesState = {
  isLoading: false,
  count: 0,
  propertiesList: null,
  property: null,
  queryOptions: {
    page: 1,
    size: '5',
  },
};

const propertiesSlice = createSlice({
  name: 'properties',
  initialState,
  reducers: {
    setLoading: (state: PropertiesState, { payload }) => {
      state.isLoading = payload;
    },
    clearProperty: (state: PropertiesState) => {
      state.property = null;
    },
    setQueryOptions: (state: PropertiesState, { payload }) => {
      state.queryOptions = payload;
    },
    updateUnit: (state: PropertiesState, { payload }) => {
      if (state?.property?.units) {
        state.property.units = state?.property.units.map(el => (el?._id === payload._id ? payload : el));
      }
    },
    addUnit: (state: PropertiesState, { payload }) => {
      if (state?.property?.units) {
        state.property.units = [payload, ...state.property.units];
      }
    },
    deleteUnit: (state: PropertiesState, { payload }) => {
      if (state?.property?.units) {
        state.property.units = state?.property.units.filter(el => el?._id !== payload.id);
      }
    },
    setPropertyList: (state: PropertiesState, { payload }) => {
      if (payload.query?.unit) {
        state.property = payload.data;
      } else {
        state.propertiesList = payload.data.items;
        state.count = payload.data.count;
      }
    },
  },
});

export default propertiesSlice;
