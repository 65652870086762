import { createSlice } from '@reduxjs/toolkit';

import { type accountingState } from './types';

const initialState: accountingState = {
  isLoading: false,
  data: [],
};

const accountingSlice = createSlice({
  name: 'accounting',
  initialState,
  reducers: {
    setLoading: (state: accountingState, { payload }) => {
      state.isLoading = payload;
    },
    getAccountingSuccess: (state: accountingState, { payload }) => {
      state.data = payload.data;
    },
  },
});

export default accountingSlice;
