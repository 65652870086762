import React from 'react';

import { Box } from '@mui/system';
import Card from 'components/shared/Card';
import { B3 } from 'components/Typography/Body';
import modalNames from 'constants/modalNames';
import { modalsSlice } from 'modals';
import { useDispatch } from 'react-redux';

import styles from './BankAccountCard.module.scss';
import SvgIcon from '../../../components/shared/utils/SvgIcon';

const BankAccountCard = ({ bankAccount }: any) => {
  const dispatch = useDispatch();

  const handleDeleteComfirmationModal = (id: string) => {
    dispatch(modalsSlice.actions.showModal({ modalName: modalNames.DELETE_SMT, data: id }));
  };
  return (
    <Card className={styles.bankAccountCard}>
      <Card.Content className={styles.bankAccountCardContent}>
        <SvgIcon icon="close" className={styles.close} onClick={() => handleDeleteComfirmationModal(bankAccount._id)} />
        <Box>
          <div className={styles.cardItem}>
            <B3 bold className={styles.cardItemtitle}>
              Account Name
            </B3>
            <p className={styles.cardItemInfo}>{bankAccount?.account_name}</p>
          </div>
          <div className={styles.cardItem}>
            <B3 bold className={styles.cardItemtitle}>
              Account Holder Name
            </B3>
            <p className={styles.cardItemInfo}>{bankAccount?.account_holder_name}</p>
          </div>
          <div className={styles.cardItem}>
            <B3 bold className={styles.cardItemtitle}>
              Routing Number
            </B3>
            <p className={styles.cardItemInfo}>{bankAccount?.routing_number}</p>
          </div>
          <div className={styles.cardItem}>
            <B3 bold className={styles.cardItemtitle}>
              Account Number
            </B3>
            <p className={styles.cardItemInfo}>{bankAccount?.account_number}</p>
          </div>
        </Box>
      </Card.Content>
    </Card>
  );
};

export default BankAccountCard;
