import { useCallback, useEffect, useState } from 'react';

import { Avatar, Box, FormControlLabel, Switch } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import master from './logos/mastercard.png';
import visa from './logos/visa.png';
import styles from './ViewPayment.module.scss';
import { getUrlParams } from '../../../common/utils/utils';
import Payments from '../../../components/Payments';
import Button from '../../../components/shared/Button';
import Loading from '../../../components/shared/Loading';
import SvgIcon from '../../../components/shared/utils/SvgIcon';
import { B1, B4 } from '../../../components/Typography/Body';
import { H4, H5 } from '../../../components/Typography/Headlines';
import modalNames from '../../../constants/modalNames';
import { routes } from '../../../constants/routes';
import { modalsSlice } from '../../../modals';
import PaymentMetodsModal from '../../../modals/PaymentMetodsModal';
import {
  confirmPaymentAction,
  deletePaymentMethodsAction,
  getPaymentByIdAction,
  getPaymentMethodsAction,
  notifyAboutPaymentAction,
} from '../store/actions';
import { paymentLoadingSelector, paymentMethodsSelector, singlePaymentSelector } from '../store/selectors';

const ViewPayment = () => {
  const dispatch = useDispatch();
  const paymentMethods = useSelector(paymentMethodsSelector);
  const paymentLoading = useSelector(paymentLoadingSelector);
  const params = useParams();
  const urlParams = getUrlParams();
  const singlePayment = useSelector(singlePaymentSelector);
  const navigate = useNavigate();
  const [autopayEnabled, setAutopayEnabled] = useState(false);
  useEffect(() => {
    if (params?.id && !urlParams?.redirect_status) {
      dispatch(getPaymentByIdAction(params.id));
    }
  }, [params?.id, urlParams?.redirect_status]);
  useEffect(() => {
    if (params.id && urlParams?.redirect_status === 'succeeded') {
      dispatch(confirmPaymentAction(params.id));
    }
  }, [params.id, urlParams?.redirect_status]);

  useEffect(() => {
    dispatch(getPaymentMethodsAction('id'));
  }, []);
  const handleAutopayToggle = () => {
    setAutopayEnabled(!autopayEnabled);
    // Additional logic if needed
  };
  const notifyCallback = useCallback(() => {
    const payload = {
      propertyId: singlePayment?.property?._id,
      unitId: singlePayment?.unit,
      status: singlePayment?.status,
      type: singlePayment?.type,
    };
    dispatch(notifyAboutPaymentAction(payload));
  }, [singlePayment]);

  console.log(autopayEnabled, 2323);
  console.log(paymentMethods, 'paymentMethods');
  return (
    <div>
      {paymentLoading && <Loading isBig />}
      <PaymentMetodsModal />
      <div className={styles.header}>
        <H4>Payment Methods</H4>
        <FormControlLabel
          value="start"
          control={<Switch color="primary" />}
          checked={autopayEnabled}
          onChange={handleAutopayToggle}
          label="Enable Autopayment"
          labelPlacement="start"
        />
      </div>
      {paymentMethods?.length && autopayEnabled && (
        <div className={styles.methodsWrap}>
          {paymentMethods.map((el: any) => (
            <Box
              key={el.id}
              className={styles.methods}
              onClick={(e: any) => {
                if (e.target.tagName !== 'use') {
                  dispatch(modalsSlice.actions.showModal({ modalName: modalNames.PAYMENT_METHODS_MODAL, data: el }));
                }
              }}
            >
              <Avatar className={styles.image} src={el.brand === 'visa' ? visa : master} alt={' '} />
              <div className={styles.info}>
                <div>
                  <B1 className={styles.brand} bold>
                    {el?.brand}
                  </B1>
                  <B4>{`Credit card ending in ... ${el?.last4}`}</B4>
                </div>
                <SvgIcon icon={'trash'} className={styles.icon} onClick={() => dispatch(deletePaymentMethodsAction(el.id))} />
              </div>
            </Box>
          ))}
        </div>
      )}
      {!urlParams?.redirect_status && (
        <Payments
          hasSecret
          secret={singlePayment?.stripePaymentIntent?.client_secret || ''}
          returnUrl={`${routes.paymentPage}/${params?.id}`}
          shouldNotify={true}
          notifyCallback={notifyCallback}
        />
      )}
      {urlParams?.redirect_status === 'succeeded' && (
        <div className={styles.sucess}>
          <H4 className={styles.title}>YOU HAVE INITIATED A PAYMENT!</H4>
          <H5 className={styles.note}>Please note!</H5>
          <B1 className={styles.note}>
            A bank or credit union transaction usualy takes 3-4 business days to complete via (ACH) used by banks to transfer
            funds. Transfers take longer to complete if over a weekend or a holiday.
          </B1>
          <Button
            variant="contained"
            label="Ok, Got It!"
            size="full-lg"
            type="submit"
            onClick={() => navigate(routes.paymentPage)}
          />
        </div>
      )}
    </div>
  );
};

export default ViewPayment;
