import React, { type ReactNode } from 'react';

import { Button as FlatButton, type ButtonProps as FlatButtonProps, type SxProps, type Theme, Typography } from '@mui/material';
import cn from 'classnames';

// Only include variant, color from MuiButtonProps
type ButtonBaseProps = Pick<FlatButtonProps, 'variant' | 'color' | 'disabled' | 'type'>;

import styles from './Button.module.scss';
import Loading from '../Loading';
interface IButtonSize {
  width: string;
  height: string;
  padding: string;
}

const sizes: Readonly<Record<string, IButtonSize>> = {
  sm: {
    width: '156px',
    height: '36px',
    padding: '10px 18px',
  },
  ds: {
    width: '127px',
    height: '40px',
    padding: '12px 18px',
  },
  nm: {
    width: '170px',
    height: '40px',
    padding: '12px 24px',
  },
  md: {
    width: '183px',
    height: '44px',
    padding: '14px 32px',
  },
  lg: {
    width: '211px',
    height: '48px',
    padding: '16px 48px',
  },
  'full-lg': {
    width: '100%',
    height: '48px',
    padding: '16px 48px',
  },
  'full-sm': {
    width: '100%',
    height: '36px',
    padding: '10px 18px',
  },
  'full-nm': {
    width: '100%',
    height: '40px',
    padding: '12px 24px',
  },
} as const;

export type Size = keyof typeof sizes;

export interface ButtonProps extends ButtonBaseProps {
  size?: Size;
  label?: string | ReactNode;
  onClick?: () => void;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  variant: any;
}

const buttonColors = (variant: 'outlined' | 'contained' | undefined) => {
  const x = {
    outlined: {
      backgroundColor: 'var(--additional-white)',
      color: 'var(--primary-blue)',
      border: `1px solid var(--primary-blue)`,
    },
    contained: {
      backgroundColor: 'var(--primary-blue)',
      color: 'var(--additional-white)',
      '&:hover': {
        backgroundColor: 'var(--primary-blue)',
        opacity: 0.7,
      },
    },
    error: {
      backgroundColor: 'var(--additional-white)',
      color: 'var(--additional-red)',
      border: `1px solid var(--additional-red)`,
    },
  };

  if (Object.keys(x).includes(variant || '') && variant) {
    return x[variant];
  }
  return {
    backgroundColor: 'var(--primary-blue)',
    color: 'var(--additional-white)',
  };
};

const Button = ({ label, size = 'nm', sx = {}, disabled = false, loading, className, variant, ...rest }: ButtonProps) => (
  <FlatButton
    style={sizes[size]}
    className={cn(styles.btn, className)}
    disabled={disabled}
    sx={{
      ...sx,
      ...buttonColors(variant),
      '&.Mui-disabled': {
        backgroundColor: 'var(--additional-light-grey-4)',
        color: 'var(--primary-black)',
        border: `1px solid var(--additional-light-grey-4)`,
      },
      textTransform: 'none',
      textDecoration: variant === 'text' ? 'underline' : 'none',
    }}
    {...rest}
  >
    <Typography variant="body1" fontSize={14} fontWeight={500} fontFamily="Lexend, sans-serif">
      {loading ? <Loading /> : label}
    </Typography>
  </FlatButton>
);

export default Button;
