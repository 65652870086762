import { Dialog, DialogContent } from '@mui/material';
import { Document, Page } from 'react-pdf';

import styles from './ImageDialog.module.scss';
import SvgIcon from '../utils/SvgIcon';

interface ImageDialogProps {
  imageUrl: string;
  onClose: any;
  pdf?: boolean;
}

const ImageDialog = ({ imageUrl, onClose, pdf = false }: ImageDialogProps) => {
  return (
    <Dialog open={true} onClose={onClose}>
      <SvgIcon icon="close" className={styles.close} onClick={onClose} />
      <DialogContent className={styles.content}>
        {!pdf ? (
          <img src={imageUrl} alt="Full screen" className={styles.image} />
        ) : (
          <Document file={imageUrl} className={styles.document}>
            <Page pageNumber={1} width={230} />
          </Document>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ImageDialog;
