import React, { useState } from 'react';

import { Avatar, Box } from '@mui/material';
import inactive from 'assets/images/inactive.png';
import cn from 'classnames';
import Button from 'components/shared/Button';
import ImageDialog from 'components/shared/ImageDialog';
import SvgIcon from 'components/shared/utils/SvgIcon';
import { B2 } from 'components/Typography/Body';
import { H5 } from 'components/Typography/Headlines';
import { routes } from 'constants/routes';
import * as process from 'process';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import styles from './PropertyCard.module.scss';
import modalNames from '../../../constants/modalNames';
import { modalsSlice } from '../../../modals';
import { type IPropertyItems } from '../../../modules/Properties/types';
import Card from '../Card';

interface CardProps {
  property: IPropertyItems;
  open?: boolean;
  hideActions?: boolean;
  isForLease?: boolean;
  action?: string;
  isMaintenance?: boolean;
}
const PropertyCard = ({
  property,
  open = false,
  hideActions = false,
  isForLease = false,
  action = 'Create Listing',
  isMaintenance = false,
}: CardProps) => {
  const navigate = useNavigate();
  const { _id, propertyName, googleApi, units, gallery } = property;
  const dispatch = useDispatch();

  const [openFullScreen, setOpenFullScreen] = useState(false);

  const handleOpenFullScreen = () => {
    setOpenFullScreen(true);
  };

  const handleCloseFullScreen = () => {
    setOpenFullScreen(false);
  };

  return (
    <Card open={open}>
      <Card.Content>
        <div className={styles.card}>
          <div className={styles.wrap}>
            <div className={styles.imageWrap}>
              <Avatar
                className={styles.image}
                onClick={handleOpenFullScreen}
                src={gallery?.cover ? `${process.env.REACT_APP_BASE_URL}/api/document?path=${gallery?.cover}` : inactive}
              />
              {openFullScreen && gallery?.cover && (
                <ImageDialog
                  imageUrl={`${process.env.REACT_APP_BASE_URL}/api/document?path=${gallery?.cover}`}
                  onClose={handleCloseFullScreen}
                />
              )}
            </div>
            <div className={styles.info}>
              <H5 bold className={styles.propertyName}>
                {propertyName}
              </H5>
              <B2
                className={styles.address}
                onClick={() => dispatch(modalsSlice.actions.showModal({ modalName: modalNames.LOCATION_MODAL, data: property }))}
              >
                <SvgIcon icon={'location'} className={styles.locationIcon} />
                {googleApi?.address?.value}
              </B2>
              <B2 bold className={styles.units}>
                {`${units.length} Units`}
              </B2>
            </div>
          </div>

          {!hideActions && (
            <Box className={styles.buttons}>
              <Button
                variant="contained"
                size="full-sm"
                type="button"
                label="Property Details"
                onClick={() => navigate(`${routes.properties}/${_id}`)}
              />
              <Button
                className={styles.edit}
                variant="outlined"
                size="full-sm"
                type="button"
                label="Edit"
                onClick={() => navigate(`${routes.createProperty}?unit=${_id}`)}
              />
            </Box>
          )}
        </div>
      </Card.Content>
      <Card.ExtendableContent className={styles.content}>
        {units.map((unit, index) => (
          <Box key={unit._id} className={styles.extendableContent}>
            <div className={styles.box}>
              <div className={cn(styles.line, { [styles.hideLine]: index === 0 })} />
              <SvgIcon icon={'box'} className={styles.boxIcon} />
              <div className={cn(styles.line, { [styles.hideLine]: index === units.length - 1 })} />
            </div>
            <div className={styles.infoWrap}>
              <div className={styles.info}>
                <H5>
                  {unit.unitNumber} | <span className={styles.status}>{unit.status.label}</span>
                </H5>
                <B2 bold className={styles.type}>
                  {`Square feet: ${unit.unitSize}`} | {`Bedroom: ${unit.bedrooms}`} | {`Bathroom: ${unit.bedrooms}`}
                </B2>
                <H5 className={styles.monthlyRent}>
                  <div className={styles.rent}>{`$${unit.monthlyRent}`}</div>
                </H5>
              </div>
              {!isForLease && (
                <Button
                  className={styles.createButton}
                  variant="contained"
                  size="full-sm"
                  type="button"
                  label={action}
                  disabled={!unit.hasActiveLease && isMaintenance}
                  onClick={() =>
                    navigate(
                      isMaintenance
                        ? `${routes.assingMaintenence}?unitId=${unit._id}`
                        : `${routes.listingsCreate}/${_id}/${unit._id}`,
                      {
                        state: { propertyId: _id },
                      }
                    )
                  }
                />
              )}
              {isForLease && !unit.hasLease && (
                <Button
                  className={styles.createButton}
                  variant="contained"
                  size="full-sm"
                  type="button"
                  label="Select unit"
                  onClick={() => navigate(`${routes.lease}/${_id}/${unit._id}`)}
                />
              )}
            </div>
          </Box>
        ))}
      </Card.ExtendableContent>
    </Card>
  );
};

export default PropertyCard;
